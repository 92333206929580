






































import { defineComponent } from '@vue/composition-api';
import { IconGift } from '@/components/atoms';
import { setTimeToken, timeValue } from '@/services';
import { i18nTxt, t } from '@/commons/i18n';
export default defineComponent({
  components: {
    IconGift
  },
  props: {
    gift: Object,
    isJp: Boolean
  },
  setup(_props, { root, emit }) {
    const convertRemainedTime = (time: string): object => {
      if (+time > 86400) {
        const day = Math.floor(+time / 60 / 60 / 24);
        const renained =
          day > 1
            ? `${day} ${setTimeToken('left', timeValue[2])}`
            : `${day} ${setTimeToken('left', i18nTxt('day')).replace(
                'day',
                'Day'
              )}`;
        // n일 남음
        return {
          emph: false,
          time: !_props.isJp
            ? renained
            : `${t('left').replace('%@', '')}${setTimeToken(
                'number_of_days.other',
                day.toString()
              )}`
        };
      } else if (+time < 86400 && +time > 3600) {
        // n시간 남음
        const hour = Math.floor(+time / 60 / 60);
        const renained =
          hour > 1
            ? `${hour} ${setTimeToken('left', timeValue[1])}`
            : `${hour} ${setTimeToken('left', i18nTxt('hour')).replace(
                'hour',
                'Hour'
              )}`;
        return {
          emph: true,
          time: !_props.isJp
            ? renained
            : `${t('left').replace('%@', '')}${setTimeToken(
                'number_of_hours.other',
                hour.toString()
              )}`
        };
      } else {
        const mimute = Math.floor(+time / 60);
        return {
          emph: true,
          time: !_props.isJp
            ? `${mimute} ${setTimeToken('left', timeValue[0])}`
            : `${t('left').replace('%@', '')}${setTimeToken(
                'number_of_minutes.other',
                mimute.toString()
              )}`
        };
      }
    };
    const detail = (
      urlHttp: string,
      id: number | string,
      distributionType: string
    ) => {
      emit('emitDetail', urlHttp, id, distributionType);
    };
    const recieve = (
      id: number | string,
      distributionType: string,
      itemType: string
    ) => {
      emit('emitReceive', id, distributionType, itemType);
    };
    return {
      convertRemainedTime,
      detail,
      recieve
    };
  }
});
