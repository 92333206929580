import { ChallengeChapter } from './challenge-chapter';

export interface LastReadChapterLocator {
  href?: string;
  type?: string;
  title?: string;
  locations?: {
    position?: number;
    progression?: number;
    totalProgression?: number;
  };
}

export class ChallengeActivity {
  subscribed: boolean;
  lastReadChapterId?: number;
  lastReadChapterLocator?: LastReadChapterLocator;
  lastReadAt?: string;
  constructor(data: ChallengeActivity) {
    this.subscribed = data.subscribed;
    this.lastReadChapterId = data.lastReadChapterId;
    this.lastReadChapterLocator = data.lastReadChapterLocator;
    this.lastReadAt = data.lastReadAt;
  }
}

export interface ChallengeGenre {
  code: string;
  genreId: number;
  name: string;
  primary: boolean;
}

export class ChallengeContent {
  contentId: number;
  name: string;
  description: string;
  catchcopy: string;
  userId: number;
  coverImageFilePath: string;
  coverImageFileName: string;
  squareImageFilePath: string;
  squareImageFileName: string;
  published: boolean;
  completed: boolean;
  mature: boolean;
  suspended: boolean;
  commentEnabled: boolean;
  totalViews: number;
  totalViewsUpdatedAt: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  totalLikes: number;
  genres: ChallengeGenre[];
  primaryGenre: string;
  chapterCount: number;
  commentCount: number;
  subscribedCount: number;
  chapters: ChallengeChapter[];
  nickname: string;
  activity?: ChallengeActivity;
  subscribed: boolean;
  avatarFilePath: string;
  avatarFileName: string;

  constructor(data: ChallengeContent) {
    this.contentId = data.contentId;
    this.name = data.name;
    this.description = data.description;
    this.catchcopy = data.catchcopy;
    this.userId = data.userId;
    this.coverImageFilePath = data.coverImageFilePath;
    this.coverImageFileName = data.coverImageFileName;
    this.squareImageFilePath = data.squareImageFilePath;
    this.squareImageFileName = data.squareImageFileName;
    this.published = data.published;
    this.completed = data.completed;
    this.mature = data.mature;
    this.suspended = data.suspended;
    this.commentEnabled = data.commentEnabled;
    this.totalViews = data.totalViews;
    this.totalViewsUpdatedAt = data.totalViewsUpdatedAt;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.publishedAt = data.publishedAt;
    this.totalLikes = data.totalLikes;
    this.genres = data.genres;
    this.primaryGenre = data.primaryGenre;
    this.chapterCount = data.chapterCount;
    this.commentCount = data.commentCount;
    this.subscribedCount = data.subscribedCount;
    this.chapters = data.chapters
      ? data.chapters.map((node) => new ChallengeChapter(node))
      : [];
    this.nickname = data.nickname;
    this.activity = data.activity ? new ChallengeActivity(data.activity) : undefined;
    this.subscribed = data.subscribed;
    this.avatarFileName = data.avatarFileName;
    this.avatarFilePath = data.avatarFilePath;
  }

  getCoverImagePath(): string {
    return `${process.env.VUE_APP_CHALLENGE_IMAGES_DOMAIN}${this.coverImageFilePath}${this.coverImageFileName}`;
  }
  getSquareImagePath(): string {
    return `${process.env.VUE_APP_CHALLENGE_IMAGES_DOMAIN}${this.squareImageFilePath}${this.squareImageFileName}`;
  }
  isStatusNew(): boolean {
    const created = new Date(this.createdAt).getTime();
    const twoWeeksLater = created + 14 * 24 * 60 * 60 * 1000;
    return new Date().getTime() < twoWeeksLater;
  }
  isStatusUp(): boolean {
    return (
      new Date(this.updatedAt).toDateString() === new Date().toDateString()
    );
  }
  getAvatarImagePath(): string {
    return `${process.env.VUE_APP_CHALLENGE_IMAGES_DOMAIN}${this.avatarFilePath}${this.avatarFileName}`;
  }
  getLinkContentHome(): string {
    return `/challenge/comic/${this.contentId}`;
  }
  getSquareCoverImagePath(): string {
    const filePath = this.squareImageFilePath || this.coverImageFilePath;
    const fileName = this.squareImageFileName || this.coverImageFileName;
    return `${process.env.VUE_APP_CHALLENGE_IMAGES_DOMAIN}${filePath}${fileName}`;
  }
}
