























import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  props: {
    navList: Array,
    current: String,
    linkType: String || Number,
    isRanking: {
      type: Boolean,
      default: false
    }
  },
  setup(_props) {
    const navType = _props.isRanking ? 'ranking' : 'genre';
    return { navType };
  }
});
