import store from '@/store/index';
import { ERROR_CODES } from '@/commons/constants/error-codes';
import { ErrorInfo, SystemInfo } from '@/models/index';
import { i18nTxt } from '@/commons/i18n/index';

/**
 * api 예외 처리
 * 리턴값이 true일 경우 api 재시도
 * @param error 에러 상태
 * isErrorPop: 이중 팝업 체크
 */
const ApiException = (code: number, error: ErrorInfo): void => {
  // Bad Request Param
  if (code === ERROR_CODES.CODE_400 && !error.message) return;

  const title: string | null =
    null || i18nTxt('api_session_error_title_bad_server_response');
  const errMsg: string = error.error || i18nTxt('system_error_dialog_massage');
  if (code !== ERROR_CODES.CODE_200) {
    const isErrorPop = store.getters['ErrorStore/errorCode'];
    if (isErrorPop) return;
    store.dispatch('ErrorStore/SET_ERROR', {
      code: code,
      title: title,
      message: errMsg,
      response: error.response || ''
    });
  }
  return;
};

/** 서비스 점검중 */
const SystemException = (code: number, error: SystemInfo): void => {
  const { endAt, title, description } = error.maintenance;
  const isErrorPop = store.getters['ErrorStore/errorCode'];
  if (isErrorPop) return;
  store.dispatch('ErrorStore/SET_ERROR', {
    code: ERROR_CODES.CODE_503,
    title: title,
    message: description,
    endAt: endAt
  });
  return;
};

/** 네트워크 오류 */
const NetworkException = (): void => {
  const isErrorPop = store.getters['ErrorStore/errorCode'];
  if (isErrorPop) return;
  store.dispatch('ErrorStore/SET_ERROR', {
    code: 9999,
    title: i18nTxt('api_session_error_title_cannot_find_host'),
    message: i18nTxt('api_session_error_desc_connection_lost')
  });
  return;
};

export { ApiException, SystemException, NetworkException };
