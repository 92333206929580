














import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'LargeFillButton',
  props: {
    btnTxt: {
      type: String,
      required: true
    },
    option: {
      type: Boolean
    },
    fill: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const emitSend = () => {
      emit('emitReceive');
    };
    return {
      emitSend
    };
  }
});
