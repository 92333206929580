import Vue from 'vue';
import langJa from 'quasar/lang/ja';
// import langKo from 'quasar/lang/ko-kr';
// import langEn from 'quasar/lang/en-us';

import './styles/quasar.sass';
import 'quasar/dist/quasar.ie.polyfills';
import '@quasar/extras/material-icons/material-icons.css';
import Quasar from 'quasar/src/vue-plugin.js';import QCarousel from 'quasar/src/components/carousel/QCarousel.js';import QCarouselControl from 'quasar/src/components/carousel/QCarouselControl.js';import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';import QDialog from 'quasar/src/components/dialog/QDialog.js';import Dialog from 'quasar/src/plugins/Dialog.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';;

const getIosName = (appLang) => {
  switch (appLang) {
    case 'ko':
      return { isoName: 'ko' };
    case 'ja':
      return langJa;
    default:
      return { isoName: 'en' };
  }
};

Vue.use(Quasar, {
  config: {},
  plugins: {
    Dialog,
    Notify,
    Loading
  },
  components: {
    QCarousel,
    QCarouselControl,
    QCarouselSlide,
    QDialog
  },
  directives: {
    ClosePopup
  },
  lang: getIosName(process.env.VUE_APP_LANGUAGE)
});
