


















































import { ref, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SortBar',
  props: {
    flagEdit: Boolean,
    len: Number,
    selectedLen: Number
  },
  setup(_props, { root, emit }) {
    const router = ref(root.$router);
    const onFlagEdit = () => emit('emitFlagEdit');
    const onDelete = () => emit('emitDelete');

    return { router, onFlagEdit, onDelete };
  }
});
