







































import { ref, computed, defineComponent, watch } from '@vue/composition-api';
import Store from '@/store/index';
import { i18nTxt } from '@/commons/i18n/index';

/**
 * ex)
 * "로그인 후 이용 가능합니다. 로그인 하시겠습니까?","로그인","취소"
 * "작품이용권 5개를 받았습니다.", "닫기", "바로보기"
 * "아래 항목에 동의합니다.", "-이용약관 -개인정보 수집이용 -이벤트 정보 알림", "거부", "동의"
 */
export default defineComponent({
  name: 'UseCoinConfirmDialog',
  props: {
    componentProps: Object
  },
  setup(props, { emit }) {
    const { getters } = Store;
    const dialog = ref(false);
    const currentPath = computed(() => getters['GlobalStore/getCurrentPath']);
    const show = () => {
      dialog.value = true;
    };
    const hide = () => {
      dialog.value = false;
    };
    const onDialogHide = () => {
      emit('hide');
    };
    const onDialogClick = (args) => {
      emit(args, {
        value: true
      });
      hide();
    };
    watch(currentPath, () => {
      onDialogClick('cancel');
    });

    return {
      dialog,
      show,
      hide,
      onDialogHide,
      onDialogClick,
      i18nTxt
    };
  }
});
