import { createAxiosService } from '@/services/common/create-axios.service';
import { ChallengeSearch } from '@/models/challenge/challenge-search';
import { superEncodeURI } from '@/services/common/utils';
const axiosService = createAxiosService();

/**
 * 도전만화 검색
 */
const getChallengeSearch = (keyword: string) => {
  return new Promise<ChallengeSearch>((resolve, reject) => {
    const path = `/challenge/search?query=${superEncodeURI(
      keyword
    )}&pageNo=0&pageSize=1000`;
    return axiosService
      .post(path)
      .then((res) => {
        const { data, result } = res.data;
        if (result.code < 300) {
          resolve(new ChallengeSearch(data));
        } else {
          reject({ code: result.code });
        }
      })
      .catch(reject);
  });
};


export const ChallengeSearchService = {
  getChallengeSearch
}