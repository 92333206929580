
































import {
  Ref,
  ref,
  computed,
  defineComponent,
  watch
} from '@vue/composition-api';
import Store from '@/store/index';
import { keyupOnlyNumber } from '@/services/common/utils';

export default defineComponent({
  name: 'TextBase',
  props: {
    formData: String,
    errorData: String,
    inputName: String,
    labels: String,
    maxLen: Number,
    isNext: Boolean,
    isDissabled: Boolean,
    isOnlyNumber: Boolean,
    placeholder: String
  },
  setup(props, { emit }) {
    const { getters } = Store;
    const inputRef: Ref<string> = ref('');
    const initState = computed(() => getters['AuthStore/getResetForm']);

    const onlyNumber = ({ target }) => {
      inputRef.value = keyupOnlyNumber(target);
    };

    const resetInput = () => {
      inputRef.value = '';
    };

    watch(inputRef, (data) => {
      data ? (inputRef.value = data) : inputRef.value === '';
      emit('formData', { inputName: props.inputName, value: inputRef.value });
    });

    watch(initState, (data) => {
      if (data) {
        inputRef.value = '';
      }
    });
    return { emit, inputRef, onlyNumber, resetInput };
  }
});
