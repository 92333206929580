import { ChallengeChapter } from '@/models/challenge/challenge-chapter';
import { ChallengeContent } from '@/models/challenge/challenge-content';
import { createAxiosService } from '@/services/common/create-axios.service';
const axiosService = createAxiosService();

export interface ChallengeChapterResponse {
	chapter: ChallengeChapter;
	content: ChallengeContent;
}

/**
 * 도전만화 - 챕터
 * @param contentId 
 * @returns 
 */
const getChallengeChapter = ({ contentId, chapterId }: { contentId: number, chapterId: number }) => {
	return new Promise<ChallengeChapterResponse>((resolve, reject) => {
		const path = `/challenge/content/${contentId}/${chapterId}`;
		return axiosService.get(path).then(res => {
			const { data, result } = res.data;
			try {
				resolve({
					chapter: new ChallengeChapter(data.chapter),
					content: new ChallengeContent(data.content)
				});
			} catch(e) {
				reject(result);
			}
		})
			.catch(reject);
	})
};

const likeChapter = ({ contentId, chapterId }: { contentId: number, chapterId: number }) => {
	return new Promise<any>((resolve, reject) => {
		// const path = `/content/${contentId}/${chapterId}/like`;
		const path = `/challenge/content/${contentId}/${chapterId}/like`;
		return axiosService.post(path).then(res => {
			const { data, result } = res.data;
			if(result.code < 300) {
				resolve(data);
			} else {
				reject(result);
			}
		})
			.catch(reject);
	})
}

const reportChapter = ({ contentId, chapterId, category }: { contentId: number, chapterId: number, category: string }, remarksDetail: string | undefined) => {
	return new Promise<any>((resolve, reject) => {
		const path = `/challenge/content/${contentId}/${chapterId}/report/${category}`;
		const params = new URLSearchParams();
		params.append('remarksDetail', remarksDetail ? remarksDetail : '');
		return axiosService.post(path, params).then(res => {
			const { data, result } = res.data;
			if(result.code < 300) {
				resolve(data);
			} else {
				reject(result);
			}
		})
			.catch(reject);
	})
};


const read = ({ contentId, chapterId }: { contentId: number, chapterId: number }) => {
	return new Promise<any>((resolve, reject) => {
		const path = `/challenge/content/${contentId}/${chapterId}/read`;
		return axiosService.post(path).then(res => {
			const { data, result } = res.data;
			if(result.code < 300) {
				resolve(data);
			} else {
				reject(result);
			}
		})
			.catch(reject);
	})
}

export const ChallengeChapterService = {
	getChallengeChapter,
	likeChapter,
	reportChapter,
	read
}