
















import {
  computed,
  defineComponent,
  onUnmounted,
  onMounted,
  PropType,
  ref
} from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import { MenuItem } from '@/models';
import { sendNclick } from '@/services';

export default defineComponent({
  name: 'HomeMenuItems',
  props: {
    items: {
      type: Array as PropType<MenuItem[]>,
      required: true
    }
  },
  setup(props) {
    const { getters } = store;
    const justifyContent = ref('center');
    const homeMenuList = ref();
    const homeMenuListItem = ref();
    const contentCategory = computed(
      () => getters['HomeStore/contentCategory']
    );

    const onMenuClick = async (menuItem: MenuItem) => {
      sendNclick('home', 'home.menu', '', '', menuItem.code);
      router.push(menuItem.apiPath).catch(() => {});
    };

    const isAvailableBadge = (menuItem: MenuItem) => {
      if (menuItem.badge == undefined) {
        return false;
      }
      const badge = menuItem.badge;
      if (badge.type == undefined || badge.type === 'none') {
        return false;
      }
      if (badge.label == undefined || badge.label.length == 0) {
        return false
      }
      return true;
    };

    const badgeBGClass = (menuItem: MenuItem) => {
      const badgeType = menuItem.badge?.type ?? '';
      switch (badgeType) {
        case 'primary':
          return 'bg_primary';
        case 'event':
          return 'bg_event';
        case 'challenge':
          return 'bg_challenge';
        default:
          return 'bg_primary';
      }
    };

    const badgeLabel = (menuItem: MenuItem) => menuItem.badge?.label ?? '';

    const resize = ()=>{
      const _innerWidth = window.innerWidth;
      const _homeMenuListItem = homeMenuListItem.value as HTMLLIElement[];

      if(_innerWidth >= 768){
        let _width = 0;
        _homeMenuListItem.forEach(dom=>{
          _width = _width + dom.clientWidth + 56;
        });

        if(_width > _innerWidth){
          justifyContent.value = 'flex-start';
        }else{
          justifyContent.value = 'center';
        }
      }else{
        justifyContent.value = 'flex-start';
      }
    };

    onUnmounted(() => {
      window.removeEventListener('resize', resize);
    });

    onMounted(()=>{
      resize();
      window.addEventListener('resize', resize);
    });

    return {
      isAvailableBadge,
      badgeBGClass,
      badgeLabel,
      contentCategory,
      onMenuClick,
      justifyContent,
      homeMenuListItem
    };
  }
});
