import { Module } from 'vuex';
import { RootState } from '@/store/index';

export interface UIStateInterface {
	windowWidth: number;
	topslideHeight: number;
	headerHeight: number;
	headerBackground: string;
}

export const UIStore: Module<UIStateInterface, RootState>  = {
	namespaced: true,
	state: {
		windowWidth: window.innerWidth,
		topslideHeight: 0,
		headerHeight: 0,
		headerBackground: '#000'
	},
	getters: {
		windowWidth(state) {
			return state.windowWidth;
		},
		isWide(state) {
			return (state.windowWidth > 767)
		},
		topslideHeight(state) {
			return state.topslideHeight;
		},
		headerHeight(state) {
			return state.headerHeight;
		},
		headerBackground: state => state.headerBackground
	},
	mutations: {
		windowWidth(state, value: number) {
			state.windowWidth = value;
		},
		topslideHeight(state, value: number) {
			state.topslideHeight = value;
		},
		headerHeight(state, value: number) {
			state.headerHeight = value;
		},
		headerBackground(state, value: string) {
			state.headerBackground = value;
		}
	}
}