import { integrateApi } from '@/services/integrate/integrate.api';
import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { idpParamsInterface, PreviousComico } from '@/models';
import { HttpResponse } from '@/commons/constants/http.types';

export interface IntegrateStateInterface {
  previousComico: PreviousComico;
  isSignin: boolean;
}

export const IntegrateStore: Module<IntegrateStateInterface, RootState> = {
  namespaced: true,
  state: {
    previousComico: {
      cno: 0,
      preCoin: 0,
      coin: 0
    },
    isSignin: true
  },
  getters: {
    previousComico: (state) => state.previousComico
  },
  mutations: {
    setPreviousComico: (state, { previousComico }) =>
      (state.previousComico = previousComico)
  },
  actions: {
    getMigration: async () => {
      return integrateApi
        .getMigration()
        .then((res: HttpResponse) => Promise.resolve(res))
        .catch((err: HttpResponse) => Promise.reject(err));
    },
    getExternalIdpUrl: async ({ commit }, loginType: string) => {
      return integrateApi
        .getExternalIdpUrl(loginType)
        .then((res: HttpResponse) => res.data.authorizeUrl);
    },
    signIn: async ({ commit }, params: idpParamsInterface) => {
      return integrateApi
        .signIn(params)
        .then((res: HttpResponse) => {
          commit('setPreviousComico', res);
          return Promise.resolve(res);
        })
        .catch((err: HttpResponse) => {
          return Promise.reject(err);
        });
    },
    migration: async ({ commit }, cno: number) => {
      return integrateApi
        .migration(cno)
        .then((res: HttpResponse) => {
          return Promise.resolve(res);
        })
        .catch((err: HttpResponse) => {
          return Promise.reject(err);
        });
    }
  }
};
