






































import {
  computed,
  defineComponent,
  onUnmounted,
  ref
} from '@vue/composition-api';
import Store from '@/store/index';
import NormalSection from '@/components/molecules/sections/NormalSection.vue';
import RankingSection from '@/components/molecules/sections/RankingSection.vue';
import BannerImageSection from '@/components/molecules/sections/BannerImageSection.vue';
import BannerListSection from '@/components/molecules/sections/BannerListSection.vue';
import BannerSwipeSection from '@/components/molecules/sections/BannerSwipeSection.vue';

export default defineComponent({
  name: 'HomeContentModule',
  components: {
    NormalSection,
    RankingSection,
    BannerImageSection,
    BannerListSection,
    BannerSwipeSection
  },
  setup() {
    const { getters } = Store;
    const sections = computed(() => getters['HomeStore/sections']);

    return {
      sections
    };
  }
});
