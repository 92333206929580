




































import {
  Ref,
  ref,
  toRefs,
  reactive,
  ComputedRef,
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  watch,
  watchEffect
} from '@vue/composition-api';
import Store from '@/store/index';
import router from '@/router/index';
import GlobalHeader from '@/components/organisms/headers/GlobalHeader.vue';
import ContentCategoryParts from '@/components/molecules/header-parts/ContentCategoryParts.vue';
import SearchSection from '@/components/molecules/sections/SearchSection.vue';
import IconParts from '@/components/molecules/header-parts/IconParts.vue';
import ContentListModuleTag from '@/components/organisms/contents-list/ContentListModuleTag.vue';
import DeleteButton from '@/components/atoms/buttons/DeleteButton.vue';
import { ContentInfo, ContentsItemInfo, HeaderUI } from '@/models';
import { isPC, ClassesHeader } from '@/services/common/utils';
import { getKeywords, setKeywords, superEncodeURI } from '@/services';

export default defineComponent({
  name: 'SearchControlModule',
  components: {
    GlobalHeader,
    ContentCategoryParts,
    IconParts,
    SearchSection,
    ContentListModuleTag,
    DeleteButton
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.q) Store.commit('SearchStore/setKeyword', to.params.q);
    next();
  },
  setup(props, { root }) {
    const { getters, dispatch, commit } = Store;
    const route = root.$route;
    const { q } = route.params;
    const invisibleResult = ref(false);
    const initFocus = computed(() => getters['SearchStore/getInitFocus']);
    const isSearchPopup: ComputedRef<boolean> = computed(
      () => getters['GlobalStore/getIsSearchPopup']
    );
    const userKeyword: ComputedRef<string> = computed(
      () => getters['SearchStore/getKeyword']
    );
    const searchItems: ComputedRef<ContentsItemInfo> = computed(
      () => getters['SearchStore/getSearchItems']
    );
    const keywords: Ref<string[]> = ref<string[]>([]);
    const searchInput = ref(null);

    /** 검색바 포커스 in/out에 따른 UI 처리 */
    const focusIn = (isFocus: boolean) => {
      keywords.value = getKeywords('keywords');
      invisibleResult.value = isFocus || true;
    };
    const focusOut = (isFocus: boolean) => {
      ClassesHeader('', 'header_search');
      if (invisibleResult.value) {
        invisibleResult.value = isFocus || false;
      } else {
        // 모바일 UI의 화면 닫기 버튼
        router.push('/').catch(() => {
          //
        });
      }
    };

    /** 검색 버튼 이벤트 */
    const onEnter = async (keyword: string) => {
      try {
        if (!keyword || keyword.length === 0) return;
        setKeywords('keywords', userKeyword.value, 10);
        commit('SearchStore/setKeyword', null);
        router.push(`/search/${superEncodeURI(keyword)}`).catch((error) => {
          //if (error.name === 'NavigationDuplicated') return;
        });
      } catch (e) {
        // console.log(e);
      }
    };

    /** 검색어 삭제 */
    const onDelete = (idx: number) => {
      keywords.value.splice(idx, 1);
      localStorage.setItem('keywords', keywords.value.join(','));
    };

    watch(
      () => root.$route.params,
      (params) => {
        try {
          if (!q) commit('SearchStore/setKeyword', params.q.toString());
        } catch (e) {
          //  console.log('watch(() => root.$route.params e:', e);
        }
      }
    );

    watch(userKeyword, async () => {
      if (!userKeyword.value || userKeyword.value.length === 0) return;
      onEnter(userKeyword.value);
    });

    watch(isSearchPopup, (res) => {
      if (!res) {
        focusOut(false);
        commit('GlobalStore/setIsSearchPopup', null);
      }
    });

    return {
      superEncodeURI,
      invisibleResult,
      isPC,
      userKeyword,
      keywords,
      searchInput,
      searchItems,
      focusIn,
      focusOut,
      onEnter,
      onDelete
    };
  }
});
