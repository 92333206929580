import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { LibraryContentsService } from '@/services/index';
import { ContentsItemInfo } from '@/models';

export interface ContentDeleteInfo {
  idx: number;
  contentType: string;
  contentId: string;
}

export interface OrderInfo {
  subscribed: boolean;
  unlocked: boolean;
}

export interface LibraryStateInterface {
  init: boolean;
  contentDel: boolean;
  contentTypeNext: string | undefined;
  contentTypeCurrent: string | undefined;
  contentDelete: ContentDeleteInfo | undefined;
  listLibrary: ContentsItemInfo;
  orderTypes: OrderInfo;
  selectToggleState: boolean;
}

/**
 * LibraryStore
 */
export const LibraryStore: Module<LibraryStateInterface, RootState> = {
  namespaced: true,
  state: {
    init: false,
    contentDel: false,
    contentTypeNext: undefined,
    contentTypeCurrent: undefined,
    contentDelete: {} as ContentDeleteInfo,
    listLibrary: {} as ContentsItemInfo,
    orderTypes: {
      subscribed: false,
      unlocked: false
    } as OrderInfo,
    selectToggleState: false
  },
  getters: {
    getState: (state) => state,
    getInitState: (state) => state.init,
    getContentDel: (state) => state.contentDel,
    getContentTypeNext: (state) => state.contentTypeNext,
    getContentTypeCurrent: (state) => state.contentTypeCurrent,
    getContentDelete: (state) => state.contentDelete,
    getLibrary: (state) => state.listLibrary,
    getOrderTypes: (state) => state.orderTypes,
    getSelectState: (state) => state.selectToggleState
  },
  mutations: {
    init(state, value: boolean) {
      state.init = value;
    },
    contentDel(state, value: boolean) {
      state.contentDel = value;
    },
    contentTypeNext(state, value: string) {
      state.contentTypeNext = value;
    },
    contentTypeCurrent(state, value: string) {
      state.contentTypeCurrent = value;
    },
    contentDelete(state, value: ContentDeleteInfo) {
      state.contentDelete = value;
      state.listLibrary.page.totalElements -= 1;
    },
    setLibrary(state, value: ContentsItemInfo) {
      state.listLibrary = value;
    },
    setOrderTypes(
      state,
      { contentType, order }: { contentType: string; order: string }
    ) {
      state.orderTypes[contentType] = order;
    },
    setFilterToggle(state, value: boolean) {
      typeof value === 'boolean'
        ? (state.selectToggleState = value)
        : (state.selectToggleState = !state.selectToggleState);
    }
  },
  actions: {
    init({ commit }, param) {
      commit('init', param);
    },
    contentTypeNext({ commit }, param) {
      commit('contentTypeNext', param);
    },
    contentTypeCurrent({ commit }, param) {
      commit('contentTypeCurrent', param);
    },
    contentDelete({ commit }, param) {
      commit('contentDelete', param);
    },
    async setListLibrary({ commit }, param) {
      let data;
      if ('hidden_unlocked_contents' === param.contentCategory) {
        data = await LibraryContentsService.getLibraryContentsHidden(param);
      } else {
        data = await LibraryContentsService.getLibraryContents(param);
      }
      commit('setLibrary', data);
      return data;
    }
  }
};
