




















































import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import CardThumbnail from '@/components/molecules/contents-elements/CardThumbnail.vue';
import IconAge from '@/components/atoms/icons/IconAge.vue';
import { Badge, BadgeHiatus } from '@/components/atoms';
import { ContentInfo } from '@/models/content-info';
import { gotoContentHome, createContentPath } from '@/services/routing/routing.helper';
import { MenuHolder } from '@/models';
import { isPC } from '@/services/common/utils';

export default defineComponent({
  name: 'BasicContentCard',
  components: {
    Badge,
    BadgeHiatus,
    CardThumbnail,
    IconAge
  },
  props: {
    content: Object,
    onlyThumb: Boolean,
    menuHolder: Object,
    isContentType: Boolean,
    selectedItem: String
  },
  setup(props, { root }) {
    const contentInfo: ComputedRef<ContentInfo> = computed(
      () => props.content as ContentInfo
    );
    const menu: ComputedRef<MenuHolder> = computed(
      () => props.menuHolder as MenuHolder
    );
    const completedBadgeCondition = computed(() => {
      const { status, badge } = contentInfo.value;
      if (menu.value && menu.value.code === 'completed') {
        if (status === 'completed') {
          return badge ? false : true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    const contentTypeStr: ComputedRef<string> = computed(() => {
      const isTag =
        props.isContentType != undefined ? props.isContentType : true;
      if (isTag) {
        switch (contentInfo.value.type) {
          case 'novel':
          case 'magazine_novel':
            return root.$t('novels') + '';
          case 'comic':
          case 'magazine_comic':
          default:
            return '';
        }
      } else {
        return '';
      }
    });

    const onContentClick = () => {
      gotoContentHome({
        contentType: contentInfo.value.type,
        contentId: contentInfo.value.id
      });
    };

    return {
      contentInfo,
      contentTypeStr,
      completedBadgeCondition,
      gotoContentHome,
      onContentClick,
      createContentPath,
      isPC
    };
  }
});
