import { i18n, i18nWrapRuby } from '@/commons/i18n/index';

export const translations = (
  keys: Object | undefined,
  plural: number | string
): string => {
  if (!keys) return '';

  return String(plural).length === 1
    ? keys['one'] || keys['other']
    : keys['other'] || keys['one'];
};

const numberOf = (key: string, value: number): string => {
  let keys = i18n.t(key);
  if (!keys) return '';

  let translation;
  if (value == 1) {
    translation = keys['one'] || keys['other'];
  } else {
    translation = keys['other'] || keys['one'];
  }
  return i18nWrapRuby(translation, value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
};

export const PluralHelperService = {
  translations, numberOf
};
