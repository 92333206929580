import { FriendInvitationResponse } from '@/models';

import { createAxiosService } from '@/services/common/create-axios.service';
const axiosService = createAxiosService();

const getFriendInvitation = async (
  id: string
): Promise<FriendInvitationResponse> => {
  // //const url = `/anniversary/10th/friend_invitation`;
  // const url = `/friend_invitation/spring24`;
  const url = `/friend_invitation/${id}`;
  return await axiosService.get(url).then((res) => {
    const {
      data: { data }
    } = res;
    return data;
  });
};

export const invitationApi = {
  getFriendInvitation
};
