




import { defineComponent, computed } from '@vue/composition-api';
import { i18nTxt } from '@/commons/i18n';

export default defineComponent({
  name: 'EditButton',
  props: {
    editItem: Boolean
  },
  setup(props, { emit }) {
    const label = computed(() =>
      i18nTxt(`${props.editItem ? 'done' : 'edit'}`)
    );

    return {
      emit,
      label
    };
  }
});
