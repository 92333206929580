








import store from '@/store';
import { getAgeGroup } from '@/services';
import { computed, ComputedRef, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SerialInfo',
  props: {
    serialInfo: String,
    usageLevel: Number
  },
  setup(props, { root }) {
    const { getters } = store;
    const age = computed(() => props.usageLevel);
    const label = computed(() => {
      if (root['$isKo']) {
        const num = getAgeGroup(age.value);
        if (num) {
          return root.$t(`restricted_age_${num}`);
        } else {
          return '';
        }
      } else {
        return '';
      }
    });
    const isWide: ComputedRef<boolean> = computed(
      () => getters['UIStore/isWide']
    );

    const clsName = computed(() => {
      const fin = root.$t('COMPLETED');
      if (props.serialInfo === fin) {
        return isWide.value ? '' : 'emph_end';
      } else {
        return 'txt_day';
      }
    });
    const publishDay = computed(() => props.serialInfo);

    return {
      label,
      clsName,
      publishDay,
      age
    };
  }
});
