









import { defineComponent } from '@vue/composition-api';
import { getForwardPath } from '@/services/contents/contents.helper';

export default defineComponent({
  name: 'GenreThumbnail',
  props: {
    element: Object
  },
  setup(props, context) {
    const gotoGenre = () => context.emit('gotoGenre', props.element);
    return {
      gotoGenre,
      getForwardPath
    };
  }
});
