import axios, { AxiosInstance } from 'axios';
import { setInterceptors } from '@/services/common/inspectors';
import { InspectorHelperService } from '@/services/common/inspector.helper';

const options = {
  baseURL:
    process.env.VUE_APP_PHASE === 'LOCAL'
      ? ''
      : process.env.VUE_APP_GLOBAL_API_URL,
  withCredentials: true
};
const axiosService = axios.create(options);
const newAxiosService = axios.create(options);

/**
 * 인터셉터 설정
 * 기본 헤더 설정을 인터셉터를 통해서 설정 함
 */
export const createAxiosService = (): AxiosInstance => {
  return setInterceptors(axiosService);
};

export const newCreateAxiosService = (): AxiosInstance => {
  newAxiosService.interceptors.request.use(async (config) => {
    InspectorHelperService.setReturnYn(config.url, 'Y');
    return config;
  });
  return setInterceptors(newAxiosService);
};
