


























import { defineComponent } from '@vue/composition-api';
import IconTypeSpan from '@/components/atoms/icons/IconTypeSpan.vue';
import { ICON_RENTAL_ONLY } from '@/commons/constants/icon-types';

export default defineComponent({
  name: 'IconCoin',
  components: {
    IconTypeSpan
  },
  props: {
    price: Number,
    originalPrice: Number,
    rentalPrice: Number,
    originalRentalPrice: Number,
    rentalOnly: Boolean
  },
  setup() {
    return {
      ICON_RENTAL_ONLY
    };
  }
});
