import { Chapter, ContentInfo } from "./content-info";
import { Popup, Section } from "./home-info";

export class Promotion {
	category?: string;
	expireRemained?: number;
	title?: string;
	constructor(props: Promotion) {
		this.category = props.category;
		this.expireRemained = props.expireRemained;
		this.title = props.title;
	}
}

export interface Banner {
	id?: number;
	area?: string;
	page?: string;
	uiType?: string;
	category?: string;
	title?: string;
	description?: string;
	themeColor?: string;
	htmlUrl?: string;
	imgUrl?: string;
	width?: number;
	height?: number;
	appLinkUrl?: string;
	webLinkUrl?: string;
	expireRemained?: number;
}

export class ContentModel {
	content: ContentInfo | null;
	constructor(props: ContentModel) {
		this.content = props.content ? new ContentInfo(props.content) : null;
	}
}

export class Contents extends ContentModel {
	private _name: 'EPISODE' | 'VOLUME';
	banners?: Banner[];
	popups?: Popup[];
	promotions?: Promotion[];
	recommends?: Section[];
	constructor(name: 'EPISODE' | 'VOLUME', props: Contents) {
		super(props);
		this._name = name;
		this.banners = props.banners ? props.banners : [];
		this.popups = props.popups ? props.popups.map(node => new Popup(node)) : [];
		this.promotions = props.promotions ? props.promotions.map(node => new Promotion(node)) : [];
		this.recommends = props.recommends ? props.recommends.map(node => new Section(node)) : [];
	}
	isContent(): boolean {
		return this.content ? true : false;
	}
	get name(): string {
		return this._name;
	}
	isExist(): boolean {
		return this.content ? true : false;
	}
	getContent(): ContentInfo | null {
		return this.content;
	}
}

export class ContentsHomeInfo {

	episode: Contents;
	volume: Contents;

	constructor(props: ContentsHomeInfo) {
		this.episode = new Contents('EPISODE', props.episode);
		this.volume = new Contents('VOLUME', props.volume);
	}
}

export class CheckedChapter {
	checked: boolean;
	disabled: boolean;
	chapter: Chapter;
	constructor(chapter: Chapter, checked?: boolean) {
		this.checked = checked ? checked : false;
		this.chapter = chapter;
		this.disabled = chapter.activity?.unlocked ? true : false;
	}
}