





import { getAgeGroup } from '@/services';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'IconAge',
  props: {
    restrictedAge: Number
  },
  setup(props, { root }) {
    const age = computed(() => props.restrictedAge);
    const clsName = computed(() => {
      if (root['$isKo']) {
        const num = getAgeGroup(age.value);
        if (num) {
          return root.$t(`ico_age_${num}`);
        } else {
          return '';
        }
      } else {
        return '';
      }
    });

    const label = computed(() => {
      if (root['$isKo']) {
        const num = getAgeGroup(age.value);
        if (num) {
          return root.$t(`restricted_age_${num}`);
        } else {
          return '';
        }
      } else {
        return '';
      }
    });

    return {
      clsName,
      label
    };
  }
});
