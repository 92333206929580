import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { NovelContentsTable } from '@/models/viewer-info';
import { ChaptersApiService } from '@/services/contents/chapters.api';
import { ViewerInfo } from '@/models/viewer-info';
import { commentApi } from '@/services/comment/comment.api';
import { Banner, ChapterImage, Section } from '@/models';
import {
  SWITCHING_CONTROLUI_INTERVAL,
  TOOLTIP_DURATION
} from '@/commons/constants/viewer-types';
import router from '@/router';
import { getErrorDialogOptions } from '@/services';
import { ERROR_CODES } from '@/commons/constants/error-codes';

export interface NovelViewerStoreInterface {
  progress: number;
  navMap: Array<NovelContentsTable>;
  isShow: boolean;
  isSettingShow: boolean;
  viewerInfo: ViewerInfo | null;
  hasTrial: boolean;
  chapterId: number | null;
  contentType: string;
  contentId: number | null;
  isControlUi: boolean;
  commentsLen: number;
  images: ChapterImage[];
  recommends: Section[];
  isSubscribed: boolean;
  banners: Banner[];
  isTooltip: boolean;
  isNotification: boolean;
  pageMode: 'vertical' | 'horizontal' | null;
  previewInfo: Object;
}

export const NovelViewerStore: Module<NovelViewerStoreInterface, RootState> = {
  namespaced: true,
  state: {
    progress: 0,
    navMap: [],
    isShow: true,
    isSettingShow: false,
    viewerInfo: null,
    hasTrial: false,
    chapterId: null,
    contentType: '',
    contentId: null,
    isControlUi: true,
    commentsLen: 0,
    images: [],
    recommends: [],
    isSubscribed: false,
    banners: [],
    isTooltip: false,
    isNotification: false,
    pageMode: null,
    previewInfo: {}
  },
  getters: {
    progress: (state) => state.progress,
    navMap: (state) => state.navMap,
    isShowController: (state) => state.isShow,
    isSettingShow: (state) => state.isSettingShow,
    viewerInfo: (state) => state.viewerInfo,
    content: (state) => state.viewerInfo?.content,
    chapter: (state) => state.viewerInfo?.chapter,
    contentType: (state) => state.contentType,
    contentId: (state) => state.contentId,
    chapterId: (state) => state.chapterId,
    isControlUi: (state) => state.isControlUi,
    commentsLen: (state) => state.commentsLen,
    images: (state) => state.images,
    recommends: (state) => state.recommends,
    isSubscribed: (state) => state.isSubscribed,
    banners: (state) => state.banners,
    isTooltip: (state) => state.isTooltip,
    pageMode: (state) => state.pageMode,
    previewInfo: (state) => state.previewInfo
  },
  mutations: {
    progress(state, value: number) {
      state.progress = value;
    },
    navMap(state, value: Array<NovelContentsTable>) {
      state.navMap = value;
    },
    isShowController(state, value: boolean) {
      state.isShow = value;
    },
    isSettingShow(state, value: boolean) {
      state.isSettingShow = value;
    },
    viewerInfo(state, value: ViewerInfo) {
      state.viewerInfo = value;
    },
    hasTrial(state, value: boolean) {
      state.hasTrial = value;
    },
    chapterId(state, value: number) {
      state.chapterId = value;
    },
    contentType(state, value: string) {
      state.contentType = value;
    },
    contentId(state, value: number) {
      state.contentId = value;
    },
    isControlUi: (state, value: boolean) => {
      state.isControlUi = value;
    },
    commentsLen: (state, value: number) => {
      state.commentsLen = value;
    },
    images: (state, value: ChapterImage[]) => {
      state.images = value;
    },
    recommends(state, value: Section[]) {
      state.recommends = value;
    },
    isSubscribed(state, value: boolean) {
      state.isSubscribed = value;
    },
    banners(state, value: Banner[]) {
      state.banners = value;
    },
    isTooltip(state, value: boolean) {
      state.isTooltip = value;
    },
    pageMode(state, value: 'vertical' | 'horizontal') {
      state.pageMode = value;
    },
    setPreviewInfo(state, value) {
      state.previewInfo = value;
    }
  },
  actions: {
    async initChapter({ commit, dispatch }, params) {
      try {
        const { contentType, contentId, chapterId } = params;
        const chapterResult = await ChaptersApiService.getChapter(params);
        const commentResult = await commentApi.getChapterComment({
          contentType,
          contentId,
          chapterId,
          orderType: 'likes'
        });
        const viewerInfo = new ViewerInfo(chapterResult);

        commit('navMap', []);
        commit('contentType', params.contentType);
        commit('contentId', +params.contentId);
        commit('chapterId', +params.chapterId);
        commit('viewerInfo', viewerInfo);
        commit('recommends', viewerInfo.recommends);
        commit('banners', viewerInfo.banners);
        commit('images', viewerInfo.chapter.images);
        commit('commentsLen', commentResult.page?.totalElements);
        commit(
          'pageMode',
          viewerInfo.content?.orientation === 'TTB' ? 'vertical' : 'horizontal'
        );

        commit(
          'isSubscribed',
          viewerInfo.content?.activity &&
            viewerInfo.content?.activity.subscribed
            ? viewerInfo.content?.activity.subscribed
            : false
        );
        return { epub: viewerInfo.chapter.epub, mature: viewerInfo.content?.mature };
      } catch (e: any) {
        const { code } = e.result;
        switch (code) {
          case ERROR_CODES.CODE_404:
          case ERROR_CODES.CODE_303:
            const options = getErrorDialogOptions({
              message: e.result.message || e.result.debug
            });
            dispatch('DialogStore/dialog', options, { root: true }).then(() => {
              router.push('/').then(() => {
                location.reload();
              });
            });
            return;
          case ERROR_CODES.CODE_428:
          case ERROR_CODES.CODE_451:
          case ERROR_CODES.CODE_401:
            return { code };
        }
      }
    },
    showControlUi({ commit }) {
      commit('isControlUi', true);
      setTimeout(() => {
        commit('isControlUi', false);
      }, SWITCHING_CONTROLUI_INTERVAL);
    },
    showTooltip({ commit }) {
      commit('isTooltip', true);
      setTimeout(() => {
        commit('isTooltip', false);
      }, TOOLTIP_DURATION);
    },
    changePageMode({ state, commit }) {
      const value = state.pageMode === 'vertical' ? 'horizontal' : 'vertical';
      commit('pageMode', value);
    },
    async getPreviewInfo(
      { commit },
      previewInfo: {
        params: {
          contentType: string;
          contentId: number;
        };
        query: {
          languageId: number;
          chapterId: number;
          expiredAt: string;
          checkSum: string;
          chapterFileSalesType: string;
        };
      }
    ) {
      return await ChaptersApiService.getPreviewInfo(previewInfo).then(
        (res) => {
          commit('navMap', []);
          commit('contentType', previewInfo.params.contentType);
          commit('contentId', +previewInfo.params.contentId);
          commit('chapterId', +previewInfo.query.chapterId);
          commit('pageMode', 'vertical');
          commit('setPreviewInfo', res);
          return res;
        }
      );
    }
  }
};
