


















import { defineComponent } from '@vue/composition-api';
import { i18nTxt } from '@/commons/i18n';

export default defineComponent({
  name: 'SafariShortcut',
  setup(props, { emit }) {
    const shortcutTxtObj = i18nTxt('safari_shortcut');
    const shortcutTxt = shortcutTxtObj.split('|');

    const safariShortcut_close = () => {
      emit('closeShortcut');
    };

    return {
      shortcutTxt,
      safariShortcut_close
    };
  }
});
