import { idpParamsInterface } from '@/models';
import { newCreateAxiosService } from '@/services/common/create-axios.service';
import { HttpResponse } from '@/commons/constants/http.types';
import { ERROR_CODES } from '@/commons/constants/error-codes';

const axiosService = newCreateAxiosService();
const config = {
  headers: {
    'content-type': 'application/x-www-form-urlencoded'
  }
};

const getMigration = async (): Promise<HttpResponse> => {
  const url = `/precomico`;
  return axiosService.get(url).then((res) => {
    const response: HttpResponse = res.data;
    return response.result.code === ERROR_CODES.CODE_200
      ? Promise.resolve(response.data)
      : Promise.reject({
          result: response.result,
          data: response.data
        });
  });
};

const getExternalIdpUrl = async (loginType: string): Promise<HttpResponse> => {
  const url = `/precomico/${loginType}/authorize-url`;
  return axiosService.get(url).then((res) => {
    const { data } = res;
    return data;
  });
};

const signIn = async (params: idpParamsInterface): Promise<HttpResponse> => {
  const url = `/precomico/${params.loginType}/signin`;
  return axiosService.post(url, params.form, config).then((res) => {
    const response: HttpResponse = res.data;
    return response.result.code === ERROR_CODES.CODE_200
      ? Promise.resolve(response.data)
      : Promise.reject({
          result: response.result,
          data: response.data
        });
  });
};

const migration = async (cno: number): Promise<HttpResponse> => {
  const url = `/precomico/migration/${cno}`;
  return axiosService.post(url).then((res) => {
    const response: HttpResponse = res.data;
    return response.result.code === ERROR_CODES.CODE_200
      ? Promise.resolve(response.data)
      : Promise.reject({
          result: response.result,
          data: response.data
        });
  });
};

export const integrateApi = {
  getMigration,
  getExternalIdpUrl,
  signIn,
  migration
};
