












import { defineComponent } from '@vue/composition-api';
import { IconBadge } from '@/components/atoms';
import InboxThumb from './InboxThumb.vue';
export default defineComponent({
  components: {
    IconBadge,
    InboxThumb
  },
  props: {
    gift: Object
  },
  setup() {
    return {};
  }
});
