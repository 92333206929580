var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":typeof _vm.contentInfo === 'undefined' ? '' : _vm.createContentPath(_vm.contentInfo.type, _vm.contentInfo.id)},on:{"click":[function($event){$event.stopPropagation();return _vm.onContentClick($event)},function($event){$event.preventDefault();}]}},[_c('card-thumbnail',{attrs:{"content":_vm.contentInfo}}),(typeof _vm.selectedItem !== 'undefined' && _vm.selectedItem === 'unlocked')?_c('p',{staticClass:"title-name"},[_vm._v(_vm._s(_vm.contentInfo.name))]):_vm._e(),(!_vm.onlyThumb)?_c('div',{staticClass:"thumb_info",class:{
      thumb_event: _vm.contentInfo.status === 'event'
    }},[_c('div',{staticClass:"tit_info",class:{
        tit_info_type:
          _vm.contentInfo.restrictedAge &&
          (_vm.contentInfo.badge ||
            _vm.contentInfo.status === 'event' ||
            _vm.contentInfo.status === 'started' ||
            _vm.contentInfo.status === 'updated' ||
            _vm.contentInfo.status === 'completed')
      }},[(_vm.$isKo && _vm.contentInfo.restrictedAge)?_c('icon-age',{attrs:{"restrictedAge":_vm.contentInfo.restrictedAge}}):_vm._e(),_c('strong',{staticClass:"tit_comic",class:{
          emph_new: _vm.contentInfo.status === 'started',
          emph_up: _vm.contentInfo.status === 'updated',
          emph_hiatus: _vm.contentInfo.status === 'suspend',
        }},[_vm._v(_vm._s(_vm.contentInfo.name))])],1),(!_vm.completedBadgeCondition)?_c('badge',{attrs:{"badge":_vm.contentInfo.badge,"status":_vm.contentInfo.status}}):_vm._e(),_c('span',{staticClass:"txt_info"},[_vm._v(_vm._s(_vm.contentTypeStr)),(_vm.contentTypeStr)?_c('span',{staticClass:"txt_bar"}):_vm._e(),_vm._v(_vm._s(_vm.contentInfo.getGenreToStr()))]),(_vm.contentInfo.badge && _vm.contentInfo.badge.type === 'none')?_c('div',{staticClass:"numviews"},[_vm._v(" "+_vm._s(_vm.contentInfo.getTotalView(_vm.$locale))+" ")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }