import { inboxApi } from '@/services/inbox/inbox.api';
import { RootState } from '@/store/index';
import { InboxGift, InboxMessage } from '@/models/inbox-info';
import { Module } from 'vuex';

interface InboxStateInterface {
  gifts: InboxGift[];
  messages: InboxMessage[];
  newMissionAvailable: boolean;
  current: string;
  impendingCount: number;
  receivable: boolean;
}

export const InboxStore: Module<InboxStateInterface, RootState> = {
  namespaced: true,
  state: {
    gifts: [] as InboxGift[],
    messages: [] as InboxMessage[],
    newMissionAvailable: false,
    current: 'gift',
    impendingCount: 0,
    receivable: false
  },

  getters: {
    getCurrent: (state) => state.current,
    getGift: (state) => state.gifts,
    getMessages: (state) => state.messages,
    getMissions: (state) => state.newMissionAvailable,
    getImpendingCount: (state) => state.impendingCount,
    getReceivable: (state) => state.receivable
  },

  mutations: {
    setGift: (state, payload: InboxGift[]) => (state.gifts = payload),
    setMessages: (state, payload: InboxMessage[]) =>
      (state.messages = [...payload]),
    setCurrent: (state, payload: string) => (state.current = payload),
    increaseImpendingCount: (state) => ++state.impendingCount,
    resetImpendingCount: (state) => (state.impendingCount = 0),
    setReceivable: (state, payload: boolean) => (state.receivable = payload)
  },

  actions: {
    getInbox: async ({ commit, dispatch }, type: string) => {
      commit('setCurrent', type);
      return await inboxApi.getInbox(type).then((res) => {
        switch (type) {
          case 'gift':
            commit('setGift', res.gifts);
            dispatch('receivableCount', res.gifts);
            break;
          case 'message':
            commit('setMessages', res.messages);
            break;
        }
        return res;
      });
    },

    receiveGift: async (
      { dispatch },
      {
        distributionType,
        itemConfigId
      }: { distributionType: string; itemConfigId: number }
    ) => {
      return await inboxApi
        .receiveGift(distributionType, itemConfigId)
        .then((res) => {
          dispatch('getInbox', 'gift');
          return res;
        });
    },
    receiveAllGift: async ({ commit, dispatch }) => {
      return await inboxApi.receiveAllGift().then((res) => {
        commit('setReceivable', false);
        dispatch('getInbox', 'gift');
        return res;
      });
    },
    receivableCount: ({ commit }, giftList: InboxGift[]) => {
      commit('resetImpendingCount');
      commit('setReceivable', false);
      giftList.forEach((item) => {
        if (
          !item.received &&
          (item.item === 'coin' || item.item === 'ticket')
        ) {
          commit('setReceivable', true);
          if (
            item.expireRemained < 86400 &&
            (item.item === 'coin' || item.item === 'ticket')
          ) {
            commit('increaseImpendingCount');
          }
        }
      });
    }
  }
};
const chunkArray = (arr: Array<any>) => {
  const chunk = 100;
  const result = arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return result;
  }, []);
};
