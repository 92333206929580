






import { defineComponent, ref } from '@vue/composition-api';
import Store from '@/store/index';
import { MenuItem } from '@/models';

export default defineComponent({
  name: 'MenuTabsNormal',
  props: {
    pageTitle: String
  },
  setup(props) {
    const title = ref(props.pageTitle);

    return {
      title
    };
  }
});
