export interface IdpInterface {
  code: string;
  name: string;
}
export interface IdpListInterface {
  'ko-KR': IdpInterface[];
  'en-US': IdpInterface[];
  'ja-JP': IdpInterface[];
  'zh-TW': IdpInterface[];
  'fr-FR': IdpInterface[];
  'de-DE': IdpInterface[];
}
export const IDP_TYPES: IdpListInterface = {
  'ko-KR': [
    {
      code: 'appleid',
      name: 'Apple'
    },
    {
      code: 'google',
      name: 'Google'
    },
    {
      code: 'facebook',
      name: 'Facebook'
    }
  ],
  'en-US': [
    {
      code: 'appleid',
      name: 'Apple'
    },
    {
      code: 'google',
      name: 'Google'
    },
    {
      code: 'facebook',
      name: 'Facebook'
    }
  ],
  'zh-TW': [
    {
      code: 'appleid',
      name: 'Apple'
    },
    {
      code: 'google',
      name: 'Google'
    },
    {
      code: 'facebook',
      name: 'Facebook'
    }
  ],
  'ja-JP': [
    {
      code: 'appleid',
      name: 'Apple'
    },
    {
      code: 'google',
      name: 'Google'
    },
    {
      code: 'facebook',
      name: 'Facebook'
    },
    {
      code: 'twitter',
      name: 'Twitter'
    }
  ],
  'fr-FR': [
    {
      code: 'appleid',
      name: 'Apple'
    },
    {
      code: 'google',
      name: 'Google'
    },
    {
      code: 'facebook',
      name: 'Facebook'
    }
  ],
  'de-DE': [
    {
      code: 'appleid',
      name: 'Apple'
    },
    {
      code: 'google',
      name: 'Google'
    },
    {
      code: 'facebook',
      name: 'Facebook'
    }
  ]
};

export const MATURE_TXT = {
  title: '성인인증',
  certificate: '성인인증 하기',
  complete: '인증 완료',
  txt1: '성인 인증을 해주세요.',
  txt2: '본인 명의의 휴대폰 정보로 인증해주세요.',
  btn: '10초만에 인증하기',
  txt3:
    '본 정보내용은 청소년 유해매체물로서 정보통신망이용촉진 및 정보보호 등에 관한 법률 및 청소년 보호법의 규정에 의하여 19세 미만의 청소년이 이용할 수 없습니다.',
  outBtn: '19세 미만 나가기',
  successMsg: '인증이 완료 되었습니다.',
  failMsg: '인증에 실패하였습니다.<br>다시 시도해 주세요.',
  btnConfirm: '확인'
};

export const TERMS_LIST = {
  'ko-KR': [
    { name: '이용약관', link: '/legal/terms_of_use', innerLink: true },
    {
      name: '개인정보처리방침',
      link: '/legal/privacy_policy',
      innerLink: true
    },
    {
      name: '청소년보호정책',
      link: '/legal/youth_protection_policy',
      innerLink: true
    },
    {
      name: '사업자정보',
      link: 'http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1448115549'
    }
  ],
  'en-US': [
    { name: 'Terms of use', link: '/legal/terms_of_use', innerLink: true },
    {
      name: 'Privacy policy',
      link: '/legal/privacy_policy',
      innerLink: true
    }
  ],
  'ja-JP': [
    { name: '利用規約', link: '/legal/terms_of_use', innerLink: true },
    { name: '個人情報保護', link: '/legal/privacy_policy', innerLink: true },
    {
      name: '資金決済法に基づく表示 ',
      link: '/legal/fund_settlement?languageCode=ja',
      innerLink: true
    },
    {
      name: '特定商取引法の表示',
      link: '/legal/special_commercial_transaction?languageCode=ja',
      innerLink: true
    }
  ],
  'zh-TW': [
    { name: 'Terms of use', link: '/legal/terms_of_use', innerLink: true },
    {
      name: 'Privacy policy',
      link: '/legal/privacy_policy',
      innerLink: true
    }
  ]
};

export const KO_IDP_NAME = {
  facebook: '페이스북',
  naver: '네이버',
  kakao: '카카오톡',
  appleid: '애플ID'
};

export const PAYCO_EXTERNALIDP_URL =
  'https://id.payco.com/oauth2.0/authorize?serviceProviderCode=FRIENDS&userLocale=ko_KR&client_id=3RDpYIfDd2SELhE7CvTu&redirect_uri=https%3A%2F%2Fapi.comico.kr%2Fmember%2Fexternal-idp%2Fweb%2Fpayco%2Fcallback&response_type=code';
