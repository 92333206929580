






















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'H',
  props: {
    text: String,
    level: Number
  }
});
