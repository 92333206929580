import { ChallengeActivity } from "./challenge-content";


export class ChallengeChapterImage {
	sort: number;
	filePath: string;
	fileName: string;
	width: number;
	height: number;
	size: number;
	constructor(data: ChallengeChapterImage) {
		this.sort = data.sort;
		this.filePath = data.filePath;
		this.fileName = data.fileName;
		this.width = data.width;
		this.height = data.height;
		this.size = data.size;
	}
	getImagePath() {
		return `${process.env.VUE_APP_CHALLENGE_IMAGES_DOMAIN}${this.filePath}${this.fileName}`;
	}
}

export class ChallengeNextPrevChapter {
	chapterId: number;
	contentId: number;
	userId: number;
	name: string;
	published: boolean;
	status: string;
	coverImageFilePath: string;
	coverImageFileName: string;
	totalLikes: number;
	commentEnabled: boolean;
	authorComment: string;
	publishedAt: string;
	createdAt: string;
	updatedAt: string;
	commentCount: number;

	constructor(data: ChallengeNextPrevChapter) {
		this.chapterId = data.chapterId;
		this.contentId = data.contentId;
		this.userId = data.userId;
		this.name = data.name;
		this.published = data.published;
		this.status = data.status;
		this.coverImageFilePath = data.coverImageFilePath;
		this.coverImageFileName = data.coverImageFileName;
		this.totalLikes = data.totalLikes;
		this.commentEnabled = data.commentEnabled;
		this.authorComment = data.authorComment;
		this.publishedAt = data.publishedAt;
		this.createdAt = data.createdAt;
		this.updatedAt = data.updatedAt;
		this.commentCount = data.commentCount;
	}

	getCoverImagePath() {
		return `${process.env.VUE_APP_CHALLENGE_IMAGES_DOMAIN}${this.coverImageFilePath}${this.coverImageFileName}`;
	}
}

export class ChallengeUserComment {
	commentId: number;
	contentId: number;
	chapterId: number;
	userId: number;
	nickname: string;
	avatarFilePath: string;
	avatarFileName: string;
	comment: string;
	totalLikes: number;
	status: string;
	createdAt: string;
	updatedAt: string;

	constructor(data: ChallengeUserComment) {
		this.commentId = data.commentId;
		this.contentId = data.contentId;
		this.chapterId = data.chapterId;
		this.userId = data.userId;
		this.comment = data.comment;
		this.totalLikes = data.totalLikes;
		this.status = data.status;
		this.createdAt = data.createdAt;
		this.nickname = data.nickname;
		this.avatarFilePath = data.avatarFilePath;
		this.avatarFileName = data.avatarFileName;
		this.updatedAt = data.updatedAt;
	}

	isAvatarThumbnail() {
		return this.avatarFilePath && this.avatarFileName;
	}

	getAvatarThumbPath() {
		return `${process.env.VUE_APP_CHALLENGE_IMAGES_DOMAIN}${this.avatarFilePath}${this.avatarFileName}`;
	}

	getCreatedAtAtToStr() {
		const current = new Date(this.createdAt).toLocaleString('ja', {
			year: 'numeric',
			month: 'short',
			day: 'numeric'
		});
		return current;
	}
}

export class ChallengeChapter {
	contentId: number;
	chapterId: number;
	name: string;
	authorComment: string;
	userId: number;
	nickname: string;
	coverImageFilePath: string;
	coverImageFileName: string;
	status: string;
	published: boolean;
	totalLikes: number;
	commentEnabled: boolean;
	createdAt: string;
	updatedAt: string;
	publishedAt: string;
	commentCount: number;
	images: ChallengeChapterImage[];
	userComments?: ChallengeUserComment[];
	activity?: ChallengeActivity;
	liked: boolean;
	nextChapter?: ChallengeNextPrevChapter;
	previousChapter?: ChallengeNextPrevChapter;

	constructor(data: ChallengeChapter) {
		this.contentId = data.contentId;
		this.chapterId = data.chapterId;
		this.name = data.name;
		this.authorComment = data.authorComment;
		this.userId = data.userId;
		this.nickname = data.nickname;
		this.coverImageFilePath = data.coverImageFilePath;
		this.coverImageFileName = data.coverImageFileName;
		this.status = data.status;
		this.published = data.published;
		this.totalLikes = data.totalLikes;
		this.commentEnabled = data.commentEnabled;
		this.createdAt = data.createdAt;
		this.updatedAt = data.updatedAt;
		this.publishedAt = data.publishedAt;
		this.commentCount = data.commentCount;
		this.images = data.images ? data.images.map(node => new ChallengeChapterImage(node)) : [];
		this.userComments = data.userComments ? data.userComments.map(node => new ChallengeUserComment(node)) : [];
		this.activity = data.activity ? new ChallengeActivity(data.activity) : undefined;
		this.liked = data.liked;
		this.nextChapter = data.nextChapter ? new ChallengeNextPrevChapter(data.nextChapter) : undefined;
		this.previousChapter = data.previousChapter ? new ChallengeNextPrevChapter(data.previousChapter) : undefined;
	}

	getCoverImagePath() {
		return `${process.env.VUE_APP_CHALLENGE_IMAGES_DOMAIN}${this.coverImageFilePath}${this.coverImageFileName}`;
	}

	getPublishedAtToStr() {
		const current = new Date(this.publishedAt).toLocaleString('ja', {
			year: 'numeric',
			month: 'short',
			day: 'numeric'
		});
		return current;
	}
}
