





















import {
  computed,
  ComputedRef,
  defineComponent,
  onUnmounted,
  watch
} from '@vue/composition-api';
import store from '@/store';
import ChapterCard from '@/components/molecules/chapter-card/ChapterCard.vue';
import { DialogStoreOption } from '@/store/dialog';
import { Chapter, ContentInfo, Inventory } from '@/models';
import { LCS_TYPES } from '@/commons/constants/lcs-types';
import { isMagazine } from '@/services';
import AlertDialog from '@/dialogs/AlertDialog.vue';

export default defineComponent({
  name: 'ChapterListModule',
  components: {
    ChapterCard
  },
  props: {
    content: ContentInfo,
    chapters: Array,
    contentType: String,
    contentId: Number,
    tabType: String,
    showAllChapters: Boolean
  },
  setup(props, { emit, root }) {
    const { $q } = root;
    const { dispatch, getters } = store;
    const contentType: ComputedRef<string | undefined> = computed(
      () => props.contentType
    );
    const tabType: ComputedRef<string> = computed(
      () => props.tabType as string
    );
    const contentInfo: ComputedRef<ContentInfo | undefined> = computed(
      () => props.content
    );
    const inventory: ComputedRef<Inventory | undefined> = computed(
      () => contentInfo.value?.inventory
    );

    const list: ComputedRef<Chapter[] | undefined> = computed(
      () => {
        if(props.showAllChapters){
          return props.chapters as Chapter[];
        }else{
          const chapters = props.chapters as Chapter[];
          const _lastReadId = contentInfo.value?.getLastReadChapterId();
          if(typeof _lastReadId === 'undefined'){
            return chapters.slice(0, 5);
          }else {
            const _index = chapters.findIndex(chapter=>{
              if(typeof chapter === 'undefined' || typeof chapter.id === 'undefined'){
                return false;
              }else if(chapter.id === _lastReadId){
                return true;
              }else {
                return false;
              }
            });
            return (_index === -1)? chapters.slice(0, 5): chapters.slice(_index, _index + 5);
          }
        }
      }
    );

    let volumeStore: { string: number[] } = {} as { string: number[] };

    const isVerticalThumbnail = computed(() => {
        return contentInfo.value?.isVerticalChapterThumbnail() == true;
    });

    const isSkipThumbnail = (chapter: Chapter) => {
      if (
        (contentType.value === 'magazine_comic' ||
          contentType.value === 'magazine_novel') &&
        tabType.value === 'EPISODE'
      ) {
        if (volumeStore[chapter.volumeId]) {
          volumeStore[chapter.volumeId].push(chapter.id);
          return true;
        } else {
          volumeStore[chapter.volumeId] = [chapter.id];
          return false;
        }
      }
    };

    const clearVoumnIdStore = () => {
      volumeStore = {} as { string: number[] };
    };

    const sendPurchase = ({ content, chapter }) => {
      if(chapter.aborted && !chapter.salesConfig.free && !chapter.isUnlock() && !chapter.isRented()){
        $q.dialog({
          component: AlertDialog,
          parent: root,
          componentProps: {
              title: '',
              message: root.$t('chapter_aborted').toString(),
              ok: 'OK'
          }
        }).onOk(() => {
          
        });
        return;
      }
      
      const options: DialogStoreOption = {
        component: () => import('@/dialogs/PurchaseDialog.vue'),
        param: {
          prevPage: LCS_TYPES.CONTENTS_HOME,
          parent: root,
          content,
          chapter
        }
      };
      dispatch('DialogStore/dialog', options).then((res) => {
        if (res) {
          emit('emitPurchaseDialog', res);
        }
      });
    };

    watch(list, () => {
      clearVoumnIdStore();
    });

    onUnmounted(() => {
      clearVoumnIdStore();
    });

    return {
      list,
      inventory,
      contentInfo,
      isSkipThumbnail,
      sendPurchase,
      isVerticalThumbnail,
      isMagazine
    };
  }
});
