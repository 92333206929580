import { render, staticRenderFns } from "./HomeBannerSlider.vue?vue&type=template&id=5374bd08&"
import script from "./HomeBannerSlider.vue?vue&type=script&lang=ts&"
export * from "./HomeBannerSlider.vue?vue&type=script&lang=ts&"
import style0 from "./HomeBannerSlider.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCarousel,QCarouselSlide,QBtn});
