import { t } from '@/commons/i18n';
import {
  LIBRARY_TYPE,
  LIBRARY_ORDER_KEY
} from '@/commons/constants/provided-types';

export const getStorageFilter = (selected: string): string => {
  if (!selected) return '';
  const getList = sessionStorage.getItem(selected) || '';
  return getList ? Object.assign({}, JSON.parse(getList)) : {};
};

export const setUserOrder = (name: string, param: string): void => {
  if (!param) return;
  const userOrder = localStorage.getItem(LIBRARY_ORDER_KEY);
  const obj = Object.assign({}, userOrder ? JSON.parse(userOrder) : '', {
    [name]: param
  });
  localStorage.setItem(LIBRARY_ORDER_KEY, JSON.stringify(obj));
};

export const getUserOrder = (name: string | undefined): string | null => {
  if (!name) return null;
  const userOrder = localStorage.getItem(LIBRARY_ORDER_KEY);
  return userOrder ? JSON.parse(userOrder)[name] : null;
};

export const getSortSelected = (
  selectedCode: string | undefined,
  idx: number
): string | undefined => {
  if (!selectedCode) return;
  switch (selectedCode) {
    case LIBRARY_TYPE.SUBSCRIBED:
      return idx === 0 ? t('order_by_updated') : t('order_by_added');
    case LIBRARY_TYPE.UNLOCKED:
      return idx === 0 ? t('order_by_unlocked') : t('order_by_updated');
  }
};

export const getText = (
  selectedCode: string | undefined,
  idx: number
): string | undefined => {
  if (!selectedCode) return;
  const selectedText = getSortSelected(selectedCode, idx) || '';
  return selectedText;
};

export const LibraryHelperService = {
  setUserOrder,
  getUserOrder,
  getStorageFilter,
  getSortSelected,
  getText
};
