






































































import {
  Ref,
  ref,
  ComputedRef,
  computed,
  defineComponent,
  watch
} from '@vue/composition-api';
import Store from '@/store/index';
import CheckboxSection from '@/components/molecules/sections/CheckboxSection.vue';
import { ForegroundedInterface } from '@/models/index';
import { POLICY_TYPES } from '@/commons/constants/policy-types';

export default defineComponent({
  name: 'PolicyTemplete',
  components: {
    CheckboxSection
  },
  props: {
    policyAllYn: Boolean,
    formData: Object
  },
  setup(props, { emit }) {
    const { getters } = Store;
    const propsPolicyAllYn = computed(() => props.policyAllYn);
    const getForegrounded: ComputedRef<ForegroundedInterface> = computed(
      () => getters['AuthStore/getForegrounded']
    );
    const verifyLegalAge = getForegrounded.value?.required?.consents?.legalAge;
    const isChecked: Ref<boolean> = ref(false);
    const onSelectAll = () => {
      emit('onSelectAll', isChecked.value);
    };
    const onUnselectAll = ({ isChecked, inputName }) => {
      emit('unselect', { isChecked, inputName });
    };
    const onPolicyHandler = (code: string, title: string) => {
      emit('policyView', { code, title });
    };
    watch(propsPolicyAllYn, (data: boolean) => {
      isChecked.value = data;
    });
    return {
      POLICY_TYPES,
      isChecked,
      verifyLegalAge,
      onSelectAll,
      onUnselectAll,
      onPolicyHandler
    };
  }
});
