import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { HomeInfo, Section } from '@/models';
import { CONTENT_TYPES } from '@/commons/constants/content.types';
import { getContentCategory, HomeService, setContentCategory } from '@/services/index';

export interface HomeStateInterface<T> {
  content: T;
  contentCategory: string;
}

/**
 * HomeStore
 */
export const HomeStore: Module<HomeStateInterface<HomeInfo>, RootState> = {
  namespaced: true,
  state: {
    content: {} as HomeInfo,
    contentCategory: getContentCategory(),
  },
  getters: {
    content(state) {
      return state.content;
    },
    popups(state) {
      return state.content.popups;
    },
    sections(state) {
      
      const shuffleTypes = ['banner_list_content_big_group', 'banner_list_group'];
      const newSections: Section[] = [];
      const firstShuffle: { [key: string]: boolean } = {};

      state.content.sections.forEach(element => {
        if (shuffleTypes.includes(element.elementUiType ?? '') && element.shuffleGroup) {
          let key = element.elementUiType + '_' + element.shuffleGroup;
          if (firstShuffle.hasOwnProperty(key) == false) {
            const groupElements = state.content.sections.filter(e => e.elementUiType == element.elementUiType && e.shuffleGroup == element.shuffleGroup);
            const randomElement = groupElements[Math.floor(Math.random() * groupElements.length)];
            newSections.push(randomElement);
            firstShuffle[key] = true;
          }
        } else {
          newSections.push(element);
        }
      });
      return newSections;      
    },
    topBanner(state) {
      return state.content.topBanner;
    },
    menu(state) {
      return state.content.menu;
    },
    contentCategory(state) {
      return state.contentCategory;
    },
    contentType(state) {
      switch(state.contentCategory) {
        case 'all_comic':
        default:
          return 'comic';
        case 'all_novel':
          return 'novel';
      }
    }
  },
  mutations: {
    content(state, value: HomeInfo) {
      state.content = value;
    },
    contentCategory(state, value: string) {
      state.contentCategory = value;
      setContentCategory(value);
    }
  },
  actions: {
    async initHomeContent({ commit }, { contentCategory }: {
      contentCategory: string
    }) {
      const data = await HomeService.getHomeContent(contentCategory);
      const result = new HomeInfo(data);
      commit('content', result);
      commit('contentCategory', contentCategory);
      return result;
    }
  }
};
