
import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { ChallengeChapter } from '@/models/challenge/challenge-chapter';
import { ChallengeContent } from '@/models/challenge/challenge-content';

export interface ChallengeReportTypes {
	chapter: ChallengeChapter | null;
	content: ChallengeContent | null;
}

export const ChallengeReportStore: Module<ChallengeReportTypes, RootState> = {
	namespaced: true,
	state: {
		chapter: null,
		content: null
	},
	getters: {
		chapter: (state) => state.chapter,
		content: (state) => state.content,
	},
	mutations: {
		chapter(state: ChallengeReportTypes, value: ChallengeChapter) {
			state.chapter = value;
		},
		content(state: ChallengeReportTypes, value: ChallengeContent) {
			state.content = value;
		},
	}
}