




















import { computed, defineComponent } from '@vue/composition-api';
import IconNextPrev from '@/components/atoms/icons/IconNextPrev.vue';
import { IconType, ICON_NEXT, ICON_PREV } from '@/commons/constants/icon-types';

export default defineComponent({
  name: 'MenuItemsNavigator',
  components: {
    IconNextPrev
  },
  props: {
    isPrevShow: Boolean,
    isNextShow: Boolean
  },
  setup(props, { emit }) {
    const prev = computed(() => props.isPrevShow);
    const next = computed(() => props.isNextShow);
    const onPrevNext = (type: IconType) => emit('onDirection', type);

    return {
      ICON_NEXT,
      ICON_PREV,
      onPrevNext,
      prev,
      next
    };
  }
});
