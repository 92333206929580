












import { Element } from '@/models';
import { getForwardPath, gotoLinkPage } from '@/services';
import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import store from '@/store';

export default defineComponent({
  name: 'BannerCard',
  props: {
    element: Object
  },
  setup(props) {
    const { getters } = store;
    const isWide: ComputedRef<boolean> = computed(
      () => getters['UIStore/isWide']
    );
    const elementInfo: ComputedRef<Element> = computed(
      () => props.element as Element
    );
    const onBannerClick = () => {
      if (!elementInfo.value.urlHttp) return;
      const { internal, path } = getForwardPath(elementInfo.value.urlHttp);
      if (internal) {
        gotoLinkPage(path);
      } else {
        window.location.href = path;
      }
    };
    return {
      onBannerClick,
      isWide
    };
  }
});
