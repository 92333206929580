import { idpParamsInterface } from '@/models';
import { createAxiosService } from '@/services/common/create-axios.service';
const axiosService = createAxiosService();
const config = {
  headers: {
    'content-type': 'application/x-www-form-urlencoded'
  }
};
const getExternalIdpUrl = async (loginType: string) => {
  // const url = `/app/config/externalIdp`;
  const url = `/member/external-idp/${loginType}/authorize-url`;
  return await axiosService.get(url).then((res) => {
    const { data } = res;
    return data;
  });
};

const externalIdpCallback = async (params) => {
  const url = `/member/external-idp/web/${params.loginType}/callback`;

  //naver 는 state 필수
  if (params.loginType === 'naver') {
    params.form.append('state', new Date().getTime());
  }

  return await axiosService.post(url, params.form).then((res) => {
    const { data } = res;
    return data;
  });
};

const signIn = async (params: idpParamsInterface) => {
  const url = `/member/external-idp/${params.loginType}/signin`;

  return await axiosService.post(url, params.form, config).then((res) => {
    const { data } = res;
    return data;
  });
};
const signUp = async (params: idpParamsInterface) => {
  const url = `/member/external-idp/${params.loginType}/signup`;

  return await axiosService.post(url, params.form, config).then((res) => {
    const { data } = res;
    return data;
  });
};

export const socialLoginApi = {
  getExternalIdpUrl,
  externalIdpCallback,
  signIn,
  signUp
};
