















































import { defineComponent, ref } from '@vue/composition-api';
import IconThumbnail from '@/components/atoms/templates/IconThumbnail.vue';
import Badge from '@/components/atoms/templates/Badge.vue';
import { originalIconClsName } from '@/commons/constants/icon-types';
import { getForwardPath } from '@/services/contents/contents.helper';

export default defineComponent({
  name: 'RankingThumbnail',
  components: {
    Badge,
    IconThumbnail
  },
  props: {
    element: Object,
    rank: Number
  },
  setup(props, context) {
    const ranked = ref(props.rank);
    const tmpRoot = context.root;
    const onClick = () => {
      context.emit('gotoContents', props.element);
    };
    const imagePathCheck = (imgPath?: string) => {
      if (imgPath) return imgPath;
      const tmpImgPath = tmpRoot['$isKo'] || tmpRoot['$isJa'] ? 'img_thumb' : 'img_thumb_pocket';
      return require('@/assets/img/' + tmpImgPath + '.png');
    }

    const imageLoadErrorCheck = (evt) => {
      evt.target.src = imagePathCheck();
    };
    return {
      onClick,
      ranked,
      originalIconClsName,
      imagePathCheck,
      imageLoadErrorCheck,
      getForwardPath
    };
  }
});
