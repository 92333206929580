import { Module } from 'vuex';
import store, { RootState } from '@/store/index';
import { ChallengeApiService } from '@/services/challenge/challenge-content.api';
import { ChallengeContent } from '@/models/challenge/challenge-content';
import { subscribe, deleteSubscribed } from '@/services/challenge/challenge-content.api';
export interface ChallengeContentTypes {
	content: ChallengeContent | null;
	orderType: 'newest' | 'oldest';
	isSubscribed: boolean;
	shareBtns: boolean;
}

export const ChallengeContentStore: Module<ChallengeContentTypes, RootState> = {
	namespaced: true,
	state: {
		content: null,
		orderType: 'oldest',
		isSubscribed: false,
		shareBtns: false
	},
	getters: {
		content: (state) => state.content,
		orderType: (state) => state.orderType,
		isSubscribed: (state) => state.isSubscribed,
		sortedChapters: (state: ChallengeContentTypes) => {
			try {
				if(state.orderType === 'newest') {
					return state.content?.chapters.sort((a, b) => {
						return b.chapterId - a.chapterId;
					});
				} else if(state.orderType === 'oldest') {
					return state.content?.chapters.sort((a, b) => {
						return a.chapterId - b.chapterId;
					});
				}
			} catch(e) {
				return []
			}
		},
		shareBtns: (state) => state.shareBtns
	},
	mutations: {
		content(state: ChallengeContentTypes, value: ChallengeContent) {
			state.content = value;
		},
		orderType(state: ChallengeContentTypes, value: 'newest' | 'oldest') {
			state.orderType = value;
		},
		isSubscribed(state: ChallengeContentTypes, value: boolean) {
			state.isSubscribed = value;
		},
		shareBtns(state: ChallengeContentTypes, value: boolean) {
			state.shareBtns = value
		}
	},
	actions: {
		async initContent({ commit }, { contentId }) {
			try {
				const result = await ChallengeApiService.getChallengeContent(contentId);
				commit('content', result);
				commit('isSubscribed', result.subscribed);
			} catch(e: any) {
				throw e;
			}
		},
		async subscribe({ commit, state }, { contentId }) {
			if(state.isSubscribed) {
				await deleteSubscribed(contentId);
				commit('isSubscribed', false);
			} else {
				await subscribe(contentId);
				commit('isSubscribed', true);
			}
		}
	}
}
