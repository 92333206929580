





























































import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import store from '@/store';
import IconCoin from '@/components/atoms/icons/IconCoin.vue';
import IconSalesState from '@/components/atoms/icons/IconSalesState.vue';
import IconWaitForFree from '@/components/atoms/icons/IconWaitForFree.vue';
import ChapterThumbnail from '@/components/molecules/chapter-card/ChapterThumbnail.vue';
import IconTypeSpan from '@/components/atoms/icons/IconTypeSpan.vue';
import { ICON_NOW } from '@/commons/constants/icon-types';
import { TYPE_SALES } from '@/commons/constants/sales-types';
import {
  ChapterHelperService,
  DateTokenHelperService
} from '@/services/index';
import {
  Activity,
  Chapter,
  ContentInfo,
  Inventory,
  RentalConfig,
  SalesConfig
} from '@/models';
import { Language } from '@/commons/constants/enum';

export default defineComponent({
  name: 'ChapterCard',
  components: {
    IconCoin,
    IconSalesState,
    IconWaitForFree,
    ChapterThumbnail,
    IconTypeSpan
  },
  props: {
    content: ContentInfo,
    chapter: Chapter,
    inventory: Inventory,
    skipThumbnail: Boolean,
    hideDate: Boolean
  },
  setup(props, { emit, root }) {
    const { getters } = store;
    const lang: ComputedRef<Language> = computed(
      () => getters['I18nStore/getCookieLanguage']
    );
    const contentInfo: ComputedRef<ContentInfo> = computed(
      () => props.content as ContentInfo
    );
    const chapter: ComputedRef<Chapter> = computed(
      () => props.chapter as Chapter
    );
    const activity: ComputedRef<Activity> = computed(
      () => chapter.value.activity as Activity
    );
    const rentalConfig: ComputedRef<RentalConfig> = computed(
      () => contentInfo.value?.rentalConfig
    );
    const inventory: ComputedRef<Inventory> = computed(
      () => props.inventory as Inventory
    );
    const salesConfig: ComputedRef<SalesConfig> = computed(
      () => props.chapter?.salesConfig as SalesConfig
    );
    const lastReadId: ComputedRef<number | undefined> = computed(() =>
      contentInfo.value?.getLastReadChapterId()
    );

    const salesState: ComputedRef<string> = computed(() => {
      const adsEnabled = contentInfo.value.adsEnabled;
      return chapter.value.getSalesState(inventory.value, adsEnabled);
    });

    const label = computed(() => {
      switch (salesState.value) {
        case TYPE_SALES.PURCHASED:
          return root.$t('unlocked'); // 구입완료
        case TYPE_SALES.REMAINED: // 대여기간 n남음
          if (chapter.value.getRentRemined()) {
            const remained = ChapterHelperService.getRemainedWithLabel(
              chapter.value.activity?.rentRemained
            );
            return DateTokenHelperService.getRentalRemainedStr(
              remained,
              lang.value
            );
          }
          break;
        case TYPE_SALES.FREE: // 무료
          return root.$t('free');
        case TYPE_SALES.WAITFREE: // 기다리면 무료
          return root.$t('available_soon');
        case TYPE_SALES.PRICE: // 코인
          return root.$t('price');
        case TYPE_SALES.READ_NOW: // 지금읽기
          return root.$t('read_now');
        case TYPE_SALES.TARGET_FREE: // 당신에게만 무료
          return root.$t('free_for_now');
      }
    });

    const onChapterClick = () => {
      emit('emitPurchase', {
        content: contentInfo.value,
        chapter: chapter.value
      });
    };

    return {
      salesState,
      salesConfig,
      label,
      activity,
      TYPE_SALES,
      lastReadId,
      rentalConfig,
      contentInfo,
      ICON_NOW,
      onChapterClick
    };
  }
});
