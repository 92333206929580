import { createAxiosService } from '@/services/common/create-axios.service';

const axiosService = createAxiosService();
/*
  documentType
  help : FAQ,
  notice : 공지사항
*/

interface DocumentInterface {
  documentType: string;
  categoryPath: string;
  documentPath?: string;
  publishDate?: string;
}

const getCategory = async (documentType: string) => {
  const url = `/document/${documentType}/category`;
  return await axiosService.get(url).then((res) => {
    const { data } = res;
    return data;
  });
};

const getDocumentList = async (categoryPath: string) => {
  // categoryPath  =  /document/{documentType}/{categoryPath}
  return await axiosService.get(categoryPath).then((res) => {
    const {
      data: { data }
    } = res;
    return data;
  });
};

const getDocumentDetail = async (documentPath: string) => {
  // documentpath = `/document/${documentType}/${categoryPath}/${documentPath}/${publishDate}`;
  return await axiosService.get(documentPath).then((res) => {
    const {
      data: { data }
    } = res;
    return data;
  });
};

export const NoticeFaqApi = { getCategory, getDocumentList, getDocumentDetail };
