export const LIBRARY_ORDER_KEY = 'libraryOrder';
export const PROVIDED_SERVICE = 'providedService';
export const PROVIDED_CONTENT_CATEGORY = 'providedContentCategory';
export const PROVIDED_CONTENT_LIST = {
  ALL: 'all',
  COMIC: 'comic',
  MAGAZINE_COMIC: 'magazine_comic',
  NOVEL: 'novel',
  MAGAZINE_NOVEL: 'magazine_novel'
};
export const LIBRARY_TYPE = {
  SUBSCRIBED: 'subscribed',
  RECENT: 'recent',
  RENTED: 'rented',
  UNLOCKED: 'unlocked'
};
export const ORDER_SUBSCRIBE_TYPE = {
  UPDATED: 'updated',
  RECENT: 'recent'
};
export const ORDER_UNLOCKED_TYPE = {
  PURCHASED: 'purchased',
  UPDATED: 'updated'
};
