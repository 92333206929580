import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { ContentInfo, ContentsItemInfo, HomeInfo, Page } from '@/models';
import { HomeService } from '@/services';
import { ContentsApiService } from '@/services/contents/contents.api';

export interface AdultHomeTypes {
	content: HomeInfo;
	contentList: Array<ContentInfo>;
	page: Page;
};

export const AdultHomeStore: Module<AdultHomeTypes, RootState> = {
	namespaced: true,
	state: {
		content: {} as HomeInfo,
		contentList: [],
		page: {} as Page
	},
	getters: {
		content(state) {
			return state.content;
		},
		popups(state) {
			return state.content.popups;
		},
		sections(state) {
			return state.content.sections;
		},
		topBanner(state) {
			return state.content.topBanner;
		},
		menu(state) {
			return state.content.menu;
		},
		contentList: (state: AdultHomeTypes) => state.contentList,
		page: (state: AdultHomeTypes) => state.page,
	},
	mutations: {
		content(state, value: HomeInfo) {
			state.content = value;
		},
		contentList(state: AdultHomeTypes, value: ContentInfo[]) {
			state.contentList = value;
		},
		page(state: AdultHomeTypes, value: Page) {
			state.page = value;
		}
	},
	actions: {
		async initAdultHome({ commit }, { apiPath }) {
			const data = await HomeService.getAdultHomeContent(apiPath);
			const result = new HomeInfo(data);
			commit('content', result);
			return result;
		},
		async initAdultContents({ dispatch, getters, commit }, { apiPath, pageState, currentItemCode }) {
			if(!currentItemCode || currentItemCode === 'home') {
				await dispatch('initAdultHome', {
					apiPath
				});
			} else {
				try {
					commit('MenuStore/pointerEvents', false, { root: true });
					const result: ContentsItemInfo = await ContentsApiService.getContentsList(apiPath, pageState);
					let newContents = result.contents.map(node => new ContentInfo(node));
					if(pageState.pageNo !== 0) {
						newContents = getters.contentList.concat(newContents);
					}
					commit('contentList', newContents);
					commit('page', result.page);
				} catch(e) {
					commit('contentList', []);
				} finally {
					commit('MenuStore/pointerEvents', true, { root: true });
				}
			}
		},
	},

}