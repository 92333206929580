import { isAOS, isIOS } from '@/services/index';
import { getLanguage, timezone } from '@/services/common/inspectors';

const queryString = (value: string) => {
  const domainLang = process.env.VUE_APP_LANGUAGE;
  let serviceAppKey = 'comicoGlobalWeb';
  switch (true) {
    case domainLang === 'ko':
      serviceAppKey = 'comicoKrWeb';
      break;
    case domainLang === 'ja':
      serviceAppKey = 'comicoJpWeb';
      break;
    default:
      serviceAppKey = 'comicoGlobalWeb';
  }
  return serviceAppKey + '.' + value;
};

// UUID 생성, 중간 4는 고정 값
// 3c8d41a9-ff74-4720-af32-c9ed1b2afc66
const createUUID = () => {
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let dt = new Date().getTime();
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

const paramsGroup = (querys: Object): string => {
  let ps = '';
  for (const key in querys) {
    ps += `&${key}=${querys[key]}`;
  }
  return ps;
};

const bipOs = (): string => {
  return isAOS() ? 'A' : isIOS() ? 'I' : 'O';
};

export const sendNclick = (
  path: string,
  clickArea: string,
  contentID: string,
  chapterID: string,
  keyword: string,
  contentType?: string
): void => {
  try {
    const obj = {
      a: queryString(clickArea),
      e: path,
      r: chapterID,
      c: contentType || '',
      i: contentID,
      k: keyword,
      m: '0',
      u: '0',
      UID: sessionStorage.getItem('userId') || '',
      DID: 'web',
      os: bipOs(),
      UUID: sessionStorage.getItem('neoIdUid') || '',
      LNG: getLanguage(),
      tz: timezone(),
      pushid: '',
      adid: '',
      immutableid: sessionStorage.getItem('ipAddress') || '0.0.0.0',
      usertype: '&EOU'
    };

    const setParams = paramsGroup(obj);
    const baseUrl: HTMLAnchorElement = document.createElement('a');
    baseUrl.href = 'https://cc.comico.jp/cc';
    baseUrl.search = setParams;
    const img = new Image();
    const images = {};
    const uuid = createUUID();
    images[uuid] = img;
    const purge = () => {
      delete images[uuid];
    };
    img.addEventListener('load', purge);
    img.addEventListener('error', purge);
    img.addEventListener('abort', purge);
    img.src = baseUrl.href;
  } catch (e) {
    console.log('nClick send Error', e);
  }
};
