









import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    status: Object
  },
  setup(props) {
    return {};
  }
});
