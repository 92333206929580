

























import {
  ref,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount
} from '@vue/composition-api';
import GlobalFooter from '@/components/organisms/footers/GlobalFooter.vue';
import { isAppWebView } from '@/services/common/utils';

export default defineComponent({
  name: 'Container',
  components: { GlobalFooter },
  setup() {
    const isDispHeader = () => {
      return isAppWebView() ? false : true;
    };
    const bodyClasses = ref('wrap_notice');
    onBeforeMount(() => {
      if (isDispHeader()) {
        document.body.classList.add(bodyClasses.value);
      }
    });
    onBeforeUnmount(() => {
      if (isDispHeader()) {
        document.body.classList.remove(bodyClasses.value);
      }
    });

    return {
      isDispHeader
    };
  }
});
