















import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
  toRefs
} from '@vue/composition-api';
import router from '@/router/index';
import IconTypeSpan from '@/components/atoms/icons/IconTypeSpan.vue';
import IconTypeAnchor from '@/components/atoms/icons/IconTypeAnchor.vue';
import Gauge from '@/components/molecules/contents/Gauge.vue';
import {
  ICON_SORT,
  ICON_SELECT_PURCHASE
} from '@/commons/constants/icon-types';
import {
  ChapterHelperService,
  DAYS_LATER,
  HOUR_LATER,
  MIN_LATER
} from '@/services/index';
import { ContentInfo, Inventory, RentalConfig } from '@/models';
import { sendNclick } from '@/services';
import store from '@/store';
import { ContentsApiService } from '@/services/contents/contents.api';
import { Language } from '@/commons/constants/enum';
import { i18n, i18nWrapRuby, t } from '@/commons/i18n';

export default defineComponent({
  name: 'ControlChapters',
  components: {
    IconTypeSpan,
    IconTypeAnchor,
    Gauge
  },
  props: {
    content: ContentInfo,
    contentType: String,
    contentId: Number,
    orderType: String,
    showAllChapters: Boolean,
    chapters: Array,
    tabType: String
  },
  methods: {
    dispTotal(){
      let _key = '';
      if(typeof this.chapters === 'undefined') return '';
      
      if(this.contentType === 'magazine_comic'){
        if(this.tabType === 'EPISODE'){
          _key = 'number_of_all_episodes';
        }else{
          _key = 'number_of_all_volumes';
        }
      }else{
        _key = 'number_of_all_episodes';
      }
      const _total = this.$t(_key).toString() as string;
      return _total.replace(/%d/, this.chapters.length.toString());
    }
  },
  setup(props, { emit, root }) {
    const { getters } = store;
    const { showAllChapters } = toRefs(props);

    const isAuth: ComputedRef<boolean> = computed(
      () => getters['AuthStore/isAuth']
    );
    const lang: ComputedRef<Language> = computed(
      () => getters['I18nStore/getCookieLanguage']
    );
    const contentInfo: ComputedRef<ContentInfo> = computed(
      () => props.content as ContentInfo
    );
    const rentalConfig: ComputedRef<RentalConfig | undefined> = computed(
      () => contentInfo.value?.rentalConfig
    );
    const inventory: ComputedRef<Inventory | undefined> = computed(
      () => contentInfo.value?.inventory
    );
    const adsEnable = computed(() => contentInfo.value?.adsEnabled);
    const gaugeState = computed(() => {
      if (adsEnable.value) {
        // 광고 설정 된 경우 : 게이지 숨김
        return false;
      } else {
        // 광고 설정 안된 경우 : 게이지 노출
        return rentalConfig.value && rentalConfig.value.gaugeUsable;
      }
    });

    const order = computed(() => props.orderType);
    const icoSort = ref(ICON_SORT.clsName);
    const label = ref(root.$t('oldest'));
    const gaugeLabel: ComputedRef<string> = computed(() =>
      isAuth.value
        ? getGaugeLabel(inventory.value)
        : root.$t('gauge_read_now') + ''
    );
    const gaugePercent: ComputedRef<number> = computed(() => {
      if (isAuth.value) {
        return ChapterHelperService.getWaitForFreePercent(
          rentalConfig.value,
          inventory.value
        );
      } else {
        return 100;
      }
    });

    const onSortClick = () => {
      emit('changeSort', {});
      sendNclick(
        router.currentRoute.path,
        'content.order',
        '' + contentInfo.value?.id,
        '',
        order.value === 'oldest' ? 'O' : 'N'
      );
    };

    const getLabel = (value: string) =>
      value === 'oldest' ? root.$t('oldest') : root.$t('newest');

    const getDirection = (value: string) =>
      value === 'oldest' ? ICON_SORT.clsName : `${ICON_SORT.clsName} ico_up`;

    const getNumberOf = (type: string) => {
      switch (type) {
        case DAYS_LATER:
          return i18n.t('number_of_days');
        case HOUR_LATER:
          return i18n.t('number_of_hours');
        case MIN_LATER:
          return i18n.t('number_of_minutes');
      }
    };

    const getGaugeLabel = (inventory: Inventory | undefined) => {
      try {
        if (inventory && inventory.readForFree) {
          const { readForFree } = inventory;
          const { recoverRemained, amount } = readForFree; // 남은기간
          if (amount && amount > 0) {
            return root.$t('gauge_read_now') + '';
          } else {
            const { time, type } = ChapterHelperService.getRemainedWithLabel(
              recoverRemained
            );
            const keys = getNumberOf(type);
            if (keys) {
              const resultValue =
                time === '1'
                  ? keys['one'] || keys['other']
                  : keys['other'] || keys['one']; // 단수, 복수
              return i18nWrapRuby(t('in'), i18nWrapRuby(resultValue, time));
            } else {
              return '';
            }
          }
        } else {
          return '';
        }
      } catch (e) {
        return '';
      }
    };

    return {
      order,
      icoSort,
      ICON_SORT,
      label,
      ICON_SELECT_PURCHASE,
      rentalConfig,
      gaugeLabel,
      gaugePercent,
      adsEnable,
      gaugeState,
      onSortClick,
      getLabel,
      getDirection
    };
  }
});
