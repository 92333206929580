













































import { defineComponent, computed, ComputedRef } from '@vue/composition-api';
import Store from '@/store/index';
import ThumbRead from '@/components/molecules/contents-card/thumb/ThumbRead.vue';
import CardThumbnail from '@/components/molecules/contents-elements/CardThumbnail.vue';
import Badge from '@/components/atoms/templates/Badge.vue';
import IconAge from '@/components/atoms/icons/IconAge.vue';
import { ContentInfo } from '@/models/content-info';
import { gotoContentHome, createContentPath } from '@/services/routing/routing.helper';
import { AUTHOR_TYPE_CREATOR } from '@/commons/constants/author-types';
import { isPC } from '@/services/common/utils';

export default defineComponent({
  name: 'RankingContentCard',
  components: {
    Badge,
    CardThumbnail,
    ThumbRead,
    IconAge
  },
  props: {
    content: ContentInfo,
    rank: Number,
    read: Boolean,
    selectedItem: String,
    isContentType: Boolean,
    priorityToCoverFeatured: Boolean
  },
  setup(props, { root }) {
    const { getters } = Store;
    const del = computed(() => getters['LibraryStore/getContentDel']);
    const contentInfo: ComputedRef<ContentInfo> = computed(
      () => props.content as ContentInfo
    );
    const onContentClick = () => {
      gotoContentHome({
        contentType: contentInfo.value.type,
        contentId: contentInfo.value.id
      });
    };

    const contentTypeStr: ComputedRef<string> = computed(() => {
      const isTag =
        props.isContentType != undefined ? props.isContentType : true;
      if (isTag) {
        switch (contentInfo.value.type) {
          case 'novel':
          case 'magazine_novel':
            return root.$t('novels') + '';
          case 'comic':
          case 'magazine_comic':
          default:
            return '';
        }
      } else {
        return '';
      }
    });

    const authorName: ComputedRef<string> = computed(() => {
      const str = contentInfo.value.getAuthorRole(AUTHOR_TYPE_CREATOR);
      return str ? str : contentInfo.value.getAuthor();
    });

    return {
      del,
      contentInfo,
      authorName,
      contentTypeStr,
      gotoContentHome,
      onContentClick,
      createContentPath,
      isPC
    };
  }
});
