



































































import {
  computed,
  ComputedRef,
  defineComponent,
  ref,
  Ref,
  watch
} from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import Controller from '@/components/molecules/viewer/Controller.vue';
import IndexSlider from '@/components/molecules/viewer/IndexSlider.vue';
import TextSetting from '@/components/molecules/viewer/TextSetting.vue';
import ContentsTable from '@/components/molecules/viewer/ContentsTable.vue';
import { Chapter } from '@/models';
import { sendNclick } from '@/services';

export default defineComponent({
  name: 'ViewerControlModule',
  components: {
    Controller,
    IndexSlider,
    TextSetting,
    ContentsTable
  },
  props: {
    isPageMode: Boolean,
    contentType: String,
    settingInfo: Object,
    commentsLen: Number,
    content: Object,
    chapter: Object,
    slider: Object,
    isShow: {
      default: true,
      type: Boolean
    },
    navMap: Array,
    showSlider: Boolean,
    pageMode: String
  },
  setup(props, { emit }) {
    const { getters, commit } = store;
    const isShowSetting: Ref<boolean> = ref(false);
    const isShowTable: Ref<boolean> = ref(false);
    const isShow: ComputedRef<boolean> = computed(
      () => getters['NovelViewerStore/isShowController']
    );
    const isSettingShow: ComputedRef<boolean> = computed(
      () => getters['NovelViewerStore/isSettingShow']
    );

    const commentsLenInfo: ComputedRef<number> = computed(() =>
      props.commentsLen ? props.commentsLen : 0
    );
    const chapterInfo: ComputedRef<Chapter> = computed(
      () => props.chapter as Chapter
    );
    const isSlideType = computed(() => props.contentType === 'comic');

    const movePage = (value: number) => {
      emit('emitMovePage', value);
    };

    const changeMode = (isInit: boolean) => {
      isShowTable.value = false;
      if (!isInit) emit('emitChangeMode');
    };

    const viewerSetting = ({ type, index }) => {
      emit('emitViewerSetting', { type: type, index: index });
    };

    const selectTableChapter = (value) => {
      emit('emitSelectTableChapter', value);
    };

    const toggleTextSetting = () => {
      isShowTable.value = false;
      isShowSetting.value = !isShowSetting.value;
      commit('NovelViewerStore/isSettingShow', isShowSetting.value);
    };

    const toggleContentsTable = () => {
      isShowSetting.value = false;
      isShowTable.value = !isShowTable.value;
      commit('NovelViewerStore/isSettingShow', isShowTable.value);
    };

    const sendComments = () => emit('emitComments', {});
    const sendPrevNext = ({ type }: { type: string }) => {
      const chapter =
        type === 'PREV'
          ? chapterInfo.value.previousChapter
          : chapterInfo.value.nextChapter;
      emit('emitPrevNext', chapter);
      if (props.content && props.chapter)
        sendNclick(
          router.currentRoute.path,
          'chapter.' + (type === 'PREV' ? 'revious' : 'forward'),
          props.content.id,
          props.chapter.id,
          '' +
            (type === 'PREV'
              ? chapterInfo.value.previousChapter?.id
              : chapterInfo.value.nextChapter?.id)
        );
    };

    watch(isShow, (data) => {
      if (!data) {
        isShowTable.value = false;
        isShowSetting.value = false;
      }
    });

    watch(isSettingShow, (data) => {
      if (!data) {
        isShowTable.value = false;
        isShowSetting.value = false;
      }
    });

    return {
      isShowSetting,
      isShowTable,
      movePage,
      changeMode,
      viewerSetting,
      selectTableChapter,
      toggleTextSetting,
      toggleContentsTable,
      sendComments,
      sendPrevNext,
      commentsLenInfo,
      chapterInfo,
      isSlideType
      // enablePrev,
      // enableNext
    };
  }
});
