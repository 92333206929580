import { Menu } from '@/models';
import { createAxiosService } from '../common/create-axios.service';
const axiosService = createAxiosService();

export const getMenu = (contentCategory: string, menuHolderCode: string): Promise<{ menu: Menu }> => {
  return new Promise<{ menu: Menu }>(resolve => {
    const url = `/menu/${contentCategory}/${menuHolderCode}`;
    return axiosService
      .get(url)
      .then(res => {
        const { data } = res;
        resolve(data.data);
      })
  });
};

export const getMenuPathById = (
  path: string,
  contentCatalogId: string
) => {
  return new Promise<{ menu: Menu }>(resolve => {
    const url = `/menu/${path}/${contentCatalogId}`;
    return axiosService
      .get(url)
      .then(res => {
        const { data } = res;
        resolve(data.data);
      })
  });
}

export const getGenreMenu = (contentCategory: string, genre: string): Promise<{ menu: Menu }> => {
  return new Promise<{ menu: Menu }>(resolve => {
    const url = `/menu/${contentCategory}/genre/${genre}`;
    return axiosService
      .get(url)
      .then(res => {
        const { data } = res;
        resolve(data.data);
      })
  })
}

export const MenuApiSerivce = {
  getMenu,
  getGenreMenu,
  getMenuPathById
}