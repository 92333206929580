import { InspectorHelperService } from '@/services/common/inspector.helper';

/** TODO: Legacy API */
const getApi = (apiPath: string): boolean => {
  switch (true) {
    case InspectorHelperService.findPath(apiPath, '/payco/result'):
    case InspectorHelperService.findPath(apiPath, '/unlock'):
    case InspectorHelperService.findPath(apiPath, '/foregrounded'):
    case InspectorHelperService.findPath(apiPath, '/chapter'):
    case InspectorHelperService.findPath(apiPath, '/library/subscribed'):
    case InspectorHelperService.findPath(apiPath, '/challenge/comment/'):
    case InspectorHelperService.findPath(apiPath, '/challenge/') &&
      InspectorHelperService.findPath(apiPath, '/report/'):
      return true;
  }
  return false;
};

export const LegacyApiService = {
  getApi
};
