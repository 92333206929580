import { Route } from 'vue-router';
import { LogInfo } from '@/models/log-info';
import { isAOS, isIOS } from '@/services/index';
import { getLanguage, timezone } from '@/services/common/inspectors';

export const pathDivision = (path: string): (string)[] => {
  if (path.length === 1)
    return ['/'];

  return path.substr(1).split('/');
};

export const chapterActivityStatus = (activity: {
  rented: boolean, unlocked: boolean
}): string => {
  return activity.unlocked ? 'unlocked' : (activity.rented ? 'rental' : 'free');
};

export const cateNames = (str: object, { name, params, query }: Route, store, userid: string | number): object | undefined => {
  try {
    if (name && name.length > 0) {
      // name이 있을 경우 or name을 써야 하는 경우
      switch (name) {
        case 'LoginEmail': return { u: `/signin` }
        case 'JoinForm': return { u: `/signup` }
        case 'Search':
        case 'SearchKeyword':
          return { u: `/${str[0]}`, k: params['q'] }
        case 'gift':
        case 'message':
        case 'mission':
          return { u: `/${str[0]}`, k: str[1] }
        case 'Library': return { u: `/${str[0]}`, k: params['dep1Param'] }
        case 'ContentHome':
          const tabType = store.getters['ContentsHomeStore/tabType'];
          return { u: `/${str[0]}`, k: tabType === 'EPISODE' ? 'episode' : 'volume' }
        case 'ComicViewer':
          const comicChapter = store.getters['ComicViewerStore/chapter'];
          return { u: `/${str[0]}/chapter`, k: chapterActivityStatus(comicChapter['activity']) }
        case 'NovelViewer':
        case 'MagazineNovelViewer':
          const novelChapter = store.getters['NovelViewerStore/chapter'];
          return { u: `/${str[0]}/chapter`, k: chapterActivityStatus(novelChapter['activity']) }
        case 'MagazineComicViewer':
          const magazineChapter = store.getters['MagazineComicStore/chapter'];
          return { u: `/${str[0]}/chapter`, k: chapterActivityStatus(magazineChapter['activity']) }
        case 'Comment': return { u: `/comment/history`, k: userid }
        case 'CoinPurchase': return { u: `/coin/sales` }
        case 'CoinHistory':
          if (str[2] === 'distributed') return { u: `/coin/history/distributed` }
          if (str[2] === 'used') return { u: `/coin/history/used` }
        case 'UserInfoContents': return { u: `/profile` }
        case 'Setting': return { u: `/setting` }
        case 'inquiry': return { u: `/inquiry` }
        case 'FindPassword': return { u: `/password/forget` }
        case 'Home': return { u: str[0] || '/' }
      }
    } else {
      // name이 없을 경우
      // 서브카테고리, 뷰어, 뷰어댓글
      switch (str[0]) {
        case 'menu': return { u: `/menu/${params['dep1Param']}`, k: query['currentItemCode'] }
        case 'comment': return { u: `/${params['contentType']}/chapter/comment` }
        case 'mypage':
          if (str[2] === 'password') return { u: `/${str[2]}/change` }
        case 'home': return { u: str[0] || '/' }
      }
    }
  } catch {
    //
  }
};

/**
 * 전달 할 데이터
 * softwareId: process.env.VUE_APP_DOMAIN
 */
const queryString = (to: string, from: string, setParams: string): string => {
  const domainLang = process.env.VUE_APP_LANGUAGE;
  let serviceAppKey = 'comicoGlobalWeb';
  switch (true) {
    case domainLang === 'ko':
      serviceAppKey = 'comicoKrWeb';
      break;
    case domainLang === 'ja':
      serviceAppKey = 'comicoJpWeb';
      break;
    default:
      serviceAppKey = 'comicoGlobalWeb';
  }

  return 'u=' + serviceAppKey + to + '&e=' + serviceAppKey + from + setParams + '&EOU';
};

// UUID 생성, 중간 4는 고정 값
// 3c8d41a9-ff74-4720-af32-c9ed1b2afc66
const createUUID = () => {
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let dt = new Date().getTime();
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
};

/**
 * 옵션 필터링 셋팅
 * u={서비스/화면영역명}&
 * e={리퍼러}&
 * TID={작품ID}&
 * ArID={화ID}&
 * k={}&
 * UID={userid}&
 * DID=app&
 * os={I / A}&
 * UUID={uuid}&
 * LNG={en-US}&
 * tz={Asia/Tokyo}&
 * pushid={}&
 * adid={adid}&
 * immutableid={immutableid}&
 * store={A, G, O}&
 * usertype={guest/공백}&EOU
 */
const paramsGroup = (querys: LogInfo): string => {
  let ps = '';
  for (const k in querys) {
    ps += `&${k}=${querys[k]}`;
  }
  return ps;
};

const bipOs = (): string => {
  return isAOS() ? 'A' : isIOS() ? 'I' : 'O';
}

// 대량 발송시를 대비하여 수정.
const sendBip = (pathTo: string, pathFrom: string, querys: LogInfo): void => {
  try {
    const obj = Object.assign({}, querys, {
      UID: sessionStorage.getItem('userId') || '',
      UUID: sessionStorage.getItem('neoIdUid') || '',
      immutableid: sessionStorage.getItem('ipAddress') || '0.0.0.0',
      DID: 'web',
      LNG: getLanguage(),
      tz: timezone(),
      pushid: '',
      adid: '',
      os: bipOs(),
      store: 'O',
      usertype: ''
    });

    const setParams = paramsGroup(obj);
    const baseUrl: HTMLAnchorElement = document.createElement('a');
    baseUrl.href = 'https://lcs.comico.jp/m';
    baseUrl.search = queryString(pathTo, pathFrom, setParams);
    const img = new Image();
    const images = {};
    const uuid = createUUID();
    images[uuid] = img;
    const purge = () => { delete images[uuid] };
    img.addEventListener('load', purge);
    img.addEventListener('error', purge);
    img.addEventListener('abort', purge);
    img.src = baseUrl.href;
    // console.log('img.src: ', baseUrl.href);
  } catch (e) {
    console.log(e);
  }
};

export { sendBip };