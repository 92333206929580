import { ChallengeContent } from './challenge-content';

export interface ChallengeSearchPage {
  totalPages: number;
  totalElements: number;
  currentPageNo: number;
  pageSize: number;
  hasNext: boolean;
}

export class ChallengeSearch {
  page: ChallengeSearchPage;
  contents: ChallengeContent[];

  constructor(data: ChallengeSearch) {
    this.page = data.page;
    this.contents = data.contents
      ? data.contents.map((node) => new ChallengeContent(node))
      : [];
  }
}
