import { NoticeFaqApi } from '@/services/mypage/notice-faq.api';
import { RootState } from '@/store/index';
import { Module } from 'vuex';
import {
  CategoryInterface,
  DocumentListInfterface,
  DocumentDetailInterface
} from '@/models';

interface NoticeFaqInterface {
  category: CategoryInterface[];
  documentList: DocumentListInfterface[];
  detail: DocumentDetailInterface;
  currentCategory: string;
}

export const NoticeFaqStore: Module<NoticeFaqInterface, RootState> = {
  namespaced: true,
  state: {
    category: [],
    documentList: [],
    detail: {
      id: null,
      title: '',
      body: '',
      publishedAt: ''
    },
    currentCategory: ''
  },

  getters: {
    documentList: (state) => state.documentList,
    category: (state) => state.category,
    detail: (state) => state.detail,
    currentCategory: (state) => state.currentCategory
  },

  mutations: {
    setCategory: (state, payload) => (state.category = payload),
    setDocumentList: (state, payload) => (state.documentList = payload),
    resetDocumentList: (state) => (state.documentList = []),
    setDetail: (state, payload) => (state.detail = payload),
    resetDetail: (state) => (state.detail = {} as DocumentDetailInterface),
    setCurrentCategory: (state, payload) => (state.currentCategory = payload)
  },
  actions: {
    getCategory: async ({ commit, dispatch }, documentType: string) => {
      return await NoticeFaqApi.getCategory(documentType).then((res) => {
        commit('setCategory', res.data.category);
        if (res.result.code === 200) {
          dispatch('getDocumentList', res.data.category[0].path);
        }
        return res;
      });
    },

    getDocumentList: ({ commit }, categoryPath: string) => {
      NoticeFaqApi.getDocumentList(categoryPath).then((res) => {
        commit('setDocumentList', res.documents);
        commit('setCurrentCategory', categoryPath);
      });
    },

    getDocumentDetail: ({ commit }, params) => {
      NoticeFaqApi.getDocumentDetail(params).then((res) => {
        commit('setDetail', res.document);
      });
    }
  }
};
