import { createAxiosService } from '@/services/common/create-axios.service';
import { ChallengeSubscriptions } from '@/models/challenge/challenge-subscribe';
import { ChallengeHistorys } from '@/models/challenge/challenge-history';
const axiosService = createAxiosService();

/**
 * 즐겨찾기
 */
const getChallengeSubscriptions = (pageNo: number, pageSize: number) => {
  return new Promise<ChallengeSubscriptions>((resolve, reject) => {
    const path = `/challenge/subscribe?pageNo=${pageNo}&pageSize=${pageSize}`;
    return axiosService
      .get(path)
      .then((res) => {
        const { data, result } = res.data;
        if (result.code < 300) {
          resolve(new ChallengeSubscriptions(data));
        } else {
          reject({ code: result.code });
        }
      })
      .catch(reject);
  });
};

/**
 * 구독삭제
 */
const deleteSubscribed = (contentIds: number[]) => {
  return new Promise((resolve, reject) => {
    const path = `/challenge/subscribe`;
    return axiosService
      .delete(path, { params: { contentIds: contentIds.join(',') } })
      .then((res) => {
        const { data, result } = res.data;
        if (result.code < 300) {
          resolve(data);
        } else {
          reject(result);
        }
      })
      .catch(reject);
  });
};

/**
 * 열람이력
 */
const getChallengeHistorys = (pageNo: number, pageSize: number) => {
  return new Promise<ChallengeHistorys>((resolve, reject) => {
    const path = `/challenge/content/history?pageNo=${pageNo}&pageSize=${pageSize}`;
    return axiosService
      .get(path)
      .then((res) => {
        const { data, result } = res.data;
        if (result.code < 300) {
          resolve(new ChallengeHistorys(data));
        } else {
          reject({ code: result.code });
        }
      })
      .catch(reject);
  });
};

const getChallengeContentRead = (contentId, chapterId) => {
  return new Promise<ChallengeHistorys>((resolve, reject) => {
    const path = `/challenge/content/${contentId}/${chapterId}/read`;
    return axiosService.post(path).then().catch(reject);
  });
};

export const ChallengeBookshelfService = {
  getChallengeSubscriptions,
  deleteSubscribed,
  getChallengeHistorys,
  getChallengeContentRead
}