import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
import { reactive } from '@vue/composition-api';

Vue.use(VueCompositionApi);

export const Unlocked = reactive<{
  entered: boolean
  hidden_works: boolean
  edit_add: boolean
  edit_minus: boolean
  is_changed: boolean
}>({
  entered: false,
  hidden_works: false,
  edit_add: false,
  edit_minus: false,
  is_changed: false
});