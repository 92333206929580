












import { defineComponent } from '@vue/composition-api';
import RecommendsSection from '@/components/molecules/sections/RecommendsSection.vue';

export default defineComponent({
  name: 'Recommend',
  components: {
    RecommendsSection
  },
  props: {
    recommend: Object,
    setIndex: Boolean
  }
});
