














import { Badge } from '@/models';
import { ContentsHelperService } from '@/services/contents/contents.helper';
import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  ref,
  Ref
} from '@vue/composition-api';

export default defineComponent({
  name: 'SliderText',
  props: {
    title: String,
    text: String,
    badge: Object,
    status: String,
    original: Boolean,
    exclusive: Boolean
  },
  setup(props, { root }) {
    const badgeInfo: ComputedRef<Badge> = computed(() => props.badge as Badge);
    const statusInfo: ComputedRef<string | undefined> = computed(
      () => props.status
    );
    const label: Ref<string> = ref('');
    const clsName: Ref<string> = ref('');

    const initBadge = (data: { type: string | undefined; label?: string }) => {
      if (!(data && data.type)) return;
      switch (data.type) {
        case 'event':
          label.value = data.label ? data.label : '';
          clsName.value = 'event';
          break;
        case 'started':
          label.value = root.$t('NEW') + '';
          clsName.value = 'new';
          break;
        case 'updated':
          label.value = root.$t('UP') + '';
          clsName.value = 'up';
          break;
        case 'completed':
          label.value = root.$t('COMPLETED') + '';
          // done 스타일 삭제, new 스타일로 대체
          clsName.value = 'new';
          break;
      }
    };

    onMounted(() => {
      const result = ContentsHelperService.getBadgeStatus(
        badgeInfo.value,
        statusInfo.value || ''
      );
      initBadge(result);
    });
    return {
      label,
      clsName
    };
  }
});
