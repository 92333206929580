import { createAxiosService } from '@/services/common/create-axios.service';
import { HttpResponseInterface } from '@/models';

const axiosService = createAxiosService();

/**
 * 버전 호출
 * "versions" : [ "2021-07-20", "2021-07-20", "2021-07-21" ]
 */
const getVersion = <T>(type: string): Promise<T> => {
  return new Promise(resolve => {
    const consentURL = `/app/consent/${type}`
    return axiosService.get(consentURL).then(res => {
      const { data } = res;
      resolve(data);
    });
  });
};

/** 약관 호출 */
const getPolicyContent = ({ type, version }: { type: string, version: string }): Promise<HttpResponseInterface> => {
  return new Promise(resolve => {
    const contentURL = `/app/consent/${type}/${version}`
    return axiosService.get(contentURL).then(res => {
      const { data } = res;
      resolve(data);
    });
  });
};

export const PolicyService = {
  getVersion,
  getPolicyContent
};
