



















import {
  computed,
  ComputedRef,
  defineComponent,
  Ref,
  ref
} from '@vue/composition-api';
import { Menu, MenuItem } from '@/models';
import store from '@/store';

interface MenuTabsProps {
  selected: string;
  homeMenu: Menu;
}

export default defineComponent({
  name: 'MenuTabs',
  props: {
    selected: String,
    homeMenu: Object
  },
  setup(props: MenuTabsProps, { emit }) {
    const { getters } = store;
    const pointerEvents: ComputedRef<boolean> = computed(
      () => getters['MenuStore/pointerEvents']
    );
    const homeMenu = computed(() => props.homeMenu);
    const list: Ref<MenuItem[]> = ref(homeMenu.value.items);
    const active: Ref<string> = computed(() => props.selected);

    const onMenuClick = (data: MenuItem) => emit('changeMenu', data);

    const getTitle = (code: string) => {
      const result = list.value.filter((node) => node.code === code).shift();
      return result?.code;
    };

    return {
      active,
      list,
      pointerEvents,
      onMenuClick,
      getTitle
    };
  }
});
