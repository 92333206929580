
















import { Ref, ref, defineComponent } from '@vue/composition-api';
import BadgeFree from '@/components/atoms/templates/BadgeFree.vue';
import TextOriginal from '@/components/atoms/templates/TextOriginal.vue';
import { gotoContentHome } from '@/services/routing/routing.helper';
import { ContentInfo } from '@/models/content-info';

export default defineComponent({
  name: 'SimpleThumbnail',
  components: {
    BadgeFree,
    TextOriginal
  },
  props: {
    content: ContentInfo
  },
  setup(props) {
    const contentRecoverable: Ref<boolean | undefined> = ref(
      props.content?.rentalConfig?.gaugeUsable
    );
    const contentThumbnail: Ref<string | undefined> = ref(
      props.content?.getThumbnail('cover')
    );
    const contentDescription: Ref<string | undefined> = ref(
      props.content?.description
    );

    return {
      contentRecoverable,
      contentThumbnail,
      contentDescription,
      gotoContentHome
    };
  }
});
