import { createAxiosService } from '../common/create-axios.service';
const axiosService = createAxiosService();
const config = {
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	}
};

interface searchInterface {
	query: string,
	pageNo: string,
	pageSize: string,
	contentType: string
};

const getSearchContents = (params: searchInterface): Object => {
	return new Promise(resolve => {
		const url = `/search`;
		const bodyFormData = new FormData();
		bodyFormData.append('query', params.query);
		bodyFormData.append('pageNo', params.pageNo);
		bodyFormData.append('pageSize', params.pageSize);
		// if (params.contentType) {
		// 	bodyFormData.append('contentType', params.contentType);
		// }
		return axiosService.post(url, bodyFormData, config).then(res => { const { data } = res; resolve(data.data); });
	})
}


export const SearchContentsService = {
	getSearchContents
}