import { HttpResponse } from '@/commons/constants/http.types';
import { ContentsItemInfo } from '@/models';
import { createAxiosService } from '../common/create-axios.service';
const axiosService = createAxiosService();

export function getContents(contentType: string, contentId: number) {
	return new Promise((resolve, reject) => {
		const url = `/${contentType}/${contentId}`;
		return axiosService
			.get(url)
			.then(res => {
				const response: HttpResponse = res.data;
				if(response.result.code === 200) {
					resolve(response.data);
				} else {
					reject({
						result: response.result,
						data: response.data
					});
				}
			});
	})
}

export function getContentsList(url: string, pageOption?: { pageNo: number, pageSize: number }): Promise<ContentsItemInfo> {
	return new Promise<ContentsItemInfo>((resolve, reject) => {
		const params = pageOption ? `?pageNo=${pageOption.pageNo}&pageSize=${pageOption.pageSize}` : '';
		return axiosService
			.get(`${url}${params}`)
			.then(res => {
				const { data } = res;
				resolve(data.data);
			})
			.catch(() => {
				reject([])
			});
	});
}

export function getContentsByUnit({ contentType, contentId, chapterUnit }: {
	contentType: string,
	contentId: number,
	chapterUnit: string
}): Promise<void> {
	const path = `/${contentType}/${contentId}/${chapterUnit}`;
	return new Promise<void>((resolve, reject) => {
		return axiosService.get(path).then(res => {
			const response: HttpResponse = res.data;
			if(response.result.code < 300) {
				resolve(response.data);
			} else {
				reject({
					result: response.result,
					data: response.data
				});
			}
		})
	})
}

export function getComingSoon({ contentType, contentId }: {
	contentType: string,
	contentId: string
}): Promise<void> {
	const path = `/${contentType}/coming_soon/${contentId}/product`;
	return new Promise<void>((resolve, reject) => {
		return axiosService.get(path).then(res => {
			const response: HttpResponse = res.data;
			if(response.result.code < 300) {
				resolve(response.data);
			} else {
				reject({
					result: response.result,
					data: response.data
				});
			}
		})
	})
}

export const ContentsApiService = {
	getContents,
	getContentsList,
	getContentsByUnit
}