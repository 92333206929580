














import { defineComponent } from '@vue/composition-api';
import { Genre } from '@/models';

export default defineComponent({
  name: 'GenreInfo',
  props: {
    genres: Array
  },
  setup(props, { emit }) {
    const onGenreClick = (genre: Genre) => emit('emitGotoGenre', genre);

    return {
      onGenreClick
    };
  }
});
