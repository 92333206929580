

































import {
  Ref,
  ref,
  ComputedRef,
  computed,
  defineComponent,
  watch,
  onMounted,
  nextTick,
  onUnmounted
} from '@vue/composition-api';
import Store from '@/store/index';

export default defineComponent({
  name: 'SearchSection',
  props: {
    userKeyword: String
  },
  setup(props, { emit }) {
    const { getters, commit } = Store;
    const deleteFlag: Ref<boolean> = ref(false);
    const inputField = ref<null | { focus: () => null; blur: () => null }>(
      null
    );
    const initFocus = computed(() => getters['SearchStore/getInitFocus']);
    const keyword: Ref<string | undefined> = ref(props.userKeyword);
    const storeKeyword: ComputedRef<string> = computed(
      () => getters['SearchStore/getKeyword']
    );

    /** 검색 */
    const onEnter = (e) => {
      if (!keyword.value || keyword.value.length === 0) return;
      e.preventDefault();
      keyword.value = keyword.value.substr(0, 100);
      commit('SearchStore/setKeyword', keyword.value);
      focusOut(e);
    };

    /** 키워드 존재 여부 확인 */
    const isKeyword = () => {
      return keyword.value && keyword.value.length > 0 ? true : false;
    };

    /** 검색바 아웃 */
    const focusOut = (event) => {
      event.target.blur();
      return setTimeout(() => {
        deleteFlag.value = false;
        emit('focusOut', event.target.className === 'btn_close' ? false : true);
      }, 10);
    };

    const onTouchMove = () => inputField.value && inputField.value.blur();

    /** 브라우저 back에 의한 검색어 갱신 */
    watch(storeKeyword, () => {
      keyword.value !== storeKeyword.value &&
        (keyword.value = storeKeyword.value);
      deleteFlag.value = false;
    });

    /**
     * ios input focus 상태에서 position fixed 버그 대응
     * // getEventListeners(window)['touchmove'].length
     */
    const initTouchMoveEvent = () => {
      window.addEventListener('touchmove', onTouchMove);
    };
    const deleteTouchMoveEvent = () => {
      window.removeEventListener('touchmove', onTouchMove);
    };

    /** 최초 진입 및 쿼리스트링 값이 없을 경우만 포커싱 */
    onMounted(() => {
      initTouchMoveEvent();
      nextTick(
        () => inputField.value && initFocus.value && inputField.value.focus()
      );
    });

    onUnmounted(() => {
      deleteTouchMoveEvent();
    });

    return {
      deleteFlag,
      isKeyword,
      inputField,
      initFocus,
      keyword,
      storeKeyword,
      onEnter,
      emit,
      focusOut
    };
  }
});
