var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"comico-global-header02",class:{
    up: !_vm.noneFixed && _vm.scrolled,
    'comico-global-header02--fixed _fixedHeader': !_vm.noneFixed
  },attrs:{"role":"navigation"},on:{"on":_vm.handleScroll}},[_c('div',{staticClass:"comico-global-header02__wrapper _globalHeader",class:{
      'comico-global-header02__wrapper--transparent':
        _vm.isMobile && _vm.router.currentRoute.name === 'ChallengeContent'
    }},[_c('div',{staticClass:"comico-global-header02__main"},[_c('div',{staticClass:"comico-global-header02__main-action1"},[_c('div',{staticClass:"comico-global-header02__site-logo"},[_c('p',[_c('routerLink',{staticClass:"comico-global-header02__site-logo-inner",attrs:{"to":"/"}},[_vm._v(" comico ")])],1)]),_vm._m(0)]),_c('div',{staticClass:"comico-global-header02__main-action2"},[_c('ul',{staticClass:"comico-global-header02__list-nav2"},[_c('btn-user',{attrs:{"isAuth":_vm.isAuth}}),_vm._m(1)],1),_c('btn-register',{attrs:{"isAuth":_vm.isAuth}})],1)])]),(!_vm.noneFixed)?_c('div',{staticClass:"comico-global-header02__wrapper _globalHeader challenge_nav"},[_c('nav',{staticClass:"comico-global-header02__sub"},[_c('div',{staticClass:"comico-global-header02__sub-category _globalHeaderSubCategory comico-global-header__sub-category--flex"},[_c('div',{staticClass:"comico-global-header02__sub-category-inner"},[_c('ul',{staticClass:"comico-global-header02__sub-category-list"},_vm._l((_vm.nav),function(item){return _c('li',{key:item.name,staticClass:"comico-global-header02__sub-category-item",class:{
                'comico-global-header02__sub-category-item--focus':
                  _vm.router.currentRoute.path === item.link
              }},[_c('routerLink',{staticClass:"comico-global-header02__sub-category-item-inner",attrs:{"to":{ path: item.link }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),0)])])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"comico-global-header02__list-nav"},[_c('li',{staticClass:"comico-global-header02__list-nav-item comico-global-header02__list-nav-item--focus"},[_c('span',{staticClass:"comico-global-header02__list-nav-item-inner"},[_vm._v("チャレンジ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"comico-global-header02__list-nav2-item comico-global-header02__list-nav2-item--bookshelf o-large-screen-object"},[_c('a',{staticClass:"comico-global-header02__list-nav2-item-inner",attrs:{"href":"#"}},[_c('span',{staticClass:"comico-global-header02__icon"},[_c('i',{staticClass:"i-bookshelf03"}),_c('span',{staticClass:"comico-global-header02__icon-notification"},[_vm._v("N")])])])])}]

export { render, staticRenderFns }