export const SHARE_BTN_PROPERTIES = [
  {
    name: 'facebook',
    src: require('@/assets/img/challenge/facebook_01.png')
  },
  {
    name: 'twitter',
    clsName: '_twitterShareBtn',
    src: require('@/assets/img/challenge/twitter_01.png')
  },
  {
    name: 'line',
    src: require('@/assets/img/challenge/line_01.png')
  }
];

export const ORDER_TYPE_NEWEST = 'newest';
export const ORDER_TYPE_OLDEST = 'oldest';

export const GENRES_KEYSET = {
  1: {
    genreId: 1,
    genreName: 'エッセイ',
    genreKey: 'essay'
  },
  2: {
    genreId: 2,
    genreName: '日常',
    genreKey: 'slice-of-life'
  },
  3: {
    genreId: 3,
    genreName: 'ギャグ',
    genreKey: 'gag'
  },
  4: {
    genreId: 4,
    genreName: 'ドラマ',
    genreKey: 'drama'
  },
  5: {
    genreId: 5,
    genreName: 'コメディ',
    genreKey: 'comedy'
  },
  6: {
    genreId: 6,
    genreName: 'BL（ライト）',
    genreKey: 'bl'
  },
  7: {
    genreId: 7,
    genreName: 'アクション',
    genreKey: 'action'
  },
  8: {
    genreId: 8,
    genreName: 'ホラー',
    genreKey: 'horror'
  },
  9: {
    genreId: 9,
    genreName: 'ギャンブル',
    genreKey: 'gamble'
  },
  10: {
    genreId: 10,
    genreName: 'ファンタジー',
    genreKey: 'fantasy'
  },
  12: {
    genreId: 12,
    genreName: 'スポーツ',
    genreKey: 'sports'
  },
  13: {
    genreId: 13,
    genreName: '歴史・時代劇',
    genreKey: 'historical'
  },
  14: {
    genreId: 14,
    genreName: '恋愛',
    genreKey: 'romance'
  },
  16: {
    genreId: 16,
    genreName: 'ミステリー',
    genreKey: 'mystery'
  },
  21: {
    genreId: 21,
    genreName: 'お色気',
    genreKey: 'sexy'
  },
  23: {
    genreId: 23,
    genreName: 'TL',
    genreKey: 'tl'
  },
  24: {
    genreId: 24,
    genreName: 'レディコミ',
    genreKey: 'lady-comic'
  },
  26: {
    genreId: 26,
    genreName: 'SF',
    genreKey: 'sci-fi'
  },
  27: {
    genreId: 27,
    genreName: '学園',
    genreKey: 'campus'
  },
  28: {
    genreId: 28,
    genreName: 'GL',
    genreKey: 'gl'
  }
};

export const imageCdn = process.env.VUE_APP_CHALLENGE_IMAGES_DOMAIN;

export const GENRES_NAV = [
  {
    name: 'ドラマ',
    genreId: 4,
    code: 'drama'
  },
  {
    name: '恋愛',
    genreId: 14,
    code: 'romance'
  },
  {
    name: 'アクション',
    genreId: 7,
    code: 'action'
  },
  {
    name: '学園',
    genreId: 27,
    code: 'campus'
  },
  {
    name: '日常',
    genreId: 2,
    code: 'slice-of-life'
  },
  {
    name: 'エッセイ',
    genreId: 1,
    code: 'essay'
  },
  {
    name: 'ファンタジー',
    genreId: 10,
    code: 'fantasy'
  },
  {
    name: 'コメディ',
    genreId: 5,
    code: 'comedy'
  },
  {
    name: 'ギャグ',
    genreId: 3,
    code: 'gag'
  },
  {
    name: 'BL',
    genreId: 6,
    code: 'bl'
  },
  {
    name: 'GL',
    genreId: 28,
    code: 'gl'
  },
  {
    name: 'ホラー',
    genreId: 8,
    code: 'horror'
  },
  {
    name: 'ミステリー',
    genreId: 16,
    code: 'mystery'
  },
  {
    name: '歴史・時代劇',
    genreId: 13,
    code: 'historical'
  },
  {
    name: 'スポーツ',
    genreId: 12,
    code: 'sports'
  },
  {
    name: 'SF',
    genreId: 26,
    code: 'sci-fi'
  },
  {
    name: 'ギャンブル',
    genreId: 9,
    code: 'gamble'
  },
  {
    name: '完結',
    genreId: 0,
    code: 'completed'
  }
];

export const BOOKSHELF_PROPERTIES = [
  {
    name: 'お気に入り',
    link: '/challenge/bookshelf/subscribe'
  },
  {
    name: '閲覧履歴',
    link: '/challenge/bookshelf/history'
  }
];

export const RANKING_NAV = [
  {
    name: '人気急上昇',
    code: 'popular'
  },
  {
    name: '新作',
    code: 'recent'
  },
  {
    name: '総合',
    code: 'total'
  },
  {
    name: 'コメント数',
    code: 'comment'
  },
  {
    name: '♡オススメ数',
    code: 'likes'
  }
];

export const DEFAULT_PAGE_STATE = {
  pageNo: 0,
  pageSize: 10
};
