import { createAxiosService } from '../common/create-axios.service';
const axiosService = createAxiosService();

export interface SubscribedType {
	contentType: string,
	contentId: number | null
}

export const setSubscribed = <T>({ contentType, contentId }: SubscribedType): Promise<T> => {
	return new Promise<T>((resolve, reject) => {
		const url = `/library/subscribed/${contentType}/${contentId}`;
		return axiosService
			.post(url)
			.then(res => {
				const { data } = res;
				if(data.result.code === 200) {
					resolve(data.data);
				} else {
					reject(data.result.code);
				}
			})
	});
}

export const deleteSubscribed = <T>({ contentType, contentId }: SubscribedType): Promise<T> => {
	return new Promise<T>((resolve, reject) => {
		const url = `/library/subscribed`;
		const body: SubscribedType[] = [{
			contentType,
			contentId
		}];
		return axiosService
			.delete(url, {
				data: body
			})
			.then(res => {
				const { data } = res;
				if(data.result.code === 200) {
					resolve(data.data);
				} else {
					reject(data.result.code);
				}
			})
	})
}

export const LibraryApiService = {
	setSubscribed,
	deleteSubscribed
};