import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { PolicyService } from '@/services';
import { PolicyStateInterface } from '@/models';

/**
 * PolicyStore
 */
export const PolicyStore: Module<PolicyStateInterface, RootState> = {
  namespaced: true,
  state: {
    consent: null,
    historyLen: null
  },
  getters: {
    getPolicy: (state) => state,
    getConsent: (state) => state.consent?.versions.reverse()
  },
  mutations: {
    setConsent(state, { data }) {
      state.consent = data;
    },
    setHistoryLen(state, params) {
      state.historyLen = params;
    }
  },
  actions: {
    async getVersion({ commit }, params) {
      const data = await PolicyService.getVersion(params);
      commit('setConsent', data);
      return data;
    }
  }
};
