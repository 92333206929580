import { Genre } from '@/models';
import { ChallengeContent } from '@/models/challenge/challenge-content';
import {
  ChallengeHome,
  ChallengeContents,
  ChallengeRanking
} from '@/models/challenge/challenge-home';
import { createAxiosService } from '@/services/common/create-axios.service';
const axiosService = createAxiosService();
const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
};

/**
 * 도전만화 - 작품홈
 * @param contentId
 * @returns
 */
export const getChallengeContent = (contentId: number) => {
  return new Promise<ChallengeContent>((resolve, reject) => {
    const path = `/challenge/content/${contentId}`;
    return axiosService
      .get(path)
      .then((res) => {
        const { data, result } = res.data;
        try {
          resolve(new ChallengeContent(data.content));
        } catch (e) {
          reject(result);
        }
      })
      .catch(reject);
  });
};

/**
 * 도전만화 - 홈
 * @returns
 */
export const getChallengeHome = async () => {
  try {
    const path = `/challenge/home`;
    const res = await axiosService.get(path);
    const { data, result } = res.data;
    // return new ChallengeHome(data);
    return data;
  } catch (e) {
    return undefined;
  }
};

/**
 * 도전만화 - 구독
 */
export const subscribe = (contentId: number) => {
  return new Promise((resolve, reject) => {
    const path = `/challenge/subscribe`;
    const params = new URLSearchParams();
    params.append('contentId', contentId + '');
    return axiosService
      .post(path, params, config)
      .then((res) => {
        const { data, result } = res.data;
        if (result.code < 300) {
          resolve(data);
        } else {
          reject(result);
        }
      })
      .catch(reject);
  });
};
/**
 * 도전만화 - 구독해제
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deleteSubscribed = (contentId: number) => {
  return new Promise((resolve, reject) => {
    const path = `/challenge/subscribe/${contentId}`;
    return axiosService
      .delete(path)
      .then((res) => {
        const { data, result } = res.data;
        if (result.code < 300) {
          resolve(data);
        } else {
          reject(result);
        }
      })
      .catch(reject);
  });
};

/**
 * 도전만화 - 장르
 * @returns
 */
export const getChallengeGenres = async (
  genreId = 4,
  pageNo = 0,
  orderType = 'recent',
  completed: string
): Promise<ChallengeContents[]> => {
  return new Promise((resolve, reject) => {
    const path = `/challenge/content/genre/${genreId}?pageNo=${pageNo}&pageSize=100&orderType=${orderType}&completed=${completed}`;
    return axiosService
      .get(path)
      .then((res) => {
        const { data, result } = res.data;
        if (result.code < 300) {
          resolve(data.contents);
        } else {
          reject(result);
        }
      })
      .catch(reject);
  });
};

/**
 * 도전만화 - 랭킹
 * @returns
 */
export const getChallengeRanking = async (
  rankingType = 'popular',
  pageNo = 0,
  orderType = 'likes'
): Promise<ChallengeRanking[]> => {
  return new Promise((resolve, reject) => {
    const path = `/challenge/content/ranking/${rankingType}?pageNo=${pageNo}&pageSize=100&orderType=${orderType}`;
    return axiosService
      .get(path)
      .then((res) => {
        const { data, result } = res.data;
        if (result.code < 300) {
          resolve(data.ranking);
        } else {
          reject(result);
        }
      })
      .catch(reject);
  });
};

/**
 * 도전만화 - 홈 작품
 * @returns
 */
export const getChallengeContents = async (
  pageNo = 0,
  orderType = 'recent'
): Promise<ChallengeContents[]> => {
  return new Promise((resolve, reject) => {
    const path = `challenge/contents?pageNo=${pageNo}&pageSize=100&orderType=${orderType}`;
    return axiosService
      .get(path)
      .then((res) => {
        const { data, result } = res.data;
        if (result.code < 300) {
          resolve(data.contents);
        } else {
          reject(result);
        }
      })
      .catch(reject);
  });
};

export const ChallengeApiService = {
  getChallengeContent,
  getChallengeHome,
  subscribe,
  getChallengeGenres,
  getChallengeRanking,
  getChallengeContents
};
