



















import { computed, defineComponent } from '@vue/composition-api';
import store from '@/store';
import router from '@/router/index';
import { CONTENT_TYPES } from '@/commons/constants/content.types';

export default defineComponent({
  name: 'ContentCategoryParts',
  setup() {
    const { dispatch, getters } = store;
    const contentCategory = computed(
      () => getters['HomeStore/contentCategory']
    );
    const sendClick = async (data: string) => {
      await dispatch('HomeStore/initHomeContent', {
        contentCategory: data
      });
      router.push('/').catch(() => {
        // same url
      });
    };
    return {
      sendClick,
      contentCategory,
      CONTENT_TYPES
    };
  }
});
