
















import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    menu: Array
  },
  setup(_props, { root }) {
    const router = ref(root.$router);
    return { router };
  }
});
