import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { ChallengeChapter } from '@/models/challenge/challenge-chapter';
import { ChallengeChapterResponse, ChallengeChapterService } from '@/services/challenge/challenge-chapter.api';
import { ChallengeContent } from '@/models/challenge/challenge-content';
import { subscribe, deleteSubscribed } from '@/services/challenge/challenge-content.api';

export interface ChallengeViewerTypes {
	chapter: ChallengeChapter | null;
	content: ChallengeContent | null;
	totalLikes: number;
	liked: boolean;
	isSubscribed: boolean;
	isHeader: boolean;
}

export const ChallengeViewerStore: Module<ChallengeViewerTypes, RootState> = {
	namespaced: true,
	state: {
		chapter: null,
		totalLikes: 0,
		liked: false,
		content: null,
		isSubscribed: false,
		isHeader: true
	},
	getters: {
		chapter: (state) => state.chapter,
		content: (state) => state.content,
		totalLikes: (state) => state.totalLikes,
		liked: (state) => state.liked,
		isSubscribed: (state) => state.isSubscribed,
		isHeader: (state) => state.isHeader,
	},
	mutations: {
		chapter(state: ChallengeViewerTypes, value: ChallengeChapter) {
			state.chapter = value;
		},
		content(state: ChallengeViewerTypes, value: ChallengeContent) {
			state.content = value;
		},
		totalLikes(state: ChallengeViewerTypes, value: number) {
			state.totalLikes = value;
		},
		liked(state: ChallengeViewerTypes, value: boolean) {
			state.liked = value;
		},
		isSubscribed(state: ChallengeViewerTypes, value: boolean) {
			state.isSubscribed = value;
		},
		isHeader(state: ChallengeViewerTypes, value: boolean) {
			state.isHeader = value;
		}
	},
	actions: {
		async initChapter({ commit }, { contentId, chapterId }: { contentId: number, chapterId: number }) {
			try {
				const result: ChallengeChapterResponse = await ChallengeChapterService.getChallengeChapter({
					contentId, chapterId
				});
				commit('chapter', result.chapter);
				commit('content', result.content);
				commit('totalLikes', result.chapter.totalLikes);
				commit('liked', result.chapter.liked);
				commit('isSubscribed', result.content.subscribed);
			} catch(e) {
				throw e;
			}
		},
		async likeChapter({ commit, state }, { contentId, chapterId }: { contentId: number, chapterId: number }) {
			try {
				await ChallengeChapterService.likeChapter({
					contentId,
					chapterId
				});
				commit('totalLikes', state.totalLikes + 1);
				commit('liked', true);
			} catch(e) {
				console.log(e);
			}
		},
		async subscribe({ commit, state }, { contentId }) {
			if(state.isSubscribed) {
				await deleteSubscribed(contentId);
				commit('isSubscribed', false);
			} else {
				await subscribe(contentId);
				commit('isSubscribed', true);
			}
		}
	}
};