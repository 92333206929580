












import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'OutLinkButton',
  props: {
    classWrap: String,
    classes: String,
    contents: Array
  },
  setup() {
    return {};
  }
});
