













import { computed, defineComponent, watch } from '@vue/composition-api';
import Container from '@/views/common/Container.vue';
import { isPC, ClassesHidden, isAppWebView } from '@/services/common/utils';
import { t } from '@/commons/i18n';

export default defineComponent({
  name: 'NotFound',
  components: {
    Container
  },
  setup(props, { root } ) {
    const isMobile = computed(() => !isPC());

    const isSuggestReload = () => {
      return root.$route.path.includes('/anniversary/10th');
    };

    const messageText = () => {
      if (isSuggestReload()) {
        return t('api_session_error_desc_bad_url_suggest_reload');
      }
      return t('api_session_error_desc_bad_url');
    };

    const pageReload = () => {
      window.location.reload();
    };

    watch(isMobile, (res: boolean) => {
      ClassesHidden(res, 'hidden');
    });
    return {
      isSuggestReload,
      pageReload,
      isAppWebView,
      messageText
    };
  }
});
