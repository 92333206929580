import { Module } from "vuex";
import { RootState } from '@/store/index';
import { Banner, ChapterImage } from "@/models";
import { ViewerInfo } from "@/models/viewer-info";
import { ChaptersApiService } from "@/services/contents/chapters.api";
import { commentApi } from "@/services/comment/comment.api";
import { Comment } from "@/models/comment-info";
import { TOOLTIP_DURATION } from "@/commons/constants/viewer-types";
import { getErrorDialogOptions } from "@/services";
import router from '@/router';
import { ERROR_CODES } from "@/commons/constants/error-codes";

export interface MagazineComicInterface {
	viewerInfo: ViewerInfo | null;
	chapterId: number | null;
	contentType: string;
	contentId: number | null;
	images: ChapterImage[];
	recommends: Element[];
	isMount: boolean;
	isSubscribed: boolean;
	direction: string;
	banners: Banner[];
	isTooltip: boolean;
	commentsLen: number;
};

export const MagazineComicStore: Module<MagazineComicInterface, RootState> = {
	namespaced: true,
	state: {
		viewerInfo: null,
		chapterId: null,
		contentType: '',
		contentId: null,
		images: [],
		recommends: [],
		isMount: true,
		isSubscribed: false,
		direction: 'horizontal',
		banners: [],
		isTooltip: false,
		commentsLen: 0
	},
	getters: {
		viewerInfo: state => state.viewerInfo,
		content: state => state.viewerInfo?.content,
		chapter: state => state.viewerInfo?.chapter,
		contentType: state => state.contentType,
		contentId: state => state.contentId,
		chapterId: state => state.chapterId,
		commentsLen: state => state.commentsLen,
		images: state => state.images,
		recommends: state => state.recommends,
		isMount: state => state.isMount,
		isSubscribed: state => state.isSubscribed,
		direction: state => state.direction,
		banners: state => state.banners,
		isTooltip: state => state.isTooltip
	},
	mutations: {
		viewerInfo(state, value: ViewerInfo) {
			state.viewerInfo = value;
		},
		contentType(state, value: string) {
			state.contentType = value;
		},
		contentId(state, value: number) {
			state.contentId = value;
		},
		chapterId(state, value: number) {
			state.chapterId = value;
		},
		commentsLen: (state, value: number) => {
			state.commentsLen = value;
		},
		images: (state, value: ChapterImage[]) => {
			state.images = value;
		},
		recommends(state, value: Element[]) {
			state.recommends = value;
		},
		isMount(state, value: boolean) {
			state.isMount = value;
		},
		isSubscribed(state, value: boolean) {
			state.isSubscribed = value;
		},
		direction(state, value: string) {
			state.direction = value;
		},
		banners(state, value: Banner[]) {
			state.banners = value;
		},
		isTooltip(state, value: boolean) {
			state.isTooltip = value;
		}
	},
	actions: {
		async initChapter({ commit, dispatch }, params) {
			try {

				const { contentType, contentId, chapterId } = params;
				const chapterResult = await ChaptersApiService.getChapter(params);
				const commentResult = await commentApi.getChapterComment({
					contentType,
					contentId,
					chapterId,
					orderType: 'likes'
				});
				const viewerInfo = new ViewerInfo(chapterResult);
				commit('contentType', params.contentType);
				commit('contentId', params.contentId);
				commit('chapterId', params.chapterId);
				// commit('hasTrial', params.chapterFileSalesType);
				commit('viewerInfo', viewerInfo);
				commit('recommends', viewerInfo.recommends);
				commit('banners', viewerInfo.banners);
				commit('images', viewerInfo.chapter.images);
				commit('commentsLen', commentResult.page?.totalElements);
				commit('isSubscribed',
					(viewerInfo.content?.activity && viewerInfo.content?.activity.subscribed)
						? viewerInfo.content?.activity.subscribed
						: false
				);
				return { images: viewerInfo.chapter.images, epub: viewerInfo.chapter.epub, mature: viewerInfo.content?.mature };
			} catch (e: any) {
				const { code } = e.result;
				switch (code) {
					case ERROR_CODES.CODE_404:
					case ERROR_CODES.CODE_303:
						const options = getErrorDialogOptions({
							message: e.result.message || e.result.debug
						});
						dispatch('DialogStore/dialog', options, { root: true }).then(() => {
							router.push('/').then(() => {
								location.reload();
							})
						});
						return;
					case ERROR_CODES.CODE_428:
					case ERROR_CODES.CODE_451:
					case ERROR_CODES.CODE_401:
						return { code };
				}
			}
		},
		showTooltip({ commit }) {
			commit('isTooltip', true);
			setTimeout(() => {
				commit('isTooltip', false);
			}, TOOLTIP_DURATION);
		}
	}
};