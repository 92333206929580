



























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    background: String,
    description: String,
    rank: Number
  },
  setup() {
    return {};
  }
});
