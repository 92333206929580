

















































import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import Store from '@/store/index';
import { t, i18nTxt, i18nWrapRuby } from '@/commons/i18n/index';
import { Badge, BadgeHiatus } from '@/components/atoms';
import IconAge from '@/components/atoms/icons/IconAge.vue';
import DeleteButton from '@/components/atoms/buttons/DeleteButton.vue';
import ConfirmDialog from '@/dialogs/ConfirmDialog.vue';
import { ContentInfo } from '@/models/content-info';
import {
  ContextHelperService,
  LibraryContentsService,
  DialogHelperServicepup,
  sendNclick,
  isPC,
} from '@/services';
import { AUTHOR_TYPE_CREATOR } from '@/commons/constants/author-types';
import { LANGUAGE_TYPES } from '@/commons/constants/config-language';

export default defineComponent({
  name: 'ThumbRead',
  components: {
    Badge,
    BadgeHiatus,
    IconAge,
    DeleteButton
  },
  props: {
    content: ContentInfo,
    idx: Number,
    selectedItem: String
  },
  setup(props, { root }) {
    const $q = root.$q;
    const { getters, commit } = Store;
    const contentType = props.content?.type;
    const contentId = props.content?.id;
    const getIdx = computed(() => props.idx);
    const del = computed(() => getters['LibraryStore/getContentDel']);
    const lang: ComputedRef<string> = computed(
      () => getters['I18nStore/getCookieLanguage']
    );
    const contentInfo: ComputedRef<ContentInfo> = computed(
      () => props.content as ContentInfo
    );
    const contentTypeCurrent: ComputedRef<string> = computed(
      () => getters['LibraryStore/getContentTypeCurrent']
    );
    const timeValue = i18nTxt('time_type').split('_');

    const addDateTxt = (type: string | undefined) => {
      let d = '';
      if (type === 'sec') d = timeValue[0];
      if (type === 'min') d = timeValue[0];
      if (type === 'hour') d = timeValue[1];
      if (type === 'day') d = timeValue[2];

      return d;
    };

    const dateFormat = ({ time, type }, lang: string, fnAddDateTxt: string) => {
      const current = new Date(time);
      const yy = current.getFullYear();
      const mm = current.getMonth();
      const dd = current.getDate();
      switch (true) {
        case lang === LANGUAGE_TYPES.KO_KR:
          return type !== 'date'
            ? `${time}${fnAddDateTxt}전`
            : `${yy}년 ${mm + 1}월 ${dd}일 ${fnAddDateTxt}`;
        default:
          return type !== 'date'
            ? ContextHelperService.useSingular(time, fnAddDateTxt, lang)
            : current.toLocaleString(lang, {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              }) +
                fnAddDateTxt +
                '';
      }
    };

    const formatting = (i18n, dateFormat) => {
      return i18nWrapRuby(root.$t(i18n).toString(), dateFormat);
    };

    /** 즐겨찾기 & 최근 본 작품 */
    const lastUpdateRead = (res, i18n) => {
      const isRecent = contentTypeCurrent.value === 'recent' && true;
      const isDate = res.type === 'date' && true;
      const params = dateFormat(res, lang.value, addDateTxt(res.type));
      try {
        if (!res) return '';
        if (!root['$isKo']) {
          if (isRecent && isDate) return params; // 최근 & 30일 이후
          if (!isRecent && !isDate) {
            return formatting('updated_ago', params); // 즐찾 & 30일 이전
          }
          if (!isRecent && isDate && lang.value === 'zh-TW') {
            return i18nWrapRuby(t('updated_long_ago'), params); // 즐찾 & zh-TW & 30일 이후, TODO: 국가별 개선 필요
          }
        }
        return formatting(i18n, params);
      } catch {
        return '';
      }
    };

    /** 대여 */
    const rentExpireDate = (res, i18n) => {
      try {
        if (!res) return '';
        return formatting(i18n, `${res.time}${addDateTxt(res.type)}`);
      } catch {
        return '';
      }
    };

    /**
     * 지금읽기
     * 지금읽기가 가능한지 판단: gaugeUsable
     * 내 남은 시간 카운트다운: recoverRemained
     * 총 걸리는 시간: recoverInterval
     * 앱의 경우 광고 시청은 오렌지 색으로 표기 되지만,
     * 웹의 경우 광고가 없기 때문에 recoverRemained(복구=남은 시간)이 0 일 때 '지금읽기' 파란색만 표시
     */
    const isReadNow = () => {
      const gaugeUsable = contentInfo.value?.rentalConfig.gaugeUsable;
      if (!gaugeUsable) return false;
      const recoverRemained =
        contentInfo.value?.inventory?.readForFree?.recoverRemained;
      if (recoverRemained && recoverRemained > 0) return false;
      return true;
    };

    const onDialogDelete = () => {
      DialogHelperServicepup.headerFixed(commit, true);
      $q.dialog({
        component: ConfirmDialog,
        componentProps: {
          title: i18nTxt('delete'),
          message: i18nTxt('dialog_delete'),
          ok: i18nTxt('delete'),
          cancel: i18nTxt('cancel'),
          class: 'btn_delete'
        }
      })
        .onOk(async () => {
          await LibraryContentsService.getLibraryContentsUpdate(
            contentType,
            contentId
          );
          commit('LibraryStore/contentDelete', {
            idx: getIdx.value,
            contentType,
            contentId
          });
          sendNclick(
            'library/' + props.selectedItem,
            'library.' + props.selectedItem + '.delete',
            contentType || '',
            '' + contentId,
            ''
          );
        })
        .onDismiss(() => {
          DialogHelperServicepup.headerFixed(commit, null);
        });
    };

    return {
      del,
      contentType,
      contentTypeCurrent,
      lastUpdateRead,
      rentExpireDate,
      isReadNow,
      onDialogDelete,
      AUTHOR_TYPE_CREATOR,
      isPC
    };
  }
});
