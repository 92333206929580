import { ChallengeContent } from './challenge-content';

export class ChallengeHistorys {
  contents: ChallengeContent[];

  constructor(data: ChallengeHistorys) {
    this.contents = data.contents
      ? data.contents.map((node) => new ChallengeContent(node))
      : [];
  }
}
