











import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    type: String
  },
  setup() {
    return {};
  }
});
