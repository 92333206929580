import { PurchaseButtons, SALES_CONFIG_RENTALLOWED, TYPE_PURCHASE_BUTTON, TYPE_SALES_STATE } from "@/commons/constants/sales-types";
import { Chapter, Inventory, RentalConfig, SalesConfig } from "@/models";


/**
		 * 무료화 체크
		 */
export const checkFree = (salesConfig: SalesConfig): Promise<void> => {
	return new Promise<void>((resolve, reject) => {
		const result = (salesConfig && salesConfig.isFree());
		if(result) {
			reject({
				action: TYPE_SALES_STATE.GOTO_VIEWER,
			});
		} else {
			resolve();
		}
	});
};

/**
 * 로그인체크
 */
export const checkSignIn = (isAuth: boolean): Promise<void> => {
	return new Promise<void>((resolve, reject) => {
		if(isAuth) {
			resolve();
		} else {
			reject({
				action: TYPE_SALES_STATE.GOTO_LOGIN
			})
		}
	});
};

/**
 * 유료화체크
 */
const checkPayment = (salesConfig: SalesConfig): Promise<void> => {
	return new Promise<void>(resolve => {
		const result = (salesConfig && salesConfig.getPrice() > 0);
		resolve();
	});
};

/**
		 * 현재 구입중인지 상태 체크
		 */
const checkUnlock = (chapter: Chapter): Promise<void> => {
	return new Promise<void>((resolve, reject) => {
		const result = (chapter && chapter.isUnlock());
		if(result) {
			reject({
				action: TYPE_SALES_STATE.GOTO_VIEWER
			});
		} else {
			resolve();
		}
	});
};


/**
 * @deprecated
 * 대여가능한 작품인지 체크
 */
const checkRentable = (salesConfig: SalesConfig): Promise<void> => {
	return new Promise<void>((resolve, reject) => {
		const result = (salesConfig && salesConfig.checkRentType(SALES_CONFIG_RENTALLOWED.GAUGE));
		if(result) {
			resolve();
		} else {
			reject({
				action: TYPE_SALES_STATE.DIALOG,
				buttons: [TYPE_PURCHASE_BUTTON.BUY_COIN]
			});
		}
	});
};

/**
 * 현재 대여중인지 체크
 */
const checkRented = (chapter: Chapter): Promise<void> => {
	return new Promise<void>((resolve, reject) => {
		const result = (chapter && chapter.isRented());
		if(result) {
			reject({
				action: TYPE_SALES_STATE.GOTO_VIEWER
			});
		} else {
			resolve();
		}
	});
};

/**
 * @deprecated
 * 프로모션 적용 여부 체크
 */
const checkPromotion = (): Promise<void> => {
	return new Promise<void>(resolve => {
		resolve();
	});
};

/**
 * @deprecated
 */
const checkTicket = (inventory: Inventory): Promise<void> => {
	return new Promise<void>((resolve, reject) => {
		const result = (inventory && inventory.getTicketAmount() > 0);
		if(result) {
			reject({
				action: TYPE_SALES_STATE.DIALOG,
				buttons: [
					TYPE_PURCHASE_BUTTON.RENT_TICKET,
					TYPE_PURCHASE_BUTTON.RENT_COIN,
					TYPE_PURCHASE_BUTTON.BUY_COIN
				]
			})
		} else {
			resolve(); // 티켓 없을경우
		}
	});
};

/**
 * @deprecated
 * 기다무 적용 체크
 */
const checkWaitForFree = (inventory: Inventory, salesConfig: SalesConfig, rentalConfig: RentalConfig): Promise<void> => {
	return new Promise<void>((resolve, reject) => {
		if(inventory && inventory.readForFree) {
			const { amount } = inventory.readForFree;
			const result = salesConfig
				&& (salesConfig.rentableRemained && salesConfig.rentableRemained > 0);

			if(rentalConfig.gaugeUsable) {
				if(amount && amount > 0) {
					resolve();
				} else {
					if(result) {
						resolve();
					} else {
						reject({
							action: TYPE_SALES_STATE.DIALOG,
							buttons: [
								TYPE_PURCHASE_BUTTON.WAITFORFREE_REMAINED,
								TYPE_PURCHASE_BUTTON.RENT_COIN,
								TYPE_PURCHASE_BUTTON.BUY_COIN
							]
						})
					}
				}
			} else {	// 기다무 불가
				reject({
					action: TYPE_SALES_STATE.DIALOG,
					buttons: [
						TYPE_PURCHASE_BUTTON.RENT_COIN,
						TYPE_PURCHASE_BUTTON.BUY_COIN
					]
				});
			}
		}
	});
};

/**
 * @deprecated
 */
const checkFilledWaitForFree = (percent: number): Promise<void> => {
	return new Promise<void>((resolve, reject) => {
		if(percent === 100) {
			resolve();
		} else {
			reject({
				action: TYPE_SALES_STATE.DIALOG,
				buttons: [
					TYPE_PURCHASE_BUTTON.WAITFORFREE,
					TYPE_PURCHASE_BUTTON.RENT_COIN,
					TYPE_PURCHASE_BUTTON.BUY_COIN
				]
			})
		}
	});
};

/**
 * @deprecated
 */
const getRemovedButton = (chapter: Chapter, buttons: Array<PurchaseButtons>, isKo: boolean, chapterUnit: string) => {
	const { salesConfig } = chapter;
	const { price, rentalPrice } = salesConfig;
	let btns = buttons;
	if(!(isKo || chapterUnit === 'episode') && chapter.hasTrial) {
		btns.push(TYPE_PURCHASE_BUTTON.PREVIEW);
	}
	if(salesConfig.isRentChapter()) {
		if(price === 0) {
			// 구매 가능하지만 0코인인 경우
			btns = remove(btns, TYPE_PURCHASE_BUTTON.BUY_COIN.type);
		}
	} else {
		// 구매불가
		btns = remove(btns, TYPE_PURCHASE_BUTTON.BUY_COIN.type);
	}
	if(salesConfig.isPurchaseChapter()) {
		if(rentalPrice === 0) {
			// 대여가능하지만 0코인인 경우
			btns = remove(btns, TYPE_PURCHASE_BUTTON.RENT_COIN.type);
		}
	} else {
		// 대여불가
		btns = remove(btns, TYPE_PURCHASE_BUTTON.RENT_COIN.type);
	}
	return btns;
}

const remove = (buttons: Array<PurchaseButtons>, type: string) =>
	buttons.filter(node => node.type !== type);

export interface SalesStateActionTypes {
	action: 'GOTO_VIEWER' | 'GOTO_LOGIN' | 'DIALOG';
	buttons?: PurchaseButtons[]
}

export const SalesStateHelperService = {
	checkFree,
	checkSignIn,
	checkPayment,
	checkUnlock,
	checkRented,
	checkRentable,
	checkPromotion,
	checkTicket,
	checkWaitForFree,
	checkFilledWaitForFree,
	getRemovedButton
}