import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { ChallengeContent } from '@/models/challenge/challenge-content';
import {
  ChallengeBookshelfService
} from '@/services/challenge/challenge-bookshelf.api';
import { ChallengeSubscribe } from '@/models/challenge/challenge-subscribe';

export interface ChallengeBookshelfTypes {
  subscriptions: ChallengeSubscribe[];
  historys: ChallengeContent[];
  hasNext: boolean;
}

const initState = (): ChallengeBookshelfTypes => {
  return {
    subscriptions: [] as ChallengeSubscribe[],
    historys: [] as ChallengeContent[],
    hasNext: true
  };
};

export const ChallengeBookshelfStore: Module<
  ChallengeBookshelfTypes,
  RootState
> = {
  namespaced: true,
  state: initState,
  getters: {
    subscriptions: (state) => state.subscriptions,
    historys: (state) => state.historys,
    selected: (state) => state.subscriptions.filter((node) => node.checked),
    hasNext: (state) => state.hasNext
  },
  mutations: {
    subscriptions(state: ChallengeBookshelfTypes, value: ChallengeSubscribe[]) {
      state.subscriptions = value;
    },
    historys(state: ChallengeBookshelfTypes, value: ChallengeContent[]) {
      state.historys = value;
    },
    setCheck(
      state,
      value: { subscribe: ChallengeSubscribe; checked: boolean }
    ) {
      state.subscriptions.forEach((node) => {
        if (node.contentId === value.subscribe.contentId) {
          node.checked = value.checked;
        }
      });
      state.subscriptions = [...state.subscriptions];
    },
    setCheckReset(state) {
      state.subscriptions.forEach((node) => (node.checked = false));

      state.subscriptions = [...state.subscriptions];
    },
    setChallengeHasNext: (state, res) => (state.hasNext = res),
    resetList: (state) => Object.assign(state, initState())
  },
  actions: {
    setBookshelfContents(
      { state, commit },
      { contents, type, pageNo }: { contents: []; type: string; pageNo: number }
    ) {
      if (pageNo === 0) commit('resetList');
      contents && contents.length > 0
        ? commit(type, [...state[type], ...contents])
        : commit('setChallengeHasNext', false);
    },
    async initSubscriptions(
      { dispatch },
      { pageNo, pageSize }: { pageNo: number; pageSize: number }
    ) {
      try {
        const result = await ChallengeBookshelfService.getChallengeSubscriptions(pageNo, pageSize);
        dispatch('setBookshelfContents', {
          contents: result.subscriptions,
          type: 'subscriptions',
          pageNo
        });
      } catch {
        // Global exceptionHandler
      }
    },
    async initHistorys(
      { dispatch },
      { pageNo, pageSize }: { pageNo: number; pageSize: number }
    ) {
      try {
        const result = await ChallengeBookshelfService.getChallengeHistorys(pageNo, pageSize);
        dispatch('setBookshelfContents', {
          contents: result.contents,
          type: 'historys',
          pageNo
        });
      } catch {
        // Global exceptionHandler
      }
    },
    async deleteSubscribed(
      { commit },
      {
        contentsIds,
        newContentList
      }: { contentsIds: number[]; newContentList: ChallengeSubscribe[] }
    ) {
      try {
        await ChallengeBookshelfService.deleteSubscribed(contentsIds);
        commit('subscriptions', newContentList);
      } catch {
        //
      }
    }
  }
};
