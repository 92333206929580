













import { defineComponent } from '@vue/composition-api';
import { Chapter } from '@/models';

export default defineComponent({
  name: 'ChapterThumbnail',
  props: {
    chapter: Chapter,
    isVerticalThumb: Boolean,
    skipThumbnail: Boolean
  }
});
