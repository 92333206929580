interface Window {
  fbq: any;
}
declare const window: Window;

const CURRENCY_LIST = {
  ko: 'KRW',
  en: 'USD',
  ja: 'YEN'
};
const currency = CURRENCY_LIST[process.env.VUE_APP_LANGUAGE];

const trackCustom = (eventName: string, params?: any) => {
  window.fbq('trackCustom', eventName, params);
};

const track = (eventName: string, params?: any) => {
  window.fbq('track', eventName, params);
};

const purchase = (amount: string | number) => {
  window.fbq('track', 'Purchase', { value: amount, currency });
};

const viewContent = (contentId: string | number) => {
  const params = {
    content_ids: contentId,
    content_type: 'product',
    contents: [{ id: contentId, quantity: 1 }],
    value: 0,
    currency
  };
  window.fbq('track', 'ViewContent', params);
};

const coinUsed = (params?: any) => {
  trackCustom('Coin_Used', { Coin_used: params });
};

const novelVisit = (params?: any) => {
  trackCustom('novel_visit', params);
};

const searchKeyword = () => {
  const uri: string = document.referrer;
  if (!uri) return;
  const link: HTMLAnchorElement = document.createElement('a');
  link.setAttribute('href', uri);
  const pattern: RegExp = new RegExp('[\\?&](?:q|query)=([^&#]*)');
  const query: null | Array<string> = link.search.match(pattern);
  let keyword: null | string = null;

  if (query && query.length > 0) {
    keyword = query[1].replace(/\+/gi, '%20');
  }

  if (keyword) {
    trackCustom('SearchKeyword', {
      hostname: link.hostname,
      keyword: decodeURIComponent(keyword)
    });
  }
};

const firstPurchase = (amount:string|number)=>{
  window.fbq('track', 'AddToCart', {
    content_name:'첫 결제',
    currency,
    amount:amount
  });
}

export const fbq = {
  trackCustom,
  track,
  viewContent,
  purchase,
  coinUsed,
  novelVisit,
  searchKeyword,
  firstPurchase
};
