
import jsSHA from '@/assets/js/sha.js';
// import CryptoJS from '@/assets/js/aes.js';
import CryptoJS from 'crypto-js'

export const AESKey = 'a7fc9dc89f2c873d79397f8a0028a4cd';

const sha256 = (text: string) => {
  const stringText = String(text);
  const shaObj = new jsSHA("SHA-256", "TEXT");
  shaObj.update(stringText);
  return shaObj.getHash("HEX");
};

const sha1 = (text: string) => {
  const stringText = String(text);
  const shaObj = new jsSHA("SHA-1", "TEXT");
  shaObj.update(stringText);
  return shaObj.getHash("HEX");
};

// const PBKDF2 = (text: string, key: string) => {
//   const iv = "4378110db6392f93e95d5159dabdee9b";
//   const salt = "18b00b2fc5f0e0ee40447bba4dabc952";
//   const plainText = text;
//   const keySize = 128;
//   const iterationCount = 100;
//   const passPhrase = key;

//   // PBKDF2 키 생성
//   const key128Bits100Iterations =
//     CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt),
//       { keySize: keySize / 32, iterations: iterationCount });

//   const encrypted = CryptoJS.AES.encrypt(
//     plainText,
//     key128Bits100Iterations,
//     { iv: CryptoJS.enc.Hex.parse(iv) });
//   return encrypted;
// }

// const AESECBPKCS5Padding = (text: string, key: string) => {
//   const iv = CryptoJS.enc.Utf8.parse(CryptoJS.enc.Hex);
//   const plainText = text;
//   const passPhrase = CryptoJS.enc.Utf8.parse(key);
//   const encrypted = CryptoJS.AES.encrypt(
//     plainText,
//     passPhrase,
//     {
//       iv: CryptoJS.enc.Hex.parse(iv),
//       mode: CryptoJS.mode.ECB
//     });
//   return encrypted;
// }

const AESDecoder = (text: string, key: string) => {
  const iv = CryptoJS.enc.Utf8.parse(CryptoJS.enc.Hex.parse(''));
  const passPhrase = CryptoJS.enc.Utf8.parse(key);
  const decrypted = CryptoJS.AES.decrypt(text, passPhrase, {
    iv,
    mode: CryptoJS.mode.CBC
  });
  return toUtfString(decrypted);
}
const AESEncoder = (text: string, key: string) => {
  const iv = CryptoJS.enc.Utf8.parse(CryptoJS.enc.Hex.parse(''));
  const passPhrase = CryptoJS.enc.Utf8.parse(key);
  const encrypted = CryptoJS.AES.encrypt(text, passPhrase, {
    iv,
    mode: CryptoJS.mode.CBC
  });
  return encrypted;
}

const toUtfString = (str: string) => CryptoJS.enc.Utf8.stringify(str);

const Encrypto = {
  sha256,
  sha1,
  // PBKDF2,
  // AESECBPKCS5Padding,
  AESDecoder,
  AESEncoder,
  AESKey
};

export { Encrypto };
