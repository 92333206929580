



































import {
  Ref,
  ref,
  ComputedRef,
  computed,
  defineComponent
} from '@vue/composition-api';
import Store from '@/store';
import { Language } from '@/commons/constants/enum';
import date from 'quasar/src/utils/date.js';;
import { ExpireCoins } from '@/models/user-info';
import { t, i18n, i18nWrapRuby } from '@/commons/i18n/index';
import { PluralHelperService } from '@/services/common/plural.helper';
import { numberComma } from '@/helpers/filters';

export default defineComponent({
  name: 'ExpireCoinListDialog',
  setup(props, { emit }) {
    const { getters } = Store;
    const expireCoins: ComputedRef<ExpireCoins[]> = computed(
      () => getters['MyPageStore/getExpireCoin']
    );
    const lang: ComputedRef<Language> = computed(
      () => getters['I18nStore/getCookieLanguage']
    );
    const dialog = ref(false);
    const show = () => (dialog.value = true);
    const hide = () => (dialog.value = false);
    const onDialogHide = () => emit('hide');
    const onClose = () => hide();
    const isScrollEnded: Ref<boolean> = ref(false);
    const EXPIRES_MAX_DAY = 30;
    const DIMMED_MIN_SIZE = 10;

    const convertLocaleDate = (date: string) => {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
      } as const;
      return new Date(date).toLocaleDateString(lang.value, options);
    };

    const handleScroll = (evt) => {
      const scrollHeight = evt.target.scrollHeight;
      const offsetHeight = evt.target.offsetHeight;
      const scrolled = evt.target.scrollTop;
      const diff = scrollHeight - offsetHeight;

      if (diff <= scrolled) {
        isScrollEnded.value = !isScrollEnded.value;
      } else {
        isScrollEnded.value = false;
      }
    };

    const dimmed = () =>
      expireCoins.value.length < DIMMED_MIN_SIZE ? false : !isScrollEnded.value;

    return {
      EXPIRES_MAX_DAY,
      dialog,
      show,
      hide,
      onDialogHide,
      onClose,
      expireCoins,
      date,
      t,
      i18nWrapRuby,
      convertLocaleDate,
      handleScroll,
      isScrollEnded,
      PluralHelperService,
      dimmed
    };
  }
});
