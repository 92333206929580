


























import {
  Ref,
  ref,
  ComputedRef,
  computed,
  defineComponent,
  onBeforeMount
} from '@vue/composition-api';
import Store from '@/store/index';
import LanguageButton from '@/components/atoms/buttons/LanguageButton.vue';
import { saveCookie } from '@/helpers/cookies';
import { LanguageInterface } from '@/models';
import { LANGUAGE_KEY } from '@/commons/constants/config-language';

export default defineComponent({
  name: 'LanguageSelect',
  components: {
    LanguageButton
  },
  props: {
    lang: String
  },
  setup(props, { root }) {
    const { dispatch, getters } = Store;
    const stateSelect: Ref<boolean> = ref(false);
    const isPort: Ref<string> = ref(
      process.env.VUE_APP_PHASE === 'LOCAL' ? ':8080' : ''
    );
    const getLanguage: ComputedRef<LanguageInterface[]> = computed(
      () => getters['GlobalStore/getConfig'].language
    );
    const ordered: Ref<LanguageInterface[]> = ref([]);

    const env = () => {
      switch (process.env.VUE_APP_PHASE) {
        case 'LOCAL':
        case 'DEV':
          return 'alpha-';
        case 'STG':
          return 'beta-';
        default:
          return '';
      }
    };

    const currentLang = () => {
      try {
        return getLanguage?.value?.filter(
          (l) => l.code === root.$i18n.locale
        )[0]['label'];
      } catch {
        //
      }
    };

    const onChangeLan = (lang: string) => {
      try {
        saveCookie(LANGUAGE_KEY, lang, { expires: 1 });
        root.$i18n.locale = lang;
        if (!root.$route.query.languageCode) {
          location.reload();
        } else {
          location.href = '/';
        }
      } catch {
        location.href = '/';
      }
    };

    onBeforeMount(async () => {
      try {
        await dispatch('GlobalStore/config', 'language');
        ordered.value = getLanguage.value.sort((a, b) => a['sort'] - b['sort']);
      } catch {
        // console.log('error:', error);
      }
    });

    return {
      currentLang,
      ordered,
      stateSelect,
      isPort,
      env,
      onChangeLan
    };
  }
});
