











import router from '@/router';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    itemList: [Array, Boolean],
    itemType: {
      type: String,
      default: 'author'
    }
  },
  setup() {
    const searchProductInfo = (type: string, item: string) => {
      const keyword = encodeURIComponent(`${type}:${item}`);
      router.push(`/search/${keyword}`);
    };

    return { searchProductInfo };
  }
});
