




















import { defineComponent } from '@vue/composition-api';
import Badge from '@/components/atoms/templates/Badge.vue';
import { numToShortForm } from '@/services/common/utils';

export default defineComponent({
  name: 'BigThumbnail',
  components: {
    Badge
  },
  props: {
    element: Object
  },
  setup(props, context) {
    const onClick = () => {
      context.emit('gotoContents', props.element);
    };
    return {
      numToShortForm,
      onClick
    };
  }
});
