import { render, staticRenderFns } from "./GiftInfo.vue?vue&type=template&id=9012466c&scoped=true&"
import script from "./GiftInfo.vue?vue&type=script&lang=ts&"
export * from "./GiftInfo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9012466c",
  null
  
)

export default component.exports