








import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'EmptyInbox',
  props: {
    emptyTxt: Object,
    isGift: Boolean
  },
  setup(props) {
    const contentType = () => (props.isGift ? 'img_gift' : 'img_message');
    return { contentType };
  }
});
