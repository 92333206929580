
















import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import IconTypeAnchor from '@/components/atoms/icons/IconTypeAnchor.vue';
import { ICON_PRODUCT_HELP } from '@/commons/constants/icon-types';
import store from '@/store';
import { DialogStoreOption } from '@/store/dialog';

export default defineComponent({
  name: 'Gauge',
  components: {
    IconTypeAnchor
  },
  props: {
    gaugePercent: {
      type: Number,
      default: 0
    },
    label: String
  },
  setup(props, { root }) {
    const { dispatch } = store;
    const per: ComputedRef<number> = computed(() => props.gaugePercent);
    const sendClick = () => {
      const options: DialogStoreOption = {
        component: () => import('@/dialogs/GaugeGuide.vue'),
        param: {
          parent: root
        }
      };
      dispatch('DialogStore/dialog', options);
    };
    return {
      per,
      ICON_PRODUCT_HELP,
      sendClick
    };
  }
});
