import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { AppService } from '@/services/index';
import {
  ConfigInterface,
  VersionInterface,
  LanguageInterface,
  StoreInterface,
  CategorysInterface,
  WebviewInterface,
  ExternalInterface
} from '@/models';

interface BrowserState {
  browserMode: string | null;
  browserWidth: number;
  browserHeight: number;
}

export interface GlobalStateInterface {
  config: ConfigInterface;
  browserState: BrowserState;
  userMenuState: boolean | false;
  dimmed: boolean;
  dimmedDeep: boolean;
  currentPath: string | null;
  isNotification: boolean;
  notificationMessage: string;
  serviceAppkey: string | null;
  readingGuide: boolean;
  routeBackCount: number;
  isSearchPopup: boolean | null;
  tempInterval: number;
}

/** GlobalStore */
export const GlobalStore: Module<GlobalStateInterface, RootState> = {
  namespaced: true,
  state: {
    config: {
      version: {} as VersionInterface,
      language: {} as LanguageInterface,
      store: {} as StoreInterface,
      category: {} as CategorysInterface,
      webview: {} as WebviewInterface,
      externalIdp: {} as ExternalInterface
    },
    browserState: {
      browserMode: null,
      browserWidth: 0,
      browserHeight: 0
    },
    userMenuState: false,
    dimmed: false,
    dimmedDeep: false,
    currentPath: null,
    isNotification: false,
    notificationMessage: '',
    serviceAppkey: null,
    readingGuide: false,
    routeBackCount: 0,
    isSearchPopup: null,
    tempInterval: 0
  },
  getters: {
    getConfig: (state) => state.config,
    getBrowserState: (state) => state.browserState,
    getUserMenuState: (state) => state.userMenuState,
    getDimmed: (state) => state.dimmed,
    getDimmedDeep: (state) => state.dimmedDeep,
    getCurrentPath: (state) => state.currentPath,
    isNotification: (state) => state.isNotification,
    notificationMessage: (state) => state.notificationMessage,
    getServiceAppkey: (state) => state.serviceAppkey,
    readingGuide: state => state.readingGuide,
    getRouteBackCount: (state) => state.routeBackCount,
    getIsSearchPopup: (state) => state.isSearchPopup,
    tempInterval: (state) => state.tempInterval
  },
  mutations: {
    language: (state, params) => (state.config.language = params['language']),
    category: (state, params) => (state.config.category = params['category']),
    webview: (state, params) => (state.config.webview = params['webview']),
    store: (state, params) => (state.config.store = params['store']),
    browserState: (state, params: BrowserState) =>
      (state.browserState = params),
    setDimmed: (state, params: boolean) => (state.dimmed = params),
    setDimmedDeep: (state, params: boolean) => (state.dimmedDeep = params),
    setCurrentPath: (state, params: string) => (state.currentPath = params),
    setUserMenuState(state, payload?: boolean) {
      if(typeof payload === 'boolean') {
        state.userMenuState = payload;
      } else {
        state.userMenuState = !state.userMenuState;
      }
    },
    isNotification(state, value: boolean) {
      state.isNotification = value;
    },
    notificationMessage(state, value: string) {
      state.notificationMessage = value;
    },
    setServiceAppkey(state, payload: string) {
      state.serviceAppkey = payload;
    },
    readingGuide(state, value: boolean) {
      state.readingGuide = value;
    },
    setRouteBackCount: (state, value: number) => state.routeBackCount = value,
    setIsSearchPopup: (state, value: boolean) => state.isSearchPopup = value,
    tempInterval(state, value: number) {
      state.tempInterval = value;
    }
  },
  actions: {
    async config({ commit }, param) {
      const data = await AppService.config(param);
      commit(param, data.data);
    },
    browserState({ commit }, params) {
      commit('browserState', params);
    },
    popNotification(
      { commit },
      { message }: { message: string; duration: number }
    ) {
      commit('isNotification', true);
      commit('notificationMessage', message);
      setTimeout(() => {
        commit('isNotification', false);
      }, 2400);
    },
    showReadingGuide({ state, commit }) {
      commit('readingGuide', true);
      clearTimeout(state.tempInterval);
      state.tempInterval = setTimeout(() => {
        commit('readingGuide', false);
      }, 2400);
    }
  }
};
