






































import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  onUnmounted,
  watch
} from '@vue/composition-api';
import store from '@/store';
import { ChapterImage } from '@/models';
import { Encrypto } from '@/services';
import ImagePanel from '@/components/atoms/templates/ImagePanel.vue';
import throttle from 'quasar/src/utils/throttle.js';;
// import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import { READING_GUIDE_DIRECTION_LTR } from '@/commons/constants/viewer-types';

declare const Swiper;

export default defineComponent({
  name: 'ImageSwiperModule',
  components: {
    ImagePanel
  },
  props: {
    content: Object,
    chapter: Object,
    images: Array,
    index: Number,
    orientation: String,
    pageMode: String
  },
  setup(props, { emit }) {
    const { getters } = store;
    const VIEWER_NAME = 'swiper_comic_viewer';
    const swiperOption = {
      // slidesPerView: 1,
      // spaceBetween: 30,
      // loop: true
      // pagination: { el: '.swiper-pagination', clickable: true },
      navigation: {
        nextEl: `.${VIEWER_NAME}_next`,
        prevEl: `.${VIEWER_NAME}_prev`,
        clickable: true
      },
      direction: 'horizontal',
      // height: window.innerHeight,
      // direction: 'vertical',
      init: true,
      speed: 100
    };
    const isWide: ComputedRef<boolean> = computed(
      () => getters['UIStore/isWide']
    );
    const pageIndex: ComputedRef<number> = computed(() =>
      props.index ? props.index : 0
    );
    const dir: ComputedRef<string> = computed(() =>
      props.orientation === READING_GUIDE_DIRECTION_LTR ? 'ltr' : 'rtl'
    );
    const pageModeInfo: ComputedRef<string> = computed(() =>
      props.pageMode ? props.pageMode : 'horizontal'
    );
    const isOddImg: ComputedRef<boolean> = computed(
      () => !isWide.value || pageModeInfo.value === 'vertical'
    );
    let swiper;

    const imagesInfo = computed(() => {
      if (isOddImg.value) {
        return props.images;
      } else {
        return imagesBundler(
          props.images ? (props.images as ChapterImage[]) : []
        );
      }
    });

    const imagesBundler = (images: ChapterImage[]) => {
      let arr: Array<ChapterImage[]> = [];
      let unitArr: ChapterImage[] = [];
      const len = images.length - 1;
      images.forEach((node, index) => {
        unitArr.push(node);
        if (index === len) {
          arr.push(unitArr);
        } else {
          if (index % 2) {
            arr.push(unitArr);
            unitArr = [];
          }
        }
      });
      return arr;
    };

    const decode = (image: ChapterImage) => {
      const { parameter, url } = image;
      const imgUrl = Encrypto.AESDecoder(url, Encrypto.AESKey);
      return `${imgUrl}?${parameter}`;
    };

    const createSwiper = (swiperOption: any) =>
      new Swiper(`.${VIEWER_NAME}`, swiperOption);

    const getCurrentIndex = () => swiper.activeIndex;
    const getMaxLen = () => swiper.slides.length;

    const onSlideChange = (e) => {
      const currentIndex = getCurrentIndex();
      const maxLen = getMaxLen();
      setIndex(currentIndex, maxLen);

      if (currentIndex >= maxLen - 1) {
        enterLastPage();
      }
    };
    const enterLastPage = () => {
      emit('emitReadFinished', {});
    };

    const setIndex = (currentIndex: number, maxLen: number) => {
      emit('emitSlideInfo', {
        max: maxLen,
        current: currentIndex
      });
    };

    const initSwiper = (swiperOption: any) => {
      swiper = createSwiper(swiperOption);
      swiper.on('slideChange', onSlideChange);
      // swiper.on('init', function () {
      //   console.log('init');
      // });
      const currentIndex = getCurrentIndex();
      const maxIndex = getMaxLen();
      setIndex(currentIndex, maxIndex);
      emit('emitRead', { swiper: true });
    };

    const gotoSlide = (index: number) => {
      if (swiper) {
        swiper.slideTo(index);
      }
    };

    const swiperUpdate = () => swiper.update();
    const getCalcedIndex = (direction: string) => {
      if (isWide.value) {
        return direction === 'vertical'
          ? swiper.activeIndex * 2
          : swiper.activeIndex / 2; // destroy 하기 전 인덱스 변수
      } else {
        return swiper.activeIndex;
      }
    };

    /**
     * rtl 정렬에서 changeDirection 로 direction 변경시 오류발생해서
     * swiper 삭제(destroy) 하고 다시 init 처리
     */
    const changeDirection = (direction: string) => {
      swiperOption.direction = direction;
      swiper.on('destroy', function () {
        swiperOption['initialSlide'] = getCalcedIndex(direction);
        // swiperOption['initialSlide'] = 0;
        // initSwiper(swiperOption);
        setTimeout(() => {
          initSwiper(swiperOption);
          // swiper.slideTo(0, 0, true);
        }, 250);
      });
      swiper.destroy();
      // swiper.changeDirection(data)
    };

    const addWheelEvent = (evt: WheelEvent) => {
      if (pageModeInfo.value === 'vertical') {
        const { deltaY } = evt;
        if (deltaY > 0) {
          // down
          swiper.slideNext();
        } else {
          // up
          swiper.slidePrev();
        }
      }
    };

    const throttler = throttle(addWheelEvent, 100);

    watch(pageIndex, (data: number) => {
      gotoSlide(data);
    });

    watch(pageModeInfo, (data: string) => {
      changeDirection(data);
    });

    onMounted(() => {
      initSwiper(swiperOption);
      window.addEventListener('wheel', throttler);
    });

    onUnmounted(() => {
      window.removeEventListener('wheel', throttler);
    });

    return {
      pageModeInfo,
      swiperOption,
      VIEWER_NAME,
      dir,
      imagesInfo,
      isWide,
      swiperUpdate,
      decode,
      emit
    };
  }
});
