import { createAxiosService } from '@/services/common/create-axios.service';
import { InboxGift, InboxMessage } from '@/models/inbox-info';
const axiosService = createAxiosService();

interface InboxInterface {
  gifts: InboxGift[];
  messages: InboxMessage[];
}

const getInbox = async (type: string): Promise<InboxInterface> => {
  const url = `/inbox/${type}`;
  const {
    data: { data }
  } = await axiosService.get(url).then((res) => res);
  return data;
};

const receiveGift = async (
  distributionType: string,
  itemConfigId: number
): Promise<Record<string, unknown>> => {
  const url = `/inbox/gift/receive/${distributionType}/${itemConfigId}`;
  const {
    data: { result }
  } = await axiosService.put(url, itemConfigId).then((res) => res);
  return result;
};

const receiveAllGift = async (): Promise<Record<string, unknown>> => {
  const url = `/inbox/gift/receive`;
  const {
    data: { result }
  } = await axiosService.put(url).then((res) => res);
  return result;
};

export const inboxApi = {
  getInbox,
  receiveGift,
  receiveAllGift
};
