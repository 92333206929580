import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { ChaptersApiService } from '@/services/contents/chapters.api';
import { ViewerInfo, SliderInfo } from '@/models/viewer-info';
import { commentApi } from '@/services/comment/comment.api';
import { Banner, ChapterImage, Section } from '@/models';
import { INDEX_SLIDER_TYPE_ABSOLUTE, SWITCHING_CONTROLUI_INTERVAL, TOOLTIP_DURATION } from '@/commons/constants/viewer-types';
import router from '@/router';
import { getErrorDialogOptions, getPageMode } from '@/services';
import { ERROR_CODES } from '@/commons/constants/error-codes';

export interface ComicViewerStoreInterface {
	viewerInfo: ViewerInfo | null;
	hasTrial: boolean;
	chapterId: number | null;
	contentType: string;
	contentId: number | null;
	isControlUi: boolean;
	commentsLen: number;
	images: ChapterImage[];
	recommends: Section[];
	isSubscribed: boolean;
	banners: Banner[];
	isTooltip: boolean;
	isNotification: boolean;
	controlInterval: number;
	sliderInfo: SliderInfo;
	mountSwiperModule: boolean;
	mountImageModule: boolean;
	pageMode: 'vertical' | 'horizontal';
};

export const ComicViewerStore: Module<ComicViewerStoreInterface, RootState> = {
	namespaced: true,
	state: {
		viewerInfo: null,
		hasTrial: false,
		chapterId: null,
		contentType: '',
		contentId: null,
		isControlUi: false,
		commentsLen: 0,
		images: [],
		recommends: [],
		isSubscribed: false,
		banners: [],
		isTooltip: false,
		isNotification: false,
		controlInterval: 0,
		sliderInfo: {
			type: INDEX_SLIDER_TYPE_ABSOLUTE,
			current: 1,
			max: 99
		} as SliderInfo,
		mountSwiperModule: true,
		mountImageModule: true,
		pageMode: 'horizontal'
	},
	getters: {
		viewerInfo: state => state.viewerInfo,
		content: state => state.viewerInfo?.content,
		chapter: state => state.viewerInfo?.chapter,
		contentType: state => state.contentType,
		contentId: state => state.contentId,
		chapterId: state => state.chapterId,
		isControlUi: state => state.isControlUi,
		commentsLen: state => state.commentsLen,
		images: state => state.images,
		recommends: state => state.recommends,
		isSubscribed: state => state.isSubscribed,
		banners: state => state.banners,
		isTooltip: state => state.isTooltip,
		controlInterval: state => state.controlInterval,
		sliderInfo: state => state.sliderInfo,
		mountSwiperModule: state => state.mountSwiperModule,
		mountImageModule: state => state.mountImageModule,
		pageMode: state => state.pageMode
	},
	mutations: {
		viewerInfo(state, value: ViewerInfo) {
			state.viewerInfo = value;
		},
		hasTrial(state, value: boolean) {
			state.hasTrial = value;
		},
		chapterId(state, value: number) {
			state.chapterId = value;
		},
		contentType(state, value: string) {
			state.contentType = value;
		},
		contentId(state, value: number) {
			state.contentId = value;
		},
		isControlUi: (state, value: boolean) => {
			state.isControlUi = value;
		},
		commentsLen: (state, value: number) => {
			state.commentsLen = value;
		},
		images: (state, value: ChapterImage[]) => {
			state.images = value;
		},
		recommends(state, value: Section[]) {
			state.recommends = value;
		},
		isSubscribed(state, value: boolean) {
			state.isSubscribed = value;
		},
		banners(state, value: Banner[]) {
			state.banners = value;
		},
		isTooltip(state, value: boolean) {
			state.isTooltip = value;
		},
		controlInterval(state, value: number) {
			state.controlInterval = value;
		},
		sliderInfo(state, value: SliderInfo) {
			state.sliderInfo = { ...state.sliderInfo, ...value };
		},
		mountSwiperModule(state, value: boolean) {
			state.mountSwiperModule = value;
		},
		mountImageModule(state, value: boolean) {
			state.mountImageModule = value;
		},
		pageMode(state, value: 'vertical' | 'horizontal') {
			state.pageMode = value;
		}
	},
	actions: {
		async initChapter({ commit, dispatch }, params) {
			try {
				const { contentType, contentId, chapterId } = params;
				const chapterResult = await ChaptersApiService.getChapter(params);
				const commentResult = await commentApi.getChapterComment({
					contentType,
					contentId,
					chapterId,
					orderType: 'likes'
				});
				const viewerInfo = new ViewerInfo(chapterResult);

				commit('contentType', params.contentType);
				commit('contentId', +params.contentId);
				commit('chapterId', +params.chapterId);
				commit('viewerInfo', viewerInfo);
				commit('recommends', viewerInfo.recommends);
				commit('banners', viewerInfo.banners);
				commit('images', viewerInfo.chapter.images);
				commit('commentsLen', commentResult.page?.totalElements);
				commit('pageMode', viewerInfo.content?.orientation ? getPageMode(viewerInfo.content?.orientation) : null);
				commit('isSubscribed',
					(viewerInfo.content?.activity && viewerInfo.content?.activity.subscribed)
						? viewerInfo.content?.activity.subscribed
						: false
				);
				return { images: viewerInfo.chapter.images, mature: viewerInfo.content?.mature };
			} catch (e: any) {
				const { code } = e.result;
				switch (code) {
					case ERROR_CODES.CODE_404:
					case ERROR_CODES.CODE_303:
						const options = getErrorDialogOptions({
							message: e.result.message || e.result.debug
						});
						dispatch('DialogStore/dialog', options, { root: true }).then(() => {
							router.push('/').then(() => {
								location.reload();
							})
						});
						return;
					case ERROR_CODES.CODE_428:
					case ERROR_CODES.CODE_451:
					case ERROR_CODES.CODE_401:
						return { code };
				}
			}
		},
		showControlUi({ commit, state }, reset) {
			const calc = (value: number) => {
				let count = value;
				const delay = 100;
				state.controlInterval = setInterval(() => {
					const condition = count === 0;
					commit('isControlUi', !condition);
					count = count - delay;
					if (condition) clearInterval(state.controlInterval);
				}, delay);
			}
			clearInterval(state.controlInterval);
			if (state.isControlUi) {
				if (reset) {
					calc(SWITCHING_CONTROLUI_INTERVAL);
				} else {
					commit('isControlUi', false);
				}
			} else {
				if (!reset) {
					calc(SWITCHING_CONTROLUI_INTERVAL);
				}
			}
		},
		showTooltip({ commit }) {
			commit('isTooltip', true);
			setTimeout(() => {
				commit('isTooltip', false);
			}, TOOLTIP_DURATION);
		},
		clearInterval({ state, commit }) {
			commit('isControlUi', false);
			clearInterval(state.controlInterval);
		},
		changePageMode({ state, commit }) {
			const value = state.pageMode === 'vertical' ? 'horizontal' : 'vertical';
			commit('pageMode', value);
		}
	}
}