








































import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import { imageCdn } from '@/commons/constants/challenge/challenge-properties';
import { convertDate, isPC } from '@/services/common/utils';
import ItemCover from '@/components/molecules/challenge/itemList/ItemCover.vue';
import ItemInfo from '@/components/molecules/challenge/itemList/ItemInfo.vue';

export default defineComponent({
  props: {
    rankingList: Array
  },
  components: {
    ItemCover,
    ItemInfo
  },
  setup(_props) {
    const isPc: ComputedRef<boolean> = computed(() => isPC());
    const setImagePath = (filePath: string, fileName: string): string =>
      `${imageCdn}${filePath}${fileName}`;

    return { setImagePath, convertDate, ItemCover, isPc };
  }
});
