import Vue from 'vue';
import ja from './ja.json';
import en from './en.json';
import zh from './tw.json';
import fr from './fr.json';
import de from './de.json';
import VueI18n from 'vue-i18n';
import { dateTimeFormats } from '@/commons/constants/i18n-types';
import {
  LANGUAGE_KEY,
  LANGUAGE_EXPIRES,
  LANGUAGE_PARAM_KEY,
  languageLongMap
} from '@/commons/constants/config-language';
import {
  getLanguageCookie,
  getCookie,
  saveCookie,
  setEnDefault,
  diffLang
} from '@/helpers/cookies';
import { getUrlParameterValue } from '@/services/common/url-parameter.helper';

Vue.use(VueI18n);

const messages = { ja, en, zh, fr, de };

/**
 * 스토어 언어
 */
const getLocale = (): string | undefined => {
  let getLen: string | undefined;
  try {
    getLen =
      getUrlParameterValue(LANGUAGE_PARAM_KEY) ||
      getCookie(LANGUAGE_KEY) ||
      process.env.VUE_APP_LANGUAGE;
    if (!getLen) {
      getLen = getLanguageCookie();
    }
  } catch {
    getLen = getLanguageCookie();
  }
  if (getLen.length === 2) getLen = languageLongMap[getLen];
  const diff: string | undefined = diffLang(
    getLen,
    process.env.VUE_APP_LANGUAGE
  );
  if (diff) getLen = diff;
  getLen = setEnDefault(getLen);
  saveCookie(LANGUAGE_KEY, getLen, { expires: LANGUAGE_EXPIRES });
  return getLen;
};

const i18n = new VueI18n({
  locale: getLocale(),
  fallbackLocale: getLanguageCookie(),
  messages,
  silentTranslationWarn: true,
  dateTimeFormats
});

const t = (value: string): string =>
  i18n.t(value).toString() ? i18n.t(value).toString() : `**${value}**`;
const i18nTxt = (value: string): string =>
  i18n.t(value).toString() ? i18n.t(value).toString() : `**${value}**`;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const i18nTObj = (value: string) => JSON.parse(JSON.stringify(i18n.t(value)));
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const i18nFormatting = (
  value: string,
  format: { [key: string]: string | number }
) => i18n.t(value, format).toString();
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const i18nWrapRuby = (ruby: string, value: string) =>
  ruby.replace(/%@|%d/g, value);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const i18nBreak = (value: string) => i18nTxt(value).split('\n').join('<br />');

// 単数か複数かで表記を変える
const i18nPluralNumberOf = (terms: string, num: number): string => {
  let keys = i18n.t(terms);
  if (!keys) return '';

  let translation;
  if (num == 1) {
    translation = keys['one'] || keys['other'];
  } else {
    translation = keys['other'] || keys['one'];
  }
  return i18nWrapRuby(translation, num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
};

// 最大単位（分以上）で丸めた表現の文字列を返す
// 3600 => '1時間'
// 120 => '2分'
// 10 => '1分'
// 0 => ''
const i18nMaximumUnit = (timeInterval: number = 0): string => {
  let terms: string
  let maxNumber: number
  if (timeInterval > 60 * 60 * 24) {
    maxNumber = Math.floor(timeInterval / 60 / 60 / 24);
    terms = 'plural_of_days';
  } else if (timeInterval > 60 * 60) {
    maxNumber = Math.floor(timeInterval / 60 / 60);
    terms = 'plural_of_hours';
  } else if (timeInterval > 0) {
    maxNumber = Math.floor(timeInterval / 60);
    terms = 'plural_of_minutes';
  } else {
    return ''
  }
  return i18nPluralNumberOf(terms, maxNumber);
};

export {
  i18n,
  t,
  i18nTxt,
  i18nTObj,
  i18nFormatting,
  i18nWrapRuby,
  i18nBreak,
  i18nPluralNumberOf,
  i18nMaximumUnit,
  getLocale
};
