

















































import {
  ComputedRef,
  computed,
  defineComponent,
  onUpdated,
  ref,
  Ref,
  onMounted
} from '@vue/composition-api';
import IconTypeSpan from '@/components/atoms/icons/IconTypeSpan.vue';
import { CommentHelperService } from '@/services';

export default defineComponent({
  name: 'Controller',
  components: {
    IconTypeSpan
  },
  props: {
    isPageMode: Boolean,
    contentType: String,
    enablePrev: Boolean,
    enableNext: Boolean,
    currentCommentsLength: Number,
    commentEnabled: Boolean,
    orientation: String,
    isShowSetting: Boolean,
    showSlider: Boolean
  },
  setup(props, { emit }) {
    const buttonToggle: Ref<boolean> = ref(false);
    const commentLen: ComputedRef<number | string> = computed(() =>
      CommentHelperService.countComments(props.currentCommentsLength)
    );
    const changeModeClick = () => {
      emit('emitChangeMode');
    };
    const toggleTextSetting = () => {
      buttonToggle.value = !buttonToggle.value;
      emit('emitToggleTextSetting', {});
    };

    const sendClick = () => emit('emitComments', {});
    const onPrevClick = () => emit('emitPrevNext', { type: 'PREV' });
    const onNextClick = () => emit('emitPrevNext', { type: 'NEXT' });

    onUpdated(() => {
      buttonToggle.value = props.isShowSetting;
    });

    onMounted(() => {
      emit('emitChangeMode', true);
    });

    return {
      buttonToggle,
      changeModeClick,
      toggleTextSetting,
      sendClick,
      onPrevClick,
      onNextClick,
      commentLen
    };
  }
});
