












import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'InputDeleteButton',
  props: {
    isShow: Boolean
  },
  setup(props, { emit }) {
    return {
      emit
    };
  }
});
