import { i18nTxt } from '@/commons/i18n/index';

export const POLICY_TYPES = {
  terms_of_use: i18nTxt('terms_of_use'),
  guest_terms_of_use: i18nTxt('terms_of_use'),
  privacy_policy: i18nTxt('privacy_policy'),
  youth_protection_policy: i18nTxt('youth_protection_policy'),
  privacy_collection_and_use: i18nTxt('agree_privacy_policy'),
  guest_privacy_collection_and_use: i18nTxt('agree_privacy_policy')
};
