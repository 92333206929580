import jsCookies, { CookieAttributes } from 'js-cookie';
import { Language } from '@/commons/constants/enum';
import { LANGUAGE_KEY, LANGUAGE_TYPES, LANGUAGE_EXPIRES, acceptShortList, languageLongMap, languageConvertMap, acceptConvertList } from '@/commons/constants/config-language';
import { envPhase } from '@/services/common/utils';

const cookie = jsCookies;
const saveCookie = (key: string, value: string, opt?: CookieAttributes): string | undefined => {
  return cookie.set(key, value, opt)
};

const getCookie = (key: string): string | undefined => {
  return cookie.get(key);
};

const deleteCookie = (value: string, path: string): void => {
  cookie.remove(value, { path: '/', domain: path });
};

/** 쿠키에 언어 유무 확인 */
const isCookieLanguage = (key: string) => {
  return !!getCookie(key);
};

// 한국 또는 일본 도메인의 경우 다른 언어 사용불가
export const diffLang = (getLen: string | undefined, envLang: string) => {
  if (!getLen || envLang === 'en')
    return getLen;

  return languageLongMap[envLang];
};

// 한국 또는 일본에서 포켓코믹스 접속시 영문으로 변경
export const setEnDefault = (lang): Language => {
  let l = lang;
  const defaultLanguage = languageLongMap[process.env.VUE_APP_LANGUAGE] as Language;
  if (envPhase(defaultLanguage, LANGUAGE_TYPES.EN_US)) {
    switch (true) {
      case l === LANGUAGE_TYPES.KO_KR:
      case l === LANGUAGE_TYPES.JA_JP:
        l = defaultLanguage;
        saveCookie(LANGUAGE_KEY, l, { expires: LANGUAGE_EXPIRES });
    }
  }
  return l;
};

/**
 * 사용중인 언어가 없을 경우 브라우저 언어 확인
 * 1순위 브라우저: navigator language가 있고 && 하이픈(-)이 있는지 검토
 * 1-1. acceptConvertList languageLongMap 해당하는 언어
 * 1-2. languageList에 해당하는 언어
 * 2순위 기본설정: env language
 * @return 'ko-KR' | 'ja-JP' | 'en-US' | 'zh-TW';
 */
const getNavigatorLanguage = () => {
  const { language } = navigator;
  const defaultLanguage = languageLongMap[process.env.VUE_APP_LANGUAGE] as Language;
  let targetLanguage = languageLongMap[process.env.VUE_APP_LANGUAGE] as Language;
  if (language.includes('-') && acceptConvertList.includes(language)) {
    // 대소 문자 구분
    targetLanguage = languageConvertMap[language] as Language;
  } else if (acceptShortList.includes(language)) {
    // 짧은 문자 구분
    targetLanguage = languageLongMap[language] as Language;
  }
  targetLanguage = setEnDefault(targetLanguage);

  saveCookie(LANGUAGE_KEY, targetLanguage, { expires: LANGUAGE_EXPIRES });
  return targetLanguage || defaultLanguage;
};

/**
 * 사용 언어 가져오기
 */
const getLanguageCookie = (): Language => {
  if (isCookieLanguage(LANGUAGE_KEY)) {
    return setEnDefault(getCookie(LANGUAGE_KEY));
  }
  return getNavigatorLanguage();
};

export { saveCookie, getCookie, deleteCookie, getLanguageCookie };
