



















































import { Ref, ref, defineComponent } from '@vue/composition-api';
import OutLinkButton from '@/components/atoms/buttons/OutLinkButton.vue';
import LanguageSelect from '@/components/molecules/footers/LanguageSelect.vue';
import Copyright from '@/components/molecules/footers/Copyright.vue';
import { Properties } from '@/commons/constants/config';
import { isIOS, isAOS } from '@/services';
import { LANGUAGE_TYPES } from '@/commons/constants/config-language';

export default defineComponent({
  name: 'FooterSection',
  components: {
    OutLinkButton,
    LanguageSelect,
    Copyright
  },
  props: {
    config: Object
  },
  setup(props, { root }) {
    const getOs: Ref<string> = ref(isAOS() ? 'aos' : isIOS() ? 'ios' : '');
    const footerConfig = ref(props.config);
    const languageType = footerConfig.value?.lang;
    const footerInofs = Properties.FooterInfo[languageType];

    const storeFilter = (os: string, infoArray: []) =>
      infoArray.filter((v) => v['os'] === os);

    const footerStore = (os: string) => {
      if (os) return storeFilter(os, footerInofs.apps);
      else return footerInofs.apps;
    };

    const copyright = () => {
      switch (footerConfig.value?.lang) {
        case LANGUAGE_TYPES.FR_FR:
          return 'Tous droits réservés.';
        default:
          return 'All rights reserved.';
      }
    };

    return {
      LANGUAGE_TYPES,
      getOs,
      footerConfig,
      footerInofs,
      footerStore,
      copyright
    };
  }
});
