




import { ref, defineComponent, watch } from '@vue/composition-api';
import FooterSection from '@/components/molecules/sections/FooterSection.vue';
import { getLocale } from '@/commons/i18n';

export default defineComponent({
  name: 'GlobalFooter',
  components: {
    FooterSection
  },
  setup(props, { root }) {
    const config = ref({
      lang: ref(getLocale()),
      type: ref('')
    });

    const exc = [
      'Callback',
      'ContentHome',
      'ComicViewer',
      'MagazineComicViewer',
      'NovelViewer',
      'MagazineNovelViewer',
      'ComingSoonViewer',
    ];

    /**
     * type이 없을 경우 푸터 비노출
     */
    watch(
      () => root.$route.name,
      (val) => {
        const page = exc.filter(
          (p) => p === val || p.indexOf('Challenge') != -1
        );
        if (page && page.length > 0) return (config.value.type = '');

        val && val === 'Home'
          ? (config.value.type = 'home')
          : (config.value.type = 'sub');
      }
    );

    return {
      config
    };
  }
});
