import { ChallengeUserComment } from '@/models/challenge/challenge-chapter';
import { createAxiosService } from '@/services/common/create-axios.service';
const axiosService = createAxiosService();
const config = {
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	}
};

/**
 * 도전만화 - 댓글등록
 */
const saveComment = ({ contentId, chapterId, comment }: { contentId: number, chapterId: number, comment: string }) => {
	return new Promise((resolve, reject) => {
		const path = `/challenge/comment/${contentId}/${chapterId}`;
		const params = new URLSearchParams({
			comment
		});
		return axiosService.post(path, params, config).then(res => {
			const { data, result } = res.data;
			resolve(null);
		})
			.catch(reject);
	});
}

/**
 * 도전만화 - 댓글 좋아요
 */
const likeComment = (commentId: number) => {
	return new Promise((resolve, reject) => {
		const path = `/challenge/comment/${commentId}/like`;
		// const params = new URLSearchParams({
		// 	commentId: commentId + ''
		// });
		return axiosService.post(path).then(res => {
			const { data, result } = res.data;
			if (result.code < 300) {
				resolve(data);
			} else {
				reject(result);
			}
		})
			.catch(reject);
	})
};

/**
 * 도전만화 - 댓글 삭제
 */
const deleteComment = (commentId: number) => {
	return new Promise((resolve, reject) => {
		const path = `/challenge/comment/${commentId}`;
		return axiosService.delete(path).then(res => {
			const { data, result } = res.data;
			resolve(null);
		})
			.catch(reject);
	})
}

const reportComment = ({ commentId, category }: { commentId: string, category: string }, remarksDetail: string | undefined) => {
	return new Promise<any>((resolve, reject) => {
		const path = `/challenge/comment/${commentId}/report/${category}`;
		const params = new URLSearchParams();
		params.append('remarksDetail', remarksDetail ? remarksDetail : '');
		return axiosService.post(path, params).then(res => {
			const { data, result } = res.data;
			if (result.code < 300) {
				resolve(data);
			} else {
				reject(result);
			}
		})
			.catch(reject);
	})
};

const getComments = ({ contentId, chapterId }: { contentId: number, chapterId: number }) => {
	return new Promise<ChallengeUserComment[]>((resolve, reject) => {
		const path = `/challenge/comment/${contentId}/${chapterId}`;
		return axiosService.get(path).then(res => {
			const { data, result } = res.data;
			if (result.code < 300) {
				const result = data.comments.map((node: ChallengeUserComment) => new ChallengeUserComment(node));
				resolve(result);
			} else {
				reject(result);
			}
		})
			.catch(reject);
	})
}

const writeComment = ({ contentId, chapterId, comment }: { contentId: number, chapterId: number, comment: string }) => {
	return new Promise((resolve, reject) => {
		const path = `/challenge/comment/${contentId}/${chapterId}`;
		const params = new URLSearchParams({
			comment
		});
		return axiosService.post(path, params, config).then(res => {
			const { data, result } = res.data;
			if (result.code < 300) {
				resolve(result);
			} else {
				reject(result);
			}
		})
			.catch(reject);
	})
}

export const ChallengeCommentService = {
	saveComment,
	likeComment,
	deleteComment,
	reportComment,
	writeComment,
	getComments
}