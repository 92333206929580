

















import { ComputedRef, computed, defineComponent, onMounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
export default defineComponent({
  name: 'LoginContainer',
  props: {
    title: String,
    customClasses: String
  },
  setup(props, { root }) {
    const { getters, commit } = store;
    const route = root.$route;
    const routeBackCount: ComputedRef<number> = computed(
      () => getters['GlobalStore/getRouteBackCount']
    );

    const serviceAppkey = computed(
      () => getters['GlobalStore/getServiceAppkey']
    );
    const isWebView = serviceAppkey.value ? true : false;

    const onBack = () => {
      if (root.$route.name !== 'Login') {
        return router.back();
      }

      //10周年記念イベント用
      //History back で戻れなくなるので。。
      const redirect = route.query.redirect ?? '';
      if (redirect.includes('/anniversary/10th/diagnosis/result')) {
        return router.go(-2);
      }

      // 브라우저 Path가 변경되었지만 로그인 페이지가 노출 중일 때
      const browserPath = document.location.pathname;
      const routePath = route.path;
      if (browserPath !== routePath) {
        location.href = '/';
      } else {
        if (routeBackCount.value > 0) {
          commit('GlobalStore/setRouteBackCount', 0);
          return location.href = '/';
        }
        commit('GlobalStore/setRouteBackCount', routeBackCount.value + 1);
        router.back();
      }
    };

    onMounted(() => {
      if (isWebView) {
        if (serviceAppkey.value === 'comicoJpApp') {
          window.location.href = 'comicojp://close';
        } else {
          window.location.href = 'pocketcomics://close';
        }
      }
    });

    return {
      isWebView,
      onBack
    };
  }
});
