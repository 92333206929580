




































































import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch
} from '@vue/composition-api';
import { imageCdn } from '@/commons/constants/challenge/challenge-properties';
import Store from '@/store';
import { isPC } from '@/services';
export default defineComponent({
  setup(_props, { root }) {
    const { getters } = Store;
    const pickups = computed(
      () => getters['ChallengeHomeStore/challengePickups']
    );

    const interval = 8000;
    const pickupList: ComputedRef = computed(() => chunk(pickups.value, 3));
    const pagerSize: ComputedRef = computed(() => pickupList.value.length);
    const currentIdx: Ref = ref(0);
    let rotatePickup: any = null;
    const isPc: ComputedRef<boolean> = computed(() => isPC());
    const chunk = (arr, size: number) =>
      Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
      );

    const setImagePath = (item: any) =>
      `${imageCdn}${item.coverImageFilePath}${item.coverImageFileName}`;

    const rotate = () =>
      pagerSize.value - 1 === currentIdx.value
        ? (currentIdx.value = 0)
        : (currentIdx.value = currentIdx.value + 1);

    const changePickup = (idx: number) => {
      currentIdx.value = idx - 1;
      clearInterval(rotatePickup);
      rotatePickup = setInterval(rotate, interval);
    };

    watch(isPc, (data) => {
      if (data) {
        rotatePickup = setInterval(rotate, interval);
      } else {
        clearInterval(rotatePickup);
      }
    });

    onMounted(() => {
      if (isPc.value && pagerSize.value > 1) {
        rotatePickup = setInterval(rotate, interval);
      }
    });

    return { setImagePath, pickupList, pagerSize, currentIdx, changePickup };
  }
});
