import { extend } from 'vee-validate';
import { i18nTxt, i18nTObj } from '@/commons/i18n/index';

const validateText = i18nTObj('VALIDATOR_MESSAGE');

/**
 * 필수: 이메일
 * @param  {} "requiredEmail"
 */
extend('requiredEmail', (value) => {
  if (!value || !value.length || value.length === 0) {
    return i18nTxt('empty_email_address');
  }
  return true;
});

/**
 * 필수: 이메일코드
 * @param  {} "requiredEmailCode"
 */
extend('requiredEmailCode', (value) => {
  if (!value || !value.length || value.length < 6) {
    return i18nTxt('enter_your_verification_code');
  }
  return true;
});

/**
 * 필수: 비밀번호
 * @param  {} "requiredPassword"
 */
extend('requiredPassword', (value) => {
  if (!value || !value.length) {
    return i18nTxt('empty_password');
  }
  return true;
});

/**
 * 필수: 닉네임
 * @param  {} "requiredNickname"
 */
extend('requiredNickname', (value) => {
  if (!value || !value.length) {
    return i18nTxt('nickname_empty');
  }
  return true;
});

/**
 * 필수: 휴대폰 번호
 * @param  {} "requiredPhoneNumber"
 */
extend('requiredPhoneNumber', (value, param?) => {
  if (param[0] === 'false') return true;
  if (!value || !value.length) {
    return i18nTxt('empty_mobile');
  }
  return true;
});

/**
 * 필수: 개인정보 약관 동의
 * @param  {} "requiredAgreeYn"
 */
extend('requiredAgreeYn', (value, param?) => {
  if (param[0] === 'false') return true;
  if (!value) {
    return i18nTxt('필수약관에 동의해주세요.');
  }
  return true;
});

/**
 * 필수: 탈퇴 이유
 * @param  {} "requiredAccountDelete"
 */
extend('requiredReason', (value, param?) => {
  if (param[0] === 'false') return true;
  if (!value || !value.length) {
    return i18nTxt('delete_account');
  }
  return true;
});

/**
 * 유효성: 공백
 * @param  {} "invalidWitespace"
 */
extend('invalidWitespace', (value) => {
  const PASSWORD_REGEXP = /\s/;
  if (PASSWORD_REGEXP.test(value)) {
    return i18nTxt('validator_invalid_witespace');
  }
  return true;
});

/**
 * 유효성: 이메일
 * @param  {} "invalidEmailAddress"
 * @param param true 로그인 시에 공백 여부 체크 안 함: 마이그레이션 회원 중 공백이 존재
 */
extend('invalidEmailAddress', (value, param?) => {
  // Check if email
  const EMAIL_REGEXP = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
  const regexp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
  const tempEmail: string = param
    ? value
    : value.replace(/(^\s*)|(\s*$)/gi, '');
  const tempMatch = param ? false : tempEmail.match(/[\s]/g);

  if (
    value.length <= 5 ||
    !EMAIL_REGEXP.test(value) ||
    tempMatch ||
    regexp.test(value)
  ) {
    return i18nTxt('invalid_email_address').split('\n')[0];
  }
  return true;
});

/**
 * 유효성: 비밀번호
 * @param  {} "invalidPassword"
 * @param pattern2Way 한국에서 로그인의 경우 2가지 조합도 허용
 * 1. 8자 이상 32자 이하
 * 2. 숫자, 영문자, 특수문자 이외의 문자가 포함되어 있습니다.
 * 3. 숫자, 영문자, 특수문자를 전체 조합하여 입력해 주세요.
 */
extend('invalidPassword', (value, param?) => {
  const val = value;

  if (val !== undefined && val.length > 0 && val.length < 8) {
    return i18nTxt('password_invalid_length');
  }
  // 숫자
  const chk_num = /[0-9]/;
  // 영문 대소문자
  const chk_eng = /[a-zA-Z]/;
  // 기본 특수 문자
  const chk_spc_base = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&¥\\\=\(\'\")]/gi;
  // 저스툰에서 쓰던 확장형 패턴
  // \"'\[]!@#$^&*=\\,<>?！'，．／：；？＾｀ 이 항목들은 기본 특수 문자와 겹쳐서 앱과 맞추기 위해 그대로 사용
  const spc_arr =
    "\"'[]!@#$^&*=\\,<>?！'，．／：；？＾｀｜￣‥…¨〃–―∥＼´ˇ˘˝˙¸˛¡¿ː（）［］｛｝〔〕〈〉《》「」『』【】＋－＜＝＞±×÷≠≤≥∞∴♂♀∠⊥⌒∂∇≡≒≪≫√∽∝∵∫∬∈∋⊆⊇⊂⊃∪∩∧∨￢⇒⇔∀∃∮∑∏＄％￦′″Å￠￡￥¤℉‰€㎕㎗㎘㏄㎣㎤㎦㎙㎚㎛㎞㎢㏊㎍㎎㎏㏏㎈㎉㏈㎧㎨㎰㎱㎲㎳㎴㎵㎶㎷㎸㎹㎀㎁㎂㎃㎄㎺㎻㎼㎽㎾㎿㎐㎑㎒㎓㎔Ω㏀㏁㎊㎋㎌㏖㏅㎭㎮㎯㏛㎩㎪㎫㎬㏝㏐㏓㏃㏉㏜㏆＃＆＊＠§※☆★○●◎◇◆□■△▲▽▼→←↑↓↔〓◁◀▷▶♤♠♡♥♧♣⊙◈▣◐◑▒▤▥▨▧▦▩♨☏☎☜☞¶†‡↕↗↙↖↘♭♩♪♬㉿㈜№㏇™㏂㏘℡ªº─│┌┐┘└├┬┤┴┼━┃┏┓┛┗┣┳┫┻╋┠┯┨┷┿┝┰┥┸╂┒┑┚┙┖┕┎┍┞┟┡┢┦┧┩┪┭┮┱┲┵┶┹┺┽┾╀╁╃╄╅╆╇╈╉╊㉠㉡㉢㉣㉤㉥㉦㉧㉨㉩㉪㉫㉬㉭㉮㉯㉰㉱㉲㉳㉴㉵㉶㉷㉸㉹㉺㉻㈀㈁㈂㈃㈄㈅㈆㈇㈈㈉㈊㈋㈌㈍㈎㈏㈐㈑㈒㈓㈔㈕㈖㈗㈘㈙㈚㈛ⓐⓑⓒⓓⓔⓕⓖⓗⓘⓙⓚⓛⓜⓝⓞⓟⓠⓡⓢⓣⓤⓥⓦⓧⓨⓩ①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⒜⒝⒞⒟⒠⒡⒢⒣⒤⒥⒦⒧⒨⒩⒪⒫⒬⒭⒮⒯⒰⒱⒲⒳⒴⒵⑴⑵⑶⑷⑸⑹⑺⑻⑼⑽⑾⑿⒀⒁⒂ⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩ½⅓⅔¼¾⅛⅜⅝⅞¹²³⁴ⁿ₁₂₃₄ΓΔΞΟΠΣΦΨΩαβγδεζηθικλμνξοπρστυφχψωÆÐĦĲĿŁØŒÞŦŊæđðħıĳĸŀłøœßŧŋŉБГДЕЁЖЗИЙКЛУФХЦЧШЩЪЫЬЭЮЯабвгдеёжзийклмнопрстуфхцчшщъыьэюя";
  // 기본 특수 문자 외에 한글, 한자 등 체크 항목: chk_spc_base + spc_arr
  // 중복 패턴은 기본 특수 문자로 대체
  const chk_spc_exc = /[a-zA-Z0-9\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&¥\\\=\(\'\")｜￣‥…¨〃–―∥＼´ˇ˘˝˙¸˛¡¿ː（）［］｛｝〔〕〈〉《》「」『』【】＋－＜＝＞±×÷≠≤≥∞∴♂♀∠⊥⌒∂∇≡≒≪≫√∽∝∵∫∬∈∋⊆⊇⊂⊃∪∩∧∨￢⇒⇔∀∃∮∑∏＄％￦′″Å￠￡￥¤℉‰€㎕㎗㎘㏄㎣㎤㎦㎙㎚㎛㎞㎢㏊㎍㎎㎏㏏㎈㎉㏈㎧㎨㎰㎱㎲㎳㎴㎵㎶㎷㎸㎹㎀㎁㎂㎃㎄㎺㎻㎼㎽㎾㎿㎐㎑㎒㎓㎔Ω㏀㏁㎊㎋㎌㏖㏅㎭㎮㎯㏛㎩㎪㎫㎬㏝㏐㏓㏃㏉㏜㏆＃＆＊＠§※☆★○●◎◇◆□■△▲▽▼→←↑↓↔〓◁◀▷▶♤♠♡♥♧♣⊙◈▣◐◑▒▤▥▨▧▦▩♨☏☎☜☞¶†‡↕↗↙↖↘♭♩♪♬㉿㈜№㏇™㏂㏘℡ªº─│┌┐┘└├┬┤┴┼━┃┏┓┛┗┣┳┫┻╋┠┯┨┷┿┝┰┥┸╂┒┑┚┙┖┕┎┍┞┟┡┢┦┧┩┪┭┮┱┲┵┶┹┺┽┾╀╁╃╄╅╆╇╈╉╊㉠㉡㉢㉣㉤㉥㉦㉧㉨㉩㉪㉫㉬㉭㉮㉯㉰㉱㉲㉳㉴㉵㉶㉷㉸㉹㉺㉻㈀㈁㈂㈃㈄㈅㈆㈇㈈㈉㈊㈋㈌㈍㈎㈏㈐㈑㈒㈓㈔㈕㈖㈗㈘㈙㈚㈛ⓐⓑⓒⓓⓔⓕⓖⓗⓘⓙⓚⓛⓜⓝⓞⓟⓠⓡⓢⓣⓤⓥⓦⓧⓨⓩ①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⒜⒝⒞⒟⒠⒡⒢⒣⒤⒥⒦⒧⒨⒩⒪⒫⒬⒭⒮⒯⒰⒱⒲⒳⒴⒵⑴⑵⑶⑷⑸⑹⑺⑻⑼⑽⑾⑿⒀⒁⒂ⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩ½⅓⅔¼¾⅛⅜⅝⅞¹²³⁴ⁿ₁₂₃₄ΓΔΞΟΠΣΦΨΩαβγδεζηθικλμνξοπρστυφχψωÆÐĦĲĿŁØŒÞŦŊæđðħıĳĸŀłøœßŧŋŉБГДЕЁЖЗИЙКЛУФХЦЧШЩЪЫЬЭЮЯабвгдеёжзийклмнопрстуфхцчшщъыьэюя]{8,32}$/gi;

  const isOnlyNum = chk_num.test(val);
  const isOnlyChar = chk_eng.test(val);
  let isOnlySpc = chk_spc_base.test(val);
  const spcExcept = val.match(chk_spc_exc);

  // 숫자 + 영문자 + 특수문자 이외의 문자가 포함되어 있습니다.
  if (!spcExcept) {
    return i18nTxt('password_invalid_characters');
  }
  // 기본 특수문자 외의 확장형 특수 문자도 허용 함
  for (let i = 0; i < spc_arr.length; i++) {
    if (val && val.indexOf(spc_arr[i]) != -1) {
      isOnlySpc = true;
    }
  }

  const isTwoPattern = param[0] && param[0].length > 0 ? true : false;
  // 숫자 + 영문자 + 특수문자
  if (!isTwoPattern && isOnlyNum && isOnlyChar && isOnlySpc) {
    return true;
  }

  // 숫자, 영문자, 특수문자 중 2개 이상 포함
  // 적용대상: EN, JP
  if (isTwoPattern || process.env.VUE_APP_LANGUAGE !== 'ko') {
    if (
      (isOnlyNum && isOnlyChar) || // 숫자 + 영문자
      (isOnlyNum && isOnlySpc) || // 숫자 + 특수문자
      (isOnlyChar && isOnlySpc) // 영문자 + 특수문자
    ) {
      return true;
    }
  }

  return i18nTxt('password_invalid_length_characters');
});

/**
 * 유효성: 비밀번호확인
 * @param  {} "invalidConfirmed"
 * @param  {string} (value
 * @param  {string} [chkValue]
 */
// extend('invalidConfirmed', (value: string, [chkValue]: string) => {
extend('invalidConfirmed', ({ value, isConfirmed }) => {
  if (value !== isConfirmed) {
    return i18nTxt('password_is_not_match');
  }

  return true;
});

const getfullday = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  let month1 = '' + month;
  let day1 = '' + day;
  if (month < 10) month1 = '0' + month;
  if (day < 10) day1 = '0' + day;

  const monthDay = month1 + day1;
  return '' + year + monthDay;
};

/**
 * 휴대폰 번호 확인
 * @param  {} "invalidPhoneNumber"
 */
extend('invalidPhoneNumber', (value, param?) => {
  if (param[0] === 'false') return true;
  const val = value;
  const regExp = /(01([0|1|6|7|8|9]))([0-9]{4})([0-9]{4})$/;
  if (!regExp.test(val)) {
    return i18nTxt('invalid_mobile');
  }

  return true;
});

export { extend };
