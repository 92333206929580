import { Module } from 'vuex';
import { RootState } from '@/store/index';
import {
  Event,
  EventInfo,
  EventDetailInfo,
  EventApiInfo,
  ContentInfo,
  Menu,
  Page
} from '@/models/index';
import { EventService, MenuApiSerivce } from '@/services/index';

export interface EventInterface {
  eventApiInfo: EventApiInfo;
  eventInfo: EventInfo;
  selectEventInfo: Event;
  eventDetailInfo: EventDetailInfo;
  contentList: Array<ContentInfo>;
  page: Page;
}

export const EventStore: Module<EventInterface, RootState> = {
  namespaced: true,
  state: {
    eventApiInfo: {} as EventApiInfo,
    eventInfo: {} as EventInfo,
    selectEventInfo: {} as Event,
    eventDetailInfo: {} as EventDetailInfo,
    contentList: [],
    page: {} as Page
  },
  getters: {
    eventApiInfo(state) {
      return state.eventApiInfo;
    },
    eventInfo(state) {
      return state.eventInfo.events;
    },
    selectEventInfo(state) {
      return state.selectEventInfo;
    },
    eventDetail(state) {
      return state.eventDetailInfo;
    },
    contentList(state) {
      return state.contentList;
    },
    page(state) {
      return state.page;
    }
  },
  mutations: {
    eventApiInfo(state, value: EventApiInfo) {
      state.eventApiInfo = value;
    },
    eventInfo(state, value: EventInfo) {
      state.eventInfo = value;
    },
    selectEventInfo(state, value: Event) {
      state.selectEventInfo = value;
      sessionStorage.setItem('event_detail_title', value.title);
    },
    eventDetailInfo(state, value: EventDetailInfo) {
      state.eventDetailInfo = value;
    },
    contentList(state, value: ContentInfo[]) {
      state.contentList = value;
    },
    page(state, value: Page) {
      state.page = value;
    }
  },
  actions: {
    async initEventInfo({ commit }, { contentCategory, apiPath }) {
      const data = await EventService.getEventsWithUrl(apiPath);
      const result = new EventInfo(data);
      const getAcceptMature =
        sessionStorage.getItem('acceptMature') === 'Y' ? true : false;
      let tmpList: Array<Event> = [];
      result.events.forEach((value) => {
        if (value.mature && getAcceptMature) {
          tmpList.push(value);
        } else if (!value.mature) {
          tmpList.push(value);
        }
      });
      result.events = tmpList;
      commit('eventInfo', result);
      return result;
    },
    async initEventDetail(
      { commit, getters },
      { contentCatalogId, prefix, pageState }
    ) {
      const result: EventDetailInfo = await EventService.getEventDetail(
        { contentCatalogId, prefix }, 
        pageState
      );
      if (pageState.pageNo === 0) {
        commit('eventApiInfo', { contentCatalogId: contentCatalogId, prefix: prefix });
        commit('eventDetailInfo', result);
        commit(
          'contentList',
          result.contents.map((node) => new ContentInfo(node))
        );
      } else {
        let newContents = result.contents.map((node) => new ContentInfo(node));
        newContents = getters.contentList.concat(newContents);
        commit('contentList', newContents);
      }
      commit('page', result.page);
    },
    async initEventDetailWithTitle(
      { commit, getters },
      { contentCatalogId, prefix, pageState }
    ) {
      
      const menuResult = await MenuApiSerivce.getMenuPathById(prefix, contentCatalogId);
      const menu = new Menu(menuResult.menu);

      const result: EventDetailInfo = await EventService.getEventDetail(
        { contentCatalogId, prefix }, 
        pageState
      );
      if (pageState.pageNo === 0) {
        commit('eventApiInfo', { contentCatalogId: contentCatalogId, prefix: prefix, title: menu.getHolder().title });
        commit('eventDetailInfo', result);
        commit(
          'contentList',
          result.contents.map((node) => new ContentInfo(node))
        );
      } else {
        let newContents = result.contents.map((node) => new ContentInfo(node));
        newContents = getters.contentList.concat(newContents);
        commit('contentList', newContents);
      }
      commit('page', result.page);
    }
  }
};
