



















import {
  Ref,
  ref,
  computed,
  defineComponent,
  watch
} from '@vue/composition-api';
import ChkBase from '@/components/atoms/inputs/ChkBase.vue';

export default defineComponent({
  name: 'CheckboxSection',
  components: {
    ChkBase
  },
  props: {
    formData: Boolean,
    sectionName: String,
    sectionId: String,
    policyView: Boolean
  },
  setup(props, { emit }) {
    const onUnselectAll = (isChecked) => {
      emit('unselect', { isChecked, inputName: props.sectionName });
    };
    const onPolicyHandler = () => {
      emit('policyView');
    };
    return { onUnselectAll, onPolicyHandler };
  }
});
