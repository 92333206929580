













































import { SliderInfo } from '@/models';
import {
  computed,
  ComputedRef,
  defineComponent,
  Ref,
  ref,
  watch
} from '@vue/composition-api';
import {
  INDEX_SLIDER_TYPE_ABSOLUTE,
  INDEX_SLIDER_TYPE_RATIO
} from '@/commons/constants/viewer-types';
export default defineComponent({
  name: 'IndexSlider',
  props: {
    isShow: {
      default: true,
      type: Boolean
    },
    slider: Object,
    isShowTable: Boolean,
    navMap: Array
  },
  setup(props, { emit }) {
    const current: Ref<number> = ref(0);
    // const sliderType: ComputedRef<boolean> = computed(() =>
    //   // true: ratio, false: 절대값 인덱스
    //   typeof props.ratio === 'number' ? true : false
    // );
    const sliderInfo: ComputedRef<SliderInfo> = computed(
      () => props.slider as SliderInfo
    );
    const progress: ComputedRef<number> = computed(() =>
      sliderInfo.value.type === INDEX_SLIDER_TYPE_ABSOLUTE
        ? sliderInfo.value.current + 1
        : sliderInfo.value.current
    );
    const max: Ref<number> = ref(100);
    const buttonToggle: ComputedRef<boolean> = computed(() =>
      props.isShow ? props.isShowTable : false
    );

    const sliderChange = (value) => {
      emit('emitMovePage', value);
    };

    const toggleTextSetting = () => {
      emit('emitToggleTextSetting');
    };

    const toggleContentsTable = () => {
      emit('emitToggleContentsTable');
    };

    watch(progress, (data) => (current.value = data));

    return {
      sliderInfo,
      buttonToggle,
      current,
      max,
      progress,
      sliderChange,
      toggleTextSetting,
      toggleContentsTable,
      INDEX_SLIDER_TYPE_RATIO,
      INDEX_SLIDER_TYPE_ABSOLUTE
    };
  }
});
