

























import { computed, defineComponent } from '@vue/composition-api';
import router from '@/router';
import { i18nMaximumUnit, i18nWrapRuby, t } from '@/commons/i18n';

export default defineComponent({
  name: 'BasicContentCard',
  components: {
  },
  props: {
    content: Object,
    onlyThumb: Boolean,
    menuHolder: Object,
    isContentType: Boolean,
    selectedItem: String
  },
  setup(props, { root }) {
    const contentInfo = computed(
      () => props.content || {}
    );
    const backgroundColor = computed(
      () => props.content?.content.themeColor?? 'gray'
    );
    const route = root.$route;
    const params = route.params;
    const publicationRemained = contentInfo.value.publicationRemained > 0 ? true : false;

    const sendComments = () => {
      const contentType = contentInfo.value.content.type;
      const contentId = contentInfo.value.content.id;
      const chapterId = props.content? props.content.trialChapterId : null;
      if (contentType && contentId && chapterId) {
        const path = `/comment/${contentType}/${contentId}/${chapterId}`;
        router.push(path);
      }
    };
    const jumpToDetail = () => {
      const type = contentInfo.value.content.type;
      const id = contentInfo.value.content.id;

      if (contentInfo.value.publicationRemained < 0) {
        const url = `/${type}/${id}`;
        router.push(url);
      } else {
        const url = `/${type}/coming_soon/${id}`;
        router.push(url);
      }

    };
    const remainsTime = () => {
      return i18nWrapRuby(t('until_release'), i18nMaximumUnit(contentInfo.value.publicationRemained));
    };

    return {
      contentInfo,
      backgroundColor,
      jumpToDetail,
      remainsTime,
      t,
      sendComments,
      publicationRemained
    };
  }
});
