




































import { ContentInfo } from '@/models';
import {
  computed,
  ComputedRef,
  defineComponent
} from '@vue/composition-api';
import { t } from '@/commons/i18n/index';
import { AUTHOR_TYPE } from '@/commons/constants/author-types';
import ProductInfoItem from './ProductInfoItem.vue';
import router from '@/router';

export default defineComponent({
  name: 'ProductionInfo',
  props: {
    content: ContentInfo
  },
  components: {
    ProductInfoItem
  },
  setup(props) {
    const contentInfo: ComputedRef<ContentInfo> = computed(
      () => props.content as ContentInfo
    );
    const author = computed(() => contentInfo.value.getAuthorRoleSearch(AUTHOR_TYPE.AUTHOR_TYPE_CREATOR));
    const writer = computed(() => contentInfo.value.getAuthorRoleSearch(AUTHOR_TYPE.AUTHOR_TYPE_WRITER));
    const artist = computed(() => contentInfo.value.getAuthorRoleSearch(AUTHOR_TYPE.AUTHOR_TYPE_ARTIST));
    const assistant = computed(() => contentInfo.value.getAuthorRoleSearch(AUTHOR_TYPE.AUTHOR_TYPE_ASSISTANT));
    const originalCreator = computed(() => contentInfo.value.getAuthorRoleSearch(AUTHOR_TYPE.AUTHOR_TYPE_ORIGINAL_CREATOR));
    const studio = computed(() => contentInfo.value.getAuthorRoleSearch(AUTHOR_TYPE.AUTHOR_TYPE_STUDIO));
    const other = computed(() => contentInfo.value.getAuthorRoleSearch(AUTHOR_TYPE.AUTHOR_TYPE_OTHER));

    const searchProductInfo = (type: string, item: string) => {
      const keyword = encodeURIComponent(`${type}:${item}`);
      router.push(`/search/${keyword}`);
    };

    return {
      t,
      AUTHOR_TYPE,
      author,
      writer,
      artist,
      assistant,
      originalCreator,
      studio,
      other,
      searchProductInfo
    };
  }
});
