




import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'DeleteButton',
  setup(props, context) {
    const onButtonClick = () => {
      context.emit('onDelete');
    };

    return {
      onButtonClick
    };
  }
});
