import { socialLoginApi } from '@/services/social/social-login.api';
import { Module } from 'vuex';
import { RootState } from '@/store/index';
import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
import { i18nTxt, i18nFormatting } from '@/commons/i18n/index';
import ConfirmDialog from '@/dialogs/ConfirmDialog.vue';
import AlertDialog from '@/dialogs/AlertDialog.vue';
import router from '@/router';
import { idpParamsInterface } from '@/models';
import {
  KO_IDP_NAME,
  PAYCO_EXTERNALIDP_URL
} from '@/commons/constants/mypage-types';
import { fbq } from '@/services/common/facebook-pixel.service';
import { getNeoChk } from '@/services/auth/auth.helper';
import { LANGUAGE_TYPES } from '@/commons/constants/config-language';
import { ERROR_CODES } from '@/commons/constants/error-codes';

interface ExternalIdpInterface {
  appleid?: string;
  facebook?: string;
  google?: string;
  naver?: string;
  kakao?: string;
  payco?: string;
}

interface AuthorizeInterface {
  loginType: string;
  accessToken: string;
}
interface SocialStateInterface {
  externalIdp: ExternalIdpInterface;
  authorize: AuthorizeInterface;
  isSignin: boolean;
}

export const SocialLoginStore: Module<SocialStateInterface, RootState> = {
  namespaced: true,
  state: {
    externalIdp: {},
    authorize: {
      loginType: '',
      accessToken: ''
    },
    isSignin: true
  },
  getters: {
    externalIdp: (state) => state.externalIdp
  },

  mutations: {
    setExternalIdpUrl: (state, paylaod) => (state.externalIdp = paylaod),
    setAuthorize: (state, payload) => {
      state.authorize = payload;
    }
  },
  actions: {
    getExternalIdpUrl: async ({ commit }, loginType: string) => {
      return await socialLoginApi.getExternalIdpUrl(loginType).then((res) => {
        return res.data.authorizeUrl;
        // commit('setExternalIdpUrl', res.data.externalIdp.authorizeUrl);
      });
    },

    externalIdpCallback: ({ commit, dispatch }, params: idpParamsInterface) => {
      socialLoginApi.externalIdpCallback(params).then((res) => {
        if (res.result.code === 200) {
          commit('setAuthorize', {
            loginType: params.loginType,
            accessToken: res.data.token
          });

          const form = new FormData();
          form.append('accessToken', res.data.token);

          const data = {
            loginType: params.loginType,
            accessToken: form
          };
          // callback api 정상 호출 시 서버에서 callback페이지로 리다이렉트

          dispatch('signIn', data);
        }
      });
    },
    signIn: ({ dispatch, commit, rootState }, params: idpParamsInterface) => {
      socialLoginApi.signIn(params).then((res) => {
        const { result, data } = res;
        const { loginType } = params;
        const isKo: boolean =
          rootState.I18nStore.language === LANGUAGE_TYPES.KO_KR;
        const resultSuccess: boolean = result.code === ERROR_CODES.CODE_200;
        const resultFail: boolean = result.code === ERROR_CODES.CODE_417;
        const isDormant: boolean = result.code === ERROR_CODES.CODE_410;
        const isPayco: boolean = loginType === 'payco';
        const redirectUrl: string =
          sessionStorage.getItem('redirectUrl') || '/';

        if (resultSuccess) {
          dispatch('MyPageStore/getUserInfo', null, { root: true }).then(
            async () => {
              await dispatch('AuthStore/foregrounded', null, { root: true });
              commit('AuthStore/login', data, { root: true });
              commit('AuthStore/setNeoChk', getNeoChk(), {
                root: true
              });
              router
                .replace({
                  name: 'Authorize',
                  params: {
                    nextPage: 'true'
                  },
                  query: { redirect: redirectUrl }
                })
                .then(() => {
                  sessionStorage.removeItem('redirectUrl');
                });
            }
          );
          //한국어가 아닐때,  회원정보 없을때 회원가입 진행
        } else if (resultFail && !isKo) {
          router.push('/login').then(() => {
            Dialog.create({
              component: ConfirmDialog,
              componentProps: {
                title: '',
                message: i18nFormatting('idp_signup_confirm', {
                  idp: `${loginType}.com`
                }),
                ok: i18nTxt('ok'),
                cancel: i18nTxt('cancel')
              }
            })
              .onOk(() => {
                dispatch('signUp', params);
              })
              .onCancel(() => {
                router.push('/');
              });
          });
        } else if (resultFail && isKo && isPayco) {
          //한국어, 페이코일때 회원가입 진행
          dispatch('signUp', params);
        } else if (resultFail && isKo && !isPayco) {
          //한국어, 타 소셜 로그인일때 메시지 노출
          router.push('/login').then(() => {
            Dialog.create({
              component: ConfirmDialog,
              componentProps: {
                title: '',
                message: `${KO_IDP_NAME[loginType]} 계정으로 가입하신 이력이 없습니다. 회원가입 페이지에서 가입 후 로그인해주세요.`,
                // ok: '회원가입',
                cancel: '닫기'
              }
            })
              // .onOk(() => {
              //   location.href = PAYCO_EXTERNALIDP_URL;
              // })
              .onCancel(() => {
                router.push('/');
              });
          });
        } else if (isDormant && data.awaitDeleted && isKo) {
          router.push('/login').then(() => {
            Dialog.create({
              component: AlertDialog,
              componentProps: {
                title: '',
                message: `사용자를 찾을 수 없습니다.`,
                ok: '확인'
              }
            });
          });
        } else if (isDormant && data.dormant && isKo) {
          const { neoIdUid } = data.dormant;
          commit('AuthStore/setDormantStatus', { neoIdUid }, { root: true });
          router.replace({
            name: 'DormantAccount',
            params: {
              nextPage: 'true'
            },
            query: { redirect: '/' }
          });
        } else {
          router.push('/login').then(() => {
            setTimeout(() => {
              dispatch(
                'ErrorStore/SET_ERROR',
                {
                  code: result.code,
                  title: result.code,
                  message: result.message,
                  response: ''
                },
                { root: true }
              );
            }, 500);
            // const isErrorPop = store.getters['ErrorStore/errorCode'];
            // if (isErrorPop) return;
          });
        }
      });
    },
    signUp: async ({ commit, dispatch }, params: idpParamsInterface) => {
      socialLoginApi.signUp(params).then((res) => {
        const { result, data } = res;
        const redirect = sessionStorage.getItem('redirectUrl') || '/';
        if (result.code === 200) {
          commit('AuthStore/login', data, { root: true });
          dispatch('MyPageStore/getUserInfo', null, { root: true }).then(
            (res) => {
              if (res.userId) {
                commit('AuthStore/login', data, { root: true });
                commit('AuthStore/setNeoChk', getNeoChk(), {
                  root: true
                });
              }
              router.push(redirect).then(() => {
                Notify.create({
                  message: i18nTxt('sign_up_completed')
                });
                sessionStorage.removeItem('redirectUrl');
              });
            }
          );

          fbq.track('ViewContent', { content_name: '회원가입완료' });
          fbq.track('CompleteRegistration');
        }
      });
    }
  }
};
