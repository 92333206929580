import { Authorization, idpParamsInterface } from '@/models';
import {
  createAxiosService,
  newCreateAxiosService
} from '@/services/common/create-axios.service';
import { HttpResponse } from '@/commons/constants/http.types';
import { ERROR_CODES } from '@/commons/constants/error-codes';

export interface ProfileInterface {
  profile: Authorization;
}

const axiosService = createAxiosService();
const newAxiosService = newCreateAxiosService();
const config = {
  headers: {
    'content-type': 'application/x-www-form-urlencoded'
  }
};

const getUserInfo = async (): Promise<ProfileInterface> => {
  const url = `/member/profile`;
  return await axiosService.get(url).then((res) => {
    const {
      data: { data }
    } = res;
    return data;
  });
};

const getMature = async () => {
  const url = `/member/profile/mature`;
  return await axiosService.get(url).then((res) => {
    const {
      data: { data }
    } = res;
    return data;
  });
};

const putAvatarImage = async (image: ImageData) => {
  const url = `/member/profile/avatar`;

  const {
    data: { result }
  } = await axiosService.put(url, image, config).then((res) => res);
  return result;
};

const deleteAvatarImage = async () => {
  const url = `/member/profile/avatar`;

  const {
    data: { result }
  } = await axiosService.delete(url).then((res) => res);
  return result;
};

const putNickName = async (nickName: string) => {
  const url = `/member/profile/nickname`;
  return await axiosService.put(url, nickName, config).then((res) => {
    const {
      data: { result }
    } = res;
    return result;
  });
};
const putEmail = async (emailInfo: FormData) => {
  const url = `/member/profile/email`;

  return await axiosService.put(url, emailInfo, config).then((res) => {
    const {
      data: { result }
    } = res;
    return result;
  });
};
const putPassword = async (password: string) => {
  const url = `/member/profile/password`;
  return await axiosService.put(url, password).then((res) => {
    const {
      data: { data }
    } = res;
    return data;
  });
};
const putMature = async (acceptMature: string) => {
  const url = `/member/profile/mature/accept/${acceptMature}`;
  return await axiosService.put(url).then((res) => {
    const {
      data: { result }
    } = res;
    return result;
  });
};

const putNotifyStatus = async (status: string) => {
  const url = `/member/profile/notify/email/${status}`;
  return await axiosService.put(url).then((res) => {
    const {
      data: { result }
    } = res;
    return result;
  });
};

const putSendVerifyToken = async (params: FormData) => {
  const url = `/member/send/verify/token`;
  return await axiosService.put(url, params).then((res) => {
    const {
      data: { result }
    } = res;
    return result;
  });
};

const putCheckVerifyToken = async (params: FormData) => {
  const url = `/member/check/verify/token`;
  return await axiosService.put(url, params).then((res) => {
    const {
      data: { result }
    } = res;
    return result;
  });
};

const getComment = async (userId: string) => {
  const url = `comment/history/author/${userId}`;
  const {
    data: { data }
  } = await axiosService.get(url).then((res) => res);
  return data;
};

const postMapping = async (emailInfo) => {
  const url = `/member/mapping`;
  const {
    data: { result }
  } = await axiosService.post(url, emailInfo, config);
  return result;
};

const postIdpMapping = async (params: idpParamsInterface) => {
  const url = `/member/external-idp/${params.loginType}/mapping`;

  const {
    data: { result }
  } = await axiosService.post(url, params.form, config);
  return result;
};

const deleteIdpMapping = async (idpType: string) => {
  const url = `/member/external-idp/${idpType}/mapping`;
  const {
    data: { result }
  } = await axiosService.delete(url);
  return result;
};

const getMatureInit = async () => {
  const url = `/certification/init`;
  const {
    data: { data }
  } = await axiosService.get(url);
  return data;
};

const certificationComplete = async (code: string) => {
  const url = `/certification/complete?code=${code}`;
  const { data } = await axiosService.get(url);
  return data;
};

const putPhoneNumber = async (cellPhone: string) => {
  const url = `/member/profile/cellphone?cellPhone=${cellPhone}`;
  const {
    data: { result }
  } = await axiosService.put(url);
  return result;
};

const putMarketingInfo = async (marketInfoAgree: string) => {
  const url = `/member/profile/market-info-agree?marketInfoAgree=${marketInfoAgree}`;
  const {
    data: { result }
  } = await axiosService.put(url);
  return result;
};

const getCoinExpireDay = async (day: number): Promise<HttpResponse> => {
  const url = `/coin/expire/${day}`;
  return newAxiosService.get(url).then((res) => {
    const response: HttpResponse = res.data;
    return response.result.code === ERROR_CODES.CODE_200
      ? Promise.resolve(response.data)
      : Promise.reject({
          result: response.result,
          data: response.data
        });
  });
};

export const mypageApi = {
  getUserInfo,
  putAvatarImage,
  putNickName,
  putEmail,
  putPassword,
  getMature,
  deleteAvatarImage,
  putMature,
  putNotifyStatus,
  putSendVerifyToken,
  putCheckVerifyToken,
  getComment,
  postMapping,
  postIdpMapping,
  deleteIdpMapping,
  getMatureInit,
  certificationComplete,
  putPhoneNumber,
  putMarketingInfo,
  getCoinExpireDay
};
