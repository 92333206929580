import {
  CoinHistoryInfo,
  CoinSalesConfig,
  CreditCardToken,
  CoinToken,
  CreditCardInfo,
  Credit4gToken
} from '@/models/index';
import { createAxiosService } from '../common/create-axios.service';
const axiosService = createAxiosService();

interface PurchasedInterface {
  token: string;
  productSeq: string;
  reserveOrderNo: string;
  totalPaymentAmt: string;
  paymentCertifyToken: string;
}

interface OrtherResultInterface {
  serviceOrderId: string;
  token: string;
  orderId: string;
  PayerID: string;
}

const getTransactionToken = (): Promise<CoinToken> => {
  return new Promise<CoinToken>((resolve) => {
    const url = '/coin/transaction/token';
    return axiosService.post(url).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const getCoinHistory = (): Promise<CoinHistoryInfo> => {
  return new Promise<CoinHistoryInfo>((resolve) => {
    const url = '/coin/history/used';
    return axiosService.get(url).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const getCoinDistribution = (): Promise<CoinHistoryInfo> => {
  return new Promise<CoinHistoryInfo>((resolve) => {
    const url = '/coin/history/distributed';
    return axiosService.get(url).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const getCoinSalesConfig = (): Promise<CoinSalesConfig> => {
  return new Promise<CoinSalesConfig>((resolve) => {
    const url = '/coin/sales/config';
    return axiosService.get(url).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const getTransaction = (): Promise<CoinToken> => {
  return new Promise<CoinToken>((resolve) => {
    const url = `/coin/transaction/token`;
    return axiosService.post(url).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

/**
 * Payco 주문예약
 * @param productSeq 구매상품 ID
 * @param paymentType 구매방식 Code
 * @returns
 */
const paycoApproval = (productSeq: string, paymentType: string) => {
  return new Promise((resolve) => {
    const url = '/coin/distribution/payco/approval';
    const bodyFormData = new FormData();
    bodyFormData.append('productSeq', productSeq);
    bodyFormData.append('paymentType', paymentType);
    return axiosService.post(url, bodyFormData).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

/**
 * Payco 결제승인 요청
 * @param params returnUrl로 받은 파라미터 값
 * @returns
 */
const paycoPurchased = (params: PurchasedInterface) => {
  return new Promise((resolve) => {
    const url = '/coin/distribution/payco/result';
    const bodyFormData = new FormData();
    bodyFormData.append('token', params.token);
    bodyFormData.append('productSeq', params.productSeq);
    return axiosService.post(url, bodyFormData).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const paymentReserve = (productSeq: string, paymentType: string) => {
  return new Promise((resolve) => {
    const url = `/coin/purchase/payment/reserve/${paymentType}`;
    const bodyFormData = new FormData();
    bodyFormData.append('productSeq', productSeq);
    bodyFormData.append('nextUrl', '/coinpurchase/result');
    return axiosService.post(url, bodyFormData).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const otherPaymentCancel = (serviceOrderId: string) => {
  return new Promise((resolve) => {
    const url = '/coin/purchase/payment/result/cancel';
    const bodyFormData = new FormData();
    bodyFormData.append('serviceOrderId', serviceOrderId);
    return axiosService.post(url, bodyFormData).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const paypalPaymentExecute = (params: OrtherResultInterface) => {
  return new Promise((resolve) => {
    const url = '/coin/purchase/payment/execute';
    const bodyFormData = new FormData();
    bodyFormData.append('serviceOrderId', params.serviceOrderId);
    bodyFormData.append('token', params.token);
    bodyFormData.append('orderId', params.orderId);
    bodyFormData.append('PayerID', params.PayerID);
    return axiosService.post(url, bodyFormData).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const stripePaymentFinish = (serviceOrderId: string) => {
  return new Promise((resolve) => {
    const url = '/coin/purchase/payment/result/finish';
    const bodyFormData = new FormData();
    bodyFormData.append('serviceOrderId', serviceOrderId);
    return axiosService.post(url, bodyFormData).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

/**
 * Japan Payment
 */
const getCreditCardInfo = () => {
  return new Promise<CreditCardInfo>((resolve) => {
    const url = '/coin/purchase/credit/cardinfo';
    return axiosService.get(url).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const registerCreditCard = (mdkToken: string, securityCode: string) => {
  return new Promise((resolve) => {
    const url = '/coin/purchase/credit/cardinfo/register';
    const bodyFormData = new FormData();
    bodyFormData.append('mdkToken', mdkToken);
    bodyFormData.append('securityCode', securityCode);
    return axiosService.post(url, bodyFormData).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const deleteCreditCard = (cardId: string) => {
  return new Promise((resolve) => {
    const url = '/coin/purchase/credit/cardinfo/delete';
    const bodyFormData = new FormData();
    bodyFormData.append('cardId', cardId);
    return axiosService.post(url, bodyFormData).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const getCreditTokenApiKey = () => {
  return new Promise<CreditCardToken>((resolve) => {
    const url = '/coin/purchase/credit/token-api-key';
    return axiosService.get(url).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const getCredit4gToken = (
  cardNumber: string,
  cardExpire: string,
  securityCode: string,
  creditToken: string
) => {
  return new Promise<Credit4gToken>((resolve, reject) => {
    const url = 'https://api.veritrans.co.jp/4gtoken';
    const bodyFormData = {
      card_number: cardNumber,
      card_expire: cardExpire,
      security_code: securityCode,
      token_api_key: creditToken,
      lang: 'ja'
    };
    return axiosService
      .post(url, JSON.stringify(bodyFormData), {
        headers: { 'Content-Type': `application/json` }
      })
      .then((res) => {
        const { data } = res;
        resolve(data);
      })
      .catch(reject);
  });
};

const creditCardExcute = (
  transactionToken: string,
  mdkToken: string,
  productSeq: string,
  cardId: string
) => {
  return new Promise((resolve) => {
    const url = '/coin/purchase/credit/excute';
    const bodyFormData = new FormData();
    if (mdkToken) bodyFormData.append('mdkToken', mdkToken);
    if (cardId) bodyFormData.append('cardId', cardId);
    bodyFormData.append('transactionToken', transactionToken);
    bodyFormData.append('productSeq', productSeq);
    return axiosService.post(url, bodyFormData).then((res) => {
      const { data } = res;
      resolve(data);
    });
  });
};

const carrierReserve = (carrier: string, productSeq: string) => {
  return new Promise((resolve) => {
    const url = `/coin/purchase/carrier/${carrier}/reserve`;
    const bodyFormData = new FormData();
    bodyFormData.append('productSeq', productSeq);
    return axiosService.post(url, bodyFormData).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const japanPaymentResult = (resultStatus: string, serviceOrderId: string) => {
  return new Promise((resolve) => {
    const url = `/coin/purchase/result/${resultStatus}`;
    const bodyFormData = new FormData();
    bodyFormData.append('serviceOrderId', serviceOrderId);
    return axiosService.post(url, bodyFormData).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const getPaypalCreditToken = () => {
  return new Promise((resovle) => {
    const url = '/coin/purchase/payment/token/paypal_credit';
    const bodyFormData = new FormData();
//     bodyFormData.append('productSeq', productSeq);
//     bodyFormData.append('nextUrl', '/coinpurchase/result');
    return axiosService.get(url).then((res) => {
      const { data } = res;
      //alert(JSON.stringify(data));
      resovle(data.data);
    });
  });
};

const paypayReserve = (productSeq: string) => {
  return new Promise((resovle) => {
    const url = '/coin/purchase/paypay/reserve';
    const bodyFormData = new FormData();
    bodyFormData.append('productSeq', productSeq);
    bodyFormData.append('nextUrl', '/coinpurchase/result');
    return axiosService.post(url, bodyFormData).then((res) => {
      const { data } = res;
      resovle(data.data);
    });
  });
};

export const CoinService = {
  getTransactionToken,
  getCoinHistory,
  getCoinDistribution,
  getCoinSalesConfig,
  getTransaction,
  paycoApproval,
  paycoPurchased,
  getPaypalCreditToken,
  paymentReserve,
  otherPaymentCancel,
  paypalPaymentExecute,
  stripePaymentFinish,
  getCreditCardInfo,
  registerCreditCard,
  deleteCreditCard,
  getCreditTokenApiKey,
  getCredit4gToken,
  creditCardExcute,
  carrierReserve,
  japanPaymentResult,
  paypayReserve
};
