











import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ImagePanel',
  props: {
    bgPath: String,
    bgPosition: String
  },
  setup(props) {
    const position = computed(() =>
      props.bgPosition ? props.bgPosition : 'center'
    );
    return {
      position
    };
  }
});
