










import { imageCdn } from '@/commons/constants/challenge/challenge-properties';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BannerItem',
  props: {
    bannerItem: Object,
    mobileBanner: Boolean
  },
  setup() {
    const setImagePath = (item) =>
      `${imageCdn}${item.filePath}${item.fileName}`;
    return { setImagePath };
  }
});
