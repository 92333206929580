















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BtnRegister',
  props: {
    isAuth: Boolean
  },
  setup(_props, { root }) {
    const currentRouter = root.$router.currentRoute.path;
    return { currentRouter };
  }
});
