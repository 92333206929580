

























































import Store from '@/store/index';
import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import CardThumbnail from '@/components/molecules/contents-elements/CardThumbnail.vue';
import IconAge from '@/components/atoms/icons/IconAge.vue';
import { Badge, BadgeHiatus } from '@/components/atoms';
import { ContentInfo } from '@/models/content-info';
import ConfirmDialog from '@/dialogs/ConfirmDialog.vue';
import { gotoContentHome, createContentPath } from '@/services/routing/routing.helper';
import { t, i18nWrapRuby } from '@/commons/i18n/index';
import { MenuHolder } from '@/models';
import { isPC } from '@/services/common/utils';
import { LibraryContentsService } from '@/services';
import { Unlocked } from '@/store/unlocked';

export default defineComponent({
  name: 'BasicContentCard',
  components: {
    Badge,
    BadgeHiatus,
    CardThumbnail,
    IconAge
  },
  props: {
    content: Object,
    onlyThumb: Boolean,
    menuHolder: Object,
    isContentType: Boolean,
    selectedItem: String
  },
  setup(props, { root, emit }) {
    const { dispatch } = Store;
    const contentInfo: ComputedRef<ContentInfo> = computed(
      () => props.content as ContentInfo
    );
    const menu: ComputedRef<MenuHolder> = computed(
      () => props.menuHolder as MenuHolder
    );
    const completedBadgeCondition = computed(() => {
      const { status, badge } = contentInfo.value;
      if (menu.value && menu.value.code === 'completed') {
        if (status === 'completed') {
          return badge ? false : true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    const contentTypeStr: ComputedRef<string> = computed(() => {
      const isTag =
        props.isContentType != undefined ? props.isContentType : true;
      if (isTag) {
        switch (contentInfo.value.type) {
          case 'novel':
          case 'magazine_novel':
            return root.$t('novels') + '';
          case 'comic':
          case 'magazine_comic':
          default:
            return '';
        }
      } else {
        return '';
      }
    });

    const hiddenDialog = (type, contentInfo) => {
      const name = contentInfo.name.slice(0, 30);
      let message = '';
      if ('add' === type) {
        message = i18nWrapRuby(t('hide_unlocked_content_confirm_message'), name);
      }
      if ('minus' === type) {
        message = i18nWrapRuby(t('unhide_unlocked_content_confirm_message'), name);
      }
      dispatch('DialogStore/dialog', {
        component: ConfirmDialog,
        param: {
          componentProps: {
            title: '',
            message: message,
            ok: root.$t('ok'),
            cancel: root.$t('cancel')
          }
        }
      }).then(async (res) => {
        if (res && res.value) {
          if ('add' === type) {
            hiddenEditAdd(contentInfo);
          }
          if ('minus' === type) {
            hiddenEditMinus(contentInfo);
          }
        }
      })
    };

    const hiddenEditAdd = (contentInfo) => {
      LibraryContentsService.hiddenLibraryContents(
        contentInfo.type,
        contentInfo.id
      ).then(() => {
        emit('emitHiddenItem', contentInfo);
      });
    };

    const hiddenEditMinus = (contentInfo) => {
      LibraryContentsService.unhiddenLibraryContents(
        contentInfo.type,
        contentInfo.id
      ).then(() => {
        emit('emitHiddenItem', contentInfo);
      });
    }

    const onContentClick = (contentInfo) => {
      if (Unlocked.entered && Unlocked.edit_add) {
        hiddenDialog('add', contentInfo);
        return;
      }
      if (Unlocked.entered && Unlocked.edit_minus) {
        hiddenDialog('minus', contentInfo);
        return;
      }
      gotoContentHome({
        contentType: contentInfo.type,
        contentId: contentInfo.id
      });
    };

    return {
      contentInfo,
      contentTypeStr,
      completedBadgeCondition,
      gotoContentHome,
      onContentClick,
      createContentPath,
      isPC,
      Unlocked
    };
  }
});
