





















import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  Ref,
  ref
} from '@vue/composition-api';
import GlobalHeader from '@/components/organisms/headers/GlobalHeader.vue';
import Store from '@/store/index';
import SearchControlModule from '@/components/organisms/search/SearchControlModule.vue';
import HomeBannerSlider from '@/components/molecules/banner-slider/HomeBannerSlider.vue';
import HomeMenuItems from '@/components/molecules/home/HomeMenuItems.vue';
import HomeContentModule from '@/components/organisms/HomeContentModule.vue';
import SafariShortcut from '@/components/organisms/SafariShortcut.vue';
import ComicoBi from '@/components/atoms/icons/ComicoBi.vue';
import IconParts from '@/components/molecules/header-parts/IconParts.vue';
import ContentCategoryParts from '@/components/molecules/header-parts/ContentCategoryParts.vue';
import IconOfferwal from '@/components/atoms/icons/IconOfferwal.vue';
import {
  DialogHelperServicepup,
  SavePopup
} from '@/services/dialog/dialog.helper';
import { isIOS, isPC, ClassesHeader } from '@/services';
import { DialogStoreOption } from '@/store/dialog';
import {
  HeaderUI,
  Menu,
  Popup,
  Section,
  TopBanner,
  ForegroundedInterface
} from '@/models';
import ConfirmDialog from '@/dialogs/ConfirmDialog.vue';
import router from '@/router';
import { getLocale } from '@/commons/i18n';

export default defineComponent({
  name: 'Home',
  components: {
    GlobalHeader,
    SearchControlModule,
    HomeBannerSlider,
    HomeMenuItems,
    HomeContentModule,
    SafariShortcut,
    ComicoBi,
    IconParts,
    ContentCategoryParts,
    IconOfferwal
  },
  metaInfo() {
    let _title = this.$t('web_app_meta_title').toString() as string;
    let _description = this.$t('web_app_meta_description').toString() as string;
    let _image = window.location.origin + process.env.VUE_APP_META_IMAGE;

    return {
      title: _title,
      meta: [
        { vmid: 'description', name: 'description', content: _description },
        { vmid: 'og:title', property: 'og:title', content: _title },
        { vmid: 'og:description', property: 'og:description', content: _description },
        { vmid: 'og:site_name', property: 'og:site_name', content: process.env.VUE_APP_METE_TITLE },
        { vmid: 'og:type', property: 'og:type', content: 'website' },
        { vmid: 'og:url', property: 'og:url', content: process.env.VUE_APP_META_URL },
        { vmid: 'og:image', property: 'og:image', content: _image },
        { vmid: 'og:locale', property: 'og:locale', content: ref(getLocale()).value },
        { vmid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
        { vmid: 'twitter:site', name: 'twitter:site', content: process.env.VUE_APP_META_TWITTER_SITE },
        { vmid: 'fb:app_id', property: 'fb:app_id', content: process.env.VUE_APP_FB_ID },
      ],
      headAttrs: { prefix: "og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# website: http://ogp.me/ns/website#" }
    };
  },
  setup(props, { root }) {
    const { $q, $t } = root;
    const { getters, commit, dispatch } = Store;
    const isShow: Ref<string> = ref(
      localStorage.getItem('safari_shorcut_check') || 'Y'
    );
    const scrollPosition: Ref<number> = ref(
      document.documentElement.scrollTop || window.scrollY
    );
    const isShortcutShow: Ref<boolean> = ref(true);
    const menu: ComputedRef<Menu> = computed(() => getters['HomeStore/menu']);
    const sections: ComputedRef<Section[]> = computed(
      () => getters['HomeStore/sections']
    );
    const topBanner: ComputedRef<TopBanner> = computed(
      () => getters['HomeStore/topBanner']
    );
    const popups: ComputedRef<Popup[]> = computed(
      () => getters['HomeStore/popups'] as Popup[]
    );
    const isAuth: ComputedRef<boolean> = computed(
      () => getters['AuthStore/isAuth']
    );
    const getForegrounded: ComputedRef<ForegroundedInterface> = computed(
      () => getters['AuthStore/getForegrounded']
    );
    const verifyPasswordExpired: ComputedRef<boolean> = computed(
      () => getForegrounded.value.required?.changePassword
    );

    const headers: HeaderUI = reactive({
      invisibleHeader: true,
      headerRef: null
    });

    const saveHeaderHeight = () => {
      if (headers.headerRef) {
        commit('UIStore/headerHeight', headers.headerRef.$el.clientHeight);
      }
    };
    const handleScroll = (evt) => {
      const openDialog = document.body.classList.contains(
        'q-body--prevent-scroll'
      );
      headers.invisibleHeader = window.scrollY <= 0 && !openDialog;
      headers.invisibleHeader
        ? ClassesHeader('main_header')
        : ClassesHeader('', 'main_header');
      if (isShow.value === 'Y' && window.scrollY > 0) {
        const newScrollTop =
          document.documentElement.scrollTop === 0
            ? window.scrollY
            : document.documentElement.scrollTop;
        isShortcutShow.value = newScrollTop - scrollPosition.value < 0;
        scrollPosition.value = newScrollTop;
      }
    };

    const getPopupTemplate = (uiType: string | undefined) => {
      switch (uiType) {
        case 'image':
          return () => import('@/dialogs/ImageDialog.vue');
        case 'content':
        default:
          return () => import('@/dialogs/ContentDialog.vue');
      }
    };

    const {
      getPopupIndex,
      checkLatestPopupTime,
      checkSkipPopup,
      savePopupInfo
    } = DialogHelperServicepup;

    const openPopup = (popup: Popup, index: number) => {
      return new Promise<SavePopup>((resolve) => {
        const options: DialogStoreOption = {
          component: getPopupTemplate(popup.uiType),
          param: {
            popup,
            parent: root
          }
        };
        dispatch('DialogStore/dialog', options).then((res) => {
          savePopupInfo(index, res, popup);
        });
      });
    };

    const initPopup = (popups: Popup[]) => {
      if (!(popups.length > 0)) return;
      const index = getPopupIndex(popups.length);
      const popup = popups[index];
      const time = checkLatestPopupTime();

      if (time) {
        if (checkSkipPopup(popup.id + '')) {
          openPopup(popup, index);
        }
      }
    };

    // const initPopup = (popups: Popup[]) => {
    //   if (!(popups.length > 0)) return;
    //   const popup = popups[0];
    //   const { SKIP_POPUP_INFO, remove } = DialogHelperServicepup;
    //   const str: string | null = localStorage.getItem(SKIP_POPUP_INFO);
    //   try {
    //     if (str) {
    //       const storageJson = JSON.parse(str);
    //       if (storageJson[popup.id]) {
    //         //  저장된 팝업인지 체크
    //         const savedJson = storageJson[popup.id];
    //         if (!savedJson['isSkip']) {
    //           // skip 체크
    //           if (
    //             popup.isEqualPopup(
    //               // 저장된 팝업, 새팝업 동일한지 체크
    //               savedJson.repeatable,
    //               savedJson.repeatInterval
    //             )
    //           ) {
    //             if (savedJson.repeatable) {
    //               // repeatable 체크
    //               if (savedJson.repeatInterval) {
    //                 // repeatInterval 체크
    //                 // (repeatInterval 시간 만큼 경과했나?)
    //                 const current = new Date().getTime();
    //                 const lastDate = +savedJson['lastDate'];
    //                 const gap = (current - lastDate) / 1000;
    //                 if (gap > savedJson.repeatInterval) {
    //                   openPopup(popup);
    //                 }
    //               } else {
    //                 openPopup(popup);
    //               }
    //             }
    //           } else {
    //             openPopup(popup);
    //           }
    //         }
    //       } else {
    //         openPopup(popup);
    //       }
    //     } else {
    //       openPopup(popup);
    //     }
    //   } catch (e) {
    //     remove();
    //   }
    // };

    const toggleOfferwall = () => {
      if (isAuth.value) {
        showOfferwall();
      } else {
        $q.dialog({
          component: ConfirmDialog,
          componentProps: {
            title: '',
            message: root
              .$t('validator_required_login')
              .toString()
              .split('\n')
              .join('<br />'),
            cancel: root.$t('cancel'),
            ok: root.$t('sign_in')
          }
        }).onOk(async () => {
          router.push('/login');
        });
      }
    };

    const showOfferwall = () => {
      commit('GlobalStore/setDimmed', true);
      commit('OfferwallStore/setVisible');
    };

    const checkSafariShortcut = () => {
      if (isPC()) {
        isShortcutShow.value = false;
      } else {
        if (isShow) {
          isShortcutShow.value = isShow.value === 'Y';
        } else {
          localStorage.setItem('safari_shorcut_check', 'Y');
          isShortcutShow.value = true;
        }
      }
    };

    const closeShortcut = () => {
      isShow.value = 'N';
      localStorage.setItem('safari_shorcut_check', isShow.value);
      isShortcutShow.value = !isShortcutShow.value;
    };

    // 비밀번호 만료 체크
    const checkPasswordExpired = async () => {
      await dispatch('AuthStore/foregrounded');

      if (verifyPasswordExpired.value) {
        router
          .push({
            name: 'CampaignPassword',
            params: { nextPage: 'true' }
          })
          .catch(() => {
            //
          });
      }
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      saveHeaderHeight();
      initPopup(popups.value);
      checkSafariShortcut();
      if (isAuth.value) checkPasswordExpired();
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      isShortcutShow,
      menu,
      sections,
      topBanner,
      ...toRefs(headers),
      toggleOfferwall,
      isIOS,
      closeShortcut
    };
  }
});
