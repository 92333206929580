

































































import {
  Ref,
  ref,
  toRefs,
  reactive,
  ComputedRef,
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  watch
} from '@vue/composition-api';
import Store from '@/store/index';
import Router from '@/router/index';
import GlobalHeader from '@/components/organisms/headers/GlobalHeader.vue';
import ContentCategoryParts from '@/components/molecules/header-parts/ContentCategoryParts.vue';
import SearchSection from '@/components/molecules/sections/SearchSection.vue';
import IconParts from '@/components/molecules/header-parts/IconParts.vue';
import ContentListModuleTag from '@/components/organisms/contents-list/ContentListModuleTag.vue';
import DeleteButton from '@/components/atoms/buttons/DeleteButton.vue';
import { ContentInfo, ContentsItemInfo, HeaderUI } from '@/models';
import { isPC } from '@/services/common/utils';
import { getKeywords, setKeywords, superEncodeURI } from '@/services';

export default defineComponent({
  name: 'Search',
  components: {
    GlobalHeader,
    ContentCategoryParts,
    IconParts,
    SearchSection,
    ContentListModuleTag,
    DeleteButton
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.q) Store.commit('SearchStore/setKeyword', to.params.q);
    next();
  },
  setup(props, { root }) {
    const { getters, dispatch, commit } = Store;
    const route = root.$route;
    const { q } = route.params;
    const invisibleResult = ref(false);
    const userKeyword: ComputedRef<string> = computed(
      () => getters['SearchStore/getKeyword']
    );
    const searchItems: ComputedRef<ContentsItemInfo> = computed(
      () => getters['SearchStore/getSearchItems']
    );
    const contentList: Ref<ContentInfo[]> = ref([]);
    const keywords: Ref<string[]> = ref<string[]>([]);
    const searchInput = ref(null);
    const noDataFlag = ref(false);
    const totalPages = ref(0);
    const currentPageNo = ref(0);
    const pageSize = ref('100');
    const headers: HeaderUI = reactive({
      invisibleHeader: true,
      headerRef: null,
      fixedTabMenu: false
    });

    /** 목록: 초기화 */
    const resetContentList = () => {
      contentList.value = [];
      totalPages.value = 0;
      currentPageNo.value = 0;
    };

    /** 작품 목록 확인: 전체리스트(contentList) 및 마지막리스트(searchItems) */
    const isContetnList = () => {
      return searchItems.value.contents && searchItems.value.contents.length > 0
        ? true
        : contentList.value.length > 0
        ? true
        : false;
    };

    /** 목록: API 요청 및 ContentList 할당 */
    const getListSearch = async () => {
      const params = {
        query: userKeyword.value,
        pageNo: currentPageNo.value.toString(),
        pageSize: pageSize.value
      };
      try {
        await dispatch('SearchStore/getListSearch', params);
        if (currentPageNo.value === 0) invisibleResult.value = false;

        noDataFlag.value = !isContetnList();
        if (noDataFlag.value) return;
        if (searchItems.value.contents) {
          const contentInfos = searchItems.value.contents.map(
            (node) => new ContentInfo(node)
          );
          contentList.value.push(...contentInfos);
        }
      } catch (e) {
        console.log(e);
        return;
      }
    };

    /** 검색바 포커스 in/out에 따른 UI 처리 */
    const focusIn = (isFocus: boolean) => {
      keywords.value = getKeywords('keywords');
      invisibleResult.value = isFocus || true;
    };
    const focusOut = (isFocus: boolean) => {
      if (invisibleResult.value) {
        invisibleResult.value = isFocus || false;
      } else {
        // 모바일 UI의 화면 닫기 버튼
        Router.push('/');
      }
    };

    /** 검색 버튼 이벤트 */
    const onEnter = async (keyword: string) => {
      try {
        if (!keyword || keyword.length === 0) return;
        setKeywords('keywords', userKeyword.value, 10);
        Router.push(`/search/${superEncodeURI(keyword)}`).catch((error) => {
          if (error.name === 'NavigationDuplicated') return;
        });
      } catch (e) {
        console.log(e);
      }
    };

    /** 검색어 삭제 */
    const onDelete = (idx: number) => {
      keywords.value.splice(idx, 1);
      localStorage.setItem('keywords', keywords.value.join(','));
    };

    /** 파마미터 검색어 유무 확인, 검색어 존재 시 팝업 레이어 노출 안 함 */
    onBeforeMount(() => {
      keywords.value = getKeywords('keywords');
      if (!q) return (noDataFlag.value = true);
      commit('SearchStore/setKeyword', q.toString());
      commit('SearchStore/initFocus', false);
    });

    /** 페이지 이동시 초기화 */
    onBeforeUnmount(() => commit('SearchStore/resetState'));

    watch(
      () => root.$route.params,
      (params) => {
        try {
          if (!q) commit('SearchStore/setKeyword', params.q.toString());
        } catch (e) {
          //  console.log('watch(() => root.$route.params e:', e);
        }
      }
    );

    watch(userKeyword, async () => {
      if (!userKeyword.value || userKeyword.value.length === 0) return;
      await onEnter(userKeyword.value);
      resetContentList();
      getListSearch();
    });

    /** 연속 스크롤 */
    const onLoad = async (index, done) => {
      if (!searchItems.value.page) return;
      const nextPage = searchItems.value.page.hasNext;
      if (nextPage) {
        currentPageNo.value++;
        await getListSearch();
        done();
      }
    };

    const handleScroll = () => {
      const { scrollY } = window;
      headers.invisibleHeader = scrollY === 0;
      headers.fixedTabMenu = scrollY > 0;
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      superEncodeURI,
      invisibleResult,
      isPC,
      userKeyword,
      keywords,
      searchInput,
      noDataFlag,
      currentPageNo,
      searchItems,
      contentList,
      resetContentList,
      getListSearch,
      focusIn,
      focusOut,
      onEnter,
      onLoad,
      handleScroll,
      onDelete,
      ...toRefs(headers)
    };
  }
});
