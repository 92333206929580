export const CODE_TYPE = {
  OS: {
    PCWEB: 'other',
    ANDROID: 'aos',
    IOS: 'ios'
  },
  PLATFORM: {
    WEB: 'web'
  },
  STORE: {
    WEB: 'other'
  }
};

export const SHARE_TYPE = {
  FACEBOOK: 'FACEBOOK',
  TWIT: 'TWIT',
  URL: 'URL'
}

/**
 * md추천, editor's추천 메뉴 코드
 */
export const CONTENT_CATALOG = 'content_catalog';