






































import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    noneFixed: Boolean
  },
  setup(_props, { root }) {
    const router = ref(root.$router);
    const nav = [
      {
        title: 'TOP',
        link: '/challenge',
        name: 'ChallengeHome'
      },
      {
        title: 'ランキング',
        link: '/challenge/ranking',
        name: 'ChallengeRanking'
      },
      {
        title: 'ジャンル',
        link: '/challenge/genre',
        name: 'ChallengeGenre'
      }
    ];
    return { nav, router };
  }
});
