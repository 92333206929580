/**
 * 정수 소수점 표기: 한국코미코 1coin = 180원
 * ex) 182 / 10 | coinInteger
 * @param  {number} value
 */
const coinInteger = (value: number) => {
  const integerCheck = Number.isInteger(value);
  let arr: string[];
  integerCheck
    ? (arr = value
        .toFixed(1)
        .toString()
        .split('.'))
    : (arr = value.toString().split('.'));
  arr[0] = arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return arr.join('.');
};

/*
 * 콤마
 */
const numberComma = (value: number | undefined) => {
  if(!value) return 0;
  const x = value.toString();
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export { coinInteger, numberComma };
