







import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'IconClose',
  props: {
    label: String
  },
  setup(props, { emit }) {
    const onCloseClick = () => {
      event?.preventDefault();
      emit('onClose', {});
    };
    return {
      onCloseClick
    };
  }
});
