























import { defineComponent, ref } from '@vue/composition-api';
import store from '@/store';
import { isIOS } from '@/services';

export default defineComponent({
  props: {
    avatarImageUrl: String
  },
  setup(_props, { root }) {
    const { $q } = root;
    const { dispatch } = store;
    const form = new FormData();
    /**
     * 아바타 이미지 변경
     * @param {File} image
     */
    const putAvatarImage = (image: File) => {
      form.append('avatar', image);
      dispatch('MyPageStore/putAvatarImage', form);
    };

    const ios = !isIOS;

    const dataURLtoFile = (dataurl, filename: string): File => {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    };

    const imageResize = (image: File) => {
      const file = image;
      if (file) {
        const reader = new FileReader();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img: HTMLImageElement = document.createElement('img');

        reader.onload = (e) => {
          new Promise((resolve) => {
            img.src = e.target?.result as string;
            resolve(img);
          })
            .then((res) => {
              let width = img.width;
              let height = img.height;
              ctx?.drawImage(img, 0, 0);

              width = (300 / width) * width;
              height = (300 / img.width) * height;

              canvas.width = width;
              canvas.height = height;

              ctx?.drawImage(img, 0, 0, width, height);
              return canvas.toDataURL(file.type);
            })
            .then((res) => {
              putAvatarImage(dataURLtoFile(res, file.name));
            })
            .catch((e) => {
              putAvatarImage(image);
            });
        };
        return reader.readAsDataURL(file);
      }
    };

    const chageAvatar = (event) => {
      const image = event.target.files[0];
      const imageSize = Math.floor(image.size / 1000);
      const imageType =
        image.type === 'image/jpeg' ||
        image.type === 'image/png' ||
        image.type === 'image/bmp';

      form.delete('avatar');
      switch (true) {
        case event.target.files.length < 1:
          return;
        case imageSize > 1024:
          imageResize(image);
          return;
        case !imageType:
          $q.notify(
            root.$i18n.t('invalid_upload_image', { value: 'jpeg, png, bmp' })
          );
          return;
        default:
          putAvatarImage(image);
      }
    };

    return {
      chageAvatar
    };
  }
});
