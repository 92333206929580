






import { defineComponent } from '@vue/composition-api';
import router from '@/router/index';

export default defineComponent({
  name: 'MyPageButton',
  setup() {
    const onButtonClick = () => {
      router.push('/login');
    };
    return {
      onButtonClick
    };
  }
});
