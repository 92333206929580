








import {
  computed,
  defineComponent,
  onBeforeUnmount,
  watch,
  onMounted
} from '@vue/composition-api';
import Store from '@/store/index';
import { i18nBreak } from '@/commons/i18n';
import Container from '@/views/common/Container.vue';
import AlertDialog from '@/dialogs/AlertDialog.vue';
import { isPC, ClassesHidden } from '@/services/common/utils';

export default defineComponent({
  name: 'ServiceError',
  components: {
    Container,
    AlertDialog
  },
  setup(props, { root }) {
    const { $q } = root;
    const { dispatch } = Store;
    const route = root.$route;
    const { errorState } = route.params;
    const isMobile = computed(() => !isPC());

    onMounted(() => {
      if (errorState) {
        const host = window.location.host;
        if (host.indexOf('alpha') != -1) {
          console.error(
            errorState['response']['url'],
            JSON.stringify(errorState)
          );
          $q.dialog({
            component: AlertDialog,
            parent: root,
            componentProps: {
              title: `ErrorCode: ${errorState['code']}`,
              message: `- ${errorState['message']}<br />- ${errorState['response']['url']}`,
              ok: 'OK',
              html: true
            }
          }).onOk(() => {
            root.$router.push('/');
          });
        }
      }
    });

    onBeforeUnmount(() => {
      dispatch('ErrorStore/RESET_STATE');
    });

    watch(isMobile, (res: boolean) => {
      ClassesHidden(res, 'hidden');
    });
    return { i18nBreak };
  }
});
