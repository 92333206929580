import { offerwallApi } from '@/services/offerwall/offerwall.api';
import { Module } from 'vuex';
import { RootState } from '.';

interface pointParkInterface {
  appKey: string | null;
  clientImmutableUid: string;
  createdAt: string;
  guest: boolean;
  os: string;
  platform: string;
  productName: string | null;
  provider: string;
  pubKey: string | null;
  purchased: number | string;
  result: string;
  resultCode: number;
  resultMessage: string | null;
  status: string;
  store: string;
  token: string;
  totalPoint: number | string;
  tradeNo: string;
  userId: number;
  zoneOffset: string | null;
  zoneRegion: string | null;
}

interface offerwallStateInterface {
  offerwall: Record<string, unknown> | null;
  visibleOfferwall: boolean;
  pointpark: pointParkInterface;
}

export const OfferwallStore: Module<offerwallStateInterface, RootState> = {
  namespaced: true,
  state: {
    offerwall: null,
    visibleOfferwall: false,
    pointpark: {} as pointParkInterface
  },

  getters: {
    getOfferwall: (state) => state.offerwall,
    visibleOfferwall: (state) => state.visibleOfferwall,
    pointParkResult: (state) => state.pointpark
  },

  mutations: {
    setOfferwal: (state, payload) => (state.offerwall = payload),
    setPointParkResult: (state, payload) => (state.pointpark = payload),
    resetPointPark: (state) => (state.pointpark = {} as pointParkInterface),
    resetOfferwall: (state) => (state.offerwall = null),
    setVisible: (state) => (state.visibleOfferwall = !state.visibleOfferwall)
  },

  actions: {
    getPincrux: ({ commit }, os: string) => {
      offerwallApi.getPincrux(os).then((res) => {
        commit('setOfferwal', res.data.pincrux);
      });
    },
    getPointPark: ({ commit }) => {
      offerwallApi.getPointPark().then((res) => {
        commit('setOfferwal', res.data.pointpark);
      });
    },
    getPointParkResult: async ({ commit }, token: string) => {
      return await offerwallApi.getPointParkResult(token).then((res) => {
        commit('setPointParkResult', res.pointpark);
        return res;
      });
    }
  }
};
