import { Page } from '@/models';
import { HistoryComment } from '@/models/comment-info';
import { commentApi } from '@/services/comment/comment.api';
import { Module } from 'vuex';
import { RootState } from '..';

export interface UserCommentHistoryTypes {
	hComment: HistoryComment | null;
	hComments: HistoryComment[];
	page: Page;
}

export const UserCommentHistoryStore: Module<UserCommentHistoryTypes, RootState> = {
	namespaced: true,
	state: {
		hComment: null,
		hComments: [],
		page: {} as Page
	},
	mutations: {
		hComment(state: UserCommentHistoryTypes, value: HistoryComment) {
			state.hComment = value;
		},
		hComments(state: UserCommentHistoryTypes, value: HistoryComment[]) {
			state.hComments = value;
		},
		page(state, value: Page) {
			state.page = value;
		}
	},
	getters: {
		hComment: state => state.hComment,
		hComments: state => state.hComments,
		page: (state) => state.page,
	},
	actions: {
		async initUserCommentHistory({ commit, state }, { authorUserId, epochSecond }: {
			authorUserId: number,
			epochSecond?: number
		}) {
			const result: {
				comments: HistoryComment[],
				page: Page
			} =
				await commentApi.getAuthorComment({
					userId: authorUserId,
					epochSecond
				});
			const comments = result.comments.map(node => new HistoryComment(node));
			commit('hComments', [...state.hComments, ...comments]);
			commit('hComment', comments[0]);
			commit('page', result.page);
		},
		async likeUserComment({ state }, { contentType, commentId }) {
			if(!contentType) return;
			try {
				await commentApi.likeComment({
					contentType,
					commentId
				});
				state.hComments.forEach(node => {
					if(node.id === commentId) {
						node.totalLikes++;
					}
				});
			} catch(e) {
				//
			}
		}
	},
}