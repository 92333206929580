











import {
  Ref,
  ref,
  computed,
  defineComponent,
  watch
} from '@vue/composition-api';

export default defineComponent({
  name: 'ChkBase',
  props: {
    formData: Boolean,
    sectionName: String,
    sectionId: String
  },
  setup(props, { emit }) {
    const propsFormData = computed(() => props.formData);
    const isChecked: Ref<boolean> = ref(false);
    const onUnselectAll = () => {
      emit('unselect', isChecked);
    };
    watch(propsFormData, (data: boolean) => {
      isChecked.value = data;
    });
    return { isChecked, onUnselectAll };
  }
});
