





import { defineComponent } from '@vue/composition-api';
import { LANGUAGE_TYPES } from '@/commons/constants/config-language';

export default defineComponent({
  name: 'LanguageButton',
  props: {
    currentLang: String,
    lang: String,
    label: String,
    link: Boolean,
    env: String,
    isPort: String
  },
  setup(props, context) {
    const onButtonClick = () => {
      context.emit('onChangeLan', props.lang);
    };

    const linkUrl = () => {
      const host = (props.lang === LANGUAGE_TYPES.KO_KR) 
        ? 'kr'
        : ((props.lang === LANGUAGE_TYPES.JA_JP) ? 'jp': 'io');
      return `https://${props.env}www.comico.${host}${props.isPort}`
    };

    return {
      onButtonClick,
      linkUrl
    };
  }
});
