
















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    urlPath: String,
    label: String,
    value: String
  },
  setup() {
    return {};
  }
});
