






















import { ComputedRef, computed, defineComponent } from '@vue/composition-api';
import IconNextPrev from '@/components/atoms/icons/IconNextPrev.vue';
import { IconType, ICON_NEXT, ICON_PREV } from '@/commons/constants/icon-types';

export default defineComponent({
  name: 'SectionNavigator',
  components: {
    IconNextPrev
  },
  props: {
    prev: Boolean,
    next: Boolean,
    sectionType: String
  },
  setup(props, { emit }) {
    const onPrevNext = (type: IconType) => emit('onDirection', type);
    const verticalCenter: ComputedRef<boolean> = computed(
      () => props.sectionType === 'banner_list'
    );
    return {
      ICON_NEXT,
      ICON_PREV,
      verticalCenter,
      onPrevNext
    };
  }
});
