




import { ref, computed, defineComponent, watch } from '@vue/composition-api';
import { PolicyService } from '@/services/policy/policy.api.ts';
import { createAxiosService } from '@/services/common/create-axios.service';
import { marked } from "marked";

export default defineComponent({
  name: 'HtmlviewTemplete',
  props: {
    contents: String,
    classes: String
  },
  setup(props) {
    // console.log('props contents', props.contents);
    // const htmlUrl = computed(() => props.url);
    // const views = ref('');
    // const axiosService = createAxiosService();

    // const viewTemplete = async () => {
    //   if (htmlUrl.value) {
    //     const pathname = new URL(htmlUrl.value).pathname;
    //     const config = { headers: { 'Content-Type': 'text/html' } };
    //     const data = await axiosService.get(pathname).then((res) => {
    //       const { data } = res;
    //       return data;
    //     });
    //     views.value = data;
    //   }
    // };

    // watch(htmlUrl, () => {
    //   viewTemplete();
    // });

    // viewTemplete();

    return {
      // htmlUrl,
      // views,
      // viewTemplete
      marked
    };
  }
});
