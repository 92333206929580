import { i18nTxt } from '@/commons/i18n/index';

export interface CommonMenuItems {
  id?: number;
  label: string;
  code: string;
}

export interface CoinHistoryTypes {
  label: string;
  message: string;
  code: string;
}

export const CONTENT_TYPES = {
  all_comic: 'all_comic',
  all_novel: 'all_novel'
};

export const LIBRARY_TYPES: Array<CommonMenuItems> = [
  {
    label: i18nTxt('subscribed'),
    code: 'subscribed'
  },
  {
    label: i18nTxt('recent'),
    code: 'recent'
  },
  {
    label: i18nTxt('rental'),
    code: 'rented'
  },
  {
    label: i18nTxt('unlocked'),
    code: 'unlocked'
  }
];

export const EDIT_TYPES: Array<CommonMenuItems> = [
  {
    id: 1,
    label: '편집',
    code: 'edit'
  },
  {
    id: 2,
    label: '완료',
    code: 'complete'
  }
];

export const CONTENT_CARD_TYPE = {
  BASIC: 'BASIC',
  MYLIB: 'MYLIB',
  RANKING: 'RANKING',
  DAILY: 'DAILY',
  COMINGSOON: 'COMINGSOON'
};

export const COIN_HISTORY_TYPE: Array<CoinHistoryTypes> = [
  {
    label: 'title1',
    message: 'message2',
    code: 'charge'
  },
  {
    label: 'title2',
    message: 'message2',
    code: 'extinct'
  }
];

export const VIEW_CHAPTER_TYPE = {
  FIRST: 'FIRST',
  CONTINUE: 'CONTINUE'
};