import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { AuthService, isEmptyObject } from '@/services/index';
import {
  RsaKeysInterface,
  Authentication,
  Auth,
  ForegroundedInterface
} from '@/models';
import { NEO_CHK } from '@/commons/constants/auth-types';
import { ERROR_CODES } from '@/commons/constants/error-codes';
import { deleteCookie } from '@/helpers/cookies';

export interface AuthStateInterface {
  certificateEmail: string | null;
  ipAddress: string | null;
  authentication: Auth;
  foregrounded: ForegroundedInterface;
  rsaKeysInfo: RsaKeysInterface;
  authErrInfo: Object;
  neoChk: string | null;
  resetForm: boolean;
  isAuth: boolean;
}

const getDefaultState = (): AuthStateInterface => {
  return {
    certificateEmail: '',
    ipAddress: sessionStorage.getItem('ipAddress') || null,
    authentication: {} as Auth,
    foregrounded: {} as ForegroundedInterface,
    rsaKeysInfo: {} as RsaKeysInterface,
    authErrInfo: {},
    neoChk: null,
    resetForm: false,
    isAuth: false
  };
};

/**
 * AuthStore
 */
export const AuthStore: Module<AuthStateInterface, RootState> = {
  namespaced: true,
  state: getDefaultState,
  getters: {
    getCertificateEmail: (state) => state.certificateEmail,
    getIP: (state) => state.ipAddress,
    getRsaKeys: (state) => state.rsaKeysInfo.rsa,
    getAuthErrInfo: (state) => state.authErrInfo,
    getResetForm: (state) => state.resetForm,
    getAuthentication: (state) => state.authentication,
    getForegrounded: (state) => state.foregrounded,
    statusDormant: (state) => state.authentication.dormantStatus,
    statusPasswordExpired: (state) =>
      state.authentication.passwordExpiredStatus,
    isAuth: (state) => {
      // 사파리 쿠키 및 state 갱신 안되는 버그로 로컬 스토리지 활용
      // const currentUser = localStorage.getItem('currentUser') ? true : false;
      state.isAuth; // state문 실행으로 getter 갱신
      return state.isAuth;
    }
  },
  mutations: {
    ipAddress: (state, value: string) => (state.ipAddress = value),
    rsaKeys: (state, value: RsaKeysInterface) => (state.rsaKeysInfo = value),
    certificateEmail: (state, value: string) =>
      (state.certificateEmail = value),
    login(state, value: Authentication) {
      state.authentication = value?.authentication;
      state.neoChk = value?.authentication.sessionToken;
      sessionStorage.setItem('neoIdUid', value?.authentication.neoIdUid);
      sessionStorage.setItem(
        'userId',
        JSON.stringify(value?.authentication.userId)
      );
      state.isAuth = true;
    },
    logout(state) {
      Object.assign(state, getDefaultState());
      sessionStorage.removeItem('neoIdUid');
      sessionStorage.removeItem('userId');
      localStorage.removeItem('currentUser');
      state.isAuth = false;
    },
    foregrounded: (state, value: ForegroundedInterface) =>
      (state.foregrounded = value),
    authErrInfo: (state, value: Object) => (state.authErrInfo = value || {}),
    setNeoChk: (state, value: string) => (state.neoChk = value),
    setIsAuth: (state, value: boolean) => (state.isAuth = value),
    setResetForm: (state, value: boolean) => (state.resetForm = value),
    setPolicyStatus: (state, value: boolean) =>
      (state.authentication.policyStatus = value),
    setPasswordExpiredStatus: (state, value: boolean) =>
      (state.authentication.passwordExpiredStatus = value),
    setDormantStatus: (
      state,
      params: { status: boolean; neoIdUid: string; responseTokenType: string }
    ) => {
      state.authentication.responseTokenType = params.responseTokenType;
      state.authentication.dormantStatus = params.status;
      state.authentication.neoIdUid = params.neoIdUid;
    }
  },
  actions: {
    async GET_IP_ADDRESS({ commit }) {
      const data = await AuthService.getIP();
      const ip = data.toString();
      if (ip != '0.0.0.0') {
        commit('ipAddress', ip);
        sessionStorage.setItem('ipAddress', ip);
      }
      return ip;
    },
    async GET_RSA_KEYS({ commit }, sessionType) {
      try {
        const data = await AuthService.getRsaKeys(sessionType);
        commit('rsaKeys', data);
        return data;
      } catch (err) {
        return err;
      }
    },
    async LOGIN({ commit, dispatch }, auth) {
      try {
        const data = await AuthService.login(auth);
        if (isEmptyObject(data.data)) {
          commit('authErrInfo', data.result);
        } else {
          try {
            if (data.result['code'] === ERROR_CODES.CODE_200) {
              await dispatch('MyPageStore/getUserInfo', null, { root: true });
              await this.dispatch('AuthStore/foregrounded');
              commit('login', data.data);
            }
          } catch (e) {
            //console.log(e);
          }
        }
        return data;
      } catch (err) {
        return err;
      }
    },
    async checkDormantPassword({ commit, dispatch }, auth) {
      try {
        const data = await AuthService.checkDormantPassword(auth);
        return data;
      } catch (err) {
        return err;
      }
    },
    async foregrounded({ commit }) {
      const data = await AuthService.foregrounded();
      commit('foregrounded', data.data);
      return data;
    },
    async memberConsent({ commit }, params) {
      const data = await AuthService.memberConsent(params);
      return data;
    },
    async certificateEmail({ commit }, params) {
      const data = await AuthService.certificateEmail(params);
      commit('certificateEmail', params.email);
      return data;
    },
    async signup({ commit, dispatch }, { auth, isKo }) {
      try {
        const data = await AuthService.signup(auth, isKo);
        try {
          if (data.result['code'] === ERROR_CODES.CODE_200) {
            await dispatch('MyPageStore/getUserInfo', null, { root: true })
            commit('login', data.data);
          }
        } catch (e) {
          //console.log(e);
        }
        return data;
      } catch (err) {
        return err;
      }
    },
    async changePassword({ commit }, auth) {
      const data = await AuthService.changePassword(auth);
      if (isEmptyObject(data.data)) {
        commit('authErrInfo', data.result);
      }
      return data;
    },
    async resetPassword({ commit }, auth) {
      const data = await AuthService.resetPassword(auth);
      if (isEmptyObject(data.data)) {
        commit('authErrInfo', data.result);
      }
      return data;
    },
    async logout({ commit }, params?) {
      if (!params) await AuthService.logout();
      commit('logout');
      commit('MyPageStore/resetUserInfo', null, { root: true });
    }
  }
};
