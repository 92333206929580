import { render, staticRenderFns } from "./MypageForm.vue?vue&type=template&id=48fac88c&scoped=true&"
import script from "./MypageForm.vue?vue&type=script&lang=ts&"
export * from "./MypageForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48fac88c",
  null
  
)

export default component.exports