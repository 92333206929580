export class ChallengeContentActivity {
  lastReadAt: string;
  lastReadChapterId: number;
  lastReadChapterLocator: string;
  subscribed: object;
  constructor(data: ChallengeContentActivity) {
    this.lastReadAt = data.lastReadAt;
    this.lastReadChapterId = data.lastReadChapterId;
    this.lastReadChapterLocator = data.lastReadChapterLocator;
    this.subscribed = data.subscribed;
  }
}

export class ChallengeBookshelf {
  name: string;
  nickname: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  activity?: ChallengeContentActivity;

  constructor(data: ChallengeBookshelf) {
    this.name = data.name;
    this.nickname = data.nickname;
    this.description = data.description;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}

export class ChallengeSubscribe extends ChallengeBookshelf {
  contentId: number;
  userId: number;
  coverImageFilePath: string;
  coverImageFileName: string;
  squareImageFilePath: string;
  squareImageFileName: string;
  checked: boolean;

  constructor(data: ChallengeSubscribe) {
    super(data);
    this.checked = data.checked;
    this.contentId = data.contentId;
    this.userId = data.userId;
    this.coverImageFilePath = data.coverImageFilePath;
    this.coverImageFileName = data.coverImageFileName;
    this.squareImageFilePath = data.squareImageFilePath;
    this.squareImageFileName = data.squareImageFileName;
  }
  getSquareCoverImagePath(): string {
    const filePath = this.squareImageFilePath || this.coverImageFilePath;
    const fileName = this.squareImageFileName || this.coverImageFileName;
    return `${process.env.VUE_APP_CHALLENGE_IMAGES_DOMAIN}${filePath}${fileName}`;
  }

  getLinkContentHome(): string {
    return `/challenge/comic/${this.contentId}`;
  }
}

export class ChallengeSubscriptions {
  subscriptions: ChallengeSubscribe[];

  constructor(data: ChallengeSubscriptions) {
    this.subscriptions = data.subscriptions
      ? data.subscriptions.map((node) => new ChallengeSubscribe(node))
      : [];
  }
}
