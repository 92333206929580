






























import { Chapter, ContentInfo, NextPrevChapter } from '@/models';
import { computed, ComputedRef, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'NextChapterCard',
  props: {
    chapter: Object,
    content: Object
  },
  setup(props, { emit }) {
    const contentInfo: ComputedRef<ContentInfo> = computed(
      () => props.content as ContentInfo
    );
    const chapterInfo: ComputedRef<Chapter> = computed(
      () => props.chapter as Chapter
    );
    const nextChapter: ComputedRef<NextPrevChapter> = computed(
      () => chapterInfo.value.nextChapter as NextPrevChapter
    );
    const onNextChapterClick = () => {
      if (nextChapter.value) {
        emit('emitNextChapter', nextChapter.value);
      }
    };
    const isVerticalThumbnail = computed(() => {
        return contentInfo.value.isVerticalChapterThumbnail();
    });

    return {
      contentInfo,
      chapterInfo,
      nextChapter,
      onNextChapterClick,
      isVerticalThumbnail
    };
  }
});
