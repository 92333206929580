const createFacebookShareHref = (targetUrl: string) => {
	return encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${targetUrl}`);
};

const createTwitterShareHref = ({ text, targetUrl }: { text: string, targetUrl: string }) => {
	const hashtags = 'comico';
	return `https://twitter.com/intent/tweet?text=${text}&url=${targetUrl}&hashtags=${hashtags}`;
};

const createLineShareHref = (targetUrl: string) => {
	const url = location.protocol + '//' + targetUrl;
	return encodeURI(`https://social-plugins.line.me/lineit/share?url=${url}`);
};

export const ChallengeHelpers = {
	createFacebookShareHref,
	createTwitterShareHref,
	createLineShareHref
};