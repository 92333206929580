











import { Ref, ref, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ChkDefault',
  props: {
    checkName: String,
    checkId: String,
    checked: Boolean
  },
  setup(props, { emit }) {
    const isStayLogged: Ref<boolean> = ref(true);
    const onChecked = () => emit('emitChecked', isStayLogged.value);
    return { isStayLogged, onChecked };
  }
});
