


























import { numberComma } from '@/helpers/filters';
import { convertDate } from '@/services';
import { defineComponent, ref } from '@vue/composition-api';
import TagStatus from '@/components/atoms/challenge/TagStatus.vue';
export default defineComponent({
  props: {
    itemInfo: Object
  },
  components: {
    TagStatus
  },
  setup(_props) {
    const createdAt = _props.itemInfo?.createdAt;
    const updatedAt = _props.itemInfo?.updatedAt;
    const completed = _props.itemInfo?.completed;

    const convertLikesCount = (likes: number) =>
      likes > 10000 ? `${(likes / 10000).toFixed(1)}万` : numberComma(likes);
    const genresSplit = (genres: []): string =>
      genres.map((item: { name: string }) => item.name).toString();

    const statusNew = () => {
      const created = new Date(createdAt).getTime();
      const twoWeeksLater = created + 14 * 24 * 60 * 60 * 1000;
      return new Date().getTime() < twoWeeksLater;
    };
    const statusUp = () =>
      !statusNew() &&
      new Date(updatedAt).toDateString() === new Date().toDateString();

    const tagStatus = () => {
      switch (true) {
        case statusNew():
          return { status: 'new', color: 'red' };
        case statusUp():
          return { status: 'up', color: 'red' };
        case completed:
          return { status: '完結', color: 'black' };
        default:
          return null;
      }
    };

    return {
      convertDate,
      genresSplit,
      convertLikesCount,
      tagStatus
    };
  }
});
