







import { defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
  name: 'IconOfferwal',
  setup(props, { emit }) {
    const visible = ref(false);
    const toggle = () => {
      visible.value = !visible.value;
      emit('toggleOfferwall', visible.value);
    };
    return {
      toggle
    };
  }
});
