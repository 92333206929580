















import { ComputedRef, defineComponent, computed } from '@vue/composition-api';
import store from '@/store';
import { NovelContentsTable } from '@/models/viewer-info';

export default defineComponent({
  name: 'ContentsTable',
  props: {
    chapterInfo: Object
  },
  setup(props, { emit }) {
    const { getters } = store;
    const navMap: ComputedRef<NovelContentsTable[]> = computed(
      () => getters['NovelViewerStore/navMap']
    );

    const selectTableChapter = (value) => {
      emit('emitSelectTableChapter', value);
    };

    return {
      navMap,
      selectTableChapter
    };
  }
});
