var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-article02",class:{
    'list-article02--type-tile list-article02--type-tile-large': _vm.isPc
  }},[_c('ul',{staticClass:"list-article02__list _challengeTitleList"},_vm._l((_vm.contents),function(item){return _c('li',{key:item.contentId,staticClass:"list-article02__item _titleItem"},[_c('router-link',{staticClass:"list-article02__item-inner",attrs:{"to":{ path: ("/challenge/comic/" + (item.contentId)) },"title":item.name}},[_c('item-cover',{attrs:{"background":_vm.setImagePath(
              item.squareImageFilePath
                ? item.squareImageFilePath
                : item.coverImageFilePath,
              item.squareImageFileName
                ? item.squareImageFileName
                : item.coverImageFileName
            ),"description":item.description}}),_c('item-info',{attrs:{"itemInfo":item}})],1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }