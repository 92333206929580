import { Chapter, Inventory, RentalConfig } from "@/models";
import store from '@/store/index';

export const DAYS_LATER = 'days_later';
export const HOUR_LATER = 'hour_later';
export const MIN_LATER = 'min_later';
export const READ_NOW = 'gauge_read_now';

export interface RemainedTypes {
	time: string;
	type: 'days_later' | 'hour_later' | 'min_later' | 'gauge_read_now';
};

export const getWaitForFreePercent = (
	rentalConfig: RentalConfig | undefined,
	inventory: Inventory | undefined
): number => {
	if (inventory && inventory.readForFree && rentalConfig) {
		const { amount, recoverRemained } = inventory.readForFree;		// 남은기간
		const { recoverInterval } = rentalConfig;											// max
		if (amount && amount > 0) {
			// recoverRemained 0 상태가 되면 inventory.readForFree.amount = 1 반환
			return 100;
		} else {
			if (recoverInterval && recoverRemained !== undefined) {
				return ((recoverInterval - recoverRemained) / recoverInterval) * 100;
			} else {
				return 0;
			}
		}
	} else {
		return 0;
	}
};

const getRemainedWithLabel = (remained: number | undefined): RemainedTypes => {
	const hour = 60 * 60;
	const day = hour * 24;
	if (remained) {
		if (remained > hour) {
			if (remained > day) {
				return {
					time: Math.floor(remained / 60 / 60 / 24) + '',
					type: DAYS_LATER
				}
			} else {
				return {
					time: Math.floor(remained / 60 / 60) + '',
					type: HOUR_LATER
				}
			}
		} else if (0 !== remained && remained <= hour) {
			return {
				time: Math.floor(remained / 60) + '',
				type: MIN_LATER
			}
		} else {
			return {
				time: `${remained}`,
				type: READ_NOW
			}
		}
	} else {
		return {
			time: `${remained}`,
			type: READ_NOW
		}
	}
};

/**
 * 선택구매 다이얼로그에서 사용할 챕터 목록 추출
 * (대여 포함됨)
 * @param chapter
 * @returns
 */
const getSelectPurchaseChapters = (chapters: Chapter[]) => {
	return chapters.filter((node) => {
		const { salesConfig } = node;
		const isFree = salesConfig.isFree();									// 무료
		const isUnlocked = node.activity?.unlocked;						// unlock체크
		const isPurchased = salesConfig.isPurchaseChapter();	// 구매체크
		return !(isFree || isUnlocked) && isPurchased;
	});
}

/**
 * 구매 가능한 챕터 목록 추출
 * (대여중, 구매완료, free 포함 안됨)
 * @param chapter
 * @returns
 */
const getPurchasableChapters = (chapter: Chapter[]) => {
	return chapter.filter(node => {
		const { salesConfig } = node;
		const isFree = salesConfig.isFree();							// 무료
		const isUnlocked = node.activity?.unlocked;				// unlock체크
		const isRented = node.activity?.rented;						// unlock체크
		const isPurchased = salesConfig.isPurchaseChapter();		// 구매체크

		return !(isFree || isUnlocked || isRented) && isPurchased;
	});
}

const READING_GUIDE_KEY = 'latest_read_direction';

const checkReadingGuide = (direction?: string) => {
	const latestDirection = localStorage.getItem(READING_GUIDE_KEY);
	if (!(direction && direction === latestDirection)) {
		store.dispatch('GlobalStore/showReadingGuide');
	}
};

const saveReadingInfo = (direction: string) =>
	localStorage.setItem(READING_GUIDE_KEY, direction);

// 단행본 뷰어 가로모드 헤더, 푸터 show/hide
const magazineUiControl = (uiHead: HTMLElement | null, uiFoot: HTMLElement | null) => {
	if (!uiHead || !uiFoot) return;
	const _isHide = uiHead?.getAttribute('data-ishide');
	if (_isHide === 'true') {
		uiHead.setAttribute('data-ishide', 'false');
		uiHead.style.display = 'block';
		uiFoot.style.display = 'block';
	} else {
		uiHead.setAttribute('data-ishide', 'true');
		uiHead.style.display = 'none';
		uiFoot.style.display = 'none';
	}
};

const getContentParserNumber = (elm: Element | null) => {
	if (!elm) return 0;
	return Number(elm.textContent?.toString().replace(',', '')) || 0;
};

export const ChapterHelperService = {
	getWaitForFreePercent,
	getRemainedWithLabel,
	getSelectPurchaseChapters,
	checkReadingGuide,
	saveReadingInfo,
	getPurchasableChapters,
	magazineUiControl,
	getContentParserNumber
};