



























































import { ref, defineComponent } from '@vue/composition-api';
import { TEXT_CONFIG } from '@/commons/constants/text-types';

export default defineComponent({
  name: 'TextSetting',
  props: {
    isPageMode: Boolean,
    settingInfo: Object
  },
  setup(props, { emit }) {
    const txtConf = ref(TEXT_CONFIG);

    const clickSettingHandler = (type: string, index: number) => {
      if (!props.settingInfo) return;
      switch (type) {
        case 'font':
          props.settingInfo.fontType = index;
          break;
        case 'size':
          props.settingInfo.fontSize = index;
          break;
        case 'line':
          props.settingInfo.lineHeight = index;
          break;
        case 'color':
          props.settingInfo.color = index;
          break;
      }
      localStorage.setItem(
        'novel_viewer_setting',
        JSON.stringify(props.settingInfo)
      );
      emit('emitViewerSetting', { type: type, index: index });
    };

    return {
      txtConf,
      clickSettingHandler
    };
  }
});
