






import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  Ref,
  ref
} from '@vue/composition-api';
import { Badge } from '@/models';
import { ContentsHelperService } from '@/services/contents/contents.helper';

export default defineComponent({
  name: 'Badge',
  props: {
    badge: Object,
    status: String
  },
  setup(props, { root }) {
    const clsName: Ref<string> = ref('');
    const label: Ref<string> = ref('');
    const badgeInfo: ComputedRef<Badge> = computed(() => props.badge as Badge);
    const statusInfo: ComputedRef<string | undefined> = computed(
      () => props.status
    );
    // none
    // started    : new  & NEW & emph_new
    // updated    : up   & UP & emph_up
    // completed  : fin  & 완결 & emph_end
    // suspend    : 휴재

    const initBadge = (data: { type: string | undefined; label?: string }) => {
      if (!(data && data.type)) return;
      switch (data.type) {
        case 'event':
          clsName.value = 'emph_event';
          label.value = data.label ? data.label : '';
          break;
      }
    };

    onMounted(() => {
      const result = ContentsHelperService.getBadgeStatus(
        badgeInfo.value,
        statusInfo.value || ''
      );
      initBadge(result);
    });

    return {
      clsName,
      label
    };
  }
});
