
import { createAxiosService } from '@/services/common/create-axios.service';

const axiosService = createAxiosService();

const postInquiry = async ({ inquiryData }) => {
  const url = `/help/inquiry/${inquiryData.category}`;
  const config = {
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  };
  const {
    data: { result }
  } = await axiosService.post(url, inquiryData.form, config).then(res => res);
  return result;
};
export const inquiryApi = {
  postInquiry
};
