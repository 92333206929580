

































import { ref, defineComponent } from '@vue/composition-api';
import { i18nTxt } from '@/commons/i18n';
import IconTypeAnchor from '@/components/atoms/icons/IconTypeAnchor.vue';
import Store from '@/store';
export default defineComponent({
  name: 'PointParkResultDialog',
  components: {
    IconTypeAnchor
  },
  props: {
    result: Object
  },

  setup(props, { emit }) {
    const dialog = ref(false);
    const { commit } = Store;
    const show = () => {
      dialog.value = true;
    };

    const hide = () => {
      dialog.value = false;
    };

    const pointPipe = (value: string) =>
      value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    const onDialogHide = () => {
      emit('hide');
      commit('OfferwallStore/resetPointPark');
    };

    return {
      i18nTxt,
      dialog,
      show,
      hide,
      onDialogHide,
      pointPipe
    };
  }
});
