import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { ChallengeSearchPage } from '@/models/challenge/challenge-search';
import { ChallengeContent } from '@/models/challenge/challenge-content';
import { ChallengeSearchService } from '@/services/challenge/challenge-search.api';

export interface ChallengeSearchTypes {
  page: ChallengeSearchPage | undefined;
  contents: ChallengeContent[];
  keyword: string | null;
}

export const ChallengeSearchStore: Module<ChallengeSearchTypes, RootState> = {
  namespaced: true,
  state: {
    page: undefined,
    contents: [],
    keyword: null
  },
  getters: {
    page: (state) => state.page,
    contents: (state) => state.contents,
    keyword: (state) => state.keyword
  },
  mutations: {
    search(state: ChallengeSearchTypes, value: ChallengeSearchTypes) {
      state.page = value.page;
      state.contents = value.contents;
    },
    keyword(state: ChallengeSearchTypes, keyword: string) {
      state.keyword = keyword;
    }
  },
  actions: {
    async initSearch({ commit }, keyword) {
      try {
        const result = await ChallengeSearchService.getChallengeSearch(keyword);
        commit('search', result);
        commit('keyword', keyword);
      } catch {
        //
      }
    }
  }
};
