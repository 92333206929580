














import { ComputedRef, computed, defineComponent, ref, watch } from '@vue/composition-api';
import Store from '@/store/index';
import { t, i18nWrapRuby } from '@/commons/i18n/index';
import TagContentCard from '@/components/molecules/contents-card/TagContentCard.vue';
import { CONTENT_CARD_TYPE } from '@/commons/constants/content.types';

export default defineComponent({
  name: 'ContentListModuleTag',
  components: {
    TagContentCard
  },
  props: {
    contentList: Array,
    moduleType: String,
    del: Boolean
  },
  setup(props) {
    const { getters } = Store;
    const userKeyword: ComputedRef<string> = computed(() => getters['SearchStore/getKeyword']);
    const cl = computed(() => props.contentList);
    const mType = computed(() => props.moduleType);
    const list = ref(cl.value);
    watch(cl, (data) => {
      list.value = data;
    });
    return {
      t,
      i18nWrapRuby,
      userKeyword,
      list,
      mType,
      CONTENT_CARD_TYPE
    };
  }
});
