import { Module } from 'vuex';
import { RootState } from '@/store/index';

export interface ErrorStateInterface {
  code: number;
  title: string | null;
  message: string | null;
  endAt: string;
  nextPage: boolean;
  response: {
    headers: Object;
    url: string | undefined;
  } | null;
}

const getDefaultState = (): ErrorStateInterface => {
  return {
    code: 0,
    title: null,
    message: null,
    endAt: '',
    nextPage: false,
    response: null
  };
};

/**
 * ErrorStore
 * state 및 other state 타입추론
 */
export const ErrorStore: Module<ErrorStateInterface, RootState> = {
  namespaced: true,
  state: getDefaultState,
  getters: {
    errorState: (state: ErrorStateInterface) => state,
    errorCode: (state: ErrorStateInterface) => state.code,
    isErrorPop: (state: ErrorStateInterface) => state.code !== 0 ? true : false,
    nextPage: (state: ErrorStateInterface) => state.nextPage
  },
  mutations: {
    resetState: state => Object.assign(state, getDefaultState()),
    postError: (state, errorObj) => Object.assign(state, errorObj),
    nextPage: (state, value: boolean) => state.nextPage = value
  },
  actions: {
    RESET_STATE({ commit }) {
      commit('resetState');
    },
    SET_ERROR({ commit }, errorObj) {
      commit('postError', errorObj);
    }
  }
};
