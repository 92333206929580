import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { Language } from '@/commons/constants/enum';
import { saveCookie } from '@/helpers/cookies';
import { LANGUAGE_KEY } from '@/commons/constants/config-language';

export interface I18nStateInterface {
  language: Language;
}

/**
 * I18nStore
 */
export const I18nStore: Module<I18nStateInterface, RootState> = {
  namespaced: true,
  state: {
    language: '' as Language
  },
  getters: {
    getCookieLanguage: (state) => state.language
  },
  mutations: {
    language(state, language: Language) {
      state.language = language;
    }
  },
  actions: {
    CHANGE_LANGUAGE({ commit }, language) {
      saveCookie(LANGUAGE_KEY, language, { expires: 365 });
      commit('language', language);
    }
  }
};
