

































































































































































































































































































































import {
  defineComponent,
  computed,
  watch,
  ref,
  onMounted,
  onUnmounted
} from '@vue/composition-api';
import { createAxiosService } from '@/services/common/create-axios.service';
import { AxiosResponse } from 'axios';
import router from '@/router';
import { HttpResponse } from '@/commons/constants/http.types';
import Store from '@/store';
import MainTab from '@/components/molecules/challenge/header/MainTab.vue';
import TabMenuMobile from '@/components/molecules/challenge/bookshelf/TabMenuMobile.vue';

export default defineComponent({
  name: 'OldGlobalJPHeader',
  data() {
    return {
      openSearchLayer: false,
      openGlobalMenu: false,
      avatarImageUrl:
        'https://resources.comico.io/deprecated/r02/common/bg_user_thumb_58x58.png',
      nickname: ''
    };
  },
  components: {
    MainTab,
    TabMenuMobile
  },
  methods: {
    getLoginPath: function () {
      return '/login/?redirect=' + encodeURIComponent(location.pathname);
    },
    search: function (event, name) {
      let _searchForm = <HTMLInputElement>this.$refs[name];
      if (_searchForm.value === '') return;
      router
        .push({ path: '/challenge/search/' + _searchForm.value })
        .then(() => {
          _searchForm.value = '';
          this.openSearchLayer = false;
        });
    },
    clickedGlobalHeaderAvatar: function (event) {
      event.preventDefault();
    },
    clickedGlobalHeader: function (event) {
      let _width = window.outerWidth;
      if (_width < 1080) {
        this.openGlobalMenu = true;
      }
    }
  },
  props: ['title', 'path'],
  setup(props, { root }) {
    const { getters } = Store;
    const isAuth = computed(() => getters['AuthStore/isAuth']);
    const meta = ref(root.$route.meta);
    const limitPosition = 72;
    const scrolled = ref(false);
    const lastPosition = ref(0);
    const isMobile = computed(() => getters['UIStore/windowWidth'] < 1080);
    const noneFixed = ref(false);
    const handleScroll = () => {
      if (
        lastPosition.value < window.scrollY &&
        limitPosition < window.scrollY
      ) {
        scrolled.value = true;
      }

      if (lastPosition.value > window.scrollY) {
        scrolled.value = false;
      }

      lastPosition.value = window.scrollY;
    };
    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });
    return { isAuth, meta, handleScroll, scrolled, isMobile, noneFixed };
  },

  mounted() {
    window.addEventListener('resize', () => {
      let _width = window.outerWidth;
      if (_width >= 1080) {
        this.openGlobalMenu = false;
      }
    });

    if (this.isAuth) {
      const _url = '/member/profile';
      const _axiosService = createAxiosService();
      _axiosService.get(_url).then((res: AxiosResponse) => {
        const result: HttpResponse = res.data;
        if (result.result.code === 200) {
          let _profile = result.data.profile;
          if (
            typeof _profile.avatarImageUrl !== 'undefined' &&
            _profile.avatarImageUrl !== null &&
            _profile.avatarImageUrl !== ''
          ) {
            this.avatarImageUrl = _profile.avatarImageUrl;
          }
          this.nickname = _profile.nickname;
        }
      });
    }
  }
});
