
















import { computed, defineComponent } from '@vue/composition-api';
import router from '@/router/index';
import Store from '@/store';
import IconTypeSpan from '@/components/atoms/icons/IconTypeSpan.vue';
import {
  IconType,
  ICON_SEARCH,
  ICON_LIBRARY,
  ICON_INBOX,
  ICON_ACCOUNT,
  TYPE_ICONS,
  ICON_SIDEMENU
} from '@/commons/constants/icon-types';
import { routerNextAborted, isSameUrl } from '@/services/common/utils';
import { MenuHelperService } from '@/services/menu/menu.helpers';
import { UserMenuComponent } from '@/components/organisms';
import { sendNclick } from '@/services';
export default defineComponent({
  name: 'IconParts',
  components: {
    IconTypeSpan,
    UserMenuComponent
  },
  setup(props, { emit }) {
    const { commit, getters } = Store;
    const errorHandler = (error: { name: string; message: string }) => {
      if (!isSameUrl(error.name)) {
        if (routerNextAborted(error.message)) {
          return;
        }
      }
    };
    const localUserMenu = (cate: string, init: string) => {
      const userMenu: {
        name: string;
        param: string;
      } | null = MenuHelperService.getUserMenu(cate);
      const depth = userMenu ? userMenu.param : init;
      return depth;
    };
    const gotoSearchPage = () => emit('onSerachFocus');
    const gotoInboxPage = () => {
      const param = localUserMenu('inbox', 'gift');
      sendNclick('globalmenu', 'global.menu', '', '', 'inbox');
      router.push(`/inbox/${param}`).catch((error) => errorHandler(error));
    };
    const gotoLibraryPage = () => {
      const param = localUserMenu('library', 'subscribed');
      sendNclick('globalmenu', 'global.menu', '', '', 'library');
      router.push(`/library/${param}`).catch((error) => errorHandler(error));
    };
    const toggleUserMenu = () => commit('GlobalStore/setUserMenuState');
    const icons = [
      TYPE_ICONS.ICON_SEARCH,
      TYPE_ICONS.ICON_INBOX,
      TYPE_ICONS.ICON_LIBRARY,
      TYPE_ICONS.ICON_SIDEMENU,
      TYPE_ICONS.ICON_ACCOUNT
    ];

    const onClick = (iconType: IconType) => {
      event?.preventDefault();
      switch (iconType.type) {
        case ICON_SEARCH.type:
          gotoSearchPage();
          break;
        case ICON_LIBRARY.type:
          gotoLibraryPage();
          break;
        case ICON_INBOX.type:
          gotoInboxPage();
          break;
        case ICON_ACCOUNT.type:
          sendNclick('globalmenu', 'global.menu', '', '', 'account');
          toggleUserMenu();
          break;
        case ICON_SIDEMENU.type:
          sendNclick('globalmenu', 'global.menu', '', '', 'account');
          toggleUserMenu();
          break;
        default:
          break;
      }
    };

    const userMenuState = computed(
      () => getters['GlobalStore/getUserMenuState']
    );
    return {
      icons,
      onClick,
      userMenuState
    };
  }
});
