





import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'IconTypeAnchor',
  props: {
    clsName: String,
    label: String
  },
  setup(props, { emit }) {
    const cls = computed(() => (props.clsName ? props.clsName : ''));
    const onIconClick = () => emit('onIconClick', {});
    return {
      cls,
      onIconClick
    };
  }
});
