import { createAxiosService } from './create-axios.service';
import { HttpResponseInterface } from '@/models';
const axiosService = createAxiosService();

/** Configration */
const config = (param): Promise<HttpResponseInterface> => {
  return new Promise<HttpResponseInterface>(resolve => {
    const url = `/app/config/${param}`;
    return axiosService.get(url).then(res => {
      const { data } = res;
      resolve(data);
    });
  });
};

export const AppService = {
  config
};
