

































import {
  computed,
  ComputedRef,
  defineComponent,
  ref,
  watch
} from '@vue/composition-api';
import RankingThumbnail from '@/components/molecules/contents-elements/RankingThumbnail.vue';
import Store from '@/store/index';
import SectionNavigation from '@/components/molecules/navigators/SectionNavigator.vue';
import IconTypeAnchor from '@/components/atoms/icons/IconTypeAnchor.vue';
import {
  gotoContentHome,
  gotoChapter,
  gotoLinkPage
} from '@/services/routing/routing.helper';
import { IconType, ICON_VIEWMORE } from '@/commons/constants/icon-types';
import { Element, Section } from '@/models';
import {
  separateUrl,
  getContentChapterPath
} from '@/services/contents/contents.helper';
import { sendNclick } from '@/services';
import { nextBtnState } from '@/services/home/section.helper';

export default defineComponent({
  name: 'RankingSection',
  components: {
    RankingThumbnail,
    SectionNavigation,
    IconTypeAnchor
  },
  props: {
    section: Section,
    maxLen: Number
  },
  setup(props) {
    const { getters } = Store;
    const sectionInfo: ComputedRef<Section> = computed(
      () => props.section as Section
    );
    const maxLen: ComputedRef<number | undefined> = computed(
      () => props.maxLen
    );
    const elements: ComputedRef<Element[]> = computed(() => {
      const sliceSections = maxLen.value
        ? sectionInfo.value.elements.slice(0, maxLen.value)
        : sectionInfo.value.elements;
      return sectionInfo.value && sectionInfo.value.elements
        ? sliceSections
        : [];
    });
    const isWide: ComputedRef<boolean> = computed(
      () => getters['UIStore/isWide']
    );
    const MAX_SLIDE_LEN = 5;
    const olRef: any = ref(null);

    const liWidth = 149;
    let currentIndex = 1;
    let currentX = 0;
    let value = Math.floor(elements.value.length / MAX_SLIDE_LEN);
    let namoji = Math.floor(elements.value.length % MAX_SLIDE_LEN);
    const prev = ref(false);
    const next = ref(nextBtnState(value, namoji));

    const sendContents = (element: Element) => {
      if (!element.urlHttp) return;
      const str = separateUrl(element.urlHttp);
      const result = getContentChapterPath(str);
      if (!result) return;
      if (result.chapterId) {
        sendNclickContent(result.chapterId);
        gotoChapter(result);
      } else {
        sendNclickContent(element.id);
        gotoContentHome(result);
      }
    };

    const sendNclickContent = (contentId: number) => {
      sendNclick(
        'home',
        'home.card',
        '' + contentId,
        '',
        sectionInfo.value.elementUiType +
          '&sectionid=' +
          sectionInfo.value.id +
          '&id=' +
          contentId
      );
    };

    const resetIndex = () => {
      try {
        currentIndex = 1;
        currentX = 0;
        value = Math.floor(elements.value.length / MAX_SLIDE_LEN);
        namoji = Math.floor(elements.value.length % MAX_SLIDE_LEN);
        prev.value = false;
        next.value = nextBtnState(value, namoji);
        olRef.value.style.transform = `translateX(0px)`;
      } catch (e) {
        //
      }
    };

    const sendDirection = ({ type }: IconType) => {
      if (type.toLowerCase() === 'prev') {
        gotoPrev();
      } else {
        gotoNext();
      }
    };

    const gotoPrev = () => {
      if (currentIndex > 1) {
        currentX = currentX - MAX_SLIDE_LEN * liWidth;
        olRef.value.style.transform = `translateX(-${currentX}px)`;
        currentIndex = currentIndex - 1;
        next.value = true;
        prev.value = currentX !== 0;
      } else {
        if (namoji > 0) {
          currentX = currentX - namoji * liWidth;
          olRef.value.style.transform = `translateX(-${currentX}px)`;
          next.value = true;
        }
        prev.value = false;
      }
    };

    const gotoNext = () => {
      if (currentIndex === value) {
        if (namoji > 0) {
          currentX = currentX + namoji * liWidth;
          olRef.value.style.transform = `translateX(-${currentX}px)`;
          prev.value = true;
        }
        next.value = false;
      } else {
        currentIndex = currentIndex + 1;
        currentX = currentX + MAX_SLIDE_LEN * liWidth;
        olRef.value.style.transform = `translateX(-${currentX}px)`;
        prev.value = true;
        next.value = !(namoji === 0 && currentIndex === value);
      }
    };

    const sendViewMore = () => {
      if (!sectionInfo.value.urlHttp) return;
      sendNclick(
        'home',
        'home.more',
        '',
        '',
        sectionInfo.value.elementUiType + '&sectionid=' + sectionInfo.value.id
      );
      const str = separateUrl(sectionInfo.value.urlHttp);
      gotoLinkPage(`/${str.join('/')}`);
    };

    watch(elements, () => {
      resetIndex();
    });

    return {
      sectionInfo,
      elements,
      isWide,
      MAX_SLIDE_LEN,
      ICON_VIEWMORE,
      sendContents,
      sendDirection,
      sendViewMore,
      olRef,
      prev,
      next
    };
  }
});
