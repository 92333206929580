import { commentApi, likeComment } from '@/services/comment/comment.api';
import { HistoryComment } from '@/models/comment-info';
import { Module } from 'vuex';
import { RootState } from '@/store/index';

interface HistoryCommentInterface {
  comments: HistoryComment[];
  hasNext: boolean;
}

export const CommentStore: Module<HistoryCommentInterface, RootState> = {
  namespaced: true,
  state: {
    comments: [] as HistoryComment[],
    hasNext: false
  },

  getters: {
    getComments: (state) => state.comments,
    getHasNext: (state) => state.hasNext
  },

  mutations: {
    setComments: (state, payload) =>
      (state.comments = [...state.comments, ...payload]),
    setHasNext: (state, payload) => (state.hasNext = payload),
    likeComment: (state, payload) => {
      state.comments[payload].totalLikes =
        state.comments[payload].totalLikes + 1;
      state.comments[payload].reaction.liked = true;
    },
    removeComment: (state, payload) => {
      state.comments = state.comments.filter((item) => item.id !== payload);
    },

    resetComments: (state) => (state.comments = [])
  },

  actions: {
    getComments: ({ commit }, epochSecond?: number) => {
      commentApi.getCommentHistory(epochSecond).then((res) => {
        commit('setComments', res.comments);
        commit('setHasNext', res.page.hasNext);
      });
    },
    deleteComment: async ({ state, commit }, params) => {
      const res = await commentApi.deleteComment(params).then((res) => {
        commit('removeComment', params.commentId);
      });
      return res;
    },
    likeComment: async ({ state, commit, dispatch }, params) => {
      await commentApi.likeComment(params).then((res) => {
        state.comments.filter((item, idx) => {
          if (item.id === params.commentId && res.code === 200) {
            commit('likeComment', idx);
          }
        });
      });
    }
  }
};
