






import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  Ref,
  ref
} from '@vue/composition-api';
import { Badge } from '@/models';
import { ContentsHelperService } from '@/services/contents/contents.helper';

export default defineComponent({
  name: 'BadgeHiatus',
  props: {
    badge: Object,
    status: String
  },
  setup(props, { root }) {
    const label: Ref<string> = ref('');
    const badgeInfo: ComputedRef<Badge> = computed(() => props.badge as Badge);
    const statusInfo: ComputedRef<string | undefined> = computed(
      () => props.status
    );
    // none
    // started    : new  & NEW & emph_new
    // updated    : up   & UP & emph_up
    // completed  : fin  & 완결 & emph_end
    // suspend    : 휴재

    const initBadge = (data: { type: string | undefined; label?: string }) => {
      if (!(data && data.type === 'suspend')) return;
      // 요일 페이지에서만..
      label.value = root.$t('hiatus') + '';
    };

    onMounted(() => {
      const result = ContentsHelperService.getBadgeStatus(
        badgeInfo.value,
        statusInfo.value || ''
      );
      initBadge(result);
    });

    return {
      label
    };
  }
});
