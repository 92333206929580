








































import { ref, defineComponent } from '@vue/composition-api';
import router from '@/router';
import { BOOKSHELF_PROPERTIES } from '@/commons/constants/challenge/challenge-properties';

export default defineComponent({
  name: 'TabMenuMobile',
  setup(props, { root }) {
    const router = ref(root.$router);

    return { BOOKSHELF_PROPERTIES, router };
  }
});
