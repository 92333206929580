/**
 * 언어팩
 * 참고: import { Language } from '@/commons/constants/enum';
 */
export const LANGUAGE_KEY = 'global_language';
export const LANGUAGE_PARAM_KEY = 'languageCode';
export const KO_KR = 'ko-KR';
export const JA_JP = 'ja-JP';
export const EN_US = 'en-US';
export const ZH_TW = 'zh-TW';
export const VI_VN = 'vi-VN';
export const FR_FR = 'fr-FR';
export const DE_DE = 'de-DE';
export const LANGUAGE_EXPIRES = 7;

export const LANGUAGE_TYPES = {
  KO_KR,
  JA_JP,
  EN_US,
  ZH_TW,
  VI_VN,
  FR_FR,
  DE_DE
};

export const acceptShortList: string[] = [
  'ko',
  'ja',
  'en',
  'zh',
  'vi',
  'fr',
  'de'
];

export const acceptConvertList: string[] = [
  'ko-kr',
  'ja-jp',
  'en-us',
  'zh-tw',
  'zh-vn',
  'fr-fr',
  'de-de',
  KO_KR,
  JA_JP,
  EN_US,
  ZH_TW,
  VI_VN,
  FR_FR,
  DE_DE
];

export const languageLongMap: { [key: string]: string } = {
  ko: KO_KR,
  ja: JA_JP,
  en: EN_US,
  zh: ZH_TW,
  vi: VI_VN,
  fr: FR_FR,
  de: DE_DE
};

export const languageConvertMap: { [key: string]: string } = {
  'ko-kr': KO_KR,
  'ko-KR': KO_KR,
  'ja-jp': JA_JP,
  'ja-JP': JA_JP,
  'en-us': EN_US,
  'en-US': EN_US,
  'zh-tw': ZH_TW,
  'zh-TW': ZH_TW,
  'vi-vn': VI_VN,
  'vi-VN': VI_VN,
  'fr-fr': FR_FR,
  'fr-FR': FR_FR,
  'de-de': DE_DE,
  'de-DE': DE_DE
};
