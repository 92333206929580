







import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'IconThumbnail',
  props: {
    text: String,
    clsName: String
  }
});
