
















import {
  Ref,
  ref,
  ComputedRef,
  computed,
  defineComponent,
  watch,
  onMounted,
  onUnmounted
} from '@vue/composition-api';
import { ComicoBi } from '@/components/atoms';
import {
  PageTitle,
  IconParts,
  ContentCategoryParts
} from '@/components/molecules';
import { UserMenuComponent } from '@/components/organisms';
import Store from '@/store';
import { isPC, ClassesHeader, inputFocusIn } from '@/services';

export default defineComponent({
  name: 'HomeHeader',
  props: {
    pageTitle: String,
    mobileTitle: {
      type: Boolean,
      default: false
    },
    hideIcons: Boolean,
    classNameDefault: String,
    classNameFixed: String
  },
  components: {
    ComicoBi,
    PageTitle,
    IconParts,
    UserMenuComponent,
    ContentCategoryParts
  },
  setup(props) {
    const { commit } = Store;
    const isPc = computed(() => isPC());
    const invisibleHeader: Ref<boolean> = ref(true);
    const classNameDefault: Ref<string> = ref(props.classNameDefault || '');
    const classNameFixed: Ref<string> = ref(props.classNameFixed || '');
    const onSearchFocus = () => {
      ClassesHeader('header_search');
      const searchInput: HTMLElement | null = document.getElementById(
        'tfSearch'
      );
      inputFocusIn(searchInput);
    };
    const onHome = () => commit('GlobalStore/setIsSearchPopup', false);

    const handleScroll = (evt) => {
      // invisibleHeader.value = window.scrollY === 0;
      const _header: HTMLDivElement | null = document.querySelector('.header');
      const _sideMenu: HTMLDivElement | null = document.querySelector(
        '.side_menu'
      );
      const _subMenu: HTMLDivElement | null = document.querySelector(
        '.sub_menu'
      );
      if (_sideMenu) {
        if (_subMenu && window.scrollY > _subMenu.offsetHeight) {
          _sideMenu.style.top = _header?.offsetHeight + 'px';
        } else {
          // _sideMenu.style.top =
          //   Number(_header?.offsetHeight) +
          //   Number(_subMenu?.offsetHeight) -
          //   window.scrollY +
          //   'px';
          _sideMenu.style.top =
            Number(_header?.offsetHeight) +
            Number(_subMenu?.offsetHeight) +
            'px';
        }
      }
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
      if (invisibleHeader.value) {
        ClassesHeader(classNameDefault.value);
      }
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    });

    // watch(invisibleHeader, (res) => {
    //   res
    //     ? ClassesHeader(classNameDefault.value, classNameFixed.value)
    //     : ClassesHeader(classNameFixed.value, classNameDefault.value);
    // });

    return {
      isPc,
      onSearchFocus,
      onHome
    };
  }
});
