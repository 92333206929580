
























import { computed, defineComponent, ref } from '@vue/composition-api';
import Store from '@/store';
export default defineComponent({
  props: {
    title: String
  },
  setup(_props, { emit }) {
    const { getters } = Store;
    // const title = computed(() => getters['ChallengeHomeStore/sortTitle']);

    const SORT = [
      {
        type: 'publishedAt',
        name: '新着順',
        code: 'recent'
      },
      {
        type: 'updatedAt',
        name: '更新順',
        code: 'updated'
      }
    ];

    const type = ref('publishedAt');
    const changeSort = (sortType: string, sortCode: string) => {
      type.value = sortType;
      emit('changeSort', sortCode);
    };

    return { SORT, type, changeSort };
  }
});
