export const TYPE_SALES = {
	FREE: 'free', 							// 무료
	READ_NOW: 'gauge_read_now',				// 지금읽기
	WAITFREE: 'available_soon',	// 기다리면 무료
	PURCHASED: 'unlocked',			// 구입완료
	REMAINED: 'remained',				// 대여기간 nn남음
	PRICE: 'coin_price',							// n코인
	TARGET_FREE: 'target_free',	// 당신에게만 무료
	ONLY_RENTAL: 'only_rental'	// only ㄷㅐ여
};
export interface PurchaseButtons {
	type: string;
	format?: string;
};
const RENT_COIN: PurchaseButtons = {		// 코인으로 대여
	type: 'rentCoin',
	format: 'price'
};
const BUY_COIN: PurchaseButtons = {			// 코인으로 구입
	type: 'buyCoin',
	format: 'price'
};
const WAITFORFREE: PurchaseButtons = {	// 기다리면 무료
	type: 'read_for_free'
};
const PREVIEW: PurchaseButtons = {			// 미리보기
	type: 'preview'
};
const RENT_TICKET: PurchaseButtons = {	// 티켓으로 대여
	type: 'read_with_ticket'
};
const READ_NOW: PurchaseButtons = {			// 지금읽기
	type: 'gauge_read_now'
};
const FREE: PurchaseButtons = {
	type: 'free'
};
const WAITFORFREE_REMAINED: PurchaseButtons = {
	type: 'waitfree_remined',
	format: 'days'
};
const RENT_SUBSCRIBE: PurchaseButtons = {
	type: 'rent_with_subscribe'
};

export const TYPE_PURCHASE_BUTTON = {
	RENT_COIN,
	BUY_COIN,
	WAITFORFREE,
	PREVIEW,
	RENT_TICKET,
	READ_NOW,
	FREE,
	WAITFORFREE_REMAINED,
	RENT_SUBSCRIBE
};

export const TYPE_SALES_STATE = {
	GOTO_VIEWER: 'GOTO_VIEWER',
	GOTO_LOGIN: 'GOTO_LOGIN',
	DIALOG: 'DIALOG'
};

const GAUGE = 'gauge';
const TICKET = 'ticket';
const COIN = 'coin';
const FREE_INCREASED = 'freeIncreased';

/**
 * 대여 상태
 */
export const SALES_CONFIG_RENTALLOWED = {
	GAUGE,	// 게이지 대여
	TICKET,	// 티켓 대여
	COIN		// 코인 대어
};
/**
 * 구매 상태
 */
export const SALES_CONFIG_PURCHASEALLOWED = {
	COIN	// 코인구매
};
/**
 * 이벤트
 */
export const SALES_CONFIG_EVENT = {
	FREE_INCREASED	// 무료화 증량
}