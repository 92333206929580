








import { defineComponent } from '@vue/composition-api';
import { convertDate } from '@/services/common/utils';
export default defineComponent({
  props: {
    messageInfo: Object
  },
  setup() {
    return { convertDate };
  }
});
