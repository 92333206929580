














import { Banner } from '@/models';
import { gotoLinkPage, getForwardPath } from '@/services';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ViewerBanner',
  props: {
    banners: Array
  },
  setup(props, { emit }) {
    const firstBanner = computed(() =>
      props.banners && props.banners.length > 0
        ? (props.banners[0] as Banner)
        : null
    );
    const onLinkClick = () => {
      if (!firstBanner.value) return;
      if (!firstBanner.value.webLinkUrl) return;
      const { internal, path } = getForwardPath(firstBanner.value.webLinkUrl);
      if (internal) {
        gotoLinkPage(path);
      } else {
        window.location.href = path;
      }
      emit('emitSendBannerNclick', firstBanner.value);
    };
    return {
      firstBanner,
      onLinkClick
    };
  }
});
