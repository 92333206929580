







import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'IconGotoTop',
  setup() {
    const onClick = () => {
      event?.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
    return {
      onClick
    };
  }
});
