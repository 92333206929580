













import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'LikeComment',
  props: {
    comment: Object
  },
  setup(props, { emit }) {
    const onLikeClick = () => emit('emitLike', {});
    return {
      onLikeClick
    };
  }
});
