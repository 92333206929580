import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { ChallengeApiService } from '@/services/challenge/challenge-content.api';
import {
  ChallengeBanner,
  ChallengeContents,
  ChallengePickup,
  ChallengeRanking
} from '@/models/challenge/challenge-home';

interface ChallengeHomeIterface {
  banners: ChallengeBanner[];
  contents: ChallengeContents[];
  pickups: ChallengePickup[];
  ranking: ChallengeRanking[];
  genres: ChallengeContents[];
  hasNext: boolean;
  currentGenreId: number | null;
  currentRankingType: string | null;
}

export const ChallengeHomeStore: Module<ChallengeHomeIterface, RootState> = {
  namespaced: true,
  state: {
    banners: [],
    contents: [],
    pickups: [],
    ranking: [],
    genres: [],
    hasNext: true,
    currentGenreId: null,
    currentRankingType: null
  },
  getters: {
    challengeBanners: (state) => state.banners,
    challengeContents: (state) => state.contents,
    challengePickups: (state) => state.pickups,
    challengeGenres: (state) => state.genres,
    challengeRanking: (state) => state.ranking,
    hasNext: (state) => state.hasNext
  },
  mutations: {
    setChallengeBanners: (state, payload) => (state.banners = payload),

    setChallengeContents: (state, payload) => {
      state.contents = [...state.contents, ...payload];
    },
    setChallengePickups: (state, payload) => (state.pickups = payload),

    setChallengeGenres: (state, payload) => {
      state.genres = [...state.genres, ...payload];
    },

    setChallengeRanking: (state, payload) => {
      state.ranking = [...state.ranking, ...payload];
    },

    setChallengeHasNext: (state, payload) => (state.hasNext = payload),

    resetList: (state, listName) => (state[listName] = []),

    setCurrenGenreId: (state, payload) => {
      state.currentGenreId = payload;
    },
    setCurrenRankingType: (state, payload) => {
      state.currentRankingType = payload;
    },
    sortList: (state, { listName, payload }) => {
      state[listName] = payload;
    }
  },
  actions: {
    getChallengeHome: async ({ commit }) => {
      const result = await ChallengeApiService.getChallengeHome();
      commit('setChallengeBanners', result?.banners);
      commit('setChallengePickups', result?.pickups);
    },

    getChallengeGenre: async (
      { commit, state },
      { genreId, pageNo, orderType }
    ) => {
      const currentGenreId = state.currentGenreId;
      const completed = +genreId === 0 ? 'Y' : 'N';
      const result = await ChallengeApiService.getChallengeGenres(
        genreId,
        pageNo,
        orderType,
        completed
      );
      switch (true) {
        case result.length < 1 && currentGenreId === genreId:
          commit('setChallengeHasNext', false);
          return;
        case currentGenreId && currentGenreId !== genreId:
          commit('resetList', 'genres');
          commit('setChallengeGenres', result);
          commit('setCurrenGenreId', genreId);
          commit('setChallengeHasNext', true);
          return;
        default:
          commit('setCurrenGenreId', genreId);
          commit('setChallengeGenres', result);
      }
    },

    getChallengeRanking: async ({ commit, state }, { rankingType, pageNo }) => {
      const result = await ChallengeApiService.getChallengeRanking(
        rankingType,
        pageNo
      );
      const currentRankingType = state.currentRankingType;
      switch (true) {
        case result.length < 1 && currentRankingType === rankingType:
          commit('setChallengeHasNext', false);
          return;
        case currentRankingType && currentRankingType !== rankingType:
          commit('resetList', 'ranking');
          commit('setChallengeRanking', result);
          commit('setCurrenRankingType', rankingType);
          commit('setChallengeHasNext', true);
          return;
        default:
          commit('setCurrenRankingType', rankingType);
          commit('setChallengeRanking', result);
      }
    },

    getChallengeContents: async ({ commit }, { pageNo, orderType }) => {
      const result = await ChallengeApiService.getChallengeContents(
        pageNo,
        orderType
      );
      result.length < 1
        ? commit('setChallengeHasNext', false)
        : commit('setChallengeContents', result);
    }
  }
};
