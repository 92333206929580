








import { defineComponent } from '@vue/composition-api';
import { getForwardPath } from '@/services/contents/contents.helper';

export default defineComponent({
  name: 'EventBannerList',
  props: {
    element: Object
  },
  setup(props, { emit }) {
    const onClick = () => emit('emitBanner', props.element);
    return {
      onClick,
      getForwardPath
    };
  }
});
