<template>
  <div class="viewerloader">
    <ul>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LoadingAnimation'
};
</script>

<style lang="scss" scoped>
@function vw($size, $viewport: 720) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@keyframes bound {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -14px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.viewerloader {
  position: relative;
  margin-inline: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  width: 100%;
  height: 720px;

  ul {
    list-style: none;
    width: 20vmin;
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      width: 4vmin;
      height: 4vmin;
      border-radius: 50%;
      background-color: #f40000;
      &:nth-child(1) {
        opacity: 0.2;
        animation: bound 1s ease-in-out 0s infinite alternate;
      }
      &:nth-child(2) {
        opacity: 0.6;
        animation: bound 1s ease-in-out 0.2s infinite alternate;
      }
      &:nth-child(3) {
        opacity: 1;
        animation: bound 1s ease-in-out 0.4s infinite alternate;
      }
    }
  }
}
</style>
