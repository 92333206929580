import router from '@/router';

const gotoLinkPage = (link: string) => {
	router.push(link).catch(() => {
		//
	});
}

const gotoContentHome = ({
	contentType, contentId
}: {
	contentType: string, contentId: number
	}): void => {
	const url = `/${contentType}/${contentId}`;
	router.push(url).catch(() => {
		//
	});
};

const gotoChapter = ({
	contentType, contentId, chapterId, chapterFileSalesType
}: {
		contentType: string, contentId: number, chapterId: number, chapterFileSalesType: string;
}): void => {
	const url = `/${contentType}/${contentId}/chapter/${chapterId}/${chapterFileSalesType}`;
	const params = {
		path: url
	};
	// if(chapterFileSalesType) {
	// 	params['query'] = {
	// 		chapterFileSalesType: chapterFileSalesType ? 'trial' : 'product'
	// 	};
	// }
	router.push(params).catch(() => {
		//
	});
}

// const routerFailure = (url: string, failure: NavigationFailure) => {
// 	if(isNavigationFailure(failure, NavigationFailureType.duplicated)) {
// 		console.log(`isNavigationFailure : ${url} ${failure}`);
// 	} else {
// 		console.log(failure);
// 	}
// };
const createContentPath = (contentType: string, contentId: number): string => {
	return `/${contentType}/${contentId}`;
};

export {
	gotoContentHome,
	gotoChapter,
	gotoLinkPage,
	createContentPath
}

// 라우터 변경에 따른 팝업 닫기
export const BrowserHandler = (fnDialogHide: EventListenerOrEventListenerObject): void => {
	window.addEventListener('popstate', fnDialogHide);
}