import { Popup } from '@/models';
import Notify from 'quasar/src/plugins/Notify.js';;
import { Module } from 'vuex';
import { RootState } from '.';

export interface DialogState {
	popup: Popup | null;
	dialog;
	currentDialog;
	notify;
};

export interface DialogStoreOption {
	component: Function;
	popup?: Popup;
	param?;
}

export const DialogStore: Module<DialogState, RootState> = {
	namespaced: true,
	state: {
		dialog: null,
		popup: null,
		currentDialog: null,
		notify: null
	},
	mutations: {
		currentDialog(state, value) {
			state.currentDialog = value;
		},
		dialog(state, value) {
			state.dialog = value;
		},
		notify(state, value) {
			state.notify = value;
		}
	},
	getters: {
		isDialog: (state) => state.currentDialog
	},
	actions: {
		async dialog({ state, commit }, {
			component,
			param
		}: DialogStoreOption) {
			return new Promise<string>(resolve => {
				const options = Object.assign({
					component,
				}, param);
				if(state.dialog) {
					const currentDialog = state.dialog(options)
						.onOk((value) => resolve(value))
						.onCancel(value => resolve(value))
						.onDismiss(() => {
							resolve('DISMISS');
						});
					commit('currentDialog', currentDialog);
				}
			})
		},
		hideDialog({ state, commit }) {
			try {
				state.currentDialog.hide();
			} catch(e) {
				commit('currentDialog', null);
			}
		},
		notify({ state }, param: Notify) {
			state.notify(param);
		}
	}
}