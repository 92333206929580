















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'MyPageForm',
  props: {
    title: {
      type: String
    },
    legend: {
      type: String
    }
  },
  setup() {
    return {};
  }
});
