import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { Chapter, CheckedChapter, CoinTxtInfo } from '@/models';

export interface SelectPurchaseType {
	chkChapters: CheckedChapter[]
}

export const SelectPurchaseStore: Module<SelectPurchaseType, RootState> = {
	namespaced: true,
	state: {
		chkChapters: []
	},
	mutations: {
		chkChapters(state, value) {
			state.chkChapters = value;
		},
		setCheck(state, value: { chapter: Chapter, checked: boolean }) {
			state.chkChapters.forEach(node => {
				if(node.chapter.id === value.chapter.id) {
					node.checked = value.checked;
				}
			});
			state.chkChapters = [...state.chkChapters];
		}
	},
	getters: {
		chkChapters: (state) => state.chkChapters,
		selected: (state) => state.chkChapters.filter(node => node.checked),
		coinTotal: (state) => {
			const checkedChapters = state.chkChapters.filter(node => node.checked);
			const priceInfo = {
				price: 0,
				originalPrice: 0,
				coinBackPrice: 0
			}
			checkedChapters.forEach(node => {
				const salesConfig = node.chapter.salesConfig;
				const { originalPrice, coinBackPrice }: CoinTxtInfo = salesConfig;
				priceInfo.price += salesConfig.getPrice();
				priceInfo.originalPrice += originalPrice ? originalPrice : 0;
				priceInfo.coinBackPrice += coinBackPrice ? coinBackPrice : 0;
			});
			return priceInfo;
		}
	},
	actions: {
		initChapters({ commit }, chapter: Chapter[]) {
			commit('chkChapters', chapter.map(node => new CheckedChapter(node)));
		},
		setCheckAll({ commit, state }, checked: boolean) {
			state.chkChapters.forEach(node => {
				if(!node.disabled) {
					node.checked = checked;
				}
			})
			commit('chkChapters', [...state.chkChapters]);
		}
	}
}