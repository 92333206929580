import { RootState } from '@/store/index';
import { createAxiosService } from '@/services/common/create-axios.service';
import { ToastLogInfo } from '@/models/log-info';
import { ERROR_CODES } from '@/commons/constants/error-codes';

const axiosService = createAxiosService();

const responsStatus = (code: number) => {
  let status: string;
  switch (true) {
    case code === ERROR_CODES.CODE_200:
    case code === ERROR_CODES.CODE_208:
      status = 'success';
      break;
    case code > 300 && code <= 500:
      status = 'error';
      break;
    default:
      status = 'console'
  }
  return status;
};

const dataQuerys = <T>(s: string, data: T): string => {
  switch (s) {
    case 'error':
      return `${JSON.stringify(data['config'])}, ${JSON.stringify(data['data']['result'])}`;
    case 'success':
      return JSON.stringify(data['config']);
    default:
      return JSON.stringify(data).replace(/\\/g, '');
  }
};

/**
 * Log & Crash
 * @param data response 
 * logType: http failed(error), http success(200), console.log 3가지로 구분 필요
 */
const comicoLcsSend = async <T>(data: T): Promise<T> => {
  try {
    let c = (data && typeof data !== 'string') ? data['data']['result']['code'] : 0;
    if (!c || typeof c !== 'number') c = 0;
    const s = responsStatus(c);
    const toastObj: ToastLogInfo = Object.assign({}, {
      projectName: process.env.VUE_APP_TOAST_KEY,
      projectVersion: process.env.VUE_APP_TOAST_PROJECT_VERSION,
      logVersion: process.env.VUE_APP_TOAST_LOG_VERSION,
      logSource: 'http',
      logType: s,
      body: dataQuerys(s, data).replace(/["]/g, '').replace(/[,]/g, ', ')
        + ', userAgent: '
        + dataQuerys('userAgent', navigator.userAgent).replace(/["]/g, '').replace(/[,]/g, ', ')
        + ', currentUser: '
        + dataQuerys('currentUser', localStorage.getItem('currentUser')).replace(/["]/g, '').replace(/[,]/g, ', ')
    })
    return await axiosService.post('/v2/log', toastObj);
  }
  catch (e) {
    // console.log(e);
    return Promise.reject('');
  }
};

export { comicoLcsSend };