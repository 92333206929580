import store from '@/store/index';
import { createAxiosService } from '../common/create-axios.service';
import { ContentInfo } from '@/models';
const axiosService = createAxiosService();

interface ContentInterface {
  contentType?: string;
  contentId?: number;
}

interface LibraryInterface {
  contentCategory: string;
  currentType: string;
  currentPageNo: number;
  sortType: string | boolean | undefined;
}

const getLibraryContents = ({
  contentCategory,
  currentType,
  currentPageNo,
  sortType
}: LibraryInterface): Promise<{ contents: ContentInfo[] }> => {
  return new Promise<{ contents: ContentInfo[] }>((resolve) => {
    const base = '/library';
    const category = contentCategory ? `/${contentCategory}` : '';
    const type = `/${currentType}`;
    const query = `?pageNo=${currentPageNo}&pageSize=20`;
    const order = sortType ? `&orderType=${sortType}` : '';
    const url = base + category + type + query + order;
    return axiosService({
      method: 'get',
      url
    }).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

/**
 * 구독, 최근본작품 삭제
 * @param contentType 'comic', 'novel'
 * @param contentId
 */
const getLibraryContentsUpdate = (
  contentType: string | undefined,
  contentId: number | undefined
): Promise<void> => {
  return new Promise((resolve) => {
    const currentPage = store.getters['LibraryStore/getContentTypeCurrent'];
    const url = `/library/${currentPage}`;
    const data: ContentInterface[] = [];
    data.push({ contentType, contentId });

    return axiosService({
      method: 'delete',
      url,
      data
    }).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const getLibraryContentsHidden = ({
  currentPageNo,
  sortType
}: LibraryInterface): Promise<{ contents: ContentInfo[] }> => {
  return new Promise<{ contents: ContentInfo[] }>((resolve) => {
    const query = `?pageNo=${currentPageNo}&pageSize=20`;
    const order = sortType ? `&orderType=${sortType}` : '';
    const url = '/library/all_comic/unlocked/hidden' + query + order;
    return axiosService({
      method: 'get',
      url
    }).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const hiddenLibraryContents = (
  contentType: string,
  contentId: number
): Promise<void> => {
  return new Promise((resolve) => {
    const url = `/library/unlocked/hidden/${contentType}/${contentId}`;

    return axiosService({
      method: 'post',
      url
    }).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

const unhiddenLibraryContents = (
  contentType: string,
  contentId: number
): Promise<void> => {
  return new Promise((resolve) => {
    const url = `/library/unlocked/hidden/${contentType}/${contentId}`;

    return axiosService({
      method: 'delete',
      url
    }).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

export const LibraryContentsService = {
  getLibraryContents,
  getLibraryContentsUpdate,
  getLibraryContentsHidden,
  hiddenLibraryContents,
  unhiddenLibraryContents
};
