













































import {
  computed,
  defineComponent,
  Ref,
  onMounted,
  ref
} from '@vue/composition-api';
import { isPC, sendBip } from '@/services/index';
import { LogInfo } from '@/models';
import Store from '@/store';
export default defineComponent({
  name: 'Offerwall',
  setup(props, { root }) {
    const { dispatch, getters, commit } = Store;
    const browserState = computed(() => getters['GlobalStore/getBrowserState']);
    const isPc = computed(() => isPC());
    const offerwallTitle = ref('');

    const getPincrux = () => {
      dispatch('OfferwallStore/getPincrux', isPc.value ? 'pc' : 'mobile');
      offerwallTitle.value = '무료 충전소';
    };

    const getPointPark = () => {
      dispatch('OfferwallStore/getPointPark');
      offerwallTitle.value = '포인트 전환';
    };
    const offerwall: Ref<string> = computed(
      () => getters['OfferwallStore/getOfferwall']
    );
    const close = () => {
      commit('GlobalStore/setDimmed', false);
      commit('OfferwallStore/setVisible');
      commit('OfferwallStore/resetOfferwall');
    };

    const iframeHeight =
      browserState.value.browserHeight < 700 && isPc.value
        ? browserState.value.browserHeight - 200
        : !isPc.value
        ? browserState.value.browserHeight - 48
        : 700;

    onMounted(() => {
      sendBip('/offerwall', root.$route.path, {} as LogInfo);
    });

    return {
      getPincrux,
      getPointPark,
      offerwall,
      close,
      browserState,
      isPc,
      iframeHeight,
      offerwallTitle
    };
  }
});
