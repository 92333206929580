import { AxiosResponse } from 'axios';

export const setReturnYn = (url: string | undefined, value: string): void => {
  if (!url) return;
  sessionStorage.setItem(url, value);
};

export const getReturnYn = (
  apiPath: string | undefined
): string | null | undefined => {
  if (!apiPath) return;
  const isReturn = sessionStorage.getItem(apiPath);
  sessionStorage.removeItem(apiPath);
  return isReturn;
};

const checkResponseUrl = (
  response: AxiosResponse,
  checkType: string
): boolean | undefined =>
  response.config.url?.toLowerCase().includes(checkType);

const findPath = (path: string | undefined, idxStr: string): boolean => {
  return path && path?.indexOf(idxStr) > -1 ? true : false;
};

export const InspectorHelperService = {
  setReturnYn,
  getReturnYn,
  checkResponseUrl,
  findPath
};
