










































import { defineComponent } from '@vue/composition-api';
import { convertDate } from '@/services/common/utils';
export default defineComponent({
  props: {
    matureTxt: Object,
    matureInfo: Object
  },
  setup() {
    const chkExpiredDate = (date: string): boolean =>
      convertDate() > convertDate(date);
    return { convertDate, chkExpiredDate };
  }
});
