













import { defineComponent, ComputedRef, computed } from '@vue/composition-api';
import Store from '@/store/index';
import Offerwall from '@/dialogs/offerwall/OfferwallDialog.vue';
import PointParkResultDialogVue from '@/dialogs/offerwall/PointParkResultDialog.vue';

export default defineComponent({
  name: 'Dimmed',
  components: { Offerwall },
  setup(_props, { root }) {
    const { getters, commit } = Store;
    const $q = root.$q;
    const visible: ComputedRef<boolean> = computed(
      () => getters['GlobalStore/getDimmed']
    );

    const visibleOfferwall = computed(
      () => getters['OfferwallStore/visibleOfferwall']
    );
    const offerwallClose = () => {
      return new Promise<void>((resolve) => {
        commit('GlobalStore/setDimmed', false);
        commit('OfferwallStore/setVisible');
        commit('OfferwallStore/resetOfferwall');
        resolve();
      });
    };

    const receiveMsgFromChild = (e: MessageEvent) => {
      const { pointpark } = e.data;
      if (pointpark) {
        offerwallClose().then(() => {
          $q.dialog({
            component: PointParkResultDialogVue,
            parent: root,
            result: pointpark
          });
        });
      }
    };
    window.addEventListener('message', receiveMsgFromChild);
    return {
      visible,
      visibleOfferwall
    };
  }
});
