import { ContentInfo, ContentsItemInfo, Menu, MenuItem, Page } from '@/models';
import { ContentsApiService } from '@/services/contents/contents.api';
import { MenuApiSerivce } from '@/services/menu/menu.api';
import { Module } from 'vuex';
import { RootState } from '.';

export interface GenreState {
	genre: string;
	menuItem: MenuItem;
	genreList: ContentInfo[];
	page: Page
}

export const GenreStore: Module<GenreState, RootState> = {
	namespaced: true,
	state: {
		genre: '',
		menuItem: {} as MenuItem,
		genreList: [],
		page: {} as Page
	},
	mutations: {
		genre(state: GenreState, value: string) {
			state.genre = value;
		},
		menuItem(state: GenreState, value: MenuItem) {
			state.menuItem = value;
		},
		genreList(state: GenreState, value: ContentInfo[]) {
			state.genreList = value;
		},
		page(state: GenreState, value: Page) {
			state.page = value;
		}
	},
	getters: {
		genre: state => state.genre,
		menuItem: state => state.menuItem,
		genreList: state => state.genreList,
		page: state => state.page
	},
	actions: {
		/**
		 * apiPath 획득하기 위한 menu 목록 초기화
		 * @param param0
		 * @param param1
		 * @returns
		 */
		async initGenreMenu({ commit }, {
			contentCategory,
			genre
		}) {
			const result: { menu: Menu } = await MenuApiSerivce.getGenreMenu(contentCategory, genre);
			if(result.menu) {
				const menu = new Menu(result.menu);
				const item: MenuItem | undefined = menu.getItem(genre);
				commit('genre', genre);
				commit('menuItem', item);
				return undefined;
			} else {
				return false;
			}
		},
		async initGenreContents({ commit, getters }, { apiPath, pageState }) {
			const result: ContentsItemInfo = await ContentsApiService.getContentsList(apiPath, pageState);
			let newGenres = result.contents.map(content => new ContentInfo(content));
			if(pageState.pageNo !== 0) {
				newGenres = getters.genreList.concat(newGenres);
			}
			commit('genreList', newGenres);
			commit('page', result.page);
		}
	}
}