import { inquiryApi } from '@/services/mypage/inquiry.api';

export const InquiryStore = {
  namespaced: true,
  state: {
  },

  getters: {
  },

  mutations: {
  },


  actions: {
    postInquiry:async ({ commit }, inquiryData:FormData) =>{
      return await inquiryApi.postInquiry({ inquiryData }).then(res => {
        return res
      });
    }
  }
};
