






















import store from '@/store';
import { computed, ComputedRef, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'PocketComicsCopyright',
  setup(props, { emit }) {
    const { getters } = store;
    const direction: ComputedRef<string> = computed(
      () => getters['MagazineComicStore/direction']
    );
    const onReportClick = () => emit('emitReport', {});
    return {
      direction,
      onReportClick
    };
  }
});
