import { render, staticRenderFns } from "./MyLibraryButton.vue?vue&type=template&id=e13f7b24&scoped=true&"
import script from "./MyLibraryButton.vue?vue&type=script&lang=ts&"
export * from "./MyLibraryButton.vue?vue&type=script&lang=ts&"
import style0 from "./MyLibraryButton.vue?vue&type=style&index=0&id=e13f7b24&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e13f7b24",
  null
  
)

export default component.exports