import store from '@/store/index';
import Dialog from 'quasar/src/plugins/Dialog.js';;
import AlertDialog from '@/dialogs/AlertDialog.vue';
import { ERROR_CODES } from '@/commons/constants/error-codes';
import { INTEGRATE_MESSAGE } from '@/commons/constants/integrate/integrate-type';

const alertDialog = (message: string, from, next) => {
  Dialog.create({
    component: AlertDialog,
    componentProps: {
      message: message,
      ok: '확인',
      html: true
    }
  }).onDismiss(() => {
    next('/');
  });
};

export const integrateRouter = [
  {
    path: '/integrate',
    meta: { authRequired: true },
    component: (): Promise<typeof import('*.vue')> =>
      import('@/views/integrate/PageModule.vue'),
    beforeEnter: (to, from, next) => {
      process.env.VUE_APP_LANGUAGE === 'ko' ? next() : next('/');
    },
    children: [
      {
        path: 'login',
        name: 'IntegrateLogin',
        meta: { isFooterHidden: true },
        component: (): Promise<typeof import('*.vue')> =>
          import('@/views/integrate/IntegrateLogin.vue'),
        beforeEnter: async (to, from, next) => {
          await store
            .dispatch('IntegrateStore/getMigration')
            .then((res) => {
              res.preComicoMigrationCheck
                ? next()
                : alertDialog(INTEGRATE_MESSAGE.accountComplete, from, next);
            })
            .catch((e) => {
              const code = e?.result?.code;
              if (!code) return next(from.fullPath);
              if (code === ERROR_CODES.CODE_404) {
                alertDialog(INTEGRATE_MESSAGE.accountNotFound, from, next);
              }
              next(false);
            });
        }
      },
      {
        path: 'coin',
        name: 'IntegrateCoin',
        meta: { isFooterHidden: true },
        component: (): Promise<typeof import('*.vue')> =>
          import('@/views/integrate/IntegrateCoin.vue')
      },
      {
        path: '/integrate/external-idp/web/:loginType/callback',
        name: 'Callback',
        component: (): Promise<typeof import('*.vue')> =>
          import('@/views/integrate/Callback.vue')
      }
    ]
  }
];
