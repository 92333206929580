

















































































































import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import IconTypeAnchor from '@/components/atoms/icons/IconTypeAnchor.vue';
import NextChapterCard from '@/components/molecules/chapter-card/NextChapterCard.vue';
import AuthorCommentCard from '@/components/molecules/viewer/AuthorCommentCard.vue';
import Recommend from '@/components/molecules/viewer/Recommend.vue';
import PocketComicsCopyright from '@/components/molecules/copyright/PocketComicsCopyright.vue';
import { AuthorComment, Chapter, NextPrevChapter, Banner } from '@/models';
import { sendNclick } from '@/services';
import ViewerBanner from '@/components/molecules/viewer/ViewerBanner.vue';
import { isEmptyObject } from '@/services/common/utils';
export default defineComponent({
  name: 'EndModule',
  components: {
    IconTypeAnchor,
    NextChapterCard,
    AuthorCommentCard,
    Recommend,
    ViewerBanner,
    PocketComicsCopyright
  },
  props: {
    content: Object,
    chapter: Object,
    recommend: Object,
    banners: Array,
    setIndex: Boolean,
    isPageMode: Boolean,
    lastPageIndex: Number,
    isLastMulti: Boolean,
    direction: String,
    addEndPageIdx: Number
  },
  setup(props, { emit }) {
    const chapterInfo: ComputedRef<Chapter> = computed(
      () => props.chapter as Chapter
    );
    const addEndPageIdx: ComputedRef<number | undefined> = computed(
      () => props.addEndPageIdx
    );
    const authorComment: ComputedRef<AuthorComment> = computed(
      () => chapterInfo.value.authorComment as AuthorComment
    );
    const emitReport = () => emit('emitReport', {});
    const sendNextChapter = (nextChapter: NextPrevChapter) =>
      emit('emitNextChapter', nextChapter);
    const bodyClickHandler = () => {
      emit('emitBodyClickHandler', {});
    };
    const onAddEndPage = (verticalShow: boolean, indexPage: number) => {
      return verticalShow
        ? addEndPageIdx.value === undefined || addEndPageIdx.value === indexPage
        : addEndPageIdx.value === indexPage;
    };
    const sendBannerNclick = (node: Banner) => {
      const tmpContent = props.content;
      if (!tmpContent) return;
      sendNclick(
        tmpContent.type + '/' + tmpContent.id,
        'banner',
        tmpContent.id + '',
        '',
        node.area + '&id=' + node.id
      );
    };
    return {
      emitReport,
      sendNextChapter,
      bodyClickHandler,
      authorComment,
      onAddEndPage,
      sendBannerNclick,
      isEmptyObject
    };
  }
});
