import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { ChallengeUserComment } from '@/models/challenge/challenge-chapter';
import { ChallengeCommentService } from '@/services/challenge/challenge-comment.api';

export interface ChallengeChapterCommentTypes {
	comments: ChallengeUserComment[];
	orderType: 'newest' | 'popular';
	contentId: number | null;
	chapterId: number | null;
}

export const ChallengeChapterCommentStore: Module<ChallengeChapterCommentTypes, RootState> = {
	namespaced: true,
	state: {
		comments: [],
		orderType: 'newest',
		contentId: null,
		chapterId: null
	},
	getters: {
		comments: (state) => state.comments,
		orderType: (state) => state.orderType,
		contentId: (state) => state.contentId,
		chapterId: (state) => state.chapterId,
		sortedComments: (state) => {
			try {
				if (state.orderType === 'newest') {
					return state.comments.sort((a, b) => {
						return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
					})
				} else if (state.orderType === 'popular') {
					return state.comments.sort((a, b) => {
						return b.totalLikes - a.totalLikes;
					})
				}
			} catch (e) {
				return [];
			}
		}
	},
	mutations: {
		comments(state: ChallengeChapterCommentTypes, value: ChallengeUserComment[]) {
			state.comments = value;
		},
		orderType(state: ChallengeChapterCommentTypes, value: 'newest' | 'popular') {
			state.orderType = value;
		},
		contentId(state: ChallengeChapterCommentTypes, value: number) {
			state.contentId = value;
		},
		chapterId(state: ChallengeChapterCommentTypes, value: number) {
			state.chapterId = value;
		},
	},
	actions: {
		async initComments({ commit }, { contentId, chapterId }: { contentId: number, chapterId: number }) {
			const result = await ChallengeCommentService.getComments({
				contentId, chapterId
			});
			commit('comments', result);
			commit('contentId', contentId);
			commit('chapterId', chapterId);
		},
		async deleteComment({ state, dispatch }, { commentId }: { commentId: number }) {
			try {
				await ChallengeCommentService.deleteComment(commentId);
				await dispatch('initComments', {
					contentId: state.contentId,
					chapterId: state.chapterId
				});
			} catch (e) {

			}
		}
	}
}