/** 검색어 get */
export const getKeywords = (key: string): string[] => {
	const list = localStorage.getItem(key);
	if (!list) return [];
	const arr: string[] = [];
	const arrTemp: string[] = list.split(',');
	arrTemp.forEach((val: string) => {
		val = val.replace(/(^\s*)|(\s*$)/g, '');
		val != '' && arr.push(val);
	});
	return arr;
};

/** 검색어 set */
export const setKeywords = (key: string, userValue: string, len: number): void => {
	const keyLocal = localStorage.getItem(key);
	let keyNewArr: string[] = [];
	keyLocal && keyLocal.length > 0 && (keyNewArr = keyLocal.split(','));

	const idx = keyNewArr.indexOf(userValue);
	idx >= 0 && keyNewArr.splice(idx, 1);
	keyNewArr.unshift(userValue);

	const keywords = keyNewArr.slice(0, len).join(',');
	localStorage.setItem(key, keywords);
};

/** 글로벌 검색 팝업 focusIn */
export const inputFocusIn = (elm: HTMLElement | null): void => {
	if (!elm) return;
	elm.focus();
};