import { mypageApi } from '@/services/mypage/mypage-api';
import { RootState } from '@/store/index';
import { Module } from 'vuex';
import { Authorization } from '@/models/user-info';
import { Inventory } from '@/models';
import router from '@/router';
import { LANGUAGE_TYPES } from '@/commons/constants/config-language';
import { HttpResponse } from '@/commons/constants/http.types';

export interface MypageInterface {
  userInfo: Authorization;
  certification: {
    iaSystemUrl: string;
    neTradeId: string;
    sessionId: string;
  };
  code: string;
}

const defaultUserInfo = (): Authorization => {
  return {
    userId: '',
    email: '',
    nickname: '',
    password: '',
    externalIdps: [],
    guest: false,
    emailNotify: false,
    avatarImageUrl: '',
    inventory: new Inventory({
      ticket: {
        amount: 0
      },
      coin: {
        purchasedAmount: 0,
        freeAmount: 0,
        closestExpireAmount: 0,
        closestExpireAt: ''
      }
    } as Inventory),
    mature: {
      acceptMature: true,
      adultVerification: {
        expiredAt: null,
        verifiedAt: null,
        status: null
      }
    },
    userExtraInfo: {
      cellPhone: null,
      lastPasswordChangedAt: null,
      marketInfoAgree: null,
      passwordExpired: null
    },
    expireCoins: {
      coins: []
    }
  };
};

export const MyPageStore: Module<MypageInterface, RootState> = {
  namespaced: true,
  state: {
    userInfo: defaultUserInfo(),
    certification: {
      iaSystemUrl: '',
      neTradeId: '',
      sessionId: ''
    },
    code: ''
  },

  getters: {
    userInfo: (state) => state.userInfo,
    certification: (state) => state.certification,
    code: (state) => state.code,
    acceptMature: (state) => state.userInfo.mature.acceptMature,
    mature: (state) => state.userInfo.mature,
    getExpireCoin: (state) => state.userInfo.expireCoins.coins
  },

  mutations: {
    setUserInfo: (state, payload) => {
      state.userInfo = Object.assign(state.userInfo, payload);
      const authentication = { authentication: payload };
      localStorage.setItem('currentUser', JSON.stringify(authentication));
    },
    resetUserInfo: (state) => (state.userInfo = defaultUserInfo()),
    setCertification: (state, payload) => (state.certification = payload),
    setCode: (state, payload) => (state.code = payload),
    setAcceptMature: (state) => {
      state.userInfo.mature.acceptMature = !state.userInfo.mature.acceptMature;
    },
    setExpireCoins: (state, payload) => (state.userInfo.expireCoins = payload)
  },

  actions: {
    getUserInfo: async ({ commit, rootState }) => {
      try {
        const res = await mypageApi.getUserInfo();
        commit('resetUserInfo', res.profile);
        commit('setUserInfo', res.profile);
        if (rootState.I18nStore.language === LANGUAGE_TYPES.KO_KR) {
          sessionStorage.setItem(
            'acceptMature',
            res.profile.mature.acceptMature ? 'Y' : 'N'
          );
        }
        return res.profile;
      } catch (e) {
        return null;
      }
    },
    getMature: ({ commit }) => {
      mypageApi.getUserInfo().then((res) => {
        commit('setUserInfo', res.profile);
      });
    },
    putAvatarImage: ({ dispatch }, image: ImageData) => {
      mypageApi.putAvatarImage(image).then((res) => {
        if (res.code === 200) {
          dispatch('getUserInfo');
        }
      });
    },
    deleteAvatarImage: ({ dispatch }) => {
      mypageApi.deleteAvatarImage().then((res) => {
        if (res.code === 200) {
          dispatch('getUserInfo');
        }
      });
    },
    putNickName: async ({ dispatch }, nickName: string) => {
      return await mypageApi.putNickName(nickName).then((res) => {
        dispatch('getUserInfo');
        return res;
      });
    },
    putMature: async ({ dispatch }, acceptMature: string) => {
      return await mypageApi.putMature(acceptMature).then(async (res) => {
        if (res.code === 200) {
          return await dispatch('getUserInfo');
        }
      });
    },
    putNotifyStatus: async ({ dispatch }, notifyStatus: string) => {
      return await mypageApi.putNotifyStatus(notifyStatus).then((res) => {
        if (res.code === 200) {
          dispatch('getUserInfo');
          return res;
        }
      });
    },
    putEmail: async ({ dispatch }, emailInfo: FormData) => {
      const res = await mypageApi.putEmail(emailInfo);
      if (res.code === 200) {
        dispatch('getUserInfo');
      }
      return res.code;
    },
    putSendVerifyToken: async ({ dispatch }, email: FormData) => {
      const res = await mypageApi.putSendVerifyToken(email);
      return res.code;
    },
    putCheckVerifyToken: async ({ dispatch }, params: FormData) => {
      const res = await mypageApi.putCheckVerifyToken(params);
      return res.code;
    },
    postMapping: async ({ dispatch }, emailData: FormData) => {
      const res = await mypageApi.postMapping(emailData);
      if (res.code === 200) {
        dispatch('getUserInfo');
      }
      return res.code;
    },
    postIdpMapping: ({ dispatch }, params) => {
      mypageApi.postIdpMapping(params).then((res) => {
        router.push('/userinfo').then(() => {
          if (res.code === 200) {
            dispatch('getUserInfo');
          } else {
            setTimeout(() => {
              dispatch(
                'ErrorStore/SET_ERROR',
                {
                  code: res.code,
                  title: res.code,
                  message: res.message,
                  response: ''
                },
                { root: true }
              );
            }, 500);
          }
        });
      });
    },
    deleteIdpMapping: async ({ dispatch }, idpType: string) => {
      return await mypageApi.deleteIdpMapping(idpType).then((res) => {
        if (res.code === 200) {
          dispatch('getUserInfo');
        }
        return res.code;
      });
    },
    getMatureInit: async ({ commit }) => {
      return await mypageApi.getMatureInit().then((res) => {
        commit('setCertification', res.certification);
        return res.certification;
      });
    },
    certificationComplete: async ({ commit }, code: string) => {
      return mypageApi.certificationComplete(code).then((res) => {
        return res;
      });
    },
    putPhoneNumber: async ({ dispatch }, cellPhone: string) => {
      return mypageApi.putPhoneNumber(cellPhone).then((res) => {
        if (res.code === 200) {
          dispatch('getUserInfo');
        }
        return res;
      });
    },
    putMarketingInfo: async ({ dispatch }, marketInfoAgree: string) => {
      return mypageApi.putMarketingInfo(marketInfoAgree).then((res) => {
        if (res.code === 200) {
          dispatch('getUserInfo');
        }
        return res;
      });
    },
    getCoinExpireDay: ({ commit }, day: number) => {
      return mypageApi
        .getCoinExpireDay(day)
        .then((res: HttpResponse) => {
          commit('setExpireCoins', res);
          return Promise.resolve(res);
        })
        .catch((err: HttpResponse) => {
          return Promise.reject(err);
        });
    }
  }
};
