










import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeUnmount,
  watch
} from '@vue/composition-api';
import store from '@/store';
import Container from '@/views/common/Container.vue';
import { isPC, ClassesHidden } from '@/services/common/utils';
import { ErrorStateInterface } from '@/store/error';
import { dateArray } from '@/services/common/utils';

export default defineComponent({
  name: 'SystemInspection',
  components: {
    Container
  },
  setup(props, { root }) {
    const { getters, dispatch } = store;
    const isMobile = computed(() => !isPC());
    const route = root.$route;
    const { errorTitle, errorMessage, errorEndAt } = route.params;
    const lang = computed(()=> getters['I18nStore/getCookieLanguage']);
    // PC에 설정 된 표준 시간대를 기준으로 파싱
    // const curr = new Date(errorState.value.endAt);
    // UTC 표준시, getTimezoneOffset 한국시간 예) -540분을 뺀값)
    // utc(1629245286000) === new Date(curr).toISOString() === errorState.value.endAt;
    // const utc = curr.getTime() + (curr.getTimezoneOffset() * 60 * 1000);
    onBeforeUnmount(() => {
      dispatch('ErrorStore/RESET_STATE');
    });

    watch(isMobile, (res: boolean) => {
      ClassesHidden(res, 'hidden');
    });

    return { 
      errorTitle,
      errorMessage,
      errorEndAt,
      dateArray,
      lang
    };
  }
});
