import { EncryptRSA } from '@/models';
import { getCookie } from '@/helpers/cookies';
import { NEO_CHK, ACCEPT_MATURE } from '@/commons/constants/auth-types';

export const getNeoChk = (): string | undefined => {
  // Client Error
  return getCookie(NEO_CHK);

  // Server Error: Sign in required, Cookie httpOnly
  // return sessionStorage.getItem('neoIdUid') || undefined;
};

export const encryptRsa = (fn: Function, values: EncryptRSA): string => {
  const { key, char1, char2 } = values;
  return fn(key) + key + fn(char1) + char1 + fn(char2) + char2;
};

export const checkCertifiAdult = (isCert: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    if (isCert === 'unverified') {
      reject({
        action: 'NO_CERTI'
      });
    } else if (isCert === 'expired') {
      reject({
        action: 'EXPIRED'
      });
    } else {
      resolve();
    }
  });
};

export const checkActiveAdultMode = (isActive: boolean): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const result = isActive;
    if (result) {
      resolve();
    } else {
      reject({
        action: 'NO_ACTIVE'
      });
    }
  });
};

export const disabledElm = (elm: HTMLElement | null, disabled: boolean) => {
  disabled
    ? elm?.setAttribute('disabled', disabled + '')
    : elm?.removeAttribute('disabled');
};

export const getCurrentUser = (): string | null => {
  const neoChk = getNeoChk();
  const isLogin = localStorage.getItem('currentUser');
  if (isLogin && neoChk) {
    return isLogin;
  } else {
    return null;
  }
};

export const checkJpAdult = () => {
  return new Promise<void>((resolve, reject) => {
    const accpetMature = getCookie(ACCEPT_MATURE);
    if (accpetMature === 'Y') {
      resolve();
    } else {
      reject({
        action: 'COOKIE_EXPIRED'
      });
    }
  });
};
