














































































































































import {
  computed,
  ComputedRef,
  defineComponent,
  ref
} from '@vue/composition-api';
import Store from '@/store';
import { isPC } from '@/services';

export default defineComponent({
  props: {
    isAuth: Boolean
  },
  setup(props, { root }) {
    const { getters } = Store;
    const className = ref(
      props.isAuth
        ? 'comico-global-menu-wrapper'
        : 'comico-global-header02__list-nav2-item--login o-large-screen-object'
    );
    const showMenu = ref(false);
    const userInfo = computed(() => getters['MyPageStore/userInfo']);
    const currentRouter = root.$router.currentRoute.path;
    const isMobile: ComputedRef<boolean> = computed(
      () => getters['UIStore/windowWidth'] < 1080
    );

    const toggleMenu = () => (showMenu.value = !showMenu.value);

    return {
      className,
      currentRouter,
      userInfo,
      showMenu,
      toggleMenu,
      isMobile
    };
  }
});
