interface ConfigStringValue {
  name: string;
  value: string;
}

interface ConfigNumberValue {
  name: string;
  value: number;
}

interface TextConfigInterface {
  fontType: ConfigStringValue[];
  fontSize: ConfigNumberValue[];
  lineHeight: ConfigNumberValue[];
  color: ConfigStringValue[];
}

export const TEXT_CONFIG: TextConfigInterface = {
  fontType: [
    { name: '시스템기본', value: 'sans-serif' },
    { name: '나눔고딕', value: 'Nanum Gothic' },
    { name: '나눔명조', value: 'Nanum Myeongjo' }
  ],
  fontSize: [
    { name: '1', value: 15 },
    { name: '2', value: 17 },
    { name: '3', value: 19 },
    { name: '4', value: 20 },
    { name: '5', value: 22 }
  ],
  lineHeight: [
    { name: '1', value: 1.5 },
    { name: '2', value: 1.7 },
    { name: '3', value: 2.0 },
    { name: '4', value: 2.4 }
  ],
  color: [
    { name: '컬러1', value: '#212529/#FFFFFF' },
    { name: '컬러2', value: '#212529/#F0EEEC' },
    { name: '컬러3', value: '#212529/#F1E9D3' },
    { name: '컬러4', value: '#212529/#D0C1A5' },
    { name: '컬러5', value: '#CED4DA/#191919' }
  ]
};
