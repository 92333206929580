import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { SearchContentsService } from '@/services/index';
import { ContentsItemInfo } from '@/models';

export interface SearchStateInterface {
  init: boolean;
  initFocus: boolean;
  keyword: string | null;
  listSearch: ContentsItemInfo;
};

const getDefaultState = (): SearchStateInterface => {
  return {
    init: false,
    initFocus: true,
    keyword: null,
    listSearch: {} as ContentsItemInfo
  };
};

/**
 * LibraryStore
 */
export const SearchStore: Module<SearchStateInterface, RootState> = {
  namespaced: true,
  state: getDefaultState,
  getters: {
    getInitFocus: (state) => state.initFocus,
    getKeyword: (state) => state.keyword,
    getSearchItems: (state) => state.listSearch
  },
  mutations: {
    resetState: state => {
      Object.assign(state, getDefaultState());
    },
    initFocus(state, value: boolean) {
      state.initFocus = value;
    },
    setKeyword(state, value: string) {
      state.keyword = value;
    },
    setSearchItems(state, value: ContentsItemInfo) {
      state.listSearch = value;
    }
  },
  actions: {
    async getListSearch({ commit }, params) {
      const data = await SearchContentsService.getSearchContents(params);
      commit('setSearchItems', data);
      return data;
    }
  }
};
