




































import {
  computed,
  ComputedRef,
  defineComponent,
  ref,
  watch
} from '@vue/composition-api';
import Store from '@/store/index';
import router from '@/router';
import NormalThumbnail from '@/components/molecules/contents-elements/NormalThumbnail.vue';
import SectionNavigator from '@/components/molecules/navigators/SectionNavigator.vue';
import IconTypeAnchor from '@/components/atoms/icons/IconTypeAnchor.vue';
import { Element, Section } from '@/models';
import {
  nextBtnState,
  SectionHelperService
} from '@/services/home/section.helper';
import {
  gotoChapter,
  gotoContentHome,
  gotoLinkPage
} from '@/services/routing/routing.helper';
import { IconType, ICON_VIEWMORE } from '@/commons/constants/icon-types';
import {
  separateUrl,
  getContentChapterPath
} from '@/services/contents/contents.helper';
import { sendNclick } from '@/services';

export default defineComponent({
  name: 'RecommendsSection',
  components: {
    NormalThumbnail,
    SectionNavigator,
    IconTypeAnchor
  },
  props: {
    section: Object,
    setIndex: Boolean,
    hideMore: Boolean
  },
  setup(props) {
    const { getters } = Store;
    const sectionInfo: ComputedRef<Section> = computed(
      () => props.section as Section
    );
    const elements: ComputedRef<Element[]> = computed(() =>
      sectionInfo.value && sectionInfo.value.elements
        ? sectionInfo.value.elements
        : []
    );
    const MAX_SLIDE_LEN = 5;
    const isWide: ComputedRef<boolean> = computed(
      () => getters['UIStore/isWide']
    );
    const liWidth = SectionHelperService.getLiWidth('recomm');
    const ulRef: any = ref(null);
    let currentIndex = 1;
    let currentX = 0;
    let value = Math.floor(elements.value.length / MAX_SLIDE_LEN);
    let namoji = Math.floor(elements.value.length % MAX_SLIDE_LEN);
    const prev = ref(false);
    const next = ref(nextBtnState(value, namoji));

    const sendContents = (element: Element) => {
      if (!element.urlHttp) return;
      const str = separateUrl(element.urlHttp);
      const result = getContentChapterPath(str);
      if (!result) return;
      if (result.chapterId) {
        gotoChapter(result);
        sendNclick(
          router.currentRoute.path,
          'chapter.recommend.comic',
          '' + result.contentId,
          '' + result.chapterId,
          '' + result.contentId
        );
      } else {
        gotoContentHome(result);
        sendNclick(
          router.currentRoute.path,
          'chapter.recommend.comic',
          '' + result.contentId,
          '' + (result.chapterId || ''),
          '' + result.contentId
        );
      }
    };

    const resetIndex = () => {
      try {
        currentIndex = 1;
        currentX = 0;
        value = Math.floor(elements.value.length / MAX_SLIDE_LEN);
        namoji = Math.floor(elements.value.length % MAX_SLIDE_LEN);
        prev.value = false;
        next.value = nextBtnState(value, namoji);
        ulRef.value.style.transform = `translateX(0px)`;
      } catch (e) {
        //
      }
    };

    const sendDirection = ({ type }: IconType) => {
      if (type.toLowerCase() === 'prev') {
        gotoPrev();
      } else {
        gotoNext();
      }
    };

    const gotoPrev = () => {
      if (currentIndex > 1) {
        currentX = currentX - MAX_SLIDE_LEN * liWidth;
        ulRef.value.style.transform = `translateX(-${currentX}px)`;
        currentIndex = currentIndex - 1;
        next.value = true;
        prev.value = currentX !== 0;
      } else {
        if (namoji > 0) {
          currentX = currentX - namoji * liWidth;
          ulRef.value.style.transform = `translateX(-${currentX}px)`;
          next.value = true;
        }
        prev.value = false;
      }
    };

    const gotoNext = () => {
      if (currentIndex === value) {
        if (namoji > 0) {
          currentX = currentX + namoji * liWidth;
          ulRef.value.style.transform = `translateX(-${currentX}px)`;
          prev.value = true;
        }
        next.value = false;
      } else {
        currentIndex = currentIndex + 1;
        currentX = currentX + MAX_SLIDE_LEN * liWidth;
        ulRef.value.style.transform = `translateX(-${currentX}px)`;
        prev.value = true;
        next.value = !(namoji === 0 && currentIndex === value);
      }
    };

    const sendViewMore = () => {
      // TODO: urlHttp or urlScheme ?
      if (!sectionInfo.value.urlScheme) return;
      sendNclick(
        'home',
        'home.more',
        '',
        '',
        sectionInfo.value.elementUiType + '&sectionid=' + sectionInfo.value.id
      );
      const str = separateUrl(sectionInfo.value.urlScheme);
      gotoLinkPage(`/${str.join('/')}`);
    };

    watch(elements, () => {
      resetIndex();
    });

    return {
      sectionInfo,
      isWide,
      elements,
      MAX_SLIDE_LEN,
      prev,
      next,
      ICON_VIEWMORE,
      sendContents,
      sendDirection,
      sendViewMore,
      ulRef
    };
  }
});
