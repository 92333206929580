import { createAxiosService } from '@/services/common/create-axios.service';

const axiosService = createAxiosService();

/**
 * 약관
 */
const getTermsItem = (value: string): Promise<{ url: string }> => {
  return new Promise<{ url: string }>(resolve => {
    const termsURL =
      `https://www.comico.kr/justoon/api/policy/history?policyType=${value}`;

    // return axiosService.get(termsURL).then(res => { const { data } = res; resolve(data.data); });
    return resolve({ url: 'https://www.comico.kr/justoon/api/resource/policy/39' });
  });
};

const TermsService = {
  getTermsItem,
};

export { TermsService };
