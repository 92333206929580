




















import {
  computed,
  ComputedRef,
  defineComponent,
  Ref,
  ref
} from '@vue/composition-api';
import ExpireRemained from '@/components/atoms/icons/ExpireRemained.vue';
import IconGotoLink from '@/components/atoms/icons/IconGotoLink.vue';
import {
  timeValue,
  setTimeToken,
  toNoticeDateStr
} from '@/services/common/date-token.helper';

export default defineComponent({
  name: 'PromotionLi',
  components: {
    ExpireRemained,
    IconGotoLink
  },
  props: {
    clsName: String,
    promotion: Object
  },
  setup(props, { emit }) {
    const cls = computed(() => (props.clsName ? props.clsName : 'emph_new'));
    const isError: Ref<boolean> = ref(false);
    const label: ComputedRef<string> = computed(() => props.promotion?.title);
    const expireRemained: ComputedRef<number> = computed(
      () => props.promotion?.expireRemained
    );
    const category: ComputedRef<number> = computed(
      () => props.promotion?.category
    );

    const toDateStr = (expireRemained: number) => {
      const str = toNoticeDateStr(expireRemained);
      if (str) {
        return str;
      } else {
        return '';
      }
    };

    return {
      cls,
      isError,
      label,
      expireRemained,
      category,
      toDateStr
    };
  }
});
