export const CustomPlugin = {
	// eslint-disable-next-line no-unused-vars
	langChecked(lang: string): boolean {
		return process.env.VUE_APP_LANGUAGE === lang ? true : false
	},
	install(Vue): void {
		Vue.prototype.$isKo = this.langChecked('ko');
		Vue.prototype.$isJa = this.langChecked('ja');
		Vue.prototype.$isEn = this.langChecked('en');
		Vue.prototype.$locale = process.env.VUE_APP_LANGUAGE;
	}
};
