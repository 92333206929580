import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
import { reactive } from '@vue/composition-api';

Vue.use(VueCompositionApi);

export const Campaign = reactive<{
  is_entered: boolean
}>({
  is_entered: false,
});