







































import { LANGUAGE_TYPES } from '@/commons/constants/config-language';
import { IdpInterface, IdpListInterface, IDP_TYPES } from '@/commons/constants/mypage-types';
import { ExternalIdps } from '@/models/user-info';
import { i18nTObj, i18nTxt, i18nWrapRuby } from '@/commons/i18n';
import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import Store from '@/store';
import ConfirmDialog from '@/dialogs/ConfirmDialog.vue';
import router from '@/router';
export default defineComponent({
  props: {
    externalIdps: {
      type: Array as () => Array<ExternalIdps>,
      default: () => []
    },
    email: String
  },
  setup(_props, { root }) {
    const { $q } = root;
    const { dispatch, getters } = Store;
    const language: ComputedRef<string> = computed(
      () => getters['I18nStore/getCookieLanguage']
    );
    const korean: ComputedRef<boolean> = computed(
      () => language.value === LANGUAGE_TYPES.KO_KR
    );
    const externalIdp = computed(() => getters['SocialLoginStore/externalIdp']);

    const IDP_LIST: IdpInterface[] = IDP_TYPES[language.value];

    const terminateTxt = korean.value
      ? `${i18nTxt('connect')} ${i18nTxt('disconnect')}`
      : i18nTxt('disconnect');

    const currentRouter = router.currentRoute.path;

    const externalIdpConnect = (loginType: string) => {
      sessionStorage.setItem('redirectUrl', currentRouter);
      getExternalIdpUrl(loginType).then((res) => {
        document.location.href = res;
      });
    };

    const terminateMsg = (idpType: string) => {
      const txt = i18nTxt('disconnect_confirm');
      return idpType !== 'payco' && korean.value
        ? i18nTxt('terminate_message')
        : i18nWrapRuby(txt, idpType);
    };

    const idpTerminate = (idpType: string) => {
      $q.dialog({
        component: ConfirmDialog,
        componentProps: {
          title: '',
          message: terminateMsg(idpType),
          cancel: i18nTxt('cancel'),
          ok: i18nTxt('ok')
        }
      }).onOk(() => {
        dispatch('MyPageStore/deleteIdpMapping', idpType).then((res) => {
          if (res === 200) {
            $q.notify({
              classes: 'notify_layer',
              message: i18nTxt('disconnect_success'),
              timeout: 1000,
              html: true
            });
          }
        });
      });
    };

    const getExternalIdpUrl = (loginType: string) =>
      dispatch('SocialLoginStore/getExternalIdpUrl', loginType);

    // !externalIdp.value.appleid ? getExternalIdpUrl() : null;

    return { idpTerminate, externalIdpConnect, IDP_LIST, terminateTxt, korean };
  }
});
