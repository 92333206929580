































































































import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  onUnmounted,
  ref
} from '@vue/composition-api';
import Store from '@/store';
import BtnRegister from '@/components/molecules/challenge/header/BtnRegister.vue';
import BtnUser from '@/components/molecules/challenge/header/BtnUser.vue';
import { isPC } from '@/services';

export default defineComponent({
  components: {
    BtnRegister,
    BtnUser
  },
  props: {
    noneFixed: Boolean
  },
  setup(_props, { root }) {
    const { getters } = Store;
    const limitPosition = 72;
    const scrolled = ref(false);
    const lastPosition = ref(0);
    const isAuth = computed(() => getters['AuthStore/isAuth']);
    const router = ref(root.$router);
    const isMobile = computed(() => getters['UIStore/windowWidth'] < 1080);

    const nav = [
      {
        name: 'TOP',
        link: '/challenge'
      },
      {
        name: 'ランキング',
        link: '/challenge/ranking'
      },
      {
        name: 'ジャンル',
        link: '/challenge/genre'
      }
    ];

    const handleScroll = () => {
      if (
        lastPosition.value < window.scrollY &&
        limitPosition < window.scrollY
      ) {
        scrolled.value = true;
      }

      if (lastPosition.value > window.scrollY) {
        scrolled.value = false;
      }

      lastPosition.value = window.scrollY;
    };
    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });
    return { handleScroll, scrolled, isAuth, BtnUser, router, nav, isMobile };
  }
});
