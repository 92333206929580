import VueRouter, { RouteConfig, Route } from 'vue-router';
import Home from '@/views/Home.vue';
import Search from '@/views/search/Search.vue';
import NotFound from '@/views/common/NotFound.vue';
import ServiceError from '@/views/common/ServiceError.vue';
import SystemInspection from '@/views/common/SystemInspection.vue';
import GlobalFooter from '@/components/organisms/footers/GlobalFooter.vue';
import store from '@/store/index';
import { CONTENT_TYPES } from '@/commons/constants/content.types';
import {
  isEmptyObject,
  isIE,
  sendBip,
  pathDivision,
  cateNames,
  getContentCategory,
  sendNclick,
  getErrorDialogOptions,
  getErrorDialogCallback,
  isNovel
} from '@/services';
import { LogInfo } from '@/models/log-info';
import { checkSignIn } from '@/services/contents/sales.state.helper';
import {
  checkJpAdult,
  getNeoChk,
  checkCertifiAdult,
  checkActiveAdultMode
} from '@/services/auth/auth.helper';
import { i18nTxt } from '@/commons/i18n';
import { TYPE_PURCHASE_BUTTON } from '@/commons/constants/sales-types';
import { ChaptersApiService } from '@/services/contents/chapters.api';
import { CoinService } from '@/services/coin/coin.api';
import { NEO_CHK, ACCEPT_MATURE } from '@/commons/constants/auth-types';
import { deleteCookie, saveCookie } from '@/helpers/cookies';
import { ERROR_CODES } from '@/commons/constants/error-codes';
import { challengeRouter } from './challenge.router';
import { integrateRouter } from './integrate.router';
import anniversaryRouter from './anniversary.router';
import campaignRouter from './campaign.router';
import { Comment } from '@/store/common';
import { Campaign } from '@/store/campaign';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: async (to, from, next) => {
      await store.dispatch('HomeStore/initHomeContent', {
        contentCategory: getContentCategory()
      });
      next();
    }
  },
  {
    path: '/:contentCategory(all_comic|all_novel)',
    components: {
      default: Home,
      footer: GlobalFooter
    },
    beforeEnter: async (to, from, next) => {
      next('/');
    }
  },
  // TODO: 要らない！
  // {
  //   path: '/menu/:contentCategory/event',
  //   name: 'Event',
  //   component: () => import('@/views/Event.vue'),
  //   beforeEnter: async (to, from, next) => {
  //     const { contentCategory } = to.params;
  //     await store.dispatch('EventStore/initEventInfo', {
  //       contentCategory
  //     });
  //     next();
  //   }
  // },
  {
    path: '/menu/content_catalog/:contentCatalogId',
    name: 'EventDetail',
    component: () => import('@/views/EventDetail.vue'),
    beforeEnter: async (to, from, next) => {
      const { contentCatalogId } = to.params;
      const prefix  = "content_catalog";
      const isWide = store.getters['UIStore/isWide'];
      await store.dispatch('EventStore/initEventDetailWithTitle', {
        contentCatalogId,
        prefix,
        pageState: { pageNo: 0, pageSize: 100 }
      });
      next();
    }
  },
  {
    path: '/menu/promotion/:contentCatalogId',
    name: 'EventDetailOfPromotion',
    component: () => import('@/views/EventDetail.vue'),
    beforeEnter: async (to, from, next) => {
      const { contentCatalogId } = to.params;
      const prefix  = "promotion/id";
      const isWide = store.getters['UIStore/isWide'];
      await store.dispatch('EventStore/initEventDetail', {
        contentCatalogId,
        prefix,
        pageState: { pageNo: 0, pageSize: 100 }
      });
      next();
    }
  },
  {
    path: '/menu/:contentCategory/adult',
    component: () => import('@/views/AdultHome.vue'),
    beforeEnter: async (to, from, next) => {
      if (process.env.VUE_APP_LANGUAGE === 'ko') {
        await checkBeforeEnterAdultHome(to, from, next);
      } else {
        await checkBeforeEnterJpAdult(to, from, next);
      }
      const { contentCategory, dep1Param } = to.params;
      const { currentItemCode } = to.query;
      const isNext = await store.dispatch('HomeStore/initHomeContent', {
        contentCategory: getContentCategory()
      });
      if (isNext) {
        const result = await store.dispatch('MenuStore/initAdultMenuItems', {
          contentCategory,
          dep1Param,
          currentItemCode: currentItemCode ? currentItemCode : ''
        });
        if (result) {
          next();
        }
      } else {
        next(false);
      }
    }
  },
  {
    path: '/menu/:contentCategory/:dep1Param',
    component: () => import('@/views/Menu.vue'),
    name: 'menu',
    beforeEnter: async (to, from, next) => {
      const { contentCategory, dep1Param } = to.params;
      const { currentItemCode } = to.query;
      const isNext = await store.dispatch('HomeStore/initHomeContent', {
        contentCategory: getContentCategory()
      });
      if (isNext) {
        const result = await store.dispatch('MenuStore/initMenuItems', {
          contentCategory,
          dep1Param,
          currentItemCode: currentItemCode ? currentItemCode : ''
        });
        if (result) {
          next();
        }
      } else {
        next(false);
      }
    }
  },
  {
    path: '/signup',
    redirect: '/signup/certificateEmail'
  },
  {
    path: '/signup/certificateEmail',
    name: 'CertificatedEmail',
    component: () => import('@/views/signup/CertificateEmail.vue')
  },
  {
    path: '/signup/certificateCheck',
    name: 'CertificateCheck',
    component: () => import('@/views/signup/CertificateCheck.vue'),
    beforeEnter: (to, from, next) => {
      const isEmail = store.getters['AuthStore/getCertificateEmail'];
      isEmail && isEmail.length > 0 ? next() : next('/');
    }
  },
  {
    path: '/signup/joinForm',
    name: 'JoinForm',
    component: () => import('@/views/signup/JoinForm.vue'),
    beforeEnter: (to, from, next) => {
      const pahse = process.env.VUE_APP_PHASE;
      if (pahse === 'LOCAL' || pahse === 'DEV') {
        const qeuryEmail = to.query.email || '';
        if (qeuryEmail) store.commit('AuthStore/certificateEmail', qeuryEmail);
      }

      const isEmail = store.getters['AuthStore/getCertificateEmail'];
      isEmail && isEmail.length > 0 ? next() : next('/signup');
    }
  },
  {
    path: '/login',
    component: () => import('@/views/login/PageModule.vue'),
    children: [
      {
        path: '/',
        name: 'Login',
        component: () => {
          if (process.env.VUE_APP_LANGUAGE === 'ko') {
            return import('@/views/login/Login.vue');
          } else {
            return import('@/views/login/LoginEmail.vue');
          }
        },
        beforeEnter: (to, from, next) => {
          const isLogin = sessionStorage.getItem('neoIdUid') ? true : false;
          if (!isLogin || !getAuth()) return next();
          const nextPage = to.params.nextPage?.toString();
          const re = to.params.redirect?.toString();
          if (nextPage && re && re.length > 0)
            return next({
              name: String(to.name),
              params: {
                redirect: re
              }
            });

          re && re.length > 0 ? next(re) : next('/');
        }
      },
      {
        path: 'email',
        name: 'LoginEmail',
        component: () => import('@/views/login/LoginEmail.vue'),
        beforeEnter: (to, from, next) => {
          if (process.env.VUE_APP_LANGUAGE !== 'ko') {
            next({ name: 'Login' });
          } else {
            const isLogin = sessionStorage.getItem('neoIdUid') ? true : false;
            isLogin && getAuth() ? next('/') : next();
          }
        }
      },
      {
        path: 'auth',
        name: 'Authorize',
        component: () => import('@/views/login/Authorize.vue'),
        beforeEnter: (to, from, next) => {
          const isNext = to.params.nextPage?.length > 0;
          isNext ? next() : next({ name: 'Login' });
        }
      },
      {
        path: 'dormant',
        name: 'DormantAccount',
        component: () => import('@/views/login/DormantAccount.vue'),
        beforeEnter: (to, from, next) => {
          const isNext = to.params.nextPage?.length > 0;
          isNext ? next() : next({ name: 'Login' });
        }
      },
      {
        path: 'agree',
        name: 'AgreeForm',
        component: () => import('@/views/login/AgreeForm.vue'),
        beforeEnter: (to, from, next) => {
          const isNext = to.params.nextPage?.length > 0;
          isNext ? next() : next({ name: 'Login' });
        }
      },
      {
        path: 'campaign',
        name: 'CampaignPassword',
        component: () => import('@/views/login/CampaignPassword.vue'),
        beforeEnter: (to, from, next) => {
          const isNext = to.params.nextPage?.length > 0;
          isNext ? next() : next({ name: 'Login' });
        }
      },
      {
        path: 'changePassword',
        name: 'ChangePassword',
        component: () => import('@/views/login/ChangePassword.vue'),
        beforeEnter(to, from, next) {
          const isNext = to.params.nextPage?.length > 0;
          isNext ? next() : next({ name: 'Login' });
        }
      }
    ]
  },
  {
    path: '/legal/abj',
    name: 'Abj',
    component: () => import('@/views/policy/AbjPage.vue')
  },
  {
    path: '/legal/:type',
    name: 'Policy',
    component: () => import('@/views/policy/Policy.vue')
  },
  {
    path: '/legal/:type/:version',
    name: 'PolicyVersion',
    component: () => import('@/views/policy/Policy.vue')
  },
  {
    path: '/password',
    component: () => import('@/views/password/PageModule.vue'),
    children: [
      {
        path: 'find',
        name: 'FindPassword',
        component: () => import('@/views/password/FindPassword.vue'),
        beforeEnter: (to, from, next) => {
          let beforePath = from.path.replace('/menu', '');
          if (beforePath === '/') beforePath = 'home';
          sendNclick(beforePath, 'account.password.forget', '', '', '');
          next();
        }
      },
      {
        path: 'result',
        name: 'ResetResult',
        component: () => import('@/views/password/ResetResult.vue'),
        beforeEnter: (to, from, next) => {
          const isNext = to.params.state?.length > 0;
          isNext ? next() : next({ name: 'Login' });
        }
      }
    ]
  },
  {
    path: '/member/profile/password/reset',
    component: () => import('@/views/password/PageModule.vue'),
    children: [
      {
        path: '',
        name: 'ResetPassword',
        component: () => import('@/views/password/ResetPassword.vue'),
        beforeEnter(to, from, next) {
          const { token } = to.query;
          token ? next() : next({ name: 'FindPassword' });
        }
      }
    ]
  },
  {
    path: '',
    name: 'MyPage',
    component: () => import('@/views/mypage/MyPage.vue'),
    meta: { authRequired: true },
    redirect: { path: '/userinfo' },
    beforeEnter: (to, from, next) => {
      if (!store.getters['MyPageStore/userInfo'].userId) {
        store.dispatch('MyPageStore/getUserInfo');
      }
      next();
    },
    children: [
      {
        path: 'userinfo',
        component: () => import('@/views/mypage/UserInfo.vue'),
        children: [
          {
            path: '',
            name: 'UserInfoContents',
            component: () => import('@/views/mypage/UserInfoContent.vue')
          },
          {
            path: 'nickname',
            component: () => import('@/views/mypage/Nickname.vue')
          },
          {
            path: 'registemail',
            component: () => import('@/views/mypage/RegistEmail.vue')
          },
          {
            path: 'changeemail',
            component: () => import('@/views/mypage/ChangeEmail.vue')
          },
          {
            path: 'password',
            component: () => import('@/views/mypage/Password.vue')
          },
          {
            path: 'phonenumber',
            component: () => import('@/views/mypage/PhoneNumber.vue')
          }
        ]
      },
      {
        path: 'coin/history/:type',
        name: 'CoinHistory',
        component: () => import('@/views/mypage/CoinHistory.vue')
      },
      {
        path: 'commenthistory',
        name: 'CommentHistory',
        component: () => import('@/views/mypage/Comment.vue')
      },
      {
        path: 'setting',
        name: 'Settings',
        component: () => import('@/views/mypage/Setting.vue')
      },
      {
        path: 'maturecertificate',
        name: 'MatureCertificate',
        component: () => import('@/views/mypage/MatureCerificate.vue')
      }
    ]
  },
  {
    path: '/library/:dep1Param',
    name: 'Library',
    meta: { authRequired: true },
    component: () => import('@/views/library/Library.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },

  {
    path: '/coinpurchase/result/:result',
    beforeEnter: (to, from, next) => {
      const { result } = to.params;
      next({
        name: 'CoinPurchaseResult',
        params: { result: result },
        query: to.query
      });
    }
  },
  {
    path: '/coinpurchase/result',
    name: 'CoinPurchaseResult',
    component: () => import('@/views/CoinPurchaseResult.vue')
  },
  {
    path: '/search/:q',
    name: 'SearchKeyword',
    component: () => import('@/views/search/Search.vue')
  },
  {
    path: '/:contentType(comic|magazine_comic|novel|magazine_novel)/:contentId',
    name: 'ContentHome',
    component: () => import('@/views/ContentsHome.vue'),
    beforeEnter: async (to, from, next) => {
      const { contentType, contentId } = to.params;
      const { tab } = to.query;
      const contentCategory = isNovel(contentType) ? 'all_novel' : 'all_comic';
      initContentCategory(contentCategory);
      const { contents, code } = await store.dispatch(
        'ContentsHomeStore/initContentsHome',
        {
          contentType,
          contentId: +contentId
        }
      );
      const {
        mature: { adultVerification }
      } = store.getters['MyPageStore/userInfo'];

      if (contents) {
        const content = contents.getContent();
        checkMatureChapter(content.mature, { to, from, next });
        let beforePath = from.path.replace('/menu', '');
        if (beforePath === '/') beforePath = 'home';
        sendNclick(beforePath, 'content', contentId, '', '', contentType);

        if (
          content.mature &&
          !adultVerification.status &&
          process.env.VUE_APP_LANGUAGE === 'ko'
        ) {
          next('/maturecertificate');
          setRedirectUrl(to);
        }

        if (tab) {
          const upperCase = tab.toString().toUpperCase();
          if (upperCase === 'EPISODE' || upperCase === 'VOLUME') {
            await store.dispatch('ContentsHomeStore/tabActive', {
              tabName: upperCase
            });
          }
          next();
        } else {
          next();
        }
      } else {
        switch (code) {
          case ERROR_CODES.CODE_428:
            alertAcceptMature(to, from, next);
            return;
          case ERROR_CODES.CODE_401: // 비로그인
            alertGoToLogin(to, from, next);
            break;
          case ERROR_CODES.CODE_451:
            const { mature } = store.getters['MyPageStore/userInfo'];
            checkSignIn(getAuth())
              .then(
                checkCertifiAdult.bind(null, mature.adultVerification.status)
              )
              .catch((res) => {
                if (!res.action) return;
                const { action } = res;
                switch (action) {
                  case 'GOTO_LOGIN':
                    alertGoToLogin(to, from, next);
                    break;
                  case 'NO_CERTI':
                    next('/maturecertificate?redirect=Y');
                    setRedirectUrl(to);
                    break;
                  case 'EXPIRED':
                    alertExpired(to, from, next);
                    break;
                }
              });
            return;
        }
      }
    }
  },
  {
    path:
      '/novel/:contentId/chapter/:chapterId/:chapterFileSalesType(product|trial)',
    name: 'NovelViewer',
    component: () => import('@/views/viewer/NovelViewer.vue'),
    beforeEnter: async (to, from, next) => {
      const { contentId, chapterId, chapterFileSalesType } = to.params;
      await contentRePurchaseCheck('novel', contentId);
      const isAuthAccess = await store.dispatch(
        'NovelViewerStore/initChapter',
        {
          contentType: 'novel',
          contentId,
          chapterId,
          chapterFileSalesType: chapterFileSalesType
            ? chapterFileSalesType
            : 'product'
        }
      );
      const { epub, code, mature } = isAuthAccess;

      if (epub) {
        checkMatureChapter(mature, { to, from, next });
        const result = checkAuthAccess(epub, {
          contentType: 'novel',
          contentId,
          redirect: to.path
        });
        next(result);
      } else {
        catchViewerErrorCode(to, from, next, {
          code,
          contentType: 'novel',
          contentId
        });
      }
    }
  },
  {
    path: '/preview/:contentType/:contentId/chapter/epub',
    name: 'Preview',
    component: () => import('@/views/viewer/Preview.vue'),
    beforeEnter: async (to, from, next) => {
      const { query, params } = to;
      const isAuthAccess = await store.dispatch(
        'NovelViewerStore/getPreviewInfo',
        {
          params,
          query
        }
      );

      if (isAuthAccess) {
        next();
      } else {
        catchViewerErrorCode(to, from, next, {
          contentType: 'novel'
        });
      }
      next();
    }
  },
  {
    path:
      '/magazine_novel/:contentId/chapter/:chapterId/:chapterFileSalesType(product|trial)',
    name: 'MagazineNovelViewer',
    component: () => import('@/views/viewer/NovelViewer.vue'),
    beforeEnter: async (to, from, next) => {
      const { contentId, chapterId, chapterFileSalesType } = to.params;
      await contentRePurchaseCheck('magazine_novel', contentId);
      const isAuthAccess = await store.dispatch(
        'NovelViewerStore/initChapter',
        {
          contentType: 'magazine_novel',
          contentId,
          chapterId,
          chapterFileSalesType: chapterFileSalesType
            ? chapterFileSalesType
            : 'product'
        }
      );
      const { epub, code } = isAuthAccess;
      if (epub) {
        const result = checkAuthAccess(epub, {
          contentType: 'magazine_novel',
          contentId,
          redirect: to.path
        });
        next(result);
      } else {
        catchViewerErrorCode(to, from, next, {
          code,
          contentType: 'magazine_novel',
          contentId
        });
      }
    }
  },
  {
    path:
      '/magazine_comic/:contentId/chapter/:chapterId/:chapterFileSalesType(product|trial)',
    name: 'MagazineComicViewer',
    component: () => import('@/views/viewer/MagazineComicViewer.vue'),
    beforeEnter: async (to, from, next) => {
      const contentType = 'magazine_comic';
      const { contentId, chapterId, chapterFileSalesType } = to.params;
      await contentRePurchaseCheck('magazine_comic', contentId);
      const isAuthAccess = await store.dispatch(
        'MagazineComicStore/initChapter',
        {
          contentType,
          contentId,
          chapterId,
          chapterFileSalesType: chapterFileSalesType
            ? chapterFileSalesType
            : 'product'
        }
      );
      const { images, epub, code, mature } = isAuthAccess;

      if (images) {
        checkMatureChapter(mature, { to, from, next });
        const result = checkAuthAccess(images, {
          contentType,
          contentId,
          redirect: to.path
        });
        next(result);
      } else if (epub) {
        const result = checkAuthAccess(epub, {
          contentType,
          contentId,
          redirect: to.path
        });
        next(result);
      } else {
        catchViewerErrorCode(to, from, next, {
          code,
          contentType,
          contentId
        });
      }
    }
  },
  {
    path:
      '/:contentType(comic)/:contentId/chapter/:chapterId/:chapterFileSalesType(product|trial)',
    name: 'ComicViewer',
    component: () => import('@/views/viewer/ComicViewer.vue'),
    beforeEnter: async (to, from, next) => {
      const {
        contentType,
        contentId,
        chapterId,
        chapterFileSalesType
      } = to.params;
      await contentRePurchaseCheck('comic', contentId);
      const isAuthAccess = await store.dispatch(
        'ComicViewerStore/initChapter',
        {
          contentType,
          contentId,
          chapterId,
          chapterFileSalesType: chapterFileSalesType
            ? chapterFileSalesType
            : 'product'
        }
      );
      const { images, code, mature } = isAuthAccess;

      if (images) {
        checkMatureChapter(mature, { to, from, next });
        const result = checkAuthAccess(images, {
          contentType,
          contentId,
          redirect: to.path
        });
        next(result);
      } else {
        catchViewerErrorCode(to, from, next, {
          code,
          contentType,
          contentId
        });
      }
    }
  },
  {
    path: '/:contentType/coming_soon/:contentId',
    name: 'ComingSoonViewer',
    component: () => import('@/views/viewer/ComingSoon.vue')
  },
  {
    path: '/:contentType/coming_soon/:contentId/:chapterFileSalesType(product|trial)',
    name: 'ComingSoonViewer',
    component: () => import('@/views/viewer/ComingSoon.vue')
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: () => import('@/views/inbox/Inbox.vue'),
    redirect: { name: 'gift' },
    meta: { authRequired: true },
    children: [
      {
        path: 'gift',
        name: 'gift',
        component: () => import('@/views/inbox/Gift.vue')
      },
      {
        path: 'message',
        name: 'message',
        component: () => import('@/views/inbox/Message.vue')
      },
      {
        path: 'mission',
        name: 'mission',
        component: () => import('@/views/inbox/Mission.vue')
      }
    ]
  },
  {
    path: '/:contentCategory(all_comic|all_novel)/genre/:genre',
    name: 'Genre',
    component: () => import('@/views/Genre.vue'),
    beforeEnter: async (to, from, next) => {
      const { dispatch } = store;
      const { contentCategory, genre } = to.params;
      await dispatch('GenreStore/initGenreMenu', {
        contentCategory,
        genre
      });
      next();
    }
  },

  {
    path: '/coinpurchase',
    name: 'CoinPurchase',
    component: () => import('@/views/CoinPurchase.vue'),
    beforeEnter: (to, from, next) => {
      const prevRouterList = ['ContentHome', 'Home', 'CoinHistory'];
      const prevRouteName = from.name || '/';
      if (prevRouterList.includes(prevRouteName)) {
        sessionStorage.setItem('redirectUrl', from.path);
      }
      next();
    }
  },
  {
    path: '/inquiry',
    name: 'inquiry',
    component: () => import('@/views/Inquiry.vue')
  },
  {
    path: '/member/external-idp/web/:loginType/callback',
    name: 'LoginCallback',
    component: () => import('@/views/login/Callback.vue'),
    beforeEnter(to, from, next) {
      if (from.path !== '/') {
        Router.replace('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/document/faq',
    component: () => import('@/views/faq/Faq.vue'),
    children: [
      {
        path: '',
        name: 'FaqList',
        component: () => import('@/views/faq/FaqList.vue')
      },
      {
        path: '/document/faq/detail',
        name: 'FaqDetail',
        component: () => import('@/views/faq/FaqDetail.vue')
      }
    ]
  },
  {
    path: '/document/notice',
    component: () => import('@/views/notice/Notice.vue'),
    children: [
      {
        path: '',
        name: 'NoticeList',
        component: () => import('@/views/notice/NoticeList.vue')
      },
      {
        path: '/document/notice/detail',
        name: 'NoticeDetail',
        component: () => import('@/views/notice/NoticeDetail.vue')
      }
    ]
  },
  {
    path: '/certificate',
    component: () => import('@/views/certificate/PageModule.vue'),
    children: [
      {
        path: 'adult',
        name: 'Certificate19',
        meta: { authRequired: true },
        component: () => import('@/views/certificate/Certificate19.vue')
      },
      {
        path: 'adult/:platform/callback',
        name: 'CertificateResult',
        component: () => import('@/views/certificate/CertificateResult.vue')
      }
    ]
  },
  {
    path: '/account',
    component: () => import('@/views/account/PageModule.vue'),
    // redirect: '/account/delete',
    meta: { authRequired: true },
    beforeEnter: (to, from, next) => {
      const auth = store.getters['AuthStore/isAuth'];
      if (!auth) {
        store.dispatch('MyPageStore/getUserInfo');
      }
      next();
    },
    children: [
      {
        path: '',
        name: 'Account',
        beforeEnter: (to, from, next) => {
          // store.commit('GlobalStore/setUserMenuState', true);
          Router.replace('/');
        }
      },
      {
        path: 'delete',
        name: 'MemberDelete',
        component: () => import('@/views/account/MemberDelete.vue')
      }
    ]
  },
  {
    path: '/comment/:contentType/:contentId/:chapterId',
    component: () => import('@/views/comment/ChapterComment.vue'),
    beforeEnter: async (to, from, next) => {
      const { contentType, contentId, chapterId } = to.params;
      await store.dispatch('ChapterCommentStore/initComments', {
        contentType,
        contentId,
        chapterId
      });
      next();
    }
  },
  {
    path: '/comment/user/:authorUserId',
    component: () => import('@/views/comment/UserCommentHistory.vue'),
    beforeEnter: async (to, from, next) => {
      const { authorUserId } = to.params;
      if (!isNaN(+authorUserId)) {
        await store.dispatch('UserCommentHistoryStore/initUserCommentHistory', {
          authorUserId
        });
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: '/error',
    name: 'ServiceError',
    component: ServiceError,
    beforeEnter: async (to, from, next) => {
      const errorState = store.getters['ErrorStore/errorState'];
      if (errorState.code !== 0) next();
      else next('/');
    }
  },
  {
    path: '/system',
    name: 'SystemInspection',
    component: SystemInspection,
    beforeEnter: async (to, from, next) => {
      const errorState = store.getters['ErrorStore/errorState'];
      if (errorState.code !== 0 && errorState.endAt.length > 0) next();
      else next('/');
    }
  },
  {
    path: '/browserguide',
    meta: {
      isGuidePage: true
    },
    name: 'IEBrowserGuide',
    component: () => import('@/views/IEBrowserGuide.vue')
  },
  {
    path: '/switch',
    name: 'DormantMeberAccount',
    component: () => import('@/views/login/DormantMeberAccount.vue')
  },
  {
    path: '/tw/input-serialcode',
    name: 'MigrationFromTW',
    component: () => import('@/views/migration/TW.vue')
  },
  {
    path: '/tw/completed-migration',
    name: 'MigrationCompletedFromTW',
    component: () => import('@/views/migration/CompletedFromTW.vue')
  },
  {
    path: '/offerwall/pointpark/result',
    name: 'PointparkResult',
    component: () => import('@/views/PointparkResult.vue')
  },
  {
    path: '/manage/title',
    component: () => import('@/views/manage/index.vue'),
    meta: { authRequired: true }
  },
  {
    path: '/manage/title/activity',
    component: () => import('@/views/manage/activity.vue'),
    meta: { authRequired: true }
  },
  {
    path: '/manage/title/new',
    component: () => import('@/views/manage/insertTitle.vue'),
    meta: { authRequired: true }
  },
  {
    path: '/manage/title/:contentId',
    component: () => import('@/views/manage/insertTitle.vue'),
    meta: { authRequired: true }
  },
  {
    path: '/manage/article/:contentId',
    component: () => import('@/views/manage/article.vue'),
    meta: { authRequired: true }
  },
  {
    path: '/manage/article/:contentId/new',
    component: () => import('@/views/manage/insertArticle.vue'),
    meta: { authRequired: true }
  },
  {
    path: '/manage/article/:contentId/:chapterId',
    component: () => import('@/views/manage/insertArticle.vue'),
    meta: { authRequired: true }
  },
  ...integrateRouter,
  ...challengeRouter,
  ...process.env.VUE_APP_LANGUAGE === 'ja' ? campaignRouter : [],
  ...process.env.VUE_APP_LANGUAGE === 'ja' ? anniversaryRouter : [],
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
];

/**
 * url 직접 접근시 contentCateogry 세팅
 * @param params
 * @returns
 */
function initContentCategory(contentCategory: string): boolean | undefined {
  const contentType = CONTENT_TYPES[contentCategory];
  if (contentType) {
    store.commit('HomeStore/contentCategory', contentType);
    return true;
  } else {
    return false;
  }
}

function getAuth(): boolean {
  const neoChk = getNeoChk();
  // const isLogin = localStorage.getItem('currentUser') ? true : false;
  // if (isLogin && neoChk && neoChk.length > 0) {
  // 앱에서 웹으로 토큰만 가지고 접근 시 허용
  if (neoChk && neoChk.length > 0) {
    store.commit('AuthStore/setIsAuth', true);
    return true;
  } else {
    store.commit('AuthStore/setIsAuth', false);
    return false;
  }
}

/** UserId Update */
async function getUserId(): Promise<string> {
  if (!getAuth()) return '';

  const currentUser = sessionStorage.getItem('userId');
  if (currentUser) return currentUser;

  try {
    const data = store.getters['MyPageStore/userInfo'];
    if (!isEmptyObject(data)) {
      sessionStorage.setItem('userId', data['userId']);
      return data['userId'];
    }
  } catch {
    sessionStorage.removeItem('userId');
  }
  return '';
}

/** BIP 전송 */
async function comicoLcsBip(to: Route, from: Route) {
  const querys = {} as LogInfo;
  const userId = await getUserId();
  const cate = pathDivision(to.path);
  const names = cateNames(cate, to, store, userId);
  if (names) {
    const u = names['u'];
    const k = names['k'];
    // if (to.params.contentType) querys.AtID = String(to.params.contentType);
    if (k && k.length > 0) querys.k = k;
    if (to.params.contentId) querys.TID = String(to.params.contentId);
    if (to.params.chapterId) querys.ArID = String(to.params.chapterId);
    sendBip(u, from.fullPath, querys);
  }
}

const checkBeforeEnterJpAdult = async (to, from, next) => {
  return new Promise<void>(async (resolve) => {
    try {
      await checkJpAdult();
      resolve();
    } catch (e) {
      alertCheckAdult(to, from, next);
    }
  });
};

const checkBeforeEnterAdultHome = async (to, from, next) => {
  const result = store.getters['MyPageStore/userInfo'];
  if (!result) {
    next(false);
    return;
  }
  const { mature, userId } = result;
  return new Promise<void>((resolve) => {
    checkSignIn(getAuth())
      .then(async () => {
        const { acceptMature } = mature;
        if (mature) {
          return checkActiveAdultMode(acceptMature ? acceptMature : false);
        } else {
          return checkActiveAdultMode(false);
        }
      })
      .then(checkCertifiAdult.bind(null, mature.adultVerification.status))
      .then(resolve)
      .catch((res) => {
        if (!res.action) {
          next(false);
          return;
        }
        const { action } = res;
        switch (action) {
          case 'GOTO_LOGIN':
            alertGoToLogin(to, from, next);
            break;
          case 'NO_CERTI':
            next('/maturecertificate?redirect=Y');
            setRedirectUrl(to);
            break;
          case 'NO_ACTIVE':
            alertAcceptMature(to, from, next);
            break;
          case 'EXPIRED':
            alertExpired(to, from, next);
            break;
        }
      });
  });
};

const alertExpired = (to, from, next) => {
  const params = {
    title: '',
    message: '',
    ok: i18nTxt('ok'),
    cancel: i18nTxt('cancel')
  };
  store
    .dispatch('DialogStore/dialog', {
      component: () => import('@/dialogs/ConfirmDialog.vue'),
      param: {
        componentProps: {
          ...params,
          message:
            '성인인증 후 1년이 경과하여 재인증이 필요합니다.인증하시겠습니까?'
        }
      }
    })
    .then((res) => {
      if (res && res.value) {
        next('/maturecertificate?redirect=Y');
        setRedirectUrl(to);
      } else {
        next(from);
      }
    });
};

const alertCheckAdult = (to, from, next) => {
  const d: Date = new Date();
  const params = {
    title: i18nTxt('warning_adults_only'),
    message: i18nTxt('warning_adults_only_message'),
    ok: i18nTxt('yes'),
    cancel: i18nTxt('no')
  };
  store
    .dispatch('DialogStore/dialog', {
      component: () => import('@/dialogs/ConfirmDialog.vue'),
      param: {
        componentProps: {
          ...params
        }
      }
    })
    .then((res) => {
      if (res && res.value) {
        saveCookie(ACCEPT_MATURE, 'Y', {
          expires: new Date(d.setDate(d.getDate() + 1))
        });
        next(to);
      } else {
        getAuth() && from.name === 'Authorize' ? next('/') : next(from);
      }
    });
};

const Router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    const isRecoveryPosition = (to.path.includes('/comic/coming_soon/'));
    if (isRecoveryPosition && savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition)
        }, 400)
      })
    } else {
      return { x: 0, y: 0 };
    }
  }
});
// const Router = new VueRouter({
//   scrollBehavior: () => ({ x: 0, y: 0 }),
//   routes,
//   mode: 'history'
// });


const checkUserInfo = () =>
  !store.getters['MyPageStore/userInfo'].userId && getAuth();

const setRedirectUrl = (route: Route) => {
  sessionStorage.setItem('redirectUrl', route.path);
};

/**
 * 페이지 이탈 시 로그아웃 처리
 * 약관동의
 */
const checkPageBounceAuth = (to: Route, from: Route) => {
  const AGREE_FORM = 'AgreeForm';
  const AUTHORIZE = 'Authorize';
  return from.name === AGREE_FORM && to.name !== AUTHORIZE ? true : false;
};

// 약관 동의 여부
const checkTermsOfUse = () => {
  const getForegrounded = store.getters['AuthStore/getForegrounded'];
  return getForegrounded?.required?.consents?.termsOfUse;
};

// 푸터 정보 여부
// true: 미존재, false: 존재
const checkWebview = () => {
  const getConfig = store.getters['GlobalStore/getConfig'];
  return isEmptyObject(getConfig?.webview) ? true : false;
};

const checkAuthAccess = (
  isAuthAccess,
  { contentType, contentId, redirect }
) => {
  if (isAuthAccess) {
    return;
  } else {
    if (store.getters['AuthStore/isAuth']) {
      return `/${contentType}/${contentId}`;
    } else {
      return {
        path: '/login',
        query: {
          redirect
        }
      };
    }
  }
};

/**
 * 비로그인 유저 처리
 */
export const alertGoToLogin = (to, from, next) => {
  const params = {
    title: '',
    message: '',
    ok: i18nTxt('ok'),
    cancel: i18nTxt('cancel')
  };
  store
    .dispatch('DialogStore/dialog', {
      component: () => import('@/dialogs/ConfirmDialog.vue'),
      param: {
        componentProps: {
          ...params,
          message: i18nTxt('validator_required_login')
        }
      }
    })
    .then((res) => {
      if (res) {
        const { fullPath } = to;
        const options = {
          path: '/login'
        };
        if (fullPath) {
          options['query'] = {
            redirect: fullPath
          };
        }
        next(options);
      } else {
        if (from && from.name) {
          next(from.fullPath);
        } else {
          next('/');
        }
      }
    });
};

/**
 * 19 on/off 처리
 */
const alertAcceptMature = (to, from, next) => {
  const params = {
    message: '',
    ok: i18nTxt('ok'),
    cancel: i18nTxt('cancel')
  };
  store
    .dispatch('DialogStore/dialog', {
      component: () => import('@/dialogs/ConfirmDialog.vue'),
      param: {
        componentProps: {
          ...params,
          message: i18nTxt('accept_mature_popup_title')
        }
      }
    })
    .then(async (res) => {
      if (res) {
        const { value } = res;
        if (value) {
          const isAuth = store.getters['AuthStore/isAuth'];
          if (isAuth) {
            await store.dispatch('MyPageStore/putMature', 'on');
          } else {
            sessionStorage.setItem('acceptMature', 'Y');
          }
          next(to.fullPath);
        } else {
          next(false);
        }
      } else {
        next(from.fullPath);
      }
    });
};

const contentRePurchaseCheck = async (contentType, contentId) => {
  const reBuyInfo = sessionStorage.getItem('redirect_purchase');
  if (reBuyInfo) {
    const jsonObj = JSON.parse(reBuyInfo);
    const buttonType = jsonObj.button.type;
    if (buttonType === TYPE_PURCHASE_BUTTON.BUY_COIN.type) {
      if (contentType && contentId && jsonObj.chapter.id) {
        const { transaction } = await CoinService.getTransaction();
        const { price, coinBackPrice } = jsonObj.chapter.salesConfig;
        const params = {
          token: transaction.token,
          price: price.toString()
        };
        if (coinBackPrice !== undefined) {
          params['coinBackPrice'] = coinBackPrice.toString();
        }
        await ChaptersApiService.unlock({
          contentType,
          contentId,
          chapterId: jsonObj.chapter.id,
          params
        }).catch((err) => {
          const options = getErrorDialogOptions({
            message: err.result.message
          });
          const { code } = err.result;
          store.dispatch('DialogStore/dialog', options).then(() => {
            getErrorDialogCallback({ code });
          });
        });
      }
    } else if (buttonType === TYPE_PURCHASE_BUTTON.RENT_COIN.type) {
      if (contentType && contentId && jsonObj.chapter.id) {
        const { transaction } = await CoinService.getTransaction();
        const { rentalPrice } = jsonObj.chapter.salesConfig;
        const params = {
          token: transaction.token
        };
        if (rentalPrice !== undefined) {
          params['rentalPrice'] = rentalPrice;
        }
        await ChaptersApiService.rentWith(jsonObj.rentType, {
          contentType,
          contentId,
          chapterId: jsonObj.chapter.id,
          params
        }).catch((err) => {
          const options = getErrorDialogOptions({
            message: err.result.message
          });
          const { code } = err.result;
          store.dispatch('DialogStore/dialog', options).then(() => {
            getErrorDialogCallback({ code });
          });
        });
      }
    }
  }
  sessionStorage.removeItem('redirect_purchase');
};

// 한국 사양
const specKoreaFn = async (to: Route, from: Route) => {
  if (process.env.VUE_APP_LANGUAGE !== 'ko') return;

  // 세션만료: 1. 브라우저 신규 탭, 2. 새로고침, 3. 백그라운드에서 재 접속 시
  // 401 Unauthorized 에러 로그 쌓이는 이슈 개선
  // 쿠키 공유되는 이슈
  try {
    if (checkWebview()) {
      // 약관 -> 새창: 로그인 유지
      const host = window.location.pathname;
      if (host.indexOf('legal') !== -1 && to.name === 'Policy') return;

      // NEO_SES가 존재하지 않을 수 있기 때문에 NEO_CHK를 삭제
      deleteCookie(NEO_CHK, '.comico.kr');

      // webview API를 1회 호출하여 NEO_SES가 있다면 NEO_CHK를 재생성하여 로그인 여부 재확인
      await store.dispatch('GlobalStore/config', 'webview');

      // 약관 -> 새창 -> 다른 페이지 이동: 로그아웃
      if (getAuth()) {
        await store.dispatch('AuthStore/foregrounded');
        if (checkTermsOfUse() && to.name !== 'Authorize') {
          await store.dispatch('AuthStore/logout');
        }
      }
    }
    // 라우터로 이동 시 로그아웃
    if (checkPageBounceAuth(to, from) && to.name !== 'Login') {
      await store.dispatch('AuthStore/logout');
    }
  } catch {
    //
  }
};

/**
 * router 최초 접근 처리
 */
const isFirstAccess = async (to, from, next) => {
  const { fullPath, name, matched } = from;
  const { contentCategory } = to.params;
  if (fullPath === '/' && name === null && matched.length === 0) {
    // 최초 접근 실행 훅
    if (contentCategory) {
      if (
        contentCategory === CONTENT_TYPES.all_comic ||
        contentCategory === CONTENT_TYPES.all_novel
      ) {
        initContentCategory(contentCategory);
      }
    }
  }
};

const catchViewerErrorCode = async (to, from, next, params) => {
  const { code, contentType, contentId } = params;
  switch (code) {
    case ERROR_CODES.CODE_428: // 19 off
      alertAcceptMature(to, from, next);
      break;
    case ERROR_CODES.CODE_401: // 비로그인
      alertGoToLogin(to, from, next);
      break;
    case ERROR_CODES.CODE_451: // 성인인증
      const { mature } = store.getters['MyPageStore/userInfo'];
      checkSignIn(getAuth())
        .then(checkCertifiAdult.bind(null, mature.adultVerification.status))
        .catch((res) => {
          if (!res.action) return;
          const { action } = res;
          switch (action) {
            case 'GOTO_LOGIN':
              alertGoToLogin(to, from, next);
              break;
            case 'NO_CERTI':
              next('/maturecertificate?redirect=Y');
              setRedirectUrl(to);
              break;
            case 'EXPIRED':
              alertExpired(to, from, next);
              break;
          }
        });
      break;
    default:
      next(`/${contentType}/${contentId}`);
      return;
  }
};

const checkUserMenuStatus = () => {
  const status = store.getters['GlobalStore/getUserMenuState'];
  status ? store.commit('GlobalStore/setUserMenuState', false) : null;
};

const checkChallengeRouting = (to: Route) => {
  const isChallengePage = to.matched[0].meta.isChallenge;
  isChallengePage
    ? document.body.classList.add('jp-challenge')
    : document.body.classList.remove('jp-challenge');
};

Router.beforeEach(async (to, from, next) => {
  try {
    await isFirstAccess(to, from, next);
    await specKoreaFn(to, from);
  } catch {
    //
  }
  if (/^\/comment\//.test(to.path)) {
    Comment.isCommentPage = true;
  } else {
    Comment.isCommentPage = false;
  }
  if (/^(\/anniversary\/|\/campaign\/)/.test(to.path)) {
    Campaign.is_entered = true;
  } else {
    Campaign.is_entered = false;
  }
  if (checkUserInfo()) {
    if (!Campaign.is_entered) {
      await store.dispatch('MyPageStore/getUserInfo');
    }
  }
  if (
    isIE() &&
    !to.matched.some((routeInfo) => {
      return routeInfo.meta.isGuidePage;
    })
  ) {
    next({ name: 'IEBrowserGuide' });
  } else {
    store.commit('GlobalStore/setCurrentPath', to.fullPath);
    if (
      to.matched.some((routeInfo) => {
        return routeInfo.meta.authRequired;
      })
    ) {
      if (getAuth()) {
        return next();
      }
      // next({ name: 'Login', query: { redirect: to.path } });
      // next: Redirected when going from to via navigation guard
      Router.push({ name: 'Login', query: { redirect: to.path } }).catch(() => {
        // same url route
      });
    } else {
      next();
    }
  }
});

Router.afterEach(async (to, from) => {
  comicoLcsBip(to, from);
  checkUserMenuStatus();
  // if (process.env.VUE_APP_LANGUAGE === 'ja') {
  //   checkChallengeRouting(to);
  // }
});

const checkMatureChapter = async (mature: boolean, { to, from, next }) => {
  if (
    process.env.VUE_APP_LANGUAGE !== 'ko' &&
    mature
  ) {
    await checkBeforeEnterJpAdult(to, from, next);
  }
}

export default Router;
