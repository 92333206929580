
















import { defineComponent } from '@vue/composition-api';
import { LANGUAGE_TYPES } from '@/commons/constants/config-language';

export default defineComponent({
  name: 'Copyright',
  props: {
    lang: String,
    copyright: String
  },
  setup() {
    return { LANGUAGE_TYPES };
  }
});
