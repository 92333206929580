import { render, staticRenderFns } from "./UseCoinConfirmDialog.vue?vue&type=template&id=9e3e9dae&scoped=true&"
import script from "./UseCoinConfirmDialog.vue?vue&type=script&lang=ts&"
export * from "./UseCoinConfirmDialog.vue?vue&type=script&lang=ts&"
import style0 from "./UseCoinConfirmDialog.vue?vue&type=style&index=0&id=9e3e9dae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e3e9dae",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard});
