import { createAxiosService } from '@/services/common/create-axios.service';

const axiosService = createAxiosService();

const getPincrux = async (os: string) => {
  const url = `/offerwall/pincrux/chargeview?client=${os}`;
  const { data } = await axiosService.get(url).then((res) => res);
  return data;
};

const pincruxAdInfo = async () => {
  const url = `/offerwall/pincrux/adinfo`;
  const { data } = await axiosService.post(url).then((res) => res);
  return data;
};

const getPointPark = async () => {
  const url = `/offerwall/pointpark/init`;
  const { data } = await axiosService.post(url).then((res) => res);
  return data;
};

const getPointParkResult = async (token: string) => {
  const url = `/offerwall/pointpark/${token}`;
  const {
    data: { data }
  } = await axiosService.get(url).then((res) => res);
  return data;
};

const pointParkCancel = async (dealId: string) => {
  const url = `/offerwall/pointpark/cancel/${dealId}`;
  const { data } = await axiosService.put(url).then((res) => res);
  return data;
};

export const offerwallApi = {
  getPincrux,
  getPointPark,
  pincruxAdInfo,
  getPointParkResult,
  pointParkCancel
};
