





import { lineBreak } from '@/services';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Synopsis',
  props: {
    synopsis: String // html
  },
  setup(props) {
    const description = computed(() => {
      try {
        const origin: string = props.synopsis ? props.synopsis : '';
        return lineBreak(origin);
      } catch (e) {
        return props.synopsis || '';
      }
    });
    return {
      description
    };
  }
});
