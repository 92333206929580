































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
// import SimpleThumbnail from '@/components/molecules/contents-elements/SimpleThumbnail.vue';
import BasicContentCard from '@/components/molecules/contents-card/BasicContentCard.vue';
import RankingContentCard from '@/components/molecules/contents-card/RankingContentCard.vue';
import { CONTENT_CARD_TYPE } from '@/commons/constants/content.types';

export default defineComponent({
  name: 'ContentListModuleLibrary',
  components: {
    // SimpleThumbnail,
    BasicContentCard,
    RankingContentCard
  },
  props: {
    contentList: Array,
    contentCardType: String,
    selectedItem: String
  },
  setup(props, { emit }) {
    const cl = computed(() => props.contentList);
    const list = ref(cl.value);
    const createUniqueKey = (contentId: number) =>
      `${props.selectedItem}_${contentId}`;

    const sendHiddenItem = (contentInfo) => {
      emit('emitHiddenAction', contentInfo);
    };

    watch(cl, (data) => {
      list.value = data;
    });

    return {
      list,
      createUniqueKey,
      sendHiddenItem,
      CONTENT_CARD_TYPE
    };
  }
});
