import { EventInfo, EventDetailInfo } from '@/models/index';
import { createAxiosService } from '../common/create-axios.service';
const axiosService = createAxiosService();

export const getEvents = (contentCategory: string) => {
  return new Promise<EventInfo>((resolve) => {
    const url = `/${contentCategory}/event`;
    return axiosService.get(url).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

export const getEventsWithUrl = (url: string) => {
  return new Promise<EventInfo>((resolve) => {
    return axiosService.get(url).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};

export const getEventDetail = (
  { contentCatalogId, prefix },
  pageOption?: { pageNo: number; pageSize: number }
) => {
  return new Promise<EventDetailInfo>((resolve) => {
    const url = `/${prefix}/${contentCatalogId}`;
    const params = pageOption
      ? `?pageNo=${pageOption.pageNo}&pageSize=${pageOption.pageSize}`
      : '';
    return axiosService.get(`${url}${params}`).then((res) => {
      const { data } = res;
      resolve(data.data);
    });
  });
};


export const EventService = {
  getEvents,
  getEventDetail,
  getEventsWithUrl,
};
