

























import { ref, defineComponent } from '@vue/composition-api';

/**
 * ex)
 * 로그인 정보가 만료되었습니다. 다시 로그인해주세요.
 * 선택하신 상품은 부족한 코인 수량보다 적습니다.
 * 참여 정보 제출이 완료되었습니다. 원하시는 경품에 다시 응모해 주세요.
 * 다른 단말에서 열람하여 구매취소가 불가능합니다.
 */
export default defineComponent({
  name: 'AlertDialog',
  props: {
    componentProps: {}
  },
  setup(props, { emit }) {
    const dialog = ref(false);
    const show = () => {
      dialog.value = true;
    };
    const hide = () => {
      dialog.value = false;
    };
    const onDialogHide = () => {
      emit('hide');
    };
    const onOKClick = () => {
      emit('ok', {
        value: true
      });
      hide();
    };

    return {
      dialog,
      show,
      hide,
      onDialogHide,
      onOKClick
    };
  }
});
