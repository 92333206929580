





















































































































































import {
  ref,
  Ref,
  reactive,
  computed,
  defineComponent,
  ComputedRef
} from '@vue/composition-api';
import router from '@/router';
import Store from '@/store/index';
import RSA from '@/services/rsa.js';
import Container from '@/views/login/Container.vue';
import InputDeleteButton from '@/components/atoms/buttons/InputDeleteButton.vue';
import ChkDefault from '@/components/atoms/inputs/ChkDefault.vue';
import { t, i18nWrapRuby } from '@/commons/i18n/index';
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import { ValidationLogin, ValidateCheked } from '@/models';
import { isEmptyObject, validateCheked } from '@/services/common/utils';
import { encryptRsa, disabledElm, sendNclick } from '@/services';
import { ERROR_CODES } from '@/commons/constants/error-codes';
import { LANGUAGE_TYPES } from '@/commons/constants/config-language';

export default defineComponent({
  name: 'LoginEmail',
  components: {
    Container,
    InputDeleteButton,
    ValidationProvider,
    ValidationObserver,
    ChkDefault
  },
  setup(props, { root }) {
    const { dispatch, getters, commit } = Store;
    const route = root.$route;
    const { redirect } = route.query;
    const btnSubmit: Ref<HTMLElement | null> = ref(null);
    const errorMsg: Ref<ValidationLogin> = ref({ email: '', password: '' });
    const initFormData = { email: '', password: '' };
    const formData = ref({ ...initFormData });
    const refs = ref({
      rsaKeys: computed(() => getters['AuthStore/getRsaKeys']),
      getAuthErrInfo: computed(() => getters['AuthStore/getAuthErrInfo']),
      userInfo: computed(() => getters['MyPageStore/userInfo'])
    });
    const getExternalIdpUrl = (loginType: string) =>
      dispatch('SocialLoginStore/getExternalIdpUrl', loginType);
    const externalIdp = computed(() => getters['SocialLoginStore/externalIdp']);
    const currentRouter = router.currentRoute.query.redirect || '/';
    const isJp = computed(
      () => getters['I18nStore/getCookieLanguage'] === LANGUAGE_TYPES.JA_JP
    );
    const flags = reactive({
      email: false,
      password: false
    });
    const isStayLogged: Ref<boolean> = ref(true);

    /** 유효성 검증 */
    const validateFrom = async () => {
      const opt: ValidateCheked = {
        validate,
        entriesArray: formData.value,
        schema: {
          email: 'requiredEmail|invalidEmailAddress:true',
          password: 'requiredPassword|invalidWitespace|invalidPassword:true'
        }
      };
      const resArray = await validateCheked(opt);
      errorMsg.value = resArray as ValidationLogin;
      return isEmptyObject(resArray) ? false : true;
    };

    /** RSA 암호화 */
    const encryptoRSA = () => {
      try {
        const rsa = new RSA.RSAKey();
        rsa.setPublic(
          refs.value.rsaKeys.publicKeyModulus,
          refs.value.rsaKeys.publicKeyExponent
        );
        const values = {
          key: refs.value.rsaKeys.sessionKey,
          char1: formData.value.email,
          char2: formData.value.password
        };
        return rsa.encrypt(encryptRsa(rsa.getLenChar, values));
      } catch (err) {
        console.error(`LoginEmail encryptoRSA err ${err}`);
        return null;
      }
    };

    const onLogin = async (api: string, isStayLogged?: boolean) => {
      const enc = encryptoRSA();
      if (!enc) return;
      const auth = {
        email: formData.value.email,
        sessionKey: refs.value.rsaKeys.sessionKey,
        rsaEncryptedString: enc
      };
      if (isStayLogged) auth['stayLogged'] = isStayLogged;
      return await dispatch(`AuthStore/${api}`, auth);
    };

    /** 휴면 회원 비밀번호 확인 */
    const onDormant = async () => {
      try {
        await dispatch('AuthStore/GET_RSA_KEYS', 'CHECK_PASSWORD');
        const res = await onLogin('checkDormantPassword');
        if (res?.result?.code === ERROR_CODES.CODE_200) {
          const neoIdUid = res.data.dormant.neoIdUid;
          if (!neoIdUid || neoIdUid.length === 0) return;

          commit('AuthStore/setDormantStatus', {
            status: true,
            neoIdUid: neoIdUid,
            responseTokenType: formData.value.email
          });
          router.replace({
            name: 'Authorize',
            params: { nextPage: 'true', neoIdUid },
            query: { redirect: redirect }
          });
        }
      } catch {
        //
      }
    };

    /** 로그인 */
    const handleSubmit = async () => {
      const isValidateErr = await validateFrom();
      if (isValidateErr) return;
      try {
        disabledElm(btnSubmit.value, true);
        await dispatch('AuthStore/GET_RSA_KEYS', 'LOGIN');
        const res = await onLogin('LOGIN', isStayLogged.value);
        if (res?.result?.code === ERROR_CODES.CODE_200) {
          const userId: number = res.data.authentication.userId;
          Object.assign(formData.value, initFormData);
          router.replace({
            name: 'Authorize',
            params: { nextPage: 'true' },
            query: { redirect: redirect }
          });
        } else if (res?.result?.code === ERROR_CODES.CODE_410) {
          onDormant();
        }
        sendNclick(router.currentRoute.path, 'account.signin', '', '', '');
        sendNclick(
          router.currentRoute.path,
          'account.signin.email',
          '',
          '',
          ''
        );
      } catch (err) {
        let errMsg: string
        if (err instanceof Error) {
          errMsg = err.message
        } else if (typeof err === 'string') {
          errMsg = err
        } else {
          errMsg = ''
        }
        sendNclick(
          router.currentRoute.path,
          'account.signin.error',
          '',
          '',
          errMsg
        );
      } finally {
        disabledElm(btnSubmit.value, false);
      }
    };

    const onSingup = () => {
      router.push('/signup');
    };

    const externalIdpConnect = (loginType: string) => {
      sendNclick(
        router.currentRoute.path,
        'account.signin.' + (loginType === 'appleid' ? 'apple' : loginType),
        '',
        '',
        ''
      );
      sessionStorage.setItem('redirectUrl', currentRouter.toString());
      getExternalIdpUrl(loginType).then((res) => {
        document.location.href = res;
      });
    };

    const sendChecked = (value: boolean) => (isStayLogged.value = value);

    const replaseTermsLink = (text: string): string => {
      let regTermsOfUse = new RegExp(t('terms_of_use'), "g");
      let regPrivacyPolicy = new RegExp(t('privacy_policy'), "g");
      text = text.replace(regTermsOfUse, "<a style='color: #adb5bd; border-bottom:1px solid;' href='/legal/terms_of_use' target='_blank'>$&</a>");
      return text.replace(regPrivacyPolicy, "<a style='color: #adb5bd; border-bottom:1px solid;' href='/legal/privacy_policy' target='_blank'>$&</a>");
    };

    return {
      t,
      i18nWrapRuby,
      formData,
      errorMsg,
      handleSubmit,
      onSingup,
      externalIdpConnect,
      btnSubmit,
      flags,
      isJp,
      isStayLogged,
      sendChecked,
      replaseTermsLink
    };
  }
});
