import { Chapter, ContentInfo } from './content-info';
import { Banner, ContentModel, Promotion } from './contents-home-info';
import { Section } from './home-info';

export interface SliderInfo {
  type: 'absolute' | 'ratio';
  current: number;
  max: number;
}
export interface ViewerSettingInfo {
  fontType: number;
  fontSize: number;
  lineHeight: number;
  color: number;
}

export interface NovelContentsTable {
  label: string;
  chapter: number;
  id: string;
  children: Array<number>;
  href: string;
}

export interface NovelContinueInfo {
  href: string;
  type: string;
  progression: number;
}

export class ViewerInfo extends ContentModel {
  chapter: Chapter;
  recommends?: Section[];
  banners?: Banner[];
  promotions?: Promotion[];

  constructor(props: ViewerInfo) {
    super(props);
    this.chapter = new Chapter(props.chapter);
    this.recommends = props.recommends
      ? props.recommends.map(node => new Section(node))
      : [];
    this.banners = props.banners ? props.banners : [];
    this.promotions = props.promotions
      ? props.promotions.map(node => new Promotion(node))
      : [];
  }
}