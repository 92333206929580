

















import { computed, ComputedRef, defineComponent, watch } from '@vue/composition-api';
import ToCanvas from '@/components/atoms/ToCanvas.vue';
import { Encrypto } from '@/services/common/encrypto.service';
import { ChapterImage, ContentInfo } from '@/models';

export default defineComponent({
  name: 'ViewersImageModule',
  components: {
    ToCanvas
  },
  props: {
    content: Object,
    chapter: Object,
    images: Array
  },
  setup(props, { emit }) {
    const contentInfo: ComputedRef<ContentInfo> = computed(
      () => props.content as ContentInfo
    );
    const imagesArr: ComputedRef<ChapterImage[]> = computed(
      () => props.images as ChapterImage[]
    );
    const decode = (image: ChapterImage) => {
      const { parameter, url } = image;
      const imgUrl = Encrypto.AESDecoder(url, Encrypto.AESKey);
      return `${imgUrl}?${parameter}`;
    };

    let loadCount = 0;
    let isErrorLoad = false;
    let totalHeight = 0;

    /**
     * 이미지 로딩이 모두 완료되면
     */
    const sendLoadCount = ({ height }: { height: number }) => {
      totalHeight += height;
      loadCount += 1;
      if (loadCount === imagesArr.value.length) {
        if (!isErrorLoad) {
          emit('emitRead', {
            totalHeight
          });
          resetValue();
        }
      }
    };

    /**
     * 오류가 발생하면
     */
    const sendErrorLoad = () => {
      if (!isErrorLoad) {
        emit('emitRead', {});
      }
      isErrorLoad = true;
    };

    /**
     * 컨텐츠 변경시 값 초기화
     */
    const resetValue = () => {
      isErrorLoad = false;
      totalHeight = 0;
      loadCount = 0;
    };

    return {
      contentInfo,
      imagesArr,
      decode,
      sendLoadCount,
      sendErrorLoad
    };
  }
});
