













import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import store from '@/store';
import { Element, Section } from '@/models';
import { getForwardPath } from '@/services/contents/contents.helper';
import { gotoLinkPage } from '@/services';

export default defineComponent({
  name: 'BannerImageSection',
  props: {
    section: Section
  },
  setup(props) {
    const { getters } = store;
    const isWide: ComputedRef<boolean> = computed(
      () => getters['UIStore/isWide']
    );
    const sectionInfo: ComputedRef<Section | undefined> = computed(
      () => props.section as Section
    );
    const elements: ComputedRef<Element[]> = computed(() =>
      sectionInfo.value && sectionInfo.value.elements
        ? sectionInfo.value.elements
        : []
    );
    const selectedBanner: ComputedRef<Element> = computed(
      () => elements.value[0]
    );

    const onBannerClick = (element: Element) => {
      try {
        if (!element.urlHttp) return;
        const { internal, path } = getForwardPath(element.urlHttp);
        if (internal) {
          gotoLinkPage(path);
        } else {
          window.location.href = path;
        }
      } catch (e) {
        //
      }
    };
    return {
      isWide,
      elements,
      selectedBanner,
      onBannerClick
    };
  }
});
