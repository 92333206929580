
























import { computed, ComputedRef, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'TabEpisodeVolume',
  props: {
    selected: {
      default: 'EPISODE',
      type: String
    }
  },
  setup(props, { emit }) {
    const enable: ComputedRef<string> = computed(() => props.selected);
    const TABS = {
      EPISODE: 'EPISODE',
      VOLUME: 'VOLUME'
    };
    const onTabClick = (tab: 'EPISODE' | 'VOLUME') => emit('emitChangeTab', tab);
    return {
      enable,
      TABS,
      onTabClick
    };
  }
});
