













import { defineComponent } from '@vue/composition-api';
import router from '@/router/index';
import { sendNclick } from '@/services';

export default defineComponent({
  name: 'ComicoBi',
  setup(props, { root, emit }) {
    const route = root.$route;
    const gotoHome = () => {
      sendNclick('globalmenu', 'global.menu', '', '', 'home');
      if (route.path === '/') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        emit('onHome');
        return;
      }
      router.push(`/`).catch((e) => {
        // catch router
      });
    };
    return {
      gotoHome
    };
  }
});
