import { ERROR_CODES } from '@/commons/constants/error-codes';
import { HttpResponse } from '@/commons/constants/http.types';
import { ViewerInfo, NovelContinueInfo } from '@/models/viewer-info';
import { AxiosResponse } from 'axios';
import { createAxiosService } from '../common/create-axios.service';
const axiosService = createAxiosService();

export const RENT_WITH_COIN = 'rent-with-coin';
export const RENT_WITH_TICKET = 'rent-with-ticket';
export const RENT_WITH_GAUGE = 'rent-with-gauge';

export function getChapter({
  contentType,
  contentId,
  chapterId,
  chapterFileSalesType
}: {
  contentType: string;
  contentId: number;
  chapterId: number;
  chapterFileSalesType: 'trial' | 'product';
}): Promise<ViewerInfo> {
  return new Promise<ViewerInfo>((resolve, reject) => {
    const url = `/${contentType}/${contentId}/chapter/${chapterId}/${chapterFileSalesType}`;
    return axiosService.get(url).then((res) => {
      const response: HttpResponse = res.data;
      if (response.result.code < 300) {
        resolve(response.data);
      } else {
        reject({
          result: response.result,
          data: response.data
        });
      }
    });
  });
}

export const unlock = ({
  contentType,
  contentId,
  chapterId,
  params
}: {
  contentType: string;
  contentId: number;
  chapterId: number;
  params: Record<string, string>;
}): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const url = `/${contentType}/${contentId}/chapter/${chapterId}/unlock`;
    return axiosService
      .post(url, new URLSearchParams(params))
      .then((res: AxiosResponse) => {
        const response: HttpResponse = res.data;
        if (response.result.code < 300) {
          resolve();
        } else {
          reject({
            result: response.result,
            data: response.data
          });
        }
      });
  });
};

export const unlockAll = ({
  contentType,
  contentId,
  params
}: {
  contentType: string;
  contentId: number;
  params: Record<string, string>;
}) => {
  return new Promise((resolve, reject) => {
    const url = `/${contentType}/${contentId}/chapter/unlock-all`;
    return axiosService
      .post(url, new URLSearchParams(params))
      .then((res: AxiosResponse) => {
        const response: HttpResponse = res.data;
        if (response.result.code === 200) {
          resolve(response.result);
        } else {
          reject({
            result: response.result,
            data: response.data
          });
        }
      });
  });
};

export const rentWith = (
  type: string,
  {
    contentType,
    contentId,
    chapterId,
    params
  }: {
    contentType: string;
    contentId: number;
    chapterId: number;
    params: Record<string, string>;
  }
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const url = `/${contentType}/${contentId}/chapter/${chapterId}/${type}`;
    return axiosService
      .post(url, new URLSearchParams(params))
      .then((res: AxiosResponse) => {
        const response: HttpResponse = res.data;
        switch (response.result.code) {
          case ERROR_CODES.CODE_200:
          case ERROR_CODES.CODE_208:
            resolve();
            break;
          default:
            reject({
              result: response.result,
              data: response.data
            });
            break;
        }
      });
  });
};

export const read = (
  {
    contentType,
    contentId,
    chapterId
  }: {
    contentType: string;
    contentId: number;
    chapterId: number;
  },
  finished?: boolean,
  continueInfo?: FormData
): Promise<void> => {
  return new Promise<void>((resolve) => {
    const url = `/${contentType}/${contentId}/chapter/${chapterId}/read/${finished ? 'finished' : ''
      }`;
    return axiosService.post(url, continueInfo).then((res: AxiosResponse) => {
      const result: HttpResponse = res.data;
      if (result.result.code === 200) {
        resolve();
      }
    });
  });
};

export const reportChapter = ({
  contentType,
  contentId,
  chapterId,
  category,
  issue
}: {
  contentType: string;
  contentId: number;
  chapterId: number;
  category: string;
  issue?: string;
}): Promise<boolean> => {
  return new Promise<any>((resolve, reject) => {
    const url = `/${contentType}/${contentId}/chapter/${chapterId}/report/${category}`;
    const params = new URLSearchParams();
    params.append('issue', issue ? issue : '');
    return axiosService.post(url, params).then((res: AxiosResponse) => {
      const { data, result } = res.data;
      if (result.code < 300) {
        resolve(data);
      } else {
        reject(result);
      }
    });
  });
};

export const getPreviewInfo = async (previewInfo: {
  params: {
    contentType: string;
    contentId: number;
  };
  query: {
    languageId: number;
    chapterId: number;
    expiredAt: string;
    checkSum: string;
    chapterFileSalesType: string;
  };
}) => {
  const { params, query } = previewInfo;

  const checkSum =
    process.env.VUE_APP_PHASE === 'LOCAL' ? 'skipChecksum' : query.checkSum;

  const url = `/${params.contentType}/${params.contentId}/chapter/epub?languageId=${query.languageId}&chapterId=${query.chapterId}&expiredAt=${query.expiredAt}&checkSum=${checkSum}&chapterFileSalesType=${query.chapterFileSalesType}`;
  return await axiosService.get(url).then((res) => res.data.data.content);
};

export const ChaptersApiService = {
  getChapter,
  unlock,
  unlockAll,
  rentWith,
  read,
  reportChapter,
  getPreviewInfo
};
