import { Comment } from '@/models/comment-info';

/**
 * 신고된 댓글 필터링
 * @param comments
 * @returns
 */
export const removeReportedComment = (comments: Comment[]) =>
  comments.filter((node) => !node.reaction.reported);

export const countComments = (
  commentsLen: number | undefined
): number | string => {
  if (!commentsLen) return '';
  if (commentsLen > 999) return '999+';
  return commentsLen;
};

export const CommentHelperService = {
  removeReportedComment,
  countComments
};
