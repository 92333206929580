export const ERROR_CODES = {
  CODE_200: 200, // OK
  CODE_208: 208, // Already Reported
  CODE_301: 301, // Moved Permanently
  CODE_303: 303, // See Other
  CODE_304: 304, // Not Modified
  CODE_400: 400, // Bad Request
  CODE_401: 401, // Unauthorized
  CODE_402: 402, // Payment Required
  CODE_403: 403, // Forbidden
  CODE_404: 404, // Not Found
  CODE_408: 408, // Request Timeout
  CODE_409: 409, // Conflict
  CODE_410: 410, // DormantAccount
  CODE_417: 417, // Expectation Failed
  CODE_426: 426, // Upgrade Required
  CODE_428: 428, // 성인 on/off 권한
  CODE_429: 429, // Too Many Requests
  CODE_500: 500, // Internal Server Error
  CODE_503: 503, // Service Unavailabe
  CODE_451: 451 // 성인 인증
};

export const ResponseReturnCode: number[] = [
  ERROR_CODES.CODE_200,
  ERROR_CODES.CODE_208,
  ERROR_CODES.CODE_303,
  ERROR_CODES.CODE_404,
  ERROR_CODES.CODE_428,
  ERROR_CODES.CODE_451
];
