










import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'pageTitle',
  props: {
    title: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      require: true
    }
  },
  setup(props) {
    return {};
  }
});
